import { useContext, useState, useRef } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiPanel,
  EuiForm,
  EuiFormRow,
  EuiSelect,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiDatePicker,
  EuiSpacer,
  EuiOverlayMask,
  EuiLoadingSpinner,
  EuiText,
} from '@elastic/eui';
import moment from 'moment';

import UserContext from 'contexts/UserContext';
import { Property, Client } from 'types/property';
import { Player, Screen } from 'types/screen';
import { Media } from 'types/media';
import { getClientList, getPropertyList } from 'apis/Corps/property';
import { getScreenList } from 'apis/Billboard/screen';
import { getPreRunSchedule } from 'apis/Reporting/playlog';
import MediaSelector from 'pages/Asset/AssetEditor/LayerEditor/MediaSelector';
import PreRunScheduleTable from './PreRunScheduleTable';
import useReportExport from 'hooks/useReportExport';

const PreRunSchedule = () => {
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['report', 'common']);

  const [date, setDate] = useState(moment());
  const [property, setProperty] = useState<string>();
  const [client, setClient] = useState<string>();
  const [screen, setScreen] = useState<string>();
  const [media, setMedia] = useState<Media>();
  const [playLog, setPlayLog] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const reportRef = useRef<any>();

  const propertiesQuery = useQuery<Property[]>(
    ['property', { organizationId: userContext.currentOrganization?.id }],
    getPropertyList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const clientsQuery = useQuery<Client[]>(
    ['property-clients', { propertyId: property }],
    getClientList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!property,
    }
  );

  const screensQuery = useQuery(
    ['screens', { organizationId: userContext.currentOrganization?.id }],
    getScreenList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const getReportMutaion = useMutation(getPreRunSchedule, {
    onError: () => {
      setIsFetching(false);
    },
    onSuccess: data => {
      setIsFetching(false);
      setPlayLog([
        ...data.results.map((d: any) => ({
          ...d,
          datetime: moment
            .utc(d.datetime)
            .local()
            .format('YYYY-MM-DD HH:mm:ss'),
        })),
      ]);
    },
  });

  const handleSubmit = () => {
    setIsFetching(true);
    getReportMutaion.mutate({
      screen: screen,
      start_at: moment(date).startOf('day').local().utc().format(),
      media: media?.id || '',
      property,
      client,
    });
  };

  return (
    <EuiPanel>
      <EuiForm>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFormRow label={t('Date')}>
              <EuiDatePicker
                name="date"
                selected={date}
                onChange={v => setDate(v || moment())}
                aria-label="Date"
                minDate={moment()}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label={t('Property')} fullWidth>
              <EuiSelect
                fullWidth
                onChange={e => setProperty(e.target.value)}
                value={property}
                options={
                  propertiesQuery.isSuccess
                    ? [
                        { text: t('All Property'), value: '' },
                        ...propertiesQuery.data.map((property: Property) => {
                          return {
                            text: property.name,
                            value: property.id,
                          };
                        }),
                      ]
                    : [{ text: t('All Property'), value: '' }]
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label={t('common:Client')} fullWidth>
              <EuiSelect
                fullWidth
                onChange={e => setClient(e.target.value)}
                value={client}
                options={
                  clientsQuery.isSuccess
                    ? [
                        { text: t('All Clients'), value: '' },
                        ...clientsQuery.data.map((client: Client) => {
                          return {
                            text: client.name || `Client #${client.id}`,
                            value: client.id,
                          };
                        }),
                      ]
                    : [{ text: t('All Clients'), value: '' }]
                }
                disabled={property == undefined}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label={t('common:Screen')} fullWidth>
              <EuiSelect
                fullWidth
                onChange={e => setScreen(e.target.value)}
                value={screen}
                options={
                  screensQuery.isSuccess
                    ? [
                        { text: t('All Screens') },
                        ...screensQuery.data.map((screen: Screen) => {
                          return {
                            text: screen.name,
                            value: screen.id,
                          };
                        }),
                      ]
                    : [{ text: t('All Screens') }]
                }
              />
            </EuiFormRow>
          </EuiFlexItem>

          <EuiFlexItem>
            <MediaSelector
              selectedMedia={media}
              onChange={(media: Media) => setMedia(media)}
              onClear={() => setMedia(undefined)}
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiButton
              fill
              disabled={!parseInt(screen || '0') || !client}
              onClick={handleSubmit}
            >
              {t('Query')}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />
        {isFetching && (
          <EuiOverlayMask>
            <EuiLoadingSpinner />
          </EuiOverlayMask>
        )}
        <div ref={reportRef}>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiText>
                <h2>{t('Pre-Run Schedule')}</h2>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiButton
                    disabled={playLog.length === 0}
                    onClick={useReportExport(
                      reportRef.current,
                      'word',
                      'Pre Run Schedule'
                    )}
                  >
                    {t('Export Word')}
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiButton
                    disabled={playLog.length === 0}
                    onClick={useReportExport(reportRef.current, 'print')}
                  >
                    {t('Print')}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
          {playLog.length > 0 && <PreRunScheduleTable log={playLog} />}
        </div>
      </EuiForm>
    </EuiPanel>
  );
};

export default PreRunSchedule;
