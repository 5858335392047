import { callApi } from 'utils/fetchApi';

const getLiveFeedList = async ({ queryKey }: any) => {
  const [_key, { organizationId, pageIndex, keyword, q = {} }] = queryKey;
  const qs = Object.keys(q)
    .map(key => (q[key] !== undefined ? `${key}=${q[key]}` : ''))
    .join('&');

  const response = await callApi({
    method: 'GET',
    path:
      `/scheduler/live_feed?` +
      `${organizationId ? `&organization=${organizationId}` : ''}` +
      `${keyword ? `&search=${keyword}` : ''}` +
      `&${qs}`,
    withCredentials: true,
  });

  return await response.data;
};

export { getLiveFeedList };
