import { useState, useEffect, useContext } from 'react';
import {
  EuiEmptyPrompt,
  EuiForm,
  EuiText,
  EuiTextColor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiFieldText,
  EuiFieldNumber,
  EuiButton,
  EuiIconTip,
  EuiIcon,
} from '@elastic/eui';
import axios from 'axios';
import ToastContext from 'contexts/ToastContext';

import CenteredContentPage from 'layouts/CenteredContentPage';
import NotFound from 'pages/404';
import { number } from 'prop-types';

declare var window: {
  apiCredentials: string;
  player: any;
  ipcRenderer: any;
  storagePath: string;
  api: any;
};

const PlayerSetup = () => {
  const toastContext = useContext(ToastContext);
  const [name, setName] = useState<string>(
    `My Player ${Math.floor(Math.random() * 999)}`
  );
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [storagePath, setStoragePath] = useState<string>();
  const [xErrors, setXErrors] = useState<Array<string>>([]);
  const [yErrors, setYErrors] = useState<Array<string>>([]);
  const [widthErrors, setWidthErrors] = useState<Array<string>>([]);
  const [heightErrors, setHeightErrors] = useState<Array<string>>([]);
  const [player, setPlayer] = useState<any>(window.player);

  const handleCreatePlayer = async () => {
    let isValid = true;
    if (!width || width <= 0 || width > 9600) {
      setWidthErrors(['Width must between 1 to 9600']);
      isValid = false;
    }
    if (!height || height <= 0 || height > 9600) {
      setHeightErrors(['Height must between 1 to 9600']);
      isValid = false;
    }
    if (!isValid) return;

    let config: any = {
      url: player.id
        ? `${process.env.REACT_APP_API_URL}/billboard/player/${player.id}`
        : `${process.env.REACT_APP_API_URL}/billboard/player`,
      method: player.id ? 'PATCH' : 'POST',
      headers: {
        authorization: `Bearer ${window.apiCredentials}`,
      },
      data: {
        config: { x, y },
        name,
        width,
        height,
        pair_code: Math.floor(Math.random() * 999999),
      },
    };

    const response = await axios(config);
    setPlayer(response.data);
    toastContext.setToasts([
      ...toastContext.toasts,
      {
        id: 'player-config-updated',
        title: `Player config updated successfully!`,
        color: 'success',
      },
    ]);
    !player.id &&
      window.ipcRenderer.sendSync('player-created', { ...response.data, x, y });
  };

  useEffect(() => {
    if (!window.apiCredentials) {
      return;
    }
    const key = window.api.onSelectedStorageFolder((path: string) => {
      setStoragePath(path);
    });
    return () => window.api.removeSelectedStorageFolderHandler(key);
  }, []);

  useEffect(() => {
    if (!window.apiCredentials) {
      return;
    }
    if (player.id) {
      setWidth(player.width);
      setHeight(player.height);
      const { x: configX, y: configY, ..._ } = player?.config || {};
      setX(configX || player.x || 0);
      setY(configY || player.y || 0);
    } else {
      window.api.getScreenSize();
      const key = window.api.onGotScreenSize(
        ({ width, height }: { width: number; height: number }) => {
          setWidth(width);
          setHeight(height);
        }
      );
      return () => window.api.removeGotScreenHandler(key);
    }
  }, [
    player?.height,
    player?.id,
    player?.width,
    player?.x,
    player?.y,
    player?.config,
  ]);

  useEffect(() => {
    if (!window.apiCredentials) {
      return;
    }
    if (!window.storagePath) {
      window.api.getAppPath('exe');
    } else {
      setStoragePath(window.storagePath);
    }
    const key = window.api.onGotAppPathExe((path: string) => {
      setStoragePath(`${path}/Assets`);
    });
    return () => window.api.removeGotAppPathExeHandler(key);
  }, []);

  useEffect(() => {
    if (player?.id) setName(player.name || '');
  }, [player?.id, player?.name]);

  if (!window.apiCredentials) {
    return <NotFound />;
  }

  return (
    <CenteredContentPage>
      <EuiEmptyPrompt
        title={<h2>Setup Your GOTIGER Player</h2>}
        body={
          <>
            {player && player.pair_code ? (
              <EuiText>
                <h3>Your Pair Code:</h3>
                <EuiTextColor color="secondary">
                  <h1>{player.pair_code}</h1>
                </EuiTextColor>
                <p style={{ marginTop: 8 }}>
                  Input the pair code to GOTIGER Scheduler <br />
                  or <br />
                  <a href="#">Sign In</a> to setup GOTIGER player
                </p>
              </EuiText>
            ) : (
              <EuiForm component="form">
                <EuiFlexGroup direction="column">
                  <EuiFlexItem>
                    <EuiFormRow fullWidth label="Player Name">
                      <EuiFieldText
                        fullWidth
                        name="name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>

                  <EuiFlexItem>
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiFormRow
                          fullWidth
                          label="Player Width"
                          isInvalid={widthErrors.length > 0}
                          error={widthErrors}
                        >
                          <EuiFieldNumber
                            fullWidth
                            name="width"
                            value={width}
                            isInvalid={widthErrors.length > 0}
                            onChange={e => setWidth(parseInt(e.target.value))}
                          />
                        </EuiFormRow>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiFormRow
                          fullWidth
                          label="Player Height"
                          isInvalid={heightErrors.length > 0}
                          error={heightErrors}
                        >
                          <EuiFieldNumber
                            fullWidth
                            name="height"
                            value={height}
                            isInvalid={heightErrors.length > 0}
                            onChange={e => setHeight(parseInt(e.target.value))}
                          />
                        </EuiFormRow>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>

                  <EuiFlexItem>
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiFormRow
                          fullWidth
                          label="X Position"
                          isInvalid={xErrors.length > 0}
                          error={xErrors}
                        >
                          <EuiFieldNumber
                            fullWidth
                            name="x"
                            value={x}
                            isInvalid={xErrors.length > 0}
                            onChange={e => setX(parseInt(e.target.value))}
                            append={
                              <EuiIconTip content="Left boundary offset of player" />
                            }
                          />
                        </EuiFormRow>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiFormRow
                          fullWidth
                          label="Y Position"
                          isInvalid={yErrors.length > 0}
                          error={yErrors}
                        >
                          <EuiFieldNumber
                            fullWidth
                            name="y"
                            value={y}
                            isInvalid={yErrors.length > 0}
                            onChange={e => setY(parseInt(e.target.value))}
                            append={
                              <EuiIconTip content="Top boundary offset of player" />
                            }
                          />
                        </EuiFormRow>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>

                  <EuiFlexItem>
                    <EuiFormRow fullWidth label="Storage Location">
                      <EuiFieldText
                        fullWidth
                        name="storagePath"
                        value={storagePath}
                        onChange={e => setStoragePath(e.target.value)}
                        readOnly
                        onClick={() => window.api.openStorageFolderSelector()}
                        append={
                          <EuiIcon
                            type="boxesHorizontal"
                            onClick={() =>
                              window.api.openStorageFolderSelector()
                            }
                          />
                        }
                      />
                    </EuiFormRow>
                  </EuiFlexItem>

                  <EuiFlexItem>
                    <EuiButton onClick={handleCreatePlayer}>
                      {player?.id ? 'Save' : 'Continue'}
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiForm>
            )}
          </>
        }
      />
    </CenteredContentPage>
  );
};

export default PlayerSetup;
