import { callApi } from 'utils/fetchApi';

const getDailyReport = async (query: any) => {
  const qs = Object.keys(query)
    .map(key => (query[key] !== undefined ? `${key}=${query[key]}` : ''))
    .join('&');
  const response = await callApi({
    method: 'GET',
    path: `/reporting/daily_report?` + `${qs}`,
    withCredentials: true,
  });

  return await response.data;
};

const getSignUsage = async (query: any) => {
  const qs = Object.keys(query)
    .map(key => (query[key] !== undefined ? `${key}=${query[key]}` : ''))
    .join('&');
  const response = await callApi({
    method: 'GET',
    path: `/reporting/sign_usage?` + `${qs}`,
    withCredentials: true,
  });

  return await response.data;
};

const getPreRunSchedule = async (query: any) => {
  const qs = Object.keys(query)
    .map(key => (query[key] !== undefined ? `${key}=${query[key]}` : ''))
    .join('&');
  const response = await callApi({
    method: 'GET',
    path: `/reporting/pre_run_schedule?` + `${qs}`,
    withCredentials: true,
  });

  return await response.data;
};

const getDailyPerformance = async (query: any) => {
  const qs = Object.keys(query)
    .map(key => (query[key] !== undefined ? `${key}=${query[key]}` : ''))
    .join('&');
  const response = await callApi({
    method: 'GET',
    path: `/reporting/daily_performance?` + `${qs}`,
    withCredentials: true,
  });

  return await response.data;
};

export { getDailyReport, getSignUsage, getPreRunSchedule, getDailyPerformance };
