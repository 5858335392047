import { callApi } from 'utils/fetchApi';

const getCCList = async ({ queryKey }: any) => {
  const [_key, { organizationId }] = queryKey;

  const response = await callApi({
    method: 'GET',
    path:
      `/scheduler/cc?` +
      `${organizationId ? `&organization=${organizationId}` : ''}`,
    withCredentials: true,
  });

  return await response.data;
};

export { getCCList };
