export const media = {
  'zh-TW': {
    Media: 'Media',
    'Upload New Media': '上傳$t(Media)',
    'Rename media': '重新命名$t(Media)',
    'Filter by ID': '搜尋ID',
    'Enter ID': '輸入ID',
    'Enter Keyword': '輸入關鍵字',
    'Search by Keyword': '搜尋關鍵字',
    'Show All': '顯示全部',
    'Show only Videos': '只顯示影片',
    'Show only Image': '只顯示圖片',
    'Filter by Type': '顯示類型',
    'Show disabled media': '顯示停用$t(Media)',
    'Loading media': '讀取中...',
    'No media found': '找不到$t(Media)',
    Thumb: '預覽',
    'File Size': '檔案大小',
    Size: '尺寸',
    'Disable Media': '停用$t(Media)',
    'Enable Media': '啟用$t(Media)',
  },
};
