export const common = {
  en: {
    'Full Name': 'Full Name',
    email: 'Email',
    or: 'or',
  },
  'zh-TW': {
    'Full Name': '姓名',
    email: 'Email',
    or: '或',
    Save: '儲存',
    Submit: '送出',
    Cancel: '取消',
    Confirm: '確定',
    OK: '確定',
    Close: '關閉',
    Delete: '刪除',
    Add: '新增',
    Create: '新增',
    Edit: '編輯',
    Search: '搜尋',
    Select: '選擇',
    Name: '名稱',
    Type: '類型',
    Preview: '預覽',
    Duration: '長度',
    Position: '位置',
    User: '使用者',
    Screen: '版位',
    Player: '播放器',
    Layer: '圖層',
    Scheduler: 'Scheduler',
    Media: 'Media',
    Asset: 'Asset',
    Playlist: '播放清單',
    Schedule: '行程表',
    'Default Settings': '預設設定',
    'Asset Templates': '$t(common:Asset)範本',
    'Asset Template': '$t(common:Asset)範本',
    Billboard: 'Billboard',
    'Live Control': 'Live Control',
    'Live Feed': '外部視訊訊號源',
    Management: '管理',
    Reporting: '統計報告',
    'Property/Client': 'Property/Client',
    'User Management': '使用者管理',
    Font: '字型',
    'Label Color': '標記顏色',
    Color: '顏色',
    Online: '線上',
    'Start At': '開始時間',
    'End At': '結束時間',
    'Upload New': '上傳',
    'Start Upload': '開始上傳',
    Loading: '讀取中...',
    'Return to Assets': '回到$t(common:Asset)列表',
    'Return To Playlist': '回到$t(common:Playlist)',
    'Return To Schedule': '回到$t(common:Schedule)',
    'Please check your internet connectivity or try reload this page.':
      '請確認網路連線或重整此頁面',
    Actions: '操作',
    errorOccur: '發生錯誤',
    modal: {
      deleteAsk: '確定要刪除 {{type}} ',
      'Are you sure to cancel changes?': '確定要取消變更嗎？',
    },
  },
};
