import { useTranslation } from 'react-i18next';
import {
  EuiTabbedContent,
  EuiTitle,
  EuiSpacer,
  EuiBreadcrumbs,
} from '@elastic/eui';
import DailyPerformanceReport from './DailyPerformanceReport';
import DailyReport from './DailyReport';
import SignUsage from './SignUsage';
import PreRunSchedule from './PreRunSchedule';
import './style.scss';

const Reporting = () => {
  const { t, i18n } = useTranslation(['report', 'common']);
  const tabs = [
    {
      id: 'daily-performance-report',
      name: 'Daily Performance Report',
      content: <DailyPerformanceReport />,
    },
    {
      id: 'daily-report',
      name: t('Daily Report'),
      content: <DailyReport />,
    },
    {
      id: 'sign-usage',
      name: t('Sign Usage by Customer'),
      content: <SignUsage />,
    },
    {
      id: 'pre-run-schedule',
      name: t('Pre-Run Schedule'),
      content: <PreRunSchedule />,
    },
  ];

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={[{ text: t('common:Management') }]}
        truncate={false}
        aria-label="Breadcrumbs of reporting"
      />

      <EuiTitle size="l">
        <h1>{t('Reporting')}</h1>
      </EuiTitle>

      <EuiSpacer />

      <EuiTabbedContent
        tabs={tabs}
        initialSelectedTab={tabs[0]}
        autoFocus="selected"
        onTabClick={tab => console.log('clicked tab', tab)}
      />
    </>
  );
};

export default Reporting;
