import './style.scss';

const size = '20%';
const prependXOffset = 100;
const appendXOffset = -100;

const Gradient = ({
  width,
  height,
  offset = 0,
}: {
  width: number;
  height: number;
  offset?: number;
}) => {
  return (
    <>
      <div
        style={{
          width,
          height,
          position: 'absolute',
          background: `
            radial-gradient(
              circle at ${-14.7 + offset}% -10%,
              rgba(78, 212, 224, 1),
              rgba(78, 212, 224, 0) ${size}
            ) transparent
          `,
          //backgroundSize: '200% 200%',
          animation: 'gradient 15s ease-in-out infinite',
          backgroundAttachment: 'fixed',
        }}
      ></div>
      <div
        style={{
          width,
          height,
          position: 'absolute',
          background: `
            radial-gradient(
              circle at ${50 + offset}% 0,
              rgba(254, 82, 34, 1),
              rgba(254, 82, 34, 0) ${size}
            ) transparent
          `,
          //backgroundSize: '200% 200%',
          animation: 'gradient 15s ease-in-out infinite',
          backgroundAttachment: 'fixed',
        }}
      ></div>
      <div
        style={{
          width,
          height,
          position: 'absolute',
          background: `
            radial-gradient(
              circle at ${50 + offset}% 120%,
              rgba(254, 82, 34, 1),
              rgba(254, 82, 34, 0) ${size}
            ) transparent
          `,
          //backgroundSize: '200% 200%',
          animation: 'gradient 15s ease-in-out infinite',
          backgroundAttachment: 'fixed',
        }}
      ></div>
      <div
        style={{
          width,
          height,
          position: 'absolute',
          background: `
            radial-gradient(
              circle at ${-6.7 + offset}% 39%,
              rgba(37, 37, 109, 1),
              rgba(37, 37, 109, 0) ${size}
            ) transparent
          `,
          //backgroundSize: '200% 200%',
          animation: 'gradient 15s ease-in-out infinite',
          backgroundAttachment: 'fixed',
        }}
      ></div>
      <div
        style={{
          width,
          height,
          position: 'absolute',
          background: `
            radial-gradient(
              circle at ${96.7 + offset}% 33%,
              rgba(169, 66, 197, 1),
              rgba(169, 66, 197, 0) ${size}
            ), transparent
          `,
          //backgroundSize: '200% 200%',
          animation: 'gradient 15s ease-in-out infinite',
          backgroundAttachment: 'fixed',
        }}
      ></div>
      <div
        style={{
          width,
          height,
          position: 'absolute',
          background: `
            radial-gradient(
              circle at ${6.7 + offset}% 83%,
              rgba(169, 66, 197, 1),
              rgba(169, 66, 197, 0) ${size}
            ), transparent
          `,
          //backgroundSize: '200% 200%',
          animation: 'gradient 15s ease-in-out infinite',
          backgroundAttachment: 'fixed',
        }}
      ></div>
      <div
        style={{
          width,
          height,
          position: 'absolute',
          background: `
            radial-gradient(
              circle at ${93.3 + offset}% 75%,
              rgba(255, 195, 45, 1),
              rgba(255, 195, 45, 0) ${size}
            ) transparent
          `,
          //backgroundSize: '200% 200%',
          animation: 'gradient 15s ease-in-out infinite',
          backgroundAttachment: 'fixed',
        }}
      ></div>
    </>
  );
};

const Canvas = ({ width, height }: { width: number; height: number }) => {
  return (
    <>
      <div
        style={{
          width,
          height,
          position: 'absolute',
          background: `
            radial-gradient(
              circle at ${54.7}% 50%,
              rgba(78, 212, 224, 1),
              rgba(78, 212, 224, 0) ${size}
            ) beige
          `,
          //backgroundSize: '200% 200%',
          animation: 'gradient 15s ease-in-out infinite',
          backgroundAttachment: 'fixed',
        }}
      ></div>
      <div
        style={{
          width,
          height,
          position: 'absolute',
          background: `
            radial-gradient(
              circle at ${54.7 + appendXOffset}% 50%,
              rgba(78, 212, 224, 1),
              rgba(78, 212, 224, 0) ${size}
            ) transprent
          `,
          //backgroundSize: '200% 200%',
          animation: 'gradient 15s ease-in-out infinite',
          backgroundAttachment: 'fixed',
        }}
      ></div>
      <div
        style={{
          width,
          height,
          position: 'absolute',
          background: `
            radial-gradient(
              circle at ${54.7 + prependXOffset}% 50%,
              rgba(78, 212, 224, 1),
              rgba(78, 212, 224, 0) ${size}
            ) transprent
          `,
          //backgroundSize: '200% 200%',
          animation: 'gradient 15s ease-in-out infinite',
          backgroundAttachment: 'fixed',
        }}
      ></div>
      <Gradient width={width} height={height} />
      <Gradient width={width} height={height} offset={appendXOffset} />
    </>
  );
};

export default Canvas;
