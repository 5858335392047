import { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  EuiEmptyPrompt,
  EuiListGroup,
  EuiLoadingSpinner,
  EuiOverlayMask,
} from '@elastic/eui';

import { Organization } from 'types/organization';
import CenteredContentPage from 'layouts/CenteredContentPage';
import UserContext from 'contexts/UserContext';
import { getOrganizationList } from 'apis/Corps/orgranization';

const OrganizationSelector = () => {
  const history = useHistory();
  const userContext = useContext(UserContext);

  const organizationListQuery = useQuery<Organization[]>(
    'organizationList',
    getOrganizationList,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const hiddenOrganizations = useMemo((): number[] => {
    const hiddenOrgIds: number[] = [];
    userContext.currentUser?.organizations.forEach(organization => {
      if (organization.role >= 99) hiddenOrgIds.push(organization.organization);
    });
    return hiddenOrgIds;
  }, [userContext.currentUser?.organizations]);

  useEffect(() => {
    if (
      !organizationListQuery.isLoading &&
      organizationListQuery.isSuccess &&
      organizationListQuery.data
    ) {
      if (organizationListQuery.data.length === 1) {
        userContext.setCurrentOrganization(organizationListQuery.data[0]);
        window.localStorage.setItem(
          'currentOrganizationId',
          organizationListQuery.data[0].id.toString()
        );
        history.push('/dashboard');
      }
    }
  }, [
    organizationListQuery.data,
    organizationListQuery.isLoading,
    organizationListQuery.isSuccess,
    userContext,
    history,
  ]);

  return (
    <CenteredContentPage>
      {organizationListQuery.isLoading ? (
        <EuiOverlayMask>
          <EuiLoadingSpinner />
        </EuiOverlayMask>
      ) : (
        <EuiEmptyPrompt
          title={
            <h2>
              {organizationListQuery.data?.length &&
              organizationListQuery.data?.length != hiddenOrganizations.length
                ? 'Select an Organziation to continue'
                : `You don't belongs to any organization currently.`}
            </h2>
          }
          body={
            organizationListQuery.data?.length &&
            organizationListQuery.data?.length != hiddenOrganizations.length ? (
              <EuiListGroup
                bordered
                listItems={organizationListQuery.data
                  .filter(
                    organization =>
                      hiddenOrganizations.indexOf(organization.id) < 0
                  )
                  .map(organization => {
                    return {
                      label: organization.name,
                      href: '#',
                      onClick: () => {
                        userContext.setCurrentOrganization(organization);
                        window.localStorage.setItem(
                          'currentOrganizationId',
                          organization.id.toString()
                        );
                        history.push('/dashboard');
                      },
                    };
                  })}
              />
            ) : (
              <p>Please wait someone to add you to their organization.</p>
            )
          }
        />
      )}
    </CenteredContentPage>
  );
};

export default OrganizationSelector;
