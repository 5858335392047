import { useState, useEffect, useCallback, Fragment } from 'react';
import useDurationFormat from 'hooks/useDurationFormat';
import { useTranslation } from 'react-i18next';
import {
  EuiButtonGroup,
  EuiCallOut,
  EuiForm,
  EuiFlexGroup,
  EuiFlexItem,
  EuiDescribedFormGroup,
  EuiFormRow,
  EuiFieldNumber,
  EuiCard,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiButton,
  EuiAccordion,
} from '@elastic/eui';

import { Asset } from 'types/asset';
import { Property, Client } from 'types/property';
import FieldDuration from 'components/utils/FieldDuration';
import './style.scss';
import tinycolor from 'tinycolor2';

type AssetAllocate = {
  asset: Asset;
  count: number;
  ratio: number;
  percentage: number;
  duration: number;
};

export default ({
  handleAllocateAssets,
  assets,
}: {
  handleAllocateAssets: (assets: Asset[]) => void;
  assets: Asset[];
}) => {
  const { t, i18n } = useTranslation(['playlist', 'common']);
  const timeformat = useDurationFormat();
  const [selectedMethod, setSelectedMethod] = useState('byRatio');
  const [designatedDuraiton, setDesignatedDuration] = useState<number>(0);
  const [roundDuration, setRoundDuration] = useState<number>(0);
  const [assetsAllocation, setAssetsAllocation] = useState<AssetAllocate[]>([]);
  const [propertyClientAssets, setPropertyClientAssets] = useState<
    {
      property: Property | any;
      clients: {
        client: Client | any;
        assets: any[];
      }[];
    }[]
  >([]);

  const handleChangeRatio = (asset: Asset, value: number) => {
    const updateAssetsAlloc = assetsAllocation.map(assetAllc => {
      if (assetAllc.asset.id === asset.id)
        return {
          ...assetAllc,
          ratio: value,
        };
      return assetAllc;
    });
    setAssetsAllocation(updateAssetsAlloc);
    groupByProperty(updateAssetsAlloc);
  };

  const handleChangePercentage = (asset: Asset, value: number) => {
    let updateAssetsAlloc = assetsAllocation.map(assetAllc => {
      if (assetAllc.asset.id === asset.id)
        return {
          ...assetAllc,
          percentage: value,
        };
      return assetAllc;
    });
    setAssetsAllocation(updateAssetsAlloc);
    groupByProperty(updateAssetsAlloc);
  };

  const handleChangeDesignatedDuration = (value: number) => {
    setDesignatedDuration(value);
    const repeatTimes = Math.floor(value / roundDuration);
    let remainderDuration = value % roundDuration;
    const remainDurationAssets = fillAssets([], remainderDuration);
    let updateAssetsAlloc = assetsAllocation.map(assetAlloc => {
      return {
        ...assetAlloc,
        count:
          repeatTimes +
          remainDurationAssets.filter(asset => asset.id === assetAlloc.asset.id)
            .length,
      };
    });
    setAssetsAllocation(updateAssetsAlloc);
    groupByProperty(updateAssetsAlloc);
  };

  const getGCD = (a: number, b: number): any => {
    if (a == 0) return b;
    return getGCD(b % a, a);
  };

  const getGCDOfList = (arr: number[]) => {
    let result = arr[0];
    for (let i = 1; i < arr.length; i++) {
      result = getGCD(arr[i], result);

      if (result == 1) {
        return 1;
      }
    }
    return result;
  };

  const fillAssets = (assetList: Asset[], duration: number): Asset[] => {
    let passCount = 0;
    assetsAllocation.forEach(assetAlloc => {
      if (assetAlloc.asset.duration <= duration) {
        assetList.push(assetAlloc.asset);
        duration -= assetAlloc.asset.duration;
      } else {
        passCount += 1;
      }
    });
    if (passCount == assetsAllocation.length || duration === 0)
      return assetList;
    fillAssets(assetList, duration);
    return assetList;
  };

  const allocateAssets = () => {
    let updateAssetsAlloc = assetsAllocation;
    if (selectedMethod != 'fillInDuration') {
      const gcd = getGCDOfList(
        updateAssetsAlloc
          .map(assetAlloc => assetAlloc.percentage)
          .filter(percentage => percentage > 0)
      );
      updateAssetsAlloc = updateAssetsAlloc.map(assetAlloc => {
        return {
          ...assetAlloc,
          count:
            selectedMethod == 'byRatio'
              ? assetAlloc.ratio
              : assetAlloc.percentage
              ? assetAlloc.percentage / gcd
              : 0,
        };
      });
    }

    let newAssets: Asset[] = [];
    updateAssetsAlloc.forEach(assetAlloc => {
      newAssets = [
        ...newAssets,
        ...Array(assetAlloc.count).fill(assetAlloc.asset),
      ];
    });
    const assetGroupByProperty: { [key: number]: Asset[] } = {};

    newAssets.forEach((asset: Asset) => {
      const propertyId = asset.client?.property.id
        ? asset.client.property.id
        : -1;
      if (!(propertyId in assetGroupByProperty))
        assetGroupByProperty[propertyId] = [];
      assetGroupByProperty[propertyId].push(asset);
    });

    const distributedPorpertyAssets = [];
    for (const [_, assets] of Object.entries(assetGroupByProperty)) {
      const assetGroupById: { [key: number]: Asset[] } = {};
      assets.forEach((asset: Asset) => {
        if (!asset.id) return;
        if (!(asset.id in assetGroupById)) assetGroupById[asset.id] = [];
        assetGroupById[asset.id].push(asset);
      });
      const assetsList: Asset[][] = [];
      const singleAssets: Asset[] = [];

      for (const [_, assets] of Object.entries(assetGroupById)) {
        if (assets.length === 1) singleAssets.push(assets[0]);
        else assetsList.push(assets);
      }
      if (singleAssets.length > 0) assetsList.push(singleAssets);
      distributedPorpertyAssets.push(distributeAsset(assetsList));
    }
    const distruibutedAssets = distributeAsset(distributedPorpertyAssets);
    handleAllocateAssets(distruibutedAssets);
  };

  const distributeTwoArray = (arrayA: Asset[], arrayB: Asset[]) => {
    var position = 0;
    arrayB.forEach((asset: Asset) => {
      arrayA.splice(position, 0, asset);
      position = position + 2;
    });
    return arrayA;
  };

  const distributeAsset = (assetsList: Asset[][]): any => {
    if (assetsList.length < 2) {
      return assetsList[0];
    }

    assetsList = assetsList.sort((a, b) => {
      return a.length - b.length;
    });
    var sortedArray = distributeTwoArray(assetsList[0], assetsList[1]);
    assetsList.splice(0, 2);
    assetsList.unshift(sortedArray);

    return distributeAsset(assetsList);
  };

  const groupByProperty = useCallback((assetAllocList: any) => {
    const propertyClients: {
      property: Property | { id: -1; name: string };
      clients: {
        client: Client | { id: -1 };
        assets: any[];
      }[];
    }[] = [];
    propertyClients.push({
      property: { id: -1, name: 'No Property' },
      clients: [
        {
          client: { id: -1 },
          assets: [],
        },
      ],
    });

    assetAllocList.forEach((assetAlloc: any) => {
      const asset: Asset = assetAlloc.asset;
      let clientItem;
      if (asset.client) {
        if (
          !propertyClients.find(
            item => item.property.id == asset.client?.property.id
          )
        )
          propertyClients.push({
            property: asset.client.property,
            clients: [],
          });
        const propertyItem = propertyClients.find(
          item => item.property.id == asset.client?.property.id
        );
        if (!propertyItem) return;
        if (
          !propertyItem.clients.find(
            clientItem => clientItem.client.id == asset.client?.id
          )
        )
          propertyItem.clients.push({
            client: asset.client,
            assets: [],
          });
        clientItem = propertyItem.clients.find(
          clientItem => clientItem.client.id == asset.client?.id
        );
      } else {
        clientItem = propertyClients.find(item =>
          item.clients.find(client => client.client.id == -1)
        )?.clients[0];
      }
      if (!clientItem) return;
      clientItem.assets.push(assetAlloc);
    });

    propertyClients.sort((a, b) => {
      return a.property.name !== 'No Property' &&
        b.property.name !== 'No Property'
        ? a.property.name
            .toLowerCase()
            .localeCompare(b.property.name.toLowerCase())
        : a.property.name == 'No Property'
        ? 1
        : -1;
    });
    setPropertyClientAssets(propertyClients);
  }, []);

  useEffect(() => {
    let assetsAlloc: AssetAllocate[] = [];
    assets.forEach(asset => {
      if (assetsAlloc.find(assetAllc => assetAllc.asset.id === asset.id))
        return;
      assetsAlloc = [
        ...assetsAlloc,
        {
          asset,
          count: assets.filter(_asset => _asset.id === asset.id).length,
          ratio: assets.filter(_asset => _asset.id === asset.id).length,
          percentage: Math.round(
            (assets.filter(_asset => _asset.id === asset.id).length /
              assets.length) *
              100
          ),
          duration:
            assets.filter(_asset => _asset.id === asset.id).length *
            asset.duration,
        },
      ];
    });

    setAssetsAllocation(assetsAlloc);

    setDesignatedDuration(
      assetsAlloc
        .map(assetAllc => assetAllc.asset.duration * assetAllc.count)
        .reduce((acc, curr) => acc + curr, 0)
    );
    setRoundDuration(
      assetsAlloc
        .map(assetAllc => assetAllc.asset.duration)
        .reduce((acc, curr) => acc + curr, 0)
    );
    groupByProperty(assetsAlloc);
  }, [assets, groupByProperty]);

  return (
    <>
      <EuiTitle size="s">
        <h2>{t('Asset Allocator')}</h2>
      </EuiTitle>

      <EuiSpacer />

      <EuiFlexGroup alignItems="flexStart" justifyContent="spaceBetween">
        <EuiFlexItem grow={false}>
          <div>{t('Select allocate method')}:</div>
          <EuiButtonGroup
            style={{ margin: '8px 0' }}
            legend="This is a basic group"
            options={[
              {
                id: 'byRatio',
                label: t('By Ratio'),
              },
              {
                id: 'byPercentage',
                label: t('By Percentage'),
              },
              {
                id: 'fillInDuration',
                label: t('Fill in Duration'),
              },
            ]}
            idSelected={selectedMethod}
            onChange={(id: string) => setSelectedMethod(id)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton onClick={() => allocateAssets()}>
            {t('Distribute')}
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiForm style={{ marginTop: 12 }}>
        {selectedMethod == 'byRatio' && (
          <>
            <EuiCallOut
              title={t(
                'This method will allocate asset in the play list by the specific ratio.'
              )}
              iconType="iInCircle"
              style={{ marginBottom: 12 }}
            />
            {propertyClientAssets.map(
              (propertyClient, index) =>
                !(
                  propertyClient.property.id == -1 &&
                  propertyClient.clients[0].assets.length == 0
                ) && (
                  <Fragment key={index}>
                    <EuiAccordion
                      id={`${index}`}
                      paddingSize="none"
                      buttonProps={{ className: 'allocate-property' }}
                      buttonContent={
                        <EuiFlexGroup>
                          <EuiFlexItem grow={6}>
                            <EuiText>{propertyClient.property?.name}</EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFlexGroup gutterSize="xs">
                              <EuiFlexItem style={{ paddingRight: 18 }}>
                                <EuiText textAlign="right">
                                  {propertyClient.clients.reduce(
                                    (acc, client) =>
                                      acc +
                                      client.assets.reduce(
                                        (acc, asset) => acc + asset.ratio,
                                        0
                                      ),
                                    0
                                  )}
                                </EuiText>
                              </EuiFlexItem>
                              <EuiFlexItem grow={false}>
                                <EuiText textAlign="right">
                                  {timeformat(
                                    propertyClient.clients.reduce(
                                      (acc, client) =>
                                        acc +
                                        client.assets.reduce(
                                          (acc, asset) => acc + asset.duration,
                                          0
                                        ),
                                      0
                                    )
                                  )}
                                </EuiText>
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      }
                      arrowDisplay="none"
                      initialIsOpen={true}
                    >
                      {propertyClient.clients.map(client => (
                        <div
                          key={client.client.id}
                          style={{
                            backgroundColor: client.client.color,
                            paddingRight: 8,
                            paddingLeft: 8,
                          }}
                        >
                          <EuiFlexGroup
                            style={{
                              borderLeft: `3px solid ${client.client.color}`,
                              backgroundColor: client.client.color,
                              color: `${
                                tinycolor(client.client.color).isDark()
                                  ? 'white'
                                  : 'black' || 'auto'
                              }`,
                            }}
                          >
                            <EuiFlexItem grow={6}>
                              <EuiText>
                                {client.client.id == -1
                                  ? t('No Client')
                                  : client.client?.name}
                              </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiText textAlign="right">
                                {client.assets.reduce(
                                  (acc, asset) => acc + asset.ratio,
                                  0
                                )}
                              </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiText textAlign="right">
                                {timeformat(
                                  client.assets.reduce(
                                    (acc, asset) => acc + asset.duration,
                                    0
                                  )
                                )}
                              </EuiText>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                          <EuiSpacer size="s" />
                          {client.assets.map((assetAlloc, index) => (
                            <EuiFlexGroup
                              key={index}
                              style={{
                                borderLeft: `3px solid ${client.client.color}`,
                                backgroundColor: client.client.color,
                                color: `${
                                  tinycolor(client.client.color).isDark()
                                    ? 'white'
                                    : 'black' || 'auto'
                                }`,
                                marginBottom: 5,
                              }}
                            >
                              <EuiFlexItem grow={6}>
                                <EuiText>
                                  {timeformat(assetAlloc.asset.duration)} |{' '}
                                  {assetAlloc.asset.name}
                                </EuiText>
                              </EuiFlexItem>
                              <EuiFlexItem>
                                <EuiFlexGroup
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <EuiText size="xs">Ratio</EuiText>
                                  <EuiFieldNumber
                                    style={{ marginLeft: 5 }}
                                    fullWidth
                                    compressed
                                    controlOnly
                                    value={assetAlloc.ratio}
                                    onChange={e =>
                                      handleChangeRatio(
                                        assetAlloc.asset,
                                        parseInt(e.target.value)
                                      )
                                    }
                                    min={1}
                                  />
                                </EuiFlexGroup>
                              </EuiFlexItem>
                              <EuiFlexItem>
                                <EuiText textAlign="right">
                                  {timeformat(
                                    assetAlloc.asset.duration * assetAlloc.ratio
                                  )}
                                </EuiText>
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          ))}
                        </div>
                      ))}
                    </EuiAccordion>
                    <EuiSpacer size="xs" />
                  </Fragment>
                )
            )}
          </>
        )}

        {selectedMethod == 'byPercentage' && (
          <>
            <EuiCallOut
              title={t(
                'This method will allocate asset in the play list by the specific percentage.'
              )}
              iconType="iInCircle"
              style={{ marginBottom: 8 }}
            />
            {propertyClientAssets.map((propertyClient, index) => (
              <Fragment key={index}>
                <EuiCard
                  key={index}
                  title={
                    <EuiFlexGroup gutterSize="xs" justifyContent="spaceBetween">
                      <EuiFlexItem>
                        {propertyClient.property.id == -1
                          ? t('No Property')
                          : propertyClient.property?.name}
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiFlexGroup gutterSize="xs">
                          <EuiFlexItem>
                            <EuiText textAlign="right">
                              {`${propertyClient.clients.reduce(
                                (acc, client) =>
                                  acc +
                                  client.assets.reduce(
                                    (acc, asset) => acc + asset.percentage,
                                    0
                                  ),
                                0
                              )}%`}
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  }
                  textAlign="left"
                >
                  {propertyClient.clients.map(client => (
                    <div
                      key={client.client.id}
                      style={{
                        backgroundColor: client.client.color,
                        paddingRight: 8,
                        paddingLeft: 8,
                      }}
                    >
                      <EuiFlexGroup
                        style={{
                          borderLeft: `3px solid ${client.client.color}`,
                          backgroundColor: client.client.color,
                          color: `${
                            tinycolor(client.client.color).isDark()
                              ? 'white'
                              : 'black' || 'auto'
                          }`,
                        }}
                      >
                        <EuiFlexItem grow={6}>
                          <EuiText>
                            {client.client.id == -1
                              ? 'No Client'
                              : client.client?.name}
                          </EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiText textAlign="right">
                            {`${client.assets.reduce(
                              (acc, asset) => acc + asset.percentage,
                              0
                            )}%`}
                          </EuiText>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                      <EuiSpacer size="s" />
                      {client.assets.map((assetAlloc, index) => (
                        <EuiFlexGroup
                          key={index}
                          style={{
                            borderLeft: `3px solid ${client.client.color}`,
                            backgroundColor: client.client.color,
                            color: `${
                              tinycolor(client.client.color).isDark()
                                ? 'white'
                                : 'black' || 'auto'
                            }`,
                            marginBottom: 5,
                          }}
                        >
                          <EuiFlexItem grow={6}>
                            <EuiText>
                              {timeformat(assetAlloc.duration)} |{' '}
                              {assetAlloc.asset.name}
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <EuiFlexGroup
                              alignItems="center"
                              justifyContent="center"
                            >
                              <EuiFieldNumber
                                prepend="Ratio"
                                fullWidth
                                compressed
                                value={assetAlloc.percentage}
                                onChange={e =>
                                  handleChangePercentage(
                                    assetAlloc.asset,
                                    parseInt(e.target.value)
                                  )
                                }
                                min={0}
                                max={100}
                                append="%"
                              />
                            </EuiFlexGroup>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      ))}
                    </div>
                  ))}
                </EuiCard>
                <EuiSpacer size="xs" />
              </Fragment>
            ))}
          </>
        )}

        {selectedMethod == 'fillInDuration' && (
          <>
            <EuiCallOut
              title={t(
                'This method will fill and repeat assets in the designated duration.'
              )}
              iconType="iInCircle"
              style={{ marginBottom: 8 }}
            />
            <EuiFormRow
              fullWidth
              label={t('common:Duration', 'Playlist Duration')}
            >
              <FieldDuration
                value={designatedDuraiton}
                setParsedValue={seconds =>
                  handleChangeDesignatedDuration(seconds)
                }
                min={15}
              />
            </EuiFormRow>
            <div style={{ margin: '12px auto' }}>
              <EuiFlexGroup>
                <EuiFlexItem grow={5}>{t('Asset Name')}</EuiFlexItem>
                <EuiFlexItem grow={1}>{t('Repeat Times')}</EuiFlexItem>
              </EuiFlexGroup>
              {assetsAllocation.map(assetAlloc => (
                <EuiFlexGroup key={assetAlloc.asset.id}>
                  <EuiFlexItem grow={5}>{assetAlloc.asset.name}</EuiFlexItem>
                  <EuiFlexItem grow={1}>{assetAlloc.count}</EuiFlexItem>
                </EuiFlexGroup>
              ))}
            </div>
          </>
        )}
      </EuiForm>
    </>
  );
};
