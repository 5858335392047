import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiBadge,
  EuiHorizontalRule,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui';

import { Playlist } from 'types/playlist';
import PlaylistSelection from './PlaylistSelection';

export default ({
  setIsOpenPlaylistPicker,
  handleFinishPickingPlaylists,
  isMultiple = true,
}: {
  setIsOpenPlaylistPicker: (arg: boolean) => void;
  handleFinishPickingPlaylists: (playlists: Playlist[]) => void;
  isMultiple?: Boolean;
}) => {
  const { t, i18n } = useTranslation(['playlist', 'common']);
  const [pickedPlaylists, setPickedPlaylists] = useState<Playlist[]>([]);

  const handlePickPlaylist = (playlist: Playlist) => {
    if (!isMultiple) {
      handleFinishPickingPlaylists([playlist]);
      setIsOpenPlaylistPicker(false);
    }
    setPickedPlaylists([...pickedPlaylists, playlist]);
  };

  const handleAdd = () => {
    handleFinishPickingPlaylists(pickedPlaylists);
    setIsOpenPlaylistPicker(false);
  };

  return (
    <EuiModal
      style={{ width: 600 }}
      onClose={() => setIsOpenPlaylistPicker(false)}
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Playlist Picker</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        {isMultiple && (
          <>
            {t('Selected Playlist')}:
            <EuiPanel>
              <EuiFlexGroup wrap gutterSize="s">
                {pickedPlaylists.map(playlist => (
                  <EuiFlexItem grow={false}>
                    <EuiBadge
                      iconType="cross"
                      iconSide="right"
                      iconOnClick={() => {}}
                      iconOnClickAriaLabel="Click this icon to remove this playlist"
                    >
                      {playlist.name}
                    </EuiBadge>
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>
            </EuiPanel>
            <EuiHorizontalRule />
          </>
        )}

        <PlaylistSelection handlePickPlaylist={handlePickPlaylist} />
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={() => handleAdd()} fill>
          {t('common:Add')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
