import { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import useDurationFormat from 'hooks/useDurationFormat';
import {
  EuiPageHeader,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiFieldText,
  EuiForm,
  EuiSpacer,
  EuiTitle,
  EuiModal,
  EuiConfirmModal,
  EuiTextColor,
  EuiModalFooter,
  EuiBreadcrumbs,
  EuiModalBody,
  EuiButtonGroup,
  EuiDragDropContext,
  euiDragDropReorder,
  EuiColorPicker,
} from '@elastic/eui';
import { AxiosError } from 'axios';

import UserContext from 'contexts/UserContext';
import ToastContext from 'contexts/ToastContext';
import AssetPicker from 'components/scheduler/AssetPicker';
import AssetAllocator from '../AssetAllocator';
import {
  getPlaylist,
  patchPlaylist,
  postPlaylist,
} from 'apis/Scheduler/playlist';
import { Playlist } from 'types/playlist';
import { Asset } from 'types/asset';
import { Schedule } from 'types/schedule';
import FieldDuration from 'components/utils/FieldDuration';
import AssetFlyout from 'pages/Asset/AssetFlyout';
import { getAsset } from 'apis/Scheduler/asset';
import PlaylistAssetEditor from './playlistAssetEditor';
import useBeforeUnload from 'hooks/useBeforeUnload';

const PlaylistEditor = ({ action }: { action: 'Create' | 'Edit' }) => {
  const { t, i18n } = useTranslation(['playlist', 'common']);
  const defaultDuration = 30 * 60;
  let { playlistId } = useParams<any>();
  const history = useHistory();
  const location = useLocation<{
    schedule?: Schedule;
    playlist?: Playlist;
  }>();
  const userContext = useContext(UserContext);
  const toastContext = useContext(ToastContext);
  const timeformat = useDurationFormat();

  const [playlist, setPlaylist] = useState<Playlist>({
    name: '',
    label_color: '',
    assets: [],
    duration: defaultDuration,
  });
  const [isOpenAssetPicker, setIsOpenAssetPicker] = useState(false);
  const [isOpenAssetAllocator, setIsOpenAssetAllocator] = useState(false);
  const [isSavedPlaylistVisible, setIsSavedPlaylistVisible] = useState(false);
  const [isSaveConfirmVisible, setIsSaveConfirmVisible] = useState(false);
  const [selectedMode, setSelectedMode] = useState<string>('timeGrid');
  const [errors, setErrors] = useState<{ [column: string]: string[] }>({});
  const [previewAssetId, setPreviewAssetId] = useState<number | undefined>(
    undefined
  );
  const [leaveCheck, setLeaveCheck] = useState(false);

  const previewAsset = useQuery(
    ['asset', { previewAssetId }],
    () => previewAssetId && getAsset(previewAssetId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const playlistQuery = useQuery<Playlist>(
    ['playlist', playlistId],
    async () => {
      const data = await getPlaylist(playlistId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: action == 'Edit',
    }
  );

  const postMutaion = useMutation(postPlaylist, {
    onSuccess: data => {
      setLeaveCheck(true);
      history.push(`/playlists/${data.id}/edit`);
      toastContext.setToasts([
        ...toastContext.toasts,
        {
          id: 'playlist-created',
          title: `New playlist: ${data.name} created!`,
          color: 'success',
        },
      ]);
    },
    onError: (error: AxiosError) => {
      setErrors(error.response?.data);
    },
  });

  const patchMutaion = useMutation(patchPlaylist, {
    onSuccess: () => {
      playlistQuery.refetch();
      toastContext.setToasts([
        ...toastContext.toasts,
        {
          id: 'playlist-updated',
          title: 'Playlist updated successfully!',
          color: 'success',
        },
      ]);
    },
    onError: (error: AxiosError) => {
      setErrors(error.response?.data);
    },
  });

  const handleAppendAssets = (assets: Asset[]) => {
    const originLength = playlist.assets.length;
    if (
      playlist.assets.reduce(
        (totalDuration, item) => (totalDuration += item.asset.duration),
        0
      ) +
        assets.reduce(
          (totalDuration, item) => (totalDuration += item.duration),
          0
        ) >
      60 * 60 * 24
    ) {
      displayNotAddMsg();
      return;
    }
    setPlaylist({
      ...playlist,
      assets: [
        ...playlist?.assets,
        ...assets.map((asset, index) => {
          return {
            index: originLength + index,
            asset: asset,
          };
        }),
      ],
    });
  };

  const handleRemoveAsset = (index: number) => {
    setPlaylist(current => {
      const newAssets = [...current.assets];
      newAssets.splice(index, 1);
      return {
        ...current,
        assets: newAssets,
      };
    });
  };

  const handleAllocateAssets = (assets: Asset[]) => {
    if (
      assets.reduce(
        (totalDuration, item) => (totalDuration += item.duration),
        0
      ) >
      60 * 60 * 24
    ) {
      displayNotAddMsg();
      return;
    }
    setPlaylist({
      ...playlist,
      assets: assets.map((asset, index) => ({ index, asset })),
    });
    setIsOpenAssetAllocator(false);
  };

  const handleSavePlaylist = () => {
    setErrors({});
    if (action == 'Create') {
      playlist.organization = userContext.currentOrganization?.id;
      postMutaion.mutate(playlist);
    } else {
      patchMutaion.mutate(playlist);
    }
  };

  const onDragEnd: any = ({
    source,
    destination,
  }: {
    source: any;
    destination: any;
  }) => {
    if (playlist && source && destination) {
      const items = euiDragDropReorder(
        playlist?.assets || [],
        source.index,
        destination.index
      );
      setPlaylist({
        ...playlist,
        assets: items.map((item, index) => {
          return {
            asset: item.asset,
            index: index,
          };
        }),
      });
    }
  };

  const displayNotAddMsg = () => {
    toastContext.setToasts([
      ...toastContext.toasts,
      {
        id: 'playlist-add-asset-denied',
        title: 'Action denied.',
        color: 'danger',
        text: <p>Playlist duration can not more than 24 hours</p>,
      },
    ]);
  };

  useBeforeUnload({
    when:
      (action == 'Edit' && playlist != playlistQuery.data) ||
      (action == 'Create' && !leaveCheck),
  });

  useEffect(() => {
    if (!playlistQuery.isLoading && playlistQuery.isSuccess) {
      setPlaylist(playlistQuery.data);
    }
  }, [playlistQuery.data, playlistQuery.isLoading, playlistQuery.isSuccess]);

  useEffect(() => {
    if (location.state?.playlist?.id) {
      setPlaylist({
        id: undefined,
        ...location.state.playlist,
        name: `Copy of ${location.state.playlist.name}`,
      });
    }
  }, [location]);

  return (
    <>
      <Prompt
        when={
          (action == 'Edit' && playlist != playlistQuery.data) ||
          (action == 'Create' && !leaveCheck)
        }
        message={t('common:modal:Are you sure to cancel changes?')}
      />

      {previewAsset && (
        <AssetFlyout
          onClose={() => setPreviewAssetId(undefined)}
          asset={previewAsset.data}
        />
      )}

      <EuiBreadcrumbs
        breadcrumbs={[
          { text: t('Scheduling') },
          {
            text: t('common:Playlist'),
            onClick: () => history.push('/playlists'),
          },
          ...(action == 'Create'
            ? []
            : [
                {
                  text: playlist.name,
                },
              ]),
        ]}
        truncate={false}
        aria-label="Breadcrumbs of create asset"
      />

      <EuiPageHeader
        pageTitle={`${
          action == 'Create'
            ? t('Create New Playlist')
            : t('Edit Playlist', `Editing Playlist`)
        }`}
        rightSideItems={[
          <EuiButton onClick={() => history.push('/playlists')}>
            {t('common:Return To Playlist', 'Return To Playlists')}
          </EuiButton>,
          ...(location.state?.schedule
            ? [
                <EuiButton
                  onClick={() =>
                    history.push(`/schedule`, {
                      schedule: location.state?.schedule,
                      screen: location.state?.schedule?.screen,
                    })
                  }
                  isLoading={postMutaion.isLoading || patchMutaion.isLoading}
                  disabled={postMutaion.isLoading || patchMutaion.isLoading}
                >
                  {t('common:Return To Schedule')}
                </EuiButton>,
              ]
            : []),
          action == 'Edit' && (
            <EuiButton
              onClick={() => history.push('/playlists/create', { playlist })}
            >
              {t('Duplicate This Playlist')}
            </EuiButton>
          ),
          <EuiButton
            onClick={() => {
              if (
                playlist.assets.reduce(
                  (totalDuration, item) =>
                    (totalDuration += item.asset.duration),
                  0
                ) > playlist.duration
              )
                setIsSaveConfirmVisible(true);
              else handleSavePlaylist();
            }}
            fill
            isLoading={postMutaion.isLoading || patchMutaion.isLoading}
            disabled={postMutaion.isLoading || patchMutaion.isLoading}
          >
            {action == 'Create' ? t('common:Submit') : t('common:Save')}
          </EuiButton>,
        ]}
        bottomBorder={false}
      />

      <EuiForm
        component="form"
        isInvalid={postMutaion.isError || patchMutaion.isError}
      >
        <EuiFlexGroup style={{ maxWidth: 1200, flexGrow: 0 }}>
          <EuiFlexItem grow={6}>
            <EuiFormRow
              label={t('common:Name', 'Playlist name')}
              isInvalid={'name' in errors}
              error={errors.name || []}
            >
              <EuiFieldText
                fullWidth
                value={playlist?.name}
                onChange={e =>
                  playlist
                    ? setPlaylist({ ...playlist, name: e.target.value })
                    : {}
                }
                isInvalid={'name' in errors}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={6}>
            <EuiFormRow
              label={t('Label Color')}
              isInvalid={'label_color' in errors}
              error={errors.label_color || []}
            >
              <EuiColorPicker
                onChange={color =>
                  playlist
                    ? setPlaylist({ ...playlist, label_color: color })
                    : {}
                }
                color={playlist?.label_color}
                placeholder="None"
                isClearable={true}
                isInvalid={'label_color' in errors}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={6}>
            <EuiFormRow
              fullWidth
              label={t('common:Duration')}
              isInvalid={'duration' in errors}
              error={errors.duration || []}
            >
              <FieldDuration
                placeholder=""
                value={playlist?.duration}
                setParsedValue={(seconds: number) =>
                  playlist
                    ? setPlaylist({
                        ...playlist,
                        duration: seconds,
                      })
                    : {}
                }
                isInvalid={'duration' in errors}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem grow={2} className="eui-hideFor--xs">
            <AssetAllocator
              handleAllocateAssets={handleAllocateAssets}
              assets={playlist.assets.map(item => {
                return item.asset;
              })}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <div>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiTitle size="s">
                    <h2>{t('common:Asset', 'Assets')}</h2>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false} className="eui-showFor--xs">
                  <EuiButton
                    fullWidth
                    onClick={() => setIsOpenAssetAllocator(true)}
                  >
                    {t('Allocate Asset')}
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton onClick={() => setIsOpenAssetPicker(true)}>
                    {t('Add Asset')}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiSpacer />
              <div style={{ paddingBottom: '10px' }}>
                <EuiButtonGroup
                  legend="This is a basic group"
                  options={[
                    {
                      id: 'list',
                      label: 'List',
                    },
                    {
                      id: 'timeGrid',
                      label: 'Time Grid',
                    },
                  ]}
                  idSelected={selectedMode}
                  onChange={(id: string) => setSelectedMode(id)}
                />
              </div>
              <EuiDragDropContext onDragEnd={onDragEnd}>
                <PlaylistAssetEditor
                  mode={selectedMode}
                  playlist={
                    action == 'Create' ? { ...playlist, id: -1 } : playlist
                  }
                  setPreviewAssetId={setPreviewAssetId}
                  handleRemoveAsset={handleRemoveAsset}
                  setPlaylist={setPlaylist}
                />
              </EuiDragDropContext>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>

      {isOpenAssetPicker && (
        <AssetPicker
          setIsOpenAssetPicker={setIsOpenAssetPicker}
          handleFinishPickingAssets={handleAppendAssets}
        />
      )}

      {isOpenAssetAllocator && (
        <EuiModal onClose={() => setIsOpenAssetAllocator(false)}>
          <EuiModalBody>
            <AssetAllocator
              handleAllocateAssets={handleAllocateAssets}
              assets={playlist.assets.map(item => {
                return item.asset;
              })}
            />
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton onClick={() => setIsOpenAssetAllocator(false)}>
              {t('Close Asset Allocator')}
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}

      {isSaveConfirmVisible && (
        <EuiConfirmModal
          maxWidth={'300'}
          onCancel={() => setIsSaveConfirmVisible(false)}
          onConfirm={() => {
            handleSavePlaylist();
            setIsSaveConfirmVisible(false);
          }}
          title={
            <EuiTitle>
              <EuiTextColor>{t('Save Playlists')}</EuiTextColor>
            </EuiTitle>
          }
          cancelButtonText={t('common:Cancel')}
          confirmButtonText={t('common:Save')}
          buttonColor={'danger'}
        >
          <p>
            <EuiTextColor>
              {t('overDurationText', {
                length: timeformat(
                  playlist.assets.reduce(
                    (totalDuration, item) =>
                      (totalDuration += item.asset.duration),
                    0
                  )
                ),
                duration: timeformat(playlist.duration || defaultDuration),
              })}
            </EuiTextColor>
          </p>
        </EuiConfirmModal>
      )}
    </>
  );
};

export default PlaylistEditor;
