import { useState, useEffect, useContext } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import {
  EuiPageHeader,
  EuiButton,
  EuiBreadcrumbs,
  EuiFlexGroup,
  EuiFlexItem,
  EuiConfirmModal,
  EuiTitle,
  EuiTextColor,
  EuiCheckbox,
  EuiFormRow,
  EuiSpacer,
} from '@elastic/eui';
import { AxiosError } from 'axios';

import ToastContext from 'contexts/ToastContext';
import useDurationFormat from 'hooks/useDurationFormat';
import {
  getPlaylist,
  getPlaylists,
  patchPlaylist,
} from 'apis/Scheduler/playlist';
import { Playlist } from 'types/playlist';
import { Schedule } from 'types/schedule';

import { getAsset } from 'apis/Scheduler/asset';
import AssetFlyout from 'pages/Asset/AssetFlyout';
import TimeGridEditor from './timeGridEditor';
import AssetAllocator from '../AssetAllocator';
import useBeforeUnload from 'hooks/useBeforeUnload';
import { Asset } from 'types/asset';

const MultiPlaylistEditor = () => {
  const { t, i18n } = useTranslation(['playlist', 'common']);
  const defaultDuration = 30 * 60;
  const location = useLocation<{
    schedule?: Schedule;
  }>();
  // eslint-disable-next-line compat/compat
  const searchParams = new URLSearchParams(location.search);
  let playlistIds = searchParams
    .getAll('playlistIds[]')
    .map(id => parseInt(id));

  const history = useHistory();
  const toastContext = useContext(ToastContext);
  const timeformat = useDurationFormat();

  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string>();
  const [previewAssetId, setPreviewAssetId] = useState<number | undefined>(
    undefined
  );
  const [isDirty, setIsDirty] = useState(false);
  const [isSaveConfirmVisible, setIsSaveConfirmVisible] = useState(false);
  const [savePlaylistIds, setSavePlaylistIds] = useState<string[]>([]);

  const previewAsset = useQuery(
    ['asset', { previewAssetId }],
    () => previewAssetId && getAsset(previewAssetId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [playlistList, setPlaylistList] = useState<{
    [playlistId: string]: Playlist;
  }>({});

  const playlistsQuery = useQuery<Playlist[]>(
    ['playlist', playlistIds],
    async () => {
      const data = await getPlaylists(playlistIds);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const patchPlaylists = async (playlistList: {
    [playlistId: string]: Playlist;
  }) => {
    const results: any = { error: [], success: [] };
    const promise = Object.entries(playlistList).map(
      async ([playlistId, playlist]) => {
        await patchPlaylist(playlist)
          .then(() => {
            results['success'].push(playlistId);
          })
          .catch(error => {
            results['error'].push(playlistId);
          });
      }
    );

    // eslint-disable-next-line compat/compat
    await Promise.all(promise);
    return results;
  };

  const patchMutaion = useMutation(patchPlaylists, {
    onSuccess: response => {
      if (response['error'].length > 0) {
        toastContext.setToasts([
          ...toastContext.toasts,
          {
            id: `playlist-update-failed`,
            title: t(
              'updateFailedText',
              { ids: response['error'].join(',#') },
              `Playlist #${response['error'].join(',#')} update failed!`
            ),
            color: 'danger',
          },
        ]);
        response['success'].map((playlistId: number) => {
          getPlaylist(playlistId).then((playlist: Playlist) => {
            setPlaylistList({
              ...playlistList,
              ...{
                [playlistId.toString()]: playlist,
              },
            });
          });
        });
      } else {
        setIsDirty(false);
        playlistsQuery.refetch();
        toastContext.setToasts([
          ...toastContext.toasts,
          {
            id: `playlist-updated`,
            title: t(`Playlist updated successfully!`),
            color: 'success',
          },
        ]);
      }
    },
    onError: (error: AxiosError) => {
      console.log(error);
      toastContext.setToasts([
        ...toastContext.toasts,
        {
          id: `playlist-update-failed`,
          title: `Playlist update failed!`,
          color: 'danger',
        },
      ]);
    },
  });

  const handleSavePlaylist = () => {
    const savePlaylistList: { [playlistId: string]: Playlist } = {};
    savePlaylistIds.forEach(id => {
      savePlaylistList[id] = playlistList[id];
    });
    patchMutaion.mutate(savePlaylistList);
  };

  const handleAllocateAssets = (assets: Asset[]) => {
    if (!selectedPlaylistId) return;
    if (
      assets.reduce(
        (totalDuration, item) => (totalDuration += item.duration),
        0
      ) >
      60 * 60 * 24
    ) {
      displayNotAddMsg();
      return;
    }

    setPlaylistList({
      ...playlistList,
      ...{
        [selectedPlaylistId]: {
          ...playlistList[selectedPlaylistId],
          assets: assets.map((asset, index) => ({ index, asset })),
        },
      },
    });
  };

  const displayNotAddMsg = () => {
    toastContext.setToasts([
      ...toastContext.toasts,
      {
        id: 'playlist-add-asset-denied',
        title: 'Action denied.',
        color: 'danger',
        text: <p>{t('Playlist duration can not more than 24 hours')}</p>,
      },
    ]);
  };

  useEffect(() => {
    if (!playlistsQuery.isLoading && playlistsQuery.isSuccess) {
      const playlists: any = {};
      playlistsQuery.data.forEach((playlist: Playlist) => {
        if (playlist.id) {
          playlists[playlist.id.toString()] = {
            ...playlist,
            duration: playlist.duration || defaultDuration,
          };
        }
      });
      setPlaylistList(playlists);
    }
  }, [
    defaultDuration,
    playlistsQuery.data,
    playlistsQuery.isLoading,
    playlistsQuery.isSuccess,
  ]);

  useBeforeUnload({ when: isDirty });

  return (
    <>
      <Prompt
        when={isDirty}
        message={t('common:modal:Are you sure to cancel changes?')}
      />

      <EuiBreadcrumbs
        breadcrumbs={[
          { text: 'Scheduling' },
          {
            text: t('common:Playlist'),
            onClick: () => history.push('/playlists'),
          },
        ]}
        truncate={false}
        aria-label="Breadcrumbs of edit playlists"
      />

      <EuiPageHeader
        pageTitle={t(`Editing Playlists`)}
        rightSideItems={[
          <EuiButton onClick={() => history.push('/playlists')}>
            {t('Return To Playlists')}
          </EuiButton>,
          <EuiButton
            onClick={() => setIsSaveConfirmVisible(true)}
            fill
            isLoading={patchMutaion.isLoading}
            disabled={patchMutaion.isLoading || !isDirty}
          >
            {t('common:Save')}
          </EuiButton>,
        ]}
        bottomBorder={false}
      />
      <EuiFlexGroup>
        <EuiFlexItem grow={1}>
          <div>
            <AssetAllocator
              handleAllocateAssets={handleAllocateAssets}
              assets={
                selectedPlaylistId
                  ? playlistList[selectedPlaylistId.toString()].assets.map(
                      asset => asset.asset
                    )
                  : []
              }
            />
          </div>
        </EuiFlexItem>
        <EuiFlexItem grow={9}>
          <TimeGridEditor
            playlistMap={playlistList}
            updatePlaylistList={playlists => {
              setIsDirty(true);
              setPlaylistList(playlists);
            }}
            setSelectedPlaylistId={setSelectedPlaylistId}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      {previewAsset && (
        <AssetFlyout
          onClose={() => setPreviewAssetId(undefined)}
          asset={previewAsset.data}
        />
      )}
      {isSaveConfirmVisible && (
        <EuiConfirmModal
          maxWidth={'300'}
          onCancel={() => setIsSaveConfirmVisible(false)}
          onConfirm={() => {
            handleSavePlaylist();
            setIsSaveConfirmVisible(false);
            setSavePlaylistIds([]);
          }}
          confirmButtonDisabled={savePlaylistIds.length == 0}
          title={
            <EuiTitle>
              <EuiTextColor>{t('Save Playlists')}</EuiTextColor>
            </EuiTitle>
          }
          cancelButtonText={t('common:Cancel')}
          confirmButtonText={t('common:Save')}
        >
          <p>
            <EuiTextColor>
              {t('Choose which playlist you would like to save.')}
            </EuiTextColor>
          </p>
          {Object.keys(playlistList).map(id => {
            return (
              <>
                <EuiFormRow
                  isInvalid={
                    playlistList[id].assets.reduce(
                      (totalDuration, item) =>
                        (totalDuration += item.asset.duration),
                      0
                    ) > playlistList[id].duration
                  }
                  error={t('overDurationText', {
                    length: timeformat(
                      playlistList[id].assets.reduce(
                        (totalDuration, item) =>
                          (totalDuration += item.asset.duration),
                        0
                      )
                    ),
                    duration: timeformat(playlistList[id].duration),
                  })}
                >
                  <EuiCheckbox
                    key={id}
                    id={id}
                    label={playlistList[id].name}
                    onChange={() => {
                      if (savePlaylistIds.indexOf(id) == -1)
                        setSavePlaylistIds([...savePlaylistIds, id]);
                      else
                        setSavePlaylistIds(
                          savePlaylistIds.filter(playlistId => playlistId != id)
                        );
                    }}
                    checked={
                      savePlaylistIds.find(playlistId => playlistId == id) !=
                      undefined
                    }
                  />
                </EuiFormRow>
                <EuiSpacer size="m" />
              </>
            );
          })}
        </EuiConfirmModal>
      )}
    </>
  );
};

export default MultiPlaylistEditor;
