export const propertyClient = {
  'zh-TW': {
    'Property and Client': 'Property and Client',
    Property: 'Property',
    Client: 'Client',
    'Edit Property': '編輯$t(Property)',
    'Edit Client': '編輯$t(Client)',
    'Add New Client': '新增$t(Client)',
    'Add New Property': '新增$t(Property)',
    Add: '新增 {{type}}',
    'Label Color': '標記顏色',
    'Change Screen': '更改版位',
    'Add Layer': '新增圖層',
    'This screen doesn‘t have any default layer.': '此版位沒有預設圖層',
  },
};
