import { useState, useRef, useContext } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiFormRow,
  EuiInputPopover,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiListGroupItem,
  EuiLoadingSpinner,
} from '@elastic/eui';

import './style.scss';
import { Font } from 'types/font';
import { getFontList } from 'apis/Scheduler/font';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useDebounce from 'hooks/useDebounce';
import UserContext from 'contexts/UserContext';

const FontSelector = ({
  label,
  selectedFont,
  onChange,
}: {
  label?: string;
  selectedFont?: any;
  onChange: (font: Font) => void;
}) => {
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['font', 'common']);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [keyword, setKeyword] = useState('');
  const debouncedKeyword = useDebounce(keyword, 1000);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery(
    ['font-selections', debouncedKeyword],
    options => {
      return getFontList({
        queryKey: [
          'font-selections',
          options.pageParam ?? {
            pageIndex,
            keyword: debouncedKeyword,
            organizationId: userContext.currentOrganization?.id,
          },
        ],
      });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => lastPage.next ?? undefined,
    }
  );

  const scrollAreaRef = useRef<any>();
  const listBottom = useRef<any>(null);

  useIntersectionObserver({
    root: scrollAreaRef.current,
    target: listBottom,
    onIntersect: () => {
      if (isFetchingNextPage || !hasNextPage) return;
      fetchNextPage({
        pageParam: {
          pageIndex: pageIndex + 1,
          keyword,
        },
      });
      setPageIndex(pageIndex + 1);
    },
    enabled: hasNextPage,
  });

  const handleKeywordChange = (value: string) => {
    setPageIndex(0);
    setKeyword(value);
  };

  const handleSelectFont = (font: any) => {
    onChange(font);
    setIsPopoverOpen(false);
  };

  return (
    <EuiFormRow fullWidth label={label || 'Font'}>
      <EuiInputPopover
        fullWidth
        input={
          <EuiFieldText
            fullWidth
            onFocus={() => setIsPopoverOpen(true)}
            placeholder={`${t('common:Search')} ${label || 'Font'}...`}
            onChange={e => handleKeywordChange(e.target.value)}
            value={!isPopoverOpen && selectedFont ? selectedFont.name : keyword}
            spellCheck={false}
          />
        }
        isOpen={isPopoverOpen}
        closePopover={() => setIsPopoverOpen(false)}
      >
        <EuiFlexGroup
          ref={scrollAreaRef}
          direction="column"
          responsive={false}
          gutterSize="s"
          style={{ maxHeight: 200, overflow: 'auto' }}
        >
          {status === 'loading' ? (
            <p>{t('common:Loading...')}</p>
          ) : status === 'error' ? (
            <span>{t('No font found')}</span>
          ) : (
            <>
              {data?.pages.map((page, index) => (
                <div key={index}>
                  {page.results.map((item: any) => (
                    <EuiFlexItem
                      key={item.id}
                      className="font-item"
                      onClick={() => handleSelectFont(item)}
                    >
                      <EuiFlexGroup responsive={false} alignItems="center">
                        <EuiFlexItem>
                          <EuiListGroupItem
                            label={item.name}
                            color="text"
                            size="s"
                          />
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  ))}
                </div>
              ))}
            </>
          )}
          <EuiFlexItem>
            <div ref={listBottom} style={{ margin: '0 auto' }}>
              {isFetching && <EuiLoadingSpinner size="xl" />}
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiInputPopover>
    </EuiFormRow>
  );
};

export default FontSelector;
