import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  EuiPage,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPageBody,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSuperSelect,
  EuiIcon,
} from '@elastic/eui';
import GOTIGERIcon from 'assets/images/gotiger_logo_square.svg';

interface Props {
  button?: any;
  children: any;
}

const CenteredContentPage: React.FC<Props> = ({ children }) => {
  const { t, i18n } = useTranslation();

  const langOptions = [
    {
      value: 'en',
      inputDisplay: 'English',
    },
    {
      value: 'zh-TW',
      inputDisplay: '繁體中文(台灣)',
    },
  ];

  const onLangChange = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
  };

  return (
    <EuiPage paddingSize="none">
      <EuiPageBody>
        <EuiPageHeader
          iconType={GOTIGERIcon}
          pageTitle="GOTIGER"
          paddingSize="l"
        />
        <EuiPageContent
          borderRadius="none"
          hasShadow={false}
          style={{ display: 'flex' }}
        >
          <EuiPageContent
            verticalPosition="center"
            horizontalPosition="center"
            paddingSize="none"
            color="subdued"
            hasShadow={false}
            style={{ width: '95%', maxWidth: '480px' }}
          >
            <EuiPageContentBody>{children}</EuiPageContentBody>
          </EuiPageContent>
        </EuiPageContent>
        <footer>
          <EuiSpacer />
          <EuiFlexGroup justifyContent="center" alignItems="center">
            <EuiFlexItem
              grow={false}
              style={{ minWidth: 180, padding: '0 20px' }}
            >
              <EuiSuperSelect
                prepend={<EuiIcon type="globe" />}
                options={langOptions}
                valueOfSelected={i18n.language}
                onChange={value => onLangChange(value)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
        </footer>
      </EuiPageBody>
    </EuiPage>
  );
};

export default CenteredContentPage;
