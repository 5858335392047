import {
  EuiBasicTable,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFlyoutBody,
  EuiDescriptionList,
  EuiHorizontalRule,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiFlyoutFooter,
  EuiTableSelectionType,
  EuiButtonIcon,
} from '@elastic/eui';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import useDurationFormat from 'hooks/useDurationFormat';
import { css } from '@emotion/react';

import { Asset } from 'types/asset';
import { Playlist, PaginatedPlaylist } from 'types/playlist';
import { getPlaylist } from 'apis/Scheduler/playlist';
import AssetFlyout from '../Asset/AssetFlyout';
import { getAsset } from 'apis/Scheduler/asset';
import './style.scss';

const PlaylistTable = ({
  playlistList,
  pageIndex,
  pageSize,
  isLoading,
  selectedPlaylists,
  setPageIndex,
  handleDelete,
  addSelectedPlaylist,
}: {
  playlistList: PaginatedPlaylist;
  pageIndex: Number;
  pageSize: Number;
  isLoading: boolean;
  selectedPlaylists: Playlist[];
  setPageIndex: (pageIndex: number) => void;
  handleDelete?: (playlist: Playlist) => void;
  addSelectedPlaylist: (Playlist: Playlist) => void;
}) => {
  const pagination: any = {
    pageIndex,
    pageSize,
    totalItemCount: playlistList.count,
    hidePerPageOptions: true,
  };
  const { t, i18n } = useTranslation(['playlist', 'common']);

  const history = useHistory();
  const timeformat = useDurationFormat();
  const tableRef = useRef<EuiBasicTable | null>(null);

  const [previewPlaylistId, setPreviewPlaylistId] = useState<
    number | undefined
  >(undefined);
  const [previewAssetId, setPreviewAssetId] = useState<number | undefined>(
    undefined
  );

  const previewPlaylist = useQuery(
    ['playlist', { previewPlaylistId }],
    () => previewPlaylistId && getPlaylist(previewPlaylistId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const previewAsset = useQuery(
    ['asset', { previewAssetId }],
    () => previewAssetId && getAsset(previewAssetId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onTableChange = ({ page }: { page: { index: number } }) => {
    const { index: pageIndex } = page;

    setPageIndex(pageIndex);
  };

  const columns: any = [
    {
      width: '50px',
      mobileOptions: {
        width: '10px',
      },
      render: (item: Playlist) => (
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            disabled={
              selectedPlaylists.find(playlist => playlist.id == item.id) !=
              undefined
            }
            onClick={() => addSelectedPlaylist(item)}
            iconType="plus"
          />
        </EuiFlexItem>
      ),
    },
    {
      field: 'label_color',
      name: '',
      width: '6px',
      className: 'assetLabel',
      mobileOptions: {
        show: false,
      },
      render: (labelColor: string) => (
        <div
          style={{
            borderLeft: `6px solid ${labelColor}`,
            width: 6,
            height: '100%',
          }}
        ></div>
      ),
    },
    {
      field: 'id',
      name: '#',
      dataType: 'string',
      width: '60px',
      css: css`
        font-style: italic;
      `,
    },
    {
      field: 'name',
      name: t('common:Name', 'Playlist Name'),
      dataType: 'string',
      render: (assetName: string, item: Playlist) => (
        <span
          className="assetName"
          onClick={() => item.id && setPreviewPlaylistId(item.id)}
        >
          {assetName}
        </span>
      ),
    },
    {
      name: t('common:Actions'),
      actions: [
        {
          name: 'Edit',
          description: 'Edit playlist',
          icon: 'pencil',
          color: 'primary',
          type: 'icon',
          onClick: (playlist: Playlist) => {
            history.push(`/playlists/${playlist.id}/edit`);
          },
        },
        {
          name: 'Delete',
          description: 'Delete playlist',
          icon: 'trash',
          color: 'danger',
          type: 'icon',
          enabled: (item: Playlist) =>
            selectedPlaylists.find(playlist => playlist.id == item.id) ==
            undefined,
          onClick: (playlist: Playlist) => {
            handleDelete && handleDelete(playlist);
          },
        },
      ],
    },
  ];

  let previewFlyout = previewPlaylist.data && (
    <EuiFlyout
      size="auto"
      ownFocus
      onClose={() => {
        setPreviewPlaylistId(undefined);
      }}
      aria-labelledby="Playlist Preview"
    >
      <EuiFlyoutHeader hasBorder>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="m">
              <h2>{previewPlaylist.data.name}</h2>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiButton
                  onClick={() =>
                    history.push(`/playlists/${previewPlaylist.data.id}/edit`)
                  }
                >
                  {t('common:Edit', 'Edit Playlist')}
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton
                  onClick={() =>
                    history.push('/playlists/create', {
                      playlist: previewPlaylist.data,
                    })
                  }
                >
                  {t('Duplicate This Playlist')}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiDescriptionList
          listItems={[
            {
              title: t('Total Duration'),
              description: `${timeformat(
                previewPlaylist.data.assets.reduce(
                  (sum: number, item: { asset: Asset }) => {
                    return (sum += item.asset.duration);
                  },
                  0
                )
              )}`,
            },
          ]}
        />

        <EuiHorizontalRule />

        {previewPlaylist.data.assets.map(
          (item: { asset: Asset; index: number }) => (
            <EuiFlexGroup key={item.index} justifyContent="spaceBetween">
              <EuiFlexItem grow={false}>#{item.index + 1}</EuiFlexItem>
              <EuiFlexItem
                className="assetName"
                onClick={() =>
                  item.asset.id && setPreviewAssetId(item.asset.id)
                }
              >
                {item.asset.name}
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                {timeformat(item.asset.duration)}
              </EuiFlexItem>
            </EuiFlexGroup>
          )
        )}
      </EuiFlyoutBody>
      {/* <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <EuiButton
              onClick={() => {
                history.push(`/playlists/${previewPlaylist.data.id}/edit`);
              }}
            >
              Edit
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter> */}
    </EuiFlyout>
  );

  return (
    <>
      <EuiBasicTable
        ref={tableRef}
        rowProps={item => {
          if (
            selectedPlaylists.find(playlist => playlist.id == item.id) !=
            undefined
          ) {
            return { className: 'selected-playlist' };
          }
        }}
        items={playlistList.results}
        itemId="id"
        columns={columns}
        loading={isLoading}
        pagination={pagination}
        onChange={onTableChange}
        hasActions
        isExpandable
        noItemsMessage={
          isLoading ? 'Loading playlists...' : 'No playlist found'
        }
      />

      {previewFlyout}

      <AssetFlyout
        onClose={() => setPreviewAssetId(undefined)}
        asset={previewAsset.data}
        enterFromParent={true}
      />
    </>
  );
};

export default PlaylistTable;
