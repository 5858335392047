import { useState, useRef, useContext } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiFormRow,
  EuiInputPopover,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiImage,
  EuiBadge,
  EuiLoadingSpinner,
  EuiIcon,
  EuiFormControlLayout,
  EuiButtonEmpty,
} from '@elastic/eui';

import './style.scss';
import { Media } from 'types/media';
import { getMediaList } from 'apis/Scheduler/media';
import { MEDIA_TYPE_NAMES } from 'pages/Media/consts';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useDebounce from 'hooks/useDebounce';
import UserContext from 'contexts/UserContext';

const MediaSelector = ({
  label,
  filterType,
  selectedMedia,
  onChange,
  onClear,
  filterLayerTemplateName = null,
}: {
  label?: string;
  filterType?: any;
  selectedMedia?: any;
  onChange: (media: Media) => void;
  onClear?: () => void;
  filterLayerTemplateName?: string | null;
}) => {
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['media', 'common']);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [keyword, setKeyword] = useState('');
  const debouncedKeyword = useDebounce(keyword, 1000);
  const [filterByLayerTemplateName, setFilterByLayerTemplateName] = useState(
    true
  );

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery(
    [
      'media-selections',
      debouncedKeyword,
      filterType,
      filterByLayerTemplateName && filterLayerTemplateName
        ? filterLayerTemplateName
        : false,
    ],
    options => {
      return getMediaList({
        queryKey: [
          'media-selections',
          options.pageParam ?? {
            organizationId: userContext.currentOrganization?.id,
            pageIndex,
            keyword: debouncedKeyword,
            type: filterType,
            filterLayerTemplateName:
              filterByLayerTemplateName && filterLayerTemplateName,
          },
        ],
      });
    },
    {
      enabled: !!userContext.currentOrganization?.id,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => lastPage.next ?? undefined,
    }
  );

  const scrollAreaRef = useRef<any>();
  const listBottom = useRef<any>(null);
  const searchFieldRef = useRef<HTMLInputElement>(null);
  useIntersectionObserver({
    root: scrollAreaRef.current,
    target: listBottom,
    onIntersect: () => {
      if (isFetchingNextPage || !hasNextPage) return;
      fetchNextPage({
        pageParam: {
          organizationId: userContext.currentOrganization?.id,
          pageIndex: pageIndex + 1,
          keyword: debouncedKeyword,
          type: filterType,
          filterLayerTemplateName:
            filterByLayerTemplateName && filterLayerTemplateName,
        },
      });
      setPageIndex(pageIndex + 1);
    },
    enabled: hasNextPage,
  });

  const handleKeywordChange = (value: string) => {
    setPageIndex(0);
    setKeyword(value);
  };

  const handleSelectMedia = (media: Media) => {
    onChange(media);
    setIsPopoverOpen(false);
    setTimeout(() => searchFieldRef.current?.blur(), 500);
  };

  return (
    <>
      <EuiFormRow fullWidth label={label || t('common:Media')}>
        <EuiInputPopover
          fullWidth
          input={
            <EuiFormControlLayout
              fullWidth
              append={
                filterLayerTemplateName ? (
                  <EuiButtonEmpty
                    size="xs"
                    iconType={filterByLayerTemplateName ? 'cross' : 'filter'}
                    iconSide="right"
                    color={filterByLayerTemplateName ? 'primary' : 'ghost'}
                    onClick={() =>
                      setFilterByLayerTemplateName(!filterByLayerTemplateName)
                    }
                  >
                    {filterByLayerTemplateName
                      ? `Filtered by ${filterLayerTemplateName}`
                      : `Filter by ${filterLayerTemplateName}`}
                  </EuiButtonEmpty>
                ) : (
                  <></>
                )
              }
            >
              <EuiFieldText
                fullWidth
                inputRef={searchFieldRef}
                onClick={e => {
                  e.preventDefault();
                  setIsPopoverOpen(true);
                  searchFieldRef.current?.focus();
                }}
                placeholder={`${t('common:Search')} ${label || 'Media'}...`}
                onChange={e => handleKeywordChange(e.target.value)}
                value={
                  !isPopoverOpen && selectedMedia ? selectedMedia.name : keyword
                }
                spellCheck={false}
                append={
                  onClear && (
                    <EuiIcon
                      onClick={() => {
                        onClear();
                        setIsPopoverOpen(false);
                      }}
                      type="cross"
                    />
                  )
                }
              />
            </EuiFormControlLayout>
          }
          isOpen={isPopoverOpen}
          closePopover={() => setIsPopoverOpen(false)}
        >
          <EuiFlexGroup
            ref={scrollAreaRef}
            direction="column"
            responsive={false}
            gutterSize="s"
            style={{ maxHeight: '360px', overflow: 'auto' }}
          >
            {status === 'loading' ? (
              <p>{t('Loading...')}</p>
            ) : status === 'error' ? (
              <span>{t('No media found.')}</span>
            ) : (
              <>
                {data?.pages.map((page, index) => (
                  <div key={index}>
                    {page.results.map((item: Media) => (
                      <EuiFlexItem
                        key={item.id}
                        className="media-item"
                        onClick={() => handleSelectMedia(item)}
                      >
                        <EuiFlexGroup responsive={false} alignItems="center">
                          <EuiFlexItem grow={false} style={{ width: 72 }}>
                            <EuiImage
                              className="preview-image"
                              size="s"
                              alt=""
                              src={`${item.thumb_location}.png`}
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiTitle size="xs">
                              <p className="eui-textNoWrap">{item.name}</p>
                            </EuiTitle>
                            <small>{item.duration}</small>
                            <small>
                              {item.width}px * {item.height}px
                            </small>
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <EuiBadge>{MEDIA_TYPE_NAMES[item.type]}</EuiBadge>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexItem>
                    ))}
                  </div>
                ))}
              </>
            )}
            <EuiFlexItem>
              <div ref={listBottom} style={{ margin: '0 auto' }}>
                {isFetching && <EuiLoadingSpinner size="xl" />}
              </div>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiInputPopover>
      </EuiFormRow>
    </>
  );
};

export default MediaSelector;
