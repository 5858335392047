import { useEffect, useState, useRef } from 'react';
import { Prompt } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiPageHeader,
  EuiButton,
  EuiLoadingSpinner,
  EuiOverlayMask,
  EuiConfirmModal,
  EuiTitle,
  EuiTextColor,
} from '@elastic/eui';

import ScheduleContext from 'contexts/ScheduleContext';
import { Screen } from 'types/screen';
import { Schedule } from 'types/schedule';
import {
  getSchedule,
  postSchedules,
  deleteSchedule,
} from 'apis/Scheduler/schedule';
import { patchPlaylist } from 'apis/Scheduler/playlist';
import TimeGridEditor from './timeGridEditor';
import ColorModal from 'components/scheduler/ColorModal';
import { AxiosError } from 'axios';

const ScheduleEditor = ({
  screens,
  screen,
  setIsOpenScreenSelector,
  backToSchedule,
}: {
  screens: Screen[];
  screen: Screen;
  setIsOpenScreenSelector: (isOpen: boolean) => void;
  backToSchedule?: Schedule;
}) => {
  const { t, i18n } = useTranslation(['schedule', 'common']);
  const [after, setAfter] = useState<Date>();
  const [before, setBefore] = useState<Date>();
  const [
    isCancelChangeConfirmVisible,
    setIsCancelChangeConfirmVisible,
  ] = useState(false);
  const [isChangeColorVisible, setIsChangeColorVisible] = useState(false);
  const [changeColorItem, setChangeColorItem] = useState<any>();
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  const [errors, setErrors] = useState<{ [column: string]: string[] }>({});
  const onDateRangeChange = (newAfter: Date, newBefore: Date) => {
    // isDirty && setIsCancelChangeConfirmVisible(true);
    setAfter(newAfter);
    setBefore(newBefore);
  };

  const [editingSchedules, setEditingSchedules] = useState<Schedule[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const cancelChangeCallback = useRef<() => void>();

  const scheduleQuery = useQuery<Schedule[]>(
    ['schedule', { screen: screen?.id, after, before }],
    getSchedule,
    {
      enabled: screen != undefined && after != undefined && before != undefined,
      refetchOnWindowFocus: false,
    }
  );

  const postMutaion = useMutation(
    async (postSchedulesObj: any) => {
      return await postSchedules(
        postSchedulesObj.deleteSchedulesIds,
        postSchedulesObj.newSchedules.map((schedule: any) => {
          return { ...schedule, screen: screen?.id };
        })
      );
    },
    {
      onSuccess: scheduleQuery.refetch,
      onError: (error: AxiosError) => {
        setErrors(error.response?.data);
      },
    }
  );

  const postPlaylistMutaion = useMutation(patchPlaylist, {
    onSuccess: scheduleQuery.refetch,
    onError: scheduleQuery.refetch,
  });

  const handleSave = () => {
    setSaveButtonLoading(true);
    const editingScheduleIds = editingSchedules.map(s => s.id);
    const deleteSchedulesIds: any =
      scheduleQuery.data &&
      scheduleQuery.data
        .filter(s => editingScheduleIds.indexOf(s.id) < 0)
        .map(s => s.id);
    const newSchedules = editingSchedules.filter(s => s.id === -1);
    postMutaion.mutate({ deleteSchedulesIds, newSchedules });
  };

  useEffect(() => {
    if (scheduleQuery.isSuccess && !scheduleQuery.isLoading) {
      setEditingSchedules(scheduleQuery.data);
      setSaveButtonLoading(false);
      setIsDirty(false);
    }
  }, [scheduleQuery.data, scheduleQuery.isLoading, scheduleQuery.isSuccess]);

  return (
    <>
      <Prompt
        when={isDirty}
        message={t('common:modal:Are you sure to cancel changes?')}
      />
      {scheduleQuery.isFetching && (
        <EuiOverlayMask>
          <EuiLoadingSpinner />
        </EuiOverlayMask>
      )}
      <EuiPageHeader
        pageTitle={screen ? screen.name : t('common:Schedule')}
        rightSideItems={[
          screens.length > 1 && (
            <EuiButton
              onClick={() => {
                if (!isDirty) setIsOpenScreenSelector(true);
                else {
                  cancelChangeCallback.current = () => {
                    setIsOpenScreenSelector(true);
                  };
                  setIsCancelChangeConfirmVisible(true);
                }
              }}
              disabled={!screen}
              fill
            >
              {t('Change Screen')}
            </EuiButton>
          ),
          <EuiButton
            disabled={!isDirty}
            onClick={() => setIsCancelChangeConfirmVisible(true)}
          >
            {t('Cancel Changes')}
          </EuiButton>,
          <EuiButton
            disabled={!isDirty}
            onClick={handleSave}
            isLoading={saveButtonLoading}
            fill
          >
            {t('common:Save')}
          </EuiButton>,
        ]}
      />
      <TimeGridEditor
        screen={screen}
        setDateRange={onDateRangeChange}
        editingSchedules={editingSchedules}
        setEditingSchedules={schedules => {
          setIsDirty(true);
          setEditingSchedules(schedules);
        }}
        backToSchedule={backToSchedule}
        cancelChangeConfirm={(callback: any) => {
          if (!isDirty) callback();
          else {
            cancelChangeCallback.current = callback;
            setIsCancelChangeConfirmVisible(true);
          }
        }}
        handleChangeColorClick={(eventItem: any) => {
          setIsChangeColorVisible(true);
          setChangeColorItem(eventItem);
        }}
      />
      {isCancelChangeConfirmVisible && (
        <EuiConfirmModal
          onCancel={() => setIsCancelChangeConfirmVisible(false)}
          onConfirm={() => {
            setIsCancelChangeConfirmVisible(false);
            scheduleQuery.data && setEditingSchedules(scheduleQuery.data);
            cancelChangeCallback.current && cancelChangeCallback.current();
            setIsDirty(false);
            cancelChangeCallback.current = undefined;
          }}
          title={
            <EuiTitle>
              <EuiTextColor>{t('Cancel Changes')}</EuiTextColor>
            </EuiTitle>
          }
          cancelButtonText={t('common:Cancel')}
          confirmButtonText={t('common:Confirm', 'Cancel changes')}
        >
          <p>
            <EuiTextColor>
              {t('common:modal:Are you sure to cancel changes?')}
            </EuiTextColor>
          </p>
        </EuiConfirmModal>
      )}
      {isChangeColorVisible && (
        <ColorModal
          setIsChangeColorVisible={setIsChangeColorVisible}
          originColor={
            changeColorItem && changeColorItem.content_object.label_color
          }
          handleConfirm={(color: string) => {
            postPlaylistMutaion.mutate({
              ...changeColorItem.content_object,
              label_color: color,
              assets: changeColorItem.content_object.assets.map(
                (assetsItem: any) => {
                  return {
                    index: assetsItem.index,
                    asset: { id: assetsItem.asset },
                  };
                }
              ),
            });
          }}
        />
      )}
    </>
  );
};

export default ScheduleEditor;
