import { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FullCalendar, {
  CalendarApi,
  EventInput,
  Dictionary,
} from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Menu, Submenu, Item, useContextMenu } from 'react-contexify';
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiSelect,
  EuiDatePicker,
  useEuiTheme,
  useIsWithinBreakpoints,
  EuiPopover,
  EuiFormRow,
  EuiFieldText,
} from '@elastic/eui';
import moment, { Moment } from 'moment';
import tinycolor from 'tinycolor2';
import ToastContext from 'contexts/ToastContext';
import './style.scss';
import 'react-contexify/dist/ReactContexify.css';

const TimeGrid = ({
  calendarOptions,
  events,
  handleEditClick,
  handleAddClick,
  setEditingEventsItem,
  setDateRange,
  cancelChangeConfirm,
  handleChangeColorClick,
}: {
  calendarOptions: any;
  events: any;
  handleEditClick?: (eventItem: any) => void;
  handleAddClick: (selectedSlot: any, selectedEvents: any) => void;
  setEditingEventsItem: (editingEvents: any[]) => void;
  setDateRange?: (after: Date, before: Date) => void;
  cancelChangeConfirm?: (callback: () => void) => void;
  handleChangeColorClick?: (eventItem: any) => void;
}) => {
  const { euiTheme } = useEuiTheme();
  const { t, i18n } = useTranslation(['schedule', 'common']);
  const isMobile = useIsWithinBreakpoints(['xs', 's']);
  const hotKeyRange: any = useMemo(() => [1, 2, 3, 4, 5], []);

  const calendarRef = useCallback(calendar => {
    // calendar will be null if FullCalendar is unmounted
    const fcApi = calendar ? calendar.getApi() : null;
    setCalendarApi(fcApi);
  }, []);
  const [calendarApi, setCalendarApi] = useState<CalendarApi>();
  const [days, setDayElements] = useState<{ date: Date; el: HTMLElement }[]>(
    []
  );
  const toastContext = useContext(ToastContext);

  const [slotDuration, setSlotDuration] = useState<string>('00:30:00');
  const [selectedDate, setSeletedDate] = useState();
  const [isEditDisabled, setIsEditDisabled] = useState(true);

  const [eventItems, setEventItems] = useState<EventInput[]>([]);
  const [selectedEventsItem, setSelectedEventsItem] = useState<{
    events: Dictionary[];
    from: 'shift' | 'ctrl' | null;
  }>();
  const [weekInfo, setWeekInfo] = useState<any>();
  const [copiedEvents, setCopiedEvents] = useState<{
    eventsItem: Dictionary[];
    isFromCut: boolean;
  }>();

  const [selectedSlot, setSelectedSlot] = useState<{
    start: Date;
    end: Date;
  }>();
  const [selectedDay, setSelectedDay] = useState<Date>();
  const [hotKeys, setHotKeys] = useState<any[]>(
    Array.from(hotKeyRange).fill(undefined)
  );

  const [shiftKeyDown, setShiftKeyDown] = useState<boolean>(false);
  const [ctrlKeyDown, setCtrlKeyDown] = useState<boolean>(false);
  const [selectedEventByShift, setSelectedEventByShift] = useState<any>();
  const [isHotKeysPopoverOpen, setIsHotKeysPopoverOpen] = useState(false);

  const { show, hideAll } = useContextMenu({
    id: 'EDIT_CONTEXT_MENU',
  });

  const handleContextMenu = (event: any) => {
    event.preventDefault();
    show(event);
  };

  const handleDatesSet = useCallback(
    info => {
      setDateRange && setDateRange(info.start, info.end);
      setWeekInfo(info);
    },
    [setDateRange]
  );

  const handleSlotSelect = useCallback(
    selectionInfo => {
      hideAll();
      setSelectedSlot({ start: selectionInfo?.start, end: selectionInfo.end });
      setSelectedEventsItem(undefined);
      setIsEditDisabled(true);
      if (calendarOptions['selectCallback']) {
        calendarOptions.selectCallback(selectionInfo);
      }
    },
    [calendarOptions, hideAll]
  );

  const handleSlotUnselect = useCallback(() => {
    setSelectedSlot(undefined);
    setSelectedEventsItem(undefined);
    setIsEditDisabled(true);
    setSelectedDay(undefined);
  }, []);

  const handleSelectDay = useCallback(
    (date: Date) => {
      const now = new Date().getTime();
      setSelectedDay(date);
      const end = new Date(date.getTime() + 86400000);
      calendarApi?.select(date, end);
      setSelectedSlot({ start: date, end });
      setSelectedEventsItem(undefined);
      setIsEditDisabled(true);
      if (calendarOptions['selectDayCallbak'])
        calendarOptions['selectDayCallbak'](date);
    },
    [calendarApi, calendarOptions]
  );

  const handleClickEventBlock = useCallback(
    info => {
      setIsEditDisabled(false);
      if (shiftKeyDown) {
        if (selectedEventByShift) {
          if (
            moment(selectedEventByShift.start).startOf('day').format() !=
              moment(info.event.extendedProps.scheduleData.start_at)
                .startOf('day')
                .format() ||
            (selectedEventsItem && selectedEventsItem.events.length > 1)
          ) {
            setSelectedEventByShift(info.event);
            setSelectedEventsItem({
              events: [info.event.extendedProps.scheduleData],
              from: 'shift',
            });
          } else if (
            selectedEventsItem &&
            selectedEventsItem?.events.length > 0
          ) {
            setSelectedEventsItem({
              events: events.filter((s: any) =>
                moment(selectedEventsItem.events[0].start_at) <
                moment(info.event.extendedProps.scheduleData.start_at)
                  ? moment(s.start_at) >=
                      moment(selectedEventsItem.events[0].start_at) &&
                    moment(s.start_at) <=
                      moment(info.event.extendedProps.scheduleData.start_at)
                  : moment(s.start_at) <=
                      moment(selectedEventsItem.events[0].start_at) &&
                    moment(s.start_at) >=
                      moment(info.event.extendedProps.scheduleData.start_at)
              ),
              from: 'shift',
            });
            setIsEditDisabled(true);
          }
        } else if (
          selectedEventsItem &&
          selectedEventsItem.events.length == 1
        ) {
          if (
            moment(selectedEventsItem.events[0].start_at)
              .startOf('day')
              .format() !=
            moment(info.event.extendedProps.scheduleData.start_at)
              .startOf('day')
              .format()
          ) {
            setSelectedEventByShift(info.event);
            setSelectedEventsItem({
              events: [info.event.extendedProps.scheduleData],
              from: 'shift',
            });
          } else {
            setSelectedEventsItem({
              events: events
                .map((event: any) => event.extendedProps.scheduleData)
                .filter((item: any) =>
                  moment(selectedEventsItem.events[0].start_at) <
                  moment(info.event.extendedProps.scheduleData.start_at)
                    ? moment(item.start_at) >=
                        moment(selectedEventsItem.events[0].start_at) &&
                      moment(item.start_at) <=
                        moment(info.event.extendedProps.scheduleData.start_at)
                    : moment(item.start_at) <=
                        moment(selectedEventsItem.events[0].start_at) &&
                      moment(item.start_at) >=
                        moment(info.event.extendedProps.scheduleData.start_at)
                ),
              from: 'shift',
            });
            setIsEditDisabled(true);
          }
        } else {
          setSelectedEventByShift(info.event);
          setSelectedEventsItem({
            events: [info.event.extendedProps.scheduleData],
            from: 'shift',
          });
        }
      } else if (ctrlKeyDown) {
        const selectedEvent =
          selectedEventsItem?.events &&
          selectedEventsItem.events.find(
            (item: any) =>
              item.start_at == info.event.extendedProps.scheduleData.start_at
          );
        if (selectedEventsItem && !selectedEvent) {
          setSelectedEventsItem({
            events: [
              ...selectedEventsItem.events,
              info.event.extendedProps.scheduleData,
            ],
            from: 'ctrl',
          });
        } else {
          setSelectedEventsItem({
            events: [info.event.extendedProps.scheduleData],
            from: 'ctrl',
          });
        }
      } else {
        setSelectedEventsItem({
          events: [info.event.extendedProps.scheduleData],
          from: null,
        });
      }
    },
    [
      ctrlKeyDown,
      events,
      selectedEventByShift,
      selectedEventsItem,
      shiftKeyDown,
    ]
  );

  const handleEventDropAndResize = useCallback(
    info => {
      info.revert();
      const existEvent: any = calendarApi
        ?.getEvents()
        .map((event: any) => event.extendedProps.scheduleData)
        .filter(
          (item: any) =>
            (moment(item.end_at) > moment(info?.event.start) &&
              moment(item.end_at) < moment(info?.event.end)) ||
            (moment(item.start_at) < moment(info?.event.end) &&
              moment(item.start_at) >= moment(info?.event.start))
        );
      if (
        info?.event.start &&
        info?.event.end &&
        (existEvent.length == 0 ||
          (existEvent.length == 1 &&
            existEvent[0].id == info.event.extendedProps.scheduleData.id))
      ) {
        setEditingEventsItem(
          events
            .map((event: any) => event.extendedProps.scheduleData)
            .map((item: any) => {
              if (
                moment(item.start_at).toISOString() ==
                info.oldEvent.start.toISOString()
              ) {
                return {
                  ...item,
                  id: -1,
                  start_at: info.event.start.toISOString(),
                  end_at: info.event.end.toISOString(),
                  duration: moment
                    .duration(
                      moment(info.event.end).diff(moment(info.event.start)),
                      'milliseconds'
                    )
                    .asSeconds(),
                };
              } else return item;
            })
        );
      }
    },
    [calendarApi, events, setEditingEventsItem]
  );

  const getDayEvents = (): any[] => {
    const events: any[] = [];
    if (!selectedDay) return events;
    const end = new Date(selectedDay.getTime() + 86400000);
    const allEvents = calendarApi?.getEvents() || [];
    for (let index = 0; index < allEvents.length; index++) {
      const event = allEvents[index];
      if (
        new Date(event.extendedProps.scheduleData.start_at) < end &&
        new Date(event.extendedProps.scheduleData.end_at) > selectedDay
      ) {
        events.push(event.extendedProps.scheduleData);
      }
    }
    return events;
  };

  const handleCopy = () => {
    let cpEvents: EventInput[] = [];
    if (selectedEventsItem) {
      cpEvents = selectedEventsItem.events;
    } else if (selectedDay) {
      cpEvents = getDayEvents();
    }
    const sortedCopiedEvents = cpEvents.sort((a: any, b: any) => {
      return new Date(a.start_at).getTime() - new Date(b.start_at).getTime();
    });
    setCopiedEvents({
      eventsItem: sortedCopiedEvents,
      isFromCut: false,
    });
  };

  const handleInsert = () => {
    const insertEvents = genInsertEvents('insert', copiedEvents);
    if (insertEvents && insertEvents.length > 0)
      setEditingEventsItem([
        ...events.map((event: any) => event.extendedProps.scheduleData),
        ...insertEvents,
      ]);
    calendarApi?.unselect();
  };

  const handleCut = () => {
    if (selectedEventsItem) {
      setCopiedEvents({
        eventsItem: selectedEventsItem.events,
        isFromCut: true,
      });
      setEditingEventsItem(
        events
          .filter(
            (event: any) =>
              event &&
              !selectedEventsItem?.events.find(
                (se: any) =>
                  se.start_at === event.extendedProps.scheduleData.start_at
              )
          )
          .map((event: any) => event.extendedProps.scheduleData)
      );
    }
  };

  const handleRemove = () => {
    if (!selectedDay) {
      setEditingEventsItem(
        events
          .filter(
            (item: any) =>
              item &&
              !selectedEventsItem?.events.find(
                (event: any) =>
                  event.start_at === item.extendedProps.scheduleData.start_at
              )
          )
          .map((event: any) => event.extendedProps.scheduleData)
      );
    } else {
      const dayEvents = getDayEvents();
      if (
        new Date(dayEvents[0].start_at) < selectedDay ||
        new Date(dayEvents[dayEvents.length - 1].end_at) >
          new Date(selectedDay.getTime() + 86400000)
      ) {
        displayNotDeletableMsg();
      } else {
        const dayEvents = getDayEvents();
        const ids: number[] = [];
        dayEvents.forEach(s => s.id && ids.push(parseInt(s.id)));
        setEditingEventsItem(
          events
            .filter((item: any) => item && ids.indexOf(parseInt(item.id)) < 0)
            .map((event: any) => event.extendedProps.scheduleData)
        );
      }
    }
    setSelectedEventsItem(undefined);
  };

  const handleReplace = () => {
    if (selectedDay) {
      const existEvents: any = calendarApi
        ?.getEvents()
        .filter(
          (event: any) =>
            (moment(event.end) >= moment(selectedDay) &&
              moment(event.end) < moment(selectedDay).endOf('day')) ||
            (moment(event.start) < moment(selectedDay).endOf('day') &&
              moment(event.start) >= moment(selectedDay))
        )
        .map(s => s.extendedProps.scheduleData);
      const newEvents: EventInput[] = [];
      copiedEvents?.eventsItem.forEach(s => {
        const startAt = moment(selectedDay)
          .add(
            moment(s.start_at).diff(moment(s.start_at).startOf('day'), 's'),
            's'
          )
          .toISOString();
        newEvents.push({
          ...s,
          start_at: startAt,
          end_at: moment(startAt).add(s.duration, 's').toISOString(),
        });
      });
      if (newEvents && newEvents.length > 0) {
        setEditingEventsItem([
          ...events
            .filter(
              (event: any) =>
                !existEvents?.find(
                  (s: any) =>
                    s.start_at === event.extendedProps.scheduleData.start_at
                )
            )
            .map((event: any) => event.extendedProps.scheduleData),
          ...newEvents.map(ns => {
            return { ...ns, id: -1 };
          }),
        ]);
      }
    } else if (selectedSlot || selectedEventsItem) {
      if (!copiedEvents) return;
      const start = selectedSlot
        ? selectedSlot.start
        : selectedEventsItem && selectedEventsItem.events[0].start_at;

      let copiedDuration = 0;
      if (copiedEvents.eventsItem.length == 1) {
        copiedDuration = copiedEvents.eventsItem[0].duration;
      } else {
        copiedDuration = moment(
          copiedEvents?.eventsItem[copiedEvents?.eventsItem.length - 1].end_at
        ).diff(moment(copiedEvents?.eventsItem[0].start_at), 's');
      }
      const existEvents: any = calendarApi
        ?.getEvents()
        .filter(
          (event: any) =>
            moment(event.start) >= moment(start) &&
            moment(event.start) < moment(start).add(copiedDuration, 's')
        )
        .map(s => s.extendedProps.scheduleData);
      const newEvents = genInsertEvents('replace', copiedEvents);
      if (newEvents && newEvents.length > 0) {
        setEditingEventsItem([
          ...events
            .filter(
              (event: any) =>
                !existEvents?.find(
                  (s: any) =>
                    s.start_at === event.extendedProps.scheduleData.start_at
                )
            )
            .map((event: any) => event.extendedProps.scheduleData),
          ...newEvents.map((ne: any) => {
            return { ...ne, id: -1 };
          }),
        ]);
      }
    }
    calendarApi?.unselect();
  };

  const handleSwitch = useCallback(
    (key: any) => {
      if (selectedEventsItem && selectedEventsItem?.events.length == 1) {
        const srcEvent = selectedEventsItem.events[0];
        const destEvent: any = events
          .map((event: any) => event.extendedProps.scheduleData)
          .find((event: any) =>
            key == 'ArrowUp'
              ? moment(event.end_at).toISOString() ==
                moment(srcEvent.start_at).toISOString()
              : moment(event.start_at).toISOString() ==
                moment(srcEvent.end_at).toISOString()
          );

        const targetStartAt = destEvent
          ? key == 'ArrowUp'
            ? destEvent.start_at
            : moment(destEvent.end_at)
                .clone()
                .subtract(srcEvent.duration, 's')
                .toISOString()
          : key == 'ArrowUp'
          ? moment(srcEvent.start_at)
              .subtract(moment.duration(slotDuration))
              .toISOString()
          : moment(srcEvent.start_at)
              .add(moment.duration(slotDuration))
              .toISOString();
        if (
          (weekInfo.view.type == 'timeGridWeek' &&
            (moment(targetStartAt) < weekInfo.start ||
              moment(targetStartAt).clone().add(srcEvent.duration, 's') >
                weekInfo.end)) ||
          (weekInfo.view.type == 'timeGridCustomDay' &&
            moment(targetStartAt) < moment(srcEvent.start_at).startOf('day'))
        )
          return;
        // console.log(`src start at: ${moment(srcSchedule.start_at).toLocaleString()}`);
        // console.log(`target start at: ${moment(targetStartAt).toLocaleString()}`);

        let eventsItem;
        if (!destEvent) {
          eventsItem = events
            .map((event: any) => event.extendedProps.scheduleData)
            .map((item: any) => {
              if (item.start_at == srcEvent.start_at) {
                return {
                  ...item,
                  id: -1,
                  start_at: targetStartAt,
                  end_at: moment(targetStartAt)
                    .clone()
                    .add(item.duration, 's')
                    .toISOString(),
                };
              } else return item;
            });
        } else {
          eventsItem = events
            .map((event: any) => event.extendedProps.scheduleData)
            .map((item: any) => {
              if (item.start_at == srcEvent.start_at) {
                return {
                  ...item,
                  id: -1,
                  start_at: targetStartAt,
                  end_at: moment(targetStartAt)
                    .clone()
                    .add(item.duration, 's')
                    .toISOString(),
                };
              } else if (item.start_at == destEvent.start_at) {
                const startAt =
                  key == 'ArrowDown'
                    ? srcEvent.start_at
                    : moment(srcEvent.end_at)
                        .clone()
                        .subtract(item.duration, 's')
                        .toISOString();
                return {
                  ...item,
                  id: -1,
                  start_at: startAt,
                  end_at: moment(startAt)
                    .clone()
                    .add(item.duration, 's')
                    .toISOString(),
                };
              } else return item;
            });
        }
        setEditingEventsItem(eventsItem);
        const selectedEventItem: any = eventsItem.find(
          (item: any) => item.start_at == targetStartAt
        );
        selectedEventItem &&
          setSelectedEventsItem({ events: [selectedEventItem], from: null });
      }
    },
    [events, selectedEventsItem, setEditingEventsItem, slotDuration, weekInfo]
  );

  const genInsertEvents = useCallback(
    (type: string, srcEvents: any): any => {
      if (!srcEvents) return;
      let copiedDuration = 0;
      if (srcEvents.eventsItem.length == 1) {
        copiedDuration = srcEvents.eventsItem[0].duration;
      } else {
        copiedDuration = moment(
          srcEvents?.eventsItem[srcEvents?.eventsItem.length - 1].end_at
        ).diff(moment(srcEvents?.eventsItem[0].start_at), 's');
      }
      if (type == 'insert' && weekInfo.view.type == 'timeGridWeek') {
        const insertEnd = moment(selectedSlot?.start).add(copiedDuration, 's');
        const existEvents: any = calendarApi
          ?.getEvents()
          .filter(
            (event: any) =>
              moment(event.start) >= moment(selectedSlot?.start) &&
              moment(event.start) < insertEnd
          );
        if (existEvents.length > 0) {
          toastContext.setToasts([
            ...toastContext.toasts,
            {
              id: 'insert-denied',
              title: 'Action denied.',
              color: 'danger',
              text: <p>There is not enough space to paste all the items.</p>,
            },
          ]);
          return [];
        }
      }
      const newEvents: EventInput[] = [];
      srcEvents.eventsItem.forEach((srcEvent: any) => {
        const startBegin = selectedSlot
          ? selectedSlot.start
          : selectedEventsItem && selectedEventsItem.events[0].start_at;
        const startAt = moment(startBegin)
          .add(
            moment(srcEvent.start_at).diff(
              moment(srcEvents.eventsItem[0].start_at),
              's'
            ),
            's'
          )
          .toISOString();
        newEvents.push({
          ...srcEvent,
          start_at: startAt,
          end_at: moment(startAt).add(srcEvent.duration, 's').toISOString(),
          id: -1,
        });
      });
      return newEvents;
    },
    [calendarApi, selectedEventsItem, selectedSlot, toastContext, weekInfo]
  );

  const handleInsertByHotkey = useCallback(
    (key: number) => {
      if (!hotKeys[key - 1]) return;
      if (weekInfo.view.type == 'timeGridWeek' && !selectedSlot) return;
      const insertEvents = genInsertEvents('insert', {
        eventsItem: [hotKeys[key - 1]],
      });
      if (insertEvents && insertEvents.length > 0)
        setEditingEventsItem([
          ...events.map((event: any) => event.extendedProps.scheduleData),
          ...insertEvents,
        ]);
      calendarApi?.unselect();
      setSelectedSlot(undefined);
      setSelectedEventsItem({ events: insertEvents, from: null });
    },
    [
      calendarApi,
      events,
      genInsertEvents,
      hotKeys,
      selectedSlot,
      setEditingEventsItem,
      weekInfo,
    ]
  );

  const displayNotDeletableMsg = () => {
    toastContext.setToasts([
      ...toastContext.toasts,
      {
        id: 'delete-denied',
        title: 'Action denied.',
        color: 'danger',
        text: (
          <p>
            The schedules in selected day contains schedule that span the day
          </p>
        ),
      },
    ]);
  };

  const hasEventInTimeRange = useMemo(() => {
    if (!selectedDay) return false;
    const end = new Date(selectedDay.getTime() + 86400);
    for (let index = 0; index < events.length; index++) {
      const event = events[index];
      if (
        new Date(event.extendedProps.scheduleData.start_at) < end &&
        new Date(event.extendedProps.scheduleData.end_at) > selectedDay
      ) {
        return true;
      }
    }
    return false;
  }, [events, selectedDay]);

  useEffect(() => {
    Object.keys(calendarOptions).forEach((k: any) => {
      calendarApi?.setOption(k, calendarOptions[k]);
    });
    'initialDate' in calendarOptions &&
      calendarApi?.gotoDate(calendarOptions['initialDate']);
  }, [calendarApi, calendarOptions]);

  const keydownListener = useCallback(
    (e: KeyboardEvent) => {
      if (hotKeyRange.indexOf(parseInt(e.key)) > -1) {
        handleInsertByHotkey(parseInt(e.key));
      }
      if (e.shiftKey) {
        setShiftKeyDown(true);
      }
      if (e.key == 'ArrowUp' || e.key == 'ArrowDown') {
        e.preventDefault();
        handleSwitch(e.key);
      }
      if (
        weekInfo &&
        weekInfo.view.type == 'timeGridCustomDay' &&
        navigator.userAgent.includes('Mac')
          ? e.key == 'Meta'
          : e.ctrlKey
      ) {
        setCtrlKeyDown(true);
      }
    },
    [handleInsertByHotkey, handleSwitch, hotKeyRange, weekInfo]
  );

  const keyupListener = (e: KeyboardEvent) => {
    setShiftKeyDown(false);
    setCtrlKeyDown(false);
    setSelectedEventByShift(undefined);
  };

  useEffect(() => {
    const items = events.map((event: any) => {
      return {
        ...event,
        borderColor:
          selectedEventsItem?.events &&
          selectedEventsItem.events.find(
            (se: any) =>
              se.start_at == event.extendedProps.scheduleData.start_at
          )
            ? 'black'
            : 'transparent',
        textColor: tinycolor(
          event.extendedProps?.scheduleData.content_object?.label_color
        ).isDark()
          ? 'white'
          : 'black' || 'auto',
      };
    });
    setEventItems(items);
  }, [events, selectedEventsItem]);

  useEffect(() => {
    window.addEventListener('keydown', keydownListener);
    window.addEventListener('keyup', keyupListener);

    return () => {
      window.removeEventListener('keydown', keydownListener);
      window.removeEventListener('keyup', keyupListener);
    };
  }, [keydownListener]);

  useEffect(() => {
    if (calendarApi) {
      days.forEach(day => {
        if (!day.el.onclick)
          day.el.addEventListener('click', () => handleSelectDay(day.date));
      });
      return () =>
        days.forEach(day => {
          day.el.removeEventListener('click', () => handleSelectDay(day.date));
        });
    }
  }, [days, calendarApi, handleSelectDay]);

  return (
    <div>
      <EuiFlexGroup
        justifyContent="flexEnd"
        style={
          !isMobile
            ? {
                position: 'sticky',
                top: 48,
                zIndex: 99,
                backgroundColor: euiTheme.colors.emptyShade,
              }
            : {}
        }
      >
        {weekInfo && weekInfo.view.type == 'timeGridWeek' && (
          <EuiFlexGroup justifyContent="flexStart" alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiSelect
                options={[
                  { text: '1 mins', value: '00:01:00' },
                  { text: '5 mins', value: '00:05:00' },
                  { text: '10 mins', value: '00:10:00' },
                  { text: '30 mins', value: '00:30:00' },
                  { text: '1 hours', value: '01:00:00' },
                  { text: '2 hours', value: '02:00:00' },
                ]}
                value={slotDuration}
                onChange={e => setSlotDuration(e.target.value)}
                prepend={t('Slot Duration')}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiDatePicker
                selected={selectedDate}
                adjustDateOnChange={false}
                onChange={(date: Moment) => {
                  cancelChangeConfirm
                    ? cancelChangeConfirm(() => {
                        calendarApi?.gotoDate(date?.toDate());
                      })
                    : calendarApi?.gotoDate(date?.toDate());
                }}
                placeholder={t('Select date to see week')}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
        <EuiFlexItem grow={false}>
          <EuiButton
            className="schedule-function"
            onClick={() => {
              handleAddClick(selectedSlot, selectedEventsItem?.events);
              setSelectedEventsItem(undefined);
            }}
            disabled={
              weekInfo && weekInfo.view.type == 'timeGridWeek'
                ? !selectedSlot
                : !selectedSlot &&
                  (!selectedEventsItem ||
                    (selectedEventsItem.events.length > 0 &&
                      selectedEventsItem.from == 'shift'))
            }
          >
            {t('Add')}
          </EuiButton>
        </EuiFlexItem>
        {handleEditClick && (
          <EuiFlexItem grow={false}>
            <EuiButton
              onClick={() =>
                selectedEventsItem &&
                handleEditClick(selectedEventsItem.events[0])
              }
              disabled={isEditDisabled}
            >
              {t('Edit')}
            </EuiButton>
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <EuiButton
            className="schedule-function"
            disabled={
              (!selectedEventsItem ||
                selectedEventsItem?.events.length == 0 ||
                selectedEventsItem.from == 'ctrl') &&
              !selectedDay
            }
            onClick={handleCopy}
          >
            {t('Copy')}
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton
            disabled={
              (!selectedEventsItem ||
                selectedEventsItem?.events.length == 0 ||
                selectedEventsItem.from == 'ctrl') &&
              !selectedDay
            }
            onClick={handleCut}
          >
            {t('Cut')}
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton
            className="schedule-function"
            disabled={
              copiedEvents === undefined ||
              (selectedSlot == undefined &&
                (selectedEventsItem?.events.length == 0 ||
                  !selectedEventsItem)) ||
              selectedDay != undefined
            }
            onClick={() => handleInsert()}
          >
            {t('Insert')}
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton
            className="schedule-function"
            disabled={
              !copiedEvents ||
              (selectedEventsItem && selectedEventsItem.events.length > 1) ||
              (!selectedDay && !selectedEventsItem && !selectedSlot)
            }
            onClick={handleReplace}
          >
            {t('Replace')}
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton
            className="schedule-function"
            disabled={
              !hasEventInTimeRange &&
              selectedEventsItem === undefined &&
              !selectedDay
            }
            onClick={handleRemove}
          >
            {t('Remove')}
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiPopover
            ownFocus={false}
            button={
              <EuiButton
                className="schedule-function"
                disabled={
                  !(selectedEventsItem && selectedEventsItem.events.length == 1)
                }
                onClick={() => setIsHotKeysPopoverOpen(true)}
              >
                {t('Hot Keys')}
              </EuiButton>
            }
            isOpen={isHotKeysPopoverOpen}
            closePopover={() => setIsHotKeysPopoverOpen(false)}
          >
            {hotKeyRange.map((targetIndex: number) => {
              return (
                <EuiFlexGroup key={targetIndex}>
                  <EuiFlexItem grow={false}>
                    <EuiFieldText
                      compressed
                      disabled={true}
                      name="input"
                      placeholder={`Slot ${targetIndex}`}
                      value={
                        hotKeys[targetIndex - 1] &&
                        hotKeys[targetIndex - 1].content_object.name
                      }
                      style={
                        hotKeys[targetIndex - 1]
                          ? { WebkitTextFillColor: 'white' }
                          : {}
                      }
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton
                      size="s"
                      onClick={() => {
                        setHotKeys(
                          hotKeys.map((eventItem: any, index: number) => {
                            if (index + 1 == targetIndex)
                              return (
                                selectedEventsItem &&
                                selectedEventsItem.events[0]
                              );
                            else return eventItem;
                          })
                        );
                      }}
                    >
                      {t('Assign')}
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              );
            })}
          </EuiPopover>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <FullCalendar
        ref={calendarRef}
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView={calendarOptions['initialView']}
        views={calendarOptions['views']}
        customButtons={{
          prevBtn: {
            icon: 'chevron-left',
            click: () => {
              cancelChangeConfirm
                ? cancelChangeConfirm(() => {
                    calendarApi?.prev();
                  })
                : calendarApi?.prev();
            },
          },
          nextBtn: {
            icon: 'chevron-right',
            click: () => {
              cancelChangeConfirm
                ? cancelChangeConfirm(() => {
                    calendarApi?.next();
                  })
                : calendarApi?.next();
            },
          },
        }}
        nowIndicator={calendarOptions['nowIndicator']}
        allDaySlot={false}
        selectable={true}
        selectMirror={true}
        eventOrder="-priority"
        datesSet={handleDatesSet}
        unselectCancel=".schedule-function"
        eventClick={handleClickEventBlock}
        dayHeaderDidMount={(day: any) => {
          setDayElements(curr => [...curr, { date: day.date, el: day.el }]);
        }}
        select={handleSlotSelect}
        unselect={handleSlotUnselect}
        slotDuration={slotDuration}
        events={eventItems}
        eventDidMount={(e: any) => {
          e.el.addEventListener('dblclick', () => {
            handleEditClick &&
              selectedEventsItem &&
              handleEditClick(selectedEventsItem.events[0]);
          });
          e.el.addEventListener('contextmenu', (jsEvent: any) => {
            if (e.event.id == '') return;
            jsEvent.preventDefault();
            calendarApi?.unselect();
            handleContextMenu(jsEvent);
            handleClickEventBlock(e);
          });
          if (calendarOptions['eventDidMountCallback'])
            calendarOptions['eventDidMountCallback'](e);
        }}
        eventDrop={handleEventDropAndResize}
        eventResize={handleEventDropAndResize}
      />

      <Menu id={'EDIT_CONTEXT_MENU'}>
        {handleEditClick && (
          <Item
            id="edit"
            onClick={() => {
              selectedEventsItem &&
                handleEditClick(selectedEventsItem.events[0]);
            }}
            disabled={isEditDisabled}
          >
            {t('Edit')}
          </Item>
        )}
        <Item
          id="copy"
          onClick={handleCopy}
          disabled={
            (!selectedEventsItem ||
              selectedEventsItem?.events.length == 0 ||
              selectedEventsItem.from == 'ctrl') &&
            !selectedDay
          }
        >
          {t('Copy')}
        </Item>
        <Item
          id="cut"
          onClick={handleCut}
          disabled={
            (!selectedEventsItem ||
              selectedEventsItem?.events.length == 0 ||
              selectedEventsItem.from == 'ctrl') &&
            !selectedDay
          }
        >
          {t('Cut')}
        </Item>
        <Item
          id="remove"
          onClick={handleCut}
          disabled={
            !hasEventInTimeRange &&
            selectedEventsItem === undefined &&
            !selectedDay
          }
        >
          {t('Remove')}
        </Item>
        <Submenu label={t('Assign Hot Key')} arrow="">
          {hotKeyRange.map((targetIndex: number) => {
            return (
              <Item
                key={targetIndex}
                onClick={() =>
                  setHotKeys(
                    hotKeys.map((eventItem: any, index: number) => {
                      if (index + 1 == targetIndex)
                        return (
                          selectedEventsItem && selectedEventsItem.events[0]
                        );
                      else return eventItem;
                    })
                  )
                }
              >{`Slot ${targetIndex}`}</Item>
            );
          })}
        </Submenu>
        {handleChangeColorClick &&
          selectedEventsItem &&
          selectedEventsItem.events[0].type == 'playlist' && (
            <Item
              id="change-color"
              onClick={() => {
                selectedEventsItem &&
                  handleChangeColorClick(selectedEventsItem.events[0]);
              }}
            >
              {t('Change Color')}
            </Item>
          )}
      </Menu>
    </div>
  );
};

export default TimeGrid;
