import { useState, useEffect, useCallback, Fragment } from 'react';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiPanel,
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  EuiIcon,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import TimeGridEditor from '../MultiPlaylistEditor/timeGridEditor';
import useBeforeUnload from 'hooks/useBeforeUnload';

import useDurationFormat from 'hooks/useDurationFormat';
import { Playlist } from 'types/playlist';

const PlaylistAssetEditor = ({
  mode,
  playlist,
  handleRemoveAsset,
  setPreviewAssetId,
  setPlaylist,
}: {
  mode: string;
  playlist: Playlist;
  handleRemoveAsset: (index: number) => void;
  setPreviewAssetId: (assetId: number | undefined) => void;
  setPlaylist: (playlist: Playlist) => void;
}) => {
  const timeformat = useDurationFormat();
  const { t, i18n } = useTranslation(['playlist', 'common']);

  const [playlistTotalLength, setPlaylistTotalLength] = useState<number>(0);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setPlaylistTotalLength(
      playlist.assets.reduce((totalDuration, item) => {
        return (totalDuration += item.asset.duration);
      }, 0)
    );
  }, [playlist]);

  useBeforeUnload({ when: isDirty });

  return (
    <>
      {mode == 'list' ? (
        <EuiDroppable droppableId={`${playlist.id}`}>
          <ul>
            {playlist?.assets.map((item, index) => (
              <Fragment key={item.index}>
                <li key={item.index}>
                  <EuiDraggable
                    index={item.index}
                    draggableId={`${playlist.id}-${item.index.toString()}`}
                    key={item.index}
                  >
                    <EuiPanel
                      style={{
                        borderLeft: `5px solid ${item.asset.label_color}`,
                      }}
                    >
                      <EuiFlexGroup alignItems="center">
                        <EuiFlexItem grow={false}>#{index + 1}</EuiFlexItem>
                        <EuiFlexItem
                          onClick={() => setPreviewAssetId(item.asset.id)}
                        >
                          <strong>{item.asset.name}</strong>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                          {timeformat(item.asset.duration)}
                        </EuiFlexItem>
                        <EuiFlexItem
                          grow={false}
                          onClick={() => handleRemoveAsset(index)}
                        >
                          <EuiIcon type="cross" size="s"></EuiIcon>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiPanel>
                  </EuiDraggable>
                </li>
                <EuiSpacer size="xs" />
              </Fragment>
            ))}
          </ul>
        </EuiDroppable>
      ) : (
        playlist.id && (
          <TimeGridEditor
            playlistMap={{ [playlist.id?.toString()]: playlist }}
            updatePlaylistList={(playlists: any) => {
              setIsDirty(true);
              playlist.id && setPlaylist(playlists[playlist.id?.toString()]);
            }}
            setSelectedPlaylistId={() => {}}
          />
        )
      )}
      <EuiSpacer />

      <EuiFlexGroup justifyContent="flexEnd">
        <EuiFlexItem grow={false}>
          {t('Total Length')}: &#160;
          {timeformat(playlistTotalLength)}
          &#160;
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
export default PlaylistAssetEditor;
