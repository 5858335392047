import moment from 'moment';

export default function useDurationFormat() {
  const aDaySec = 24 * 60 * 60;
  const aHourSec = 60 * 60;

  const format = (sec: number) => {
    if (sec >= aDaySec) {
      const d = Math.floor(sec / aDaySec);
      return `${d * 24}:${moment.utc(sec * 1000).format('mm:ss')}`;
    }
    if (sec >= aHourSec) return moment.utc(sec * 1000).format('HH:mm:ss');
    return moment.utc(sec * 1000).format('mm:ss');
  };

  return format;
}
