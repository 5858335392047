import { callApi } from 'utils/fetchApi';

const getRoles = async () => {
  const response = await callApi({
    method: 'GET',
    path: `/corps/organization/roles`,
    withCredentials: true,
  });

  return await response.data;
};

const getOrganizationList = async () => {
  const response = await callApi({
    method: 'GET',
    path: `/corps/organization`,
    withCredentials: true,
  });

  return await response.data;
};

const getOrganization = async (organizationId: number) => {
  const response = await callApi({
    method: 'GET',
    path: `/corps/organization/${organizationId}`,
    withCredentials: true,
  });

  return await response.data;
};

const patchOrganization = async (organizationId: number, payload: any) => {
  const response = await callApi({
    method: 'PATCH',
    path: `/corps/organization/${organizationId}`,
    withCredentials: true,
    data: payload,
  });

  return await response.data;
};

const getOrganizationSetting = async (organizationId: number) => {
  const response = await callApi({
    method: 'GET',
    path: `/corps/organization_setting/${organizationId}`,
    withCredentials: true,
  });

  return await response.data;
};

const addOrganizationMember = async (organizationId: number, email: string) => {
  const response = await callApi({
    method: 'POST',
    path: `/corps/organization/${organizationId}/add_organization_member`,
    withCredentials: true,
    data: {
      email,
    },
  });

  return await response.data;
};

const updateMemberRole = async (
  organizationId: number,
  userId: number,
  roleId: number
) => {
  const response = await callApi({
    method: 'POST',
    path: `/corps/organization/${organizationId}/member_role`,
    withCredentials: true,
    data: {
      user: userId,
      role: roleId,
    },
  });

  return await response.data;
};

const putOrganizationSetting = async (setting: any) => {
  const response = await callApi({
    method: 'PUT',
    path: `/corps/organization_setting/${setting.organization}`,
    withCredentials: true,
    data: setting,
  });

  return await response.data;
};

const generateApiToken = async (organizationId: number) => {
  const response = await callApi({
    method: 'GET',
    path: `/corps/organization/${organizationId}/token`,
    withCredentials: true,
  });
  return await response.data;
};

const getLogoImageUploadSignedUrl = async (
  fileName: string,
  organizationId: number
) => {
  const response = await callApi({
    method: 'GET',
    path: `/upload/user_content/get_upload_signed_url?filename=${fileName}&organization_id=${organizationId}`,
    withCredentials: true,
  });
  return await response.data;
};

export {
  getRoles,
  getOrganizationList,
  getOrganization,
  patchOrganization,
  addOrganizationMember,
  updateMemberRole,
  getOrganizationSetting,
  putOrganizationSetting,
  generateApiToken,
  getLogoImageUploadSignedUrl,
};
