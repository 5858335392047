import { Asset, Layer } from 'types/asset';
import { TextOverlayContent } from 'types/textOverlay';
import { callApi } from 'utils/fetchApi';

const getAssetList = async ({ queryKey }: any) => {
  const [_key, { organizationId, pageIndex, keyword, q = {} }] = queryKey;
  const qs = Object.keys(q)
    .map(key => (q[key] !== undefined ? `${key}=${q[key]}` : ''))
    .join('&');

  const response = await callApi({
    method: 'GET',
    path:
      `/scheduler/asset?limit=15` +
      `${organizationId ? `&organization=${organizationId}` : ''}` +
      `${keyword ? `&search=${keyword}` : ''}` +
      `&offset=${pageIndex * 15}` +
      `&${qs}`,
    withCredentials: true,
  });

  return await response.data;
};

const getAsset = async (assetId: number) => {
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/asset/${assetId}`,
    withCredentials: true,
  });

  return await response.data;
};

const getLayerContentType = async () => {
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/layer/content_types`,
    withCredentials: true,
  });

  return await response.data;
};

const postAsset = async (asset: Asset) => {
  const response = await callApi({
    method: 'POST',
    path: `/scheduler/asset`,
    withCredentials: true,
    data: serializeAsset(asset),
  });

  return await response.data;
};

const patchAsset = async (asset: Asset) => {
  let assetData: any = { ...asset };
  assetData.layers = assetData.layers.map((layer: Layer, index: number) => {
    return {
      ...layer,
      content_type: layer.content_type.id,
      z_index: asset.layers.length - index,
    };
  });

  const response = await callApi({
    method: 'PATCH',
    path: `/scheduler/asset/${asset.id}`,
    withCredentials: true,
    data: serializeAsset(asset),
  });

  return await response.data;
};

const deleteAsset = async (assetId: number) => {
  const response = await callApi({
    method: 'DELETE',
    path: `/scheduler/asset/${assetId}`,
    withCredentials: true,
  });

  return await response.data;
};

const serializeAsset = (asset: Asset) => {
  let assetData: any = { ...asset };
  assetData.layers = assetData.layers.map((layer: Layer, index: number) => {
    return {
      ...layer,
      content_type: layer.content_type.id,
      z_index: asset.layers.length - index,
    };
  });

  return assetData;
};

const getDataTagList = async () => {
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/datatag`,
    withCredentials: true,
  });

  return await response.data;
};

const getTextOverlayStyle = async () => {
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/textoverlay/styles`,
    withCredentials: true,
  });

  return await response.data;
};

const getLayer = async (id: number) => {
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/layer/${id}`,
    withCredentials: true,
  });

  return await response.data;
};

const genParsedTextOverlayContent = async (
  organization: number,
  content: TextOverlayContent[]
) => {
  const response = await callApi({
    method: 'POST',
    path: `/scheduler/textoverlay/parse_content?organization=${organization}`,
    withCredentials: true,
    data: content,
  });

  return await response.data;
};

export {
  getAssetList,
  getAsset,
  getLayerContentType,
  postAsset,
  patchAsset,
  deleteAsset,
  getDataTagList,
  getTextOverlayStyle,
  getLayer,
  genParsedTextOverlayContent,
};
