import { callApi } from 'utils/fetchApi';

const getMediaList = async ({ queryKey }: any) => {
  const [
    _key,
    {
      organizationId,
      pageIndex,
      keyword,
      type,
      id,
      filterLayerTemplateName,
      deleted,
    },
  ] = queryKey;

  const response = await callApi({
    method: 'GET',
    path:
      `/scheduler/media?limit=15` +
      `${deleted ? `&deleted=${deleted}` : ''}` +
      `${organizationId ? `&organization=${organizationId}` : ''}` +
      `${keyword ? `&search=${keyword}` : ''}` +
      `${type ? `&type=${type}` : ''}` +
      `&offset=${pageIndex ? pageIndex * 15 : 0}` +
      `${id ? `&id=${id}` : ''}` +
      `${
        filterLayerTemplateName
          ? `&layer_template_name=${filterLayerTemplateName}`
          : ''
      }`,
    withCredentials: true,
  });

  return await response.data;
};

const getMediaUploadSignedUrl = async (
  fileName: string,
  organizationId: number,
  layerTemplateName: string | null
) => {
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/media/upload_sign_url?filename=${fileName}&organization_id=${organizationId}${
      layerTemplateName ? 'layer_template_name=' + layerTemplateName : ''
    }`,
    withCredentials: true,
  });

  return await response.data;
};

const patchMedia = async ({ mediaId, data }: any) => {
  const response = await callApi({
    method: 'PATCH',
    path: `/scheduler/media/${mediaId}`,
    withCredentials: true,
    data,
  });
  return await response.data;
};

const deleteMedia = async ({ mediaId }: { mediaId: number }) => {
  const response = await callApi({
    method: 'DELETE',
    path: `/scheduler/media/${mediaId}`,
    withCredentials: true,
  });
  return await response.data;
};

const getMediaDownloadUrls = async (
  organizationId: string,
  fileId: any
): Promise<string[]> => {
  const response = await callApi({
    method: 'GET',
    path: `/corps/organization/${organizationId}/file_download_url?id=${fileId}&type=media`,
    withCredentials: true,
  });
  return await response.data;
};

const getAssetsWithMedia = async (media_id: number) => {
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/media/${media_id}/assets_with_media`,
    withCredentials: true,
  });
  return response.data;
};

export {
  getMediaList,
  getMediaUploadSignedUrl,
  patchMedia,
  deleteMedia,
  getMediaDownloadUrls,
  getAssetsWithMedia,
};
