import { createContext, Dispatch, SetStateAction } from 'react';
import { FileItem, UploadStatus, UploadProgress } from 'types/upload';

const UploadContext = createContext<{
  isUploading: boolean;
  setIsUploading: Dispatch<SetStateAction<boolean>>;
  uploadQueue: FileItem[];
  setUploadQueue: Dispatch<SetStateAction<FileItem[]>>;
  uploadStatus: UploadStatus;
  setUploadStatus: Dispatch<SetStateAction<UploadStatus>>;
  uploadProgress: UploadProgress;
  setUploadProgress: Dispatch<SetStateAction<UploadProgress>>;
}>({
  isUploading: false,
  setIsUploading: () => {},
  uploadQueue: [],
  setUploadQueue: () => {},
  uploadStatus: {},
  setUploadStatus: () => {},
  uploadProgress: {},
  setUploadProgress: () => {},
});

export default UploadContext;
