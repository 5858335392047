import { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EventSourceInput } from '@fullcalendar/react';
import { EuiLoadingContent } from '@elastic/eui';
import moment from 'moment';
import TimeGrid from 'components/scheduler/TimeGrid';

import useDurationFormat from 'hooks/useDurationFormat';
import AssetPicker from 'components/scheduler/AssetPicker';
import ToastContext from 'contexts/ToastContext';
import { Playlist } from 'types/playlist';
import { Asset } from 'types/asset';
import { useLocation } from 'react-router-dom';
import { Schedule } from 'types/schedule';

const TimeGridEditor = ({
  playlistMap,
  updatePlaylistList,
  setSelectedPlaylistId,
}: {
  playlistMap: any;
  updatePlaylistList: (playlists: any) => void;
  setSelectedPlaylistId: (playlistId: string) => void;
}) => {
  const history = useHistory();
  const location = useLocation<{
    schedule?: Schedule;
  }>();
  const timeformat = useDurationFormat();
  const { t, i18n } = useTranslation(['playlist', 'common']);

  const toastContext = useContext(ToastContext);
  const [options, setOptions] = useState<any>();
  const [playlistTotalLength, setPlaylistTotalLength] = useState<number>(0);
  const [playlistsItems, setPlaylistsItems] = useState<EventSourceInput>([]);
  const [isOpenAssetPicker, setIsOpenAssetPicker] = useState(false);
  const [assetPickerPlaylistId, setAssetPickerPlaylistId] = useState<number>();
  const [selectedPlaylistIndex, setSelectedPlaylistIndex] = useState<{
    [playlistId: string]: number[];
  }>();
  const [maxDurationSec, setMaxDurationSec] = useState<number>(1800);

  const startAt = moment().startOf('day').format();

  const playlistCount = Object.keys(playlistMap).length;
  const playlists: Array<Playlist> = Object.values(playlistMap);

  const isDurationLimitExceeded = (assets: Asset[]) => {
    return (
      assets.reduce(
        (totalDuration, item) => (totalDuration += item.duration),
        0
      ) > maxDurationSec
    );
  };

  const handleSelectDay = useCallback(
    (date: Date) => {
      const targetPlaylistId = Object.keys(playlistMap)[
        moment(date).diff(moment(new Date()).startOf('day'), 'days')
      ];
      setSelectedPlaylistId(targetPlaylistId);
    },
    [playlistMap, setSelectedPlaylistId]
  );

  const handleAppendAssets = (assets: Asset[]) => {
    if (!assetPickerPlaylistId && !selectedPlaylistIndex) return;
    if (assetPickerPlaylistId) {
      const playlist: Playlist = playlistMap[assetPickerPlaylistId.toString()];
      if (
        isDurationLimitExceeded([
          ...assets,
          ...playlist.assets.map(playlistAsset => playlistAsset.asset),
        ])
      ) {
        displayNotAddMsg();
        return;
      }
      updatePlaylistList({
        ...playlistMap,
        ...{
          [assetPickerPlaylistId.toString()]: {
            ...playlist,
            assets: [
              ...playlist?.assets,
              ...assets.map((asset, index) => {
                return {
                  index: playlist.assets.length + index,
                  asset: asset,
                };
              }),
            ],
          },
        },
      });
    } else if (selectedPlaylistIndex) {
      const tmpPlaylistList = { ...playlistMap };

      Object.keys(selectedPlaylistIndex).map((playlistId: string) => {
        const playlist: Playlist = playlistMap[playlistId];
        const playlistAfterInsert = playlist.assets.map(
          playlistAsset => playlistAsset.asset
        );
        selectedPlaylistIndex[playlistId].map(index =>
          playlistAfterInsert.push(...assets)
        );
        if (isDurationLimitExceeded(playlistAfterInsert)) displayNotAddMsg();

        selectedPlaylistIndex[playlistId]
          .sort((a, b) => a - b)
          .map((insertIndex, index) =>
            tmpPlaylistList[playlistId].assets.splice(
              insertIndex + 1 + index * assets.length,
              0,
              ...assets.map((asset: any, insertIdx: number) => {
                return {
                  asset,
                  index: insertIdx + insertIndex + 1 + index,
                };
              })
            )
          );
      });
      Object.keys(tmpPlaylistList).forEach((id: string) => {
        updatePlaylistList((current: any) => {
          return {
            ...current,
            [id]: {
              ...tmpPlaylistList[id],
              assets: tmpPlaylistList[id].assets.map(
                (playlistAsset: any, index: number) => {
                  return {
                    asset: playlistAsset.asset,
                    index,
                  };
                }
              ),
            },
          };
        });
      });
    }
    setAssetPickerPlaylistId(undefined);
    setSelectedPlaylistIndex(undefined);
  };

  const displayNotAddMsg = () => {
    toastContext.setToasts([
      ...toastContext.toasts,
      {
        id: 'playlist-add-asset-warning',
        title: 'Action warning.',
        color: 'warning',
        text: <p>{t('Playlist total length more than duration')}</p>,
      },
    ]);
  };

  const handleEditPlaylists = (eventsItem: any) => {
    const tmpPlaylists: any = {};
    Object.keys(playlistMap).forEach((playlistId, index) => {
      const assets = eventsItem.filter(
        (asset: any) =>
          moment(asset.start_at).diff(
            moment(new Date()).startOf('day'),
            'days'
          ) == index
      );
      assets.sort(
        (a: any, b: any) =>
          moment(a.start_at).unix() - moment(b.start_at).unix()
      );
      let originAssets = assets.filter(
        (asset: any) => asset.playlistId == playlistId
      );
      let newAssets = assets.filter(
        (asset: any) => asset.playlistId !== playlistId
      );
      const assetItemWithSameStartAt =
        newAssets.length > 0
          ? assets.filter(
              (asset: any) =>
                asset.start_at == newAssets[0].start_at && asset.id != -1
            )
          : [];
      if (assetItemWithSameStartAt.length == 0) {
        originAssets = assets;
        newAssets = [];
      }

      if (
        isDurationLimitExceeded([...assets.map((asset: any) => asset.asset)])
      ) {
        displayNotAddMsg();
      }
      if (newAssets.length > 0) {
        let index = originAssets.length;
        const selectedAssetItem = eventsItem.find(
          (eventItem: any) =>
            eventItem.start_at == newAssets[0].start_at && eventItem.id != -1
        );
        if (selectedAssetItem) index = selectedAssetItem.index + 1;
        originAssets.splice(
          index,
          0,
          ...newAssets.map((asset: any) => {
            return {
              asset: asset.asset,
            };
          })
        );
      }
      tmpPlaylists[playlistId] = {
        ...playlistMap[playlistId],
        assets: originAssets.map((asset: any, index: number) => {
          return {
            asset: asset.asset,
            index,
          };
        }),
      };
    });
    updatePlaylistList(tmpPlaylists);
  };

  useEffect(() => {
    const events: any = [];
    let maxLength = 0;
    Object.keys(playlistMap).forEach((id: string, idx: number) => {
      let start = moment(startAt).add(idx, 'd');
      playlistMap[id].assets.map((asset: any) => {
        const endAt = moment(start).add(asset.asset.duration, 'seconds');
        const item = {
          id: `${playlistMap[id].id}_${asset.index}`,
          groupId: `${playlistMap[id].id}_${asset.index}`,
          title: asset.asset.name,
          start: moment(start).toISOString(),
          end: endAt.toISOString(),
          backgroundColor: asset.asset.label_color || 'auto',
          extendedProps: {
            scheduleData: {
              content_object: asset.asset,
              type: 'asset',
              start_at: moment(start).toISOString(),
              end_at: endAt.toISOString(),
              duration: asset.asset.duration,
              ...asset,
              playlistId: id,
            },
          },
        };

        start = moment(endAt);
        events.push(item);
      });
      const playlistLength = playlistMap[id].assets.reduce(
        (totalDuration: any, item: any) => {
          return (totalDuration += item.asset.duration);
        },
        0
      );
      maxLength = Math.max(playlistLength, maxLength);
    });
    setPlaylistsItems(events);
    setPlaylistTotalLength(maxLength);
    setMaxDurationSec(
      Math.max(
        ...Object.values(playlistMap).map((item: any) => item.duration)
      ) || 30 * 60
    );
  }, [playlistMap, startAt]);

  useEffect(() => {
    const show = playlistMap[-1] ? true : playlistCount && playlistTotalLength;
    show &&
      setOptions({
        initialView: 'timeGridCustomDay',
        views: {
          timeGridCustomDay: {
            type: 'timeGrid',
            duration: { day: playlistCount },
          },
        },
        expandRows: false,
        nowIndicator: false,
        editable: false,
        headerToolbar: false,
        slotEventOverlap: false,
        slotLabelFormat: (date: any) => {
          const slotTime =
            moment(date.date).diff(moment(date.date).startOf('day')) / 1000;
          return slotTime >= 60 * 60
            ? timeformat(slotTime)
            : `00:${timeformat(slotTime)}`;
        },
        slotMaxTime:
          Math.max(playlistTotalLength, maxDurationSec) < 60 * 60
            ? `00:${timeformat(Math.max(playlistTotalLength, maxDurationSec))}`
            : timeformat(Math.max(playlistTotalLength, maxDurationSec)),
        slotLabelInterval: '00:00:15',
        slotDuration: '00:00:15',
        dayHeaderContent: (args: any) => {
          const index: number = moment(args.date).diff(
            moment(new Date()).startOf('day'),
            'days'
          );
          args.text =
            playlists[index]['id'] == -1
              ? playlists[index]['name']
              : `#${playlists[index]['id']} - ${playlists[index]['name']}`;
        },
        eventBackgroundColor: 'rgba(52,216,235,.5)',
        eventMinHeight: 10,
        eventDurationEditable: false,
        displayEventTime: false,
        contentHeight: 'auto',
        eventDidMountCallback: (e: any) => {
          e.el.addEventListener('dblclick', () => {
            history.push(
              `/assets/${e.event.extendedProps.scheduleData.asset.id}/edit`,
              {
                playlistIds: Object.keys(playlistMap),
                schedule: location.state?.schedule,
              }
            );
          });
        },
        eventOrder: '-priority',
        selectDayCallbak: handleSelectDay,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistCount, playlistTotalLength, maxDurationSec]);

  return (
    <>
      {options ? (
        <TimeGrid
          calendarOptions={options}
          events={playlistsItems}
          setEditingEventsItem={handleEditPlaylists}
          handleAddClick={(selectedSlot: any, selectedEvents: any) => {
            let playlistId: number = -1;
            if (selectedSlot) {
              const index: number = moment(selectedSlot.start).diff(
                moment(new Date()).startOf('day'),
                'days'
              );
              playlistId = parseInt(Object.keys(playlistMap)[index]);
              setAssetPickerPlaylistId(playlistId);
            } else if (selectedEvents) {
              Object.keys(playlistMap).forEach(playlistId => {
                setSelectedPlaylistIndex(current => {
                  return {
                    ...current,
                    [playlistId]: selectedEvents
                      .filter((item: any) => item.playlistId == playlistId)
                      .map((item: any) => item.index),
                  };
                });
              });
            }
            setIsOpenAssetPicker(true);
          }}
        />
      ) : (
        <EuiLoadingContent />
      )}
      {isOpenAssetPicker && (
        <AssetPicker
          setIsOpenAssetPicker={setIsOpenAssetPicker}
          handleFinishPickingAssets={handleAppendAssets}
        />
      )}
    </>
  );
};
export default TimeGridEditor;
