export const member = {
  'zh-TW': {
    User: '使用者',
    Role: '身份',
    'Add Member': '新增Member',
    'Disable User': '停用使用者',
    'Enable User': '啟用使用者',
    Members: 'Members',
  },
};
