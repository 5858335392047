import { useState, useContext } from 'react';
import { EuiSuperSelect, EuiText } from '@elastic/eui';
import { useQuery } from 'react-query';

import { WindowCapture } from 'types/windowCapture';
import { getWindowCaptureList } from 'apis/Scheduler/windowCapture';
import UserContext from 'contexts/UserContext';

const WindowCaptureSelector = ({
  selectedApplication,
  onChange,
}: {
  selectedApplication?: any;
  onChange: (windowCapture?: WindowCapture) => void;
}) => {
  const userContext = useContext(UserContext);

  const windowCaptureQuery = useQuery<WindowCapture[]>(
    [
      'windowCaptures',
      {
        organizationId: userContext.currentOrganization?.id,
      },
    ],
    getWindowCaptureList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!userContext.currentOrganization?.id,
    }
  );

  const handleWindowCaptureChange = (name: string) => {
    const selectedApplication:
      | WindowCapture
      | undefined = windowCaptureQuery.data?.find(el => {
      return el.name === name;
    });

    onChange(selectedApplication);
  };

  return (
    <EuiSuperSelect
      fullWidth
      options={
        windowCaptureQuery.isSuccess
          ? windowCaptureQuery.data.map((windowCapture: WindowCapture) => ({
              value: windowCapture.name,
              inputDisplay: windowCapture.name,
              dropdownDisplay: (
                <>
                  <strong>{windowCapture.name}</strong>
                  <EuiText size="s" color="subdued">
                    <p>
                      {windowCapture.path} - {windowCapture.config.param} <br />{' '}
                      scale mode: {windowCapture.config.scale_mode}
                    </p>
                    {/* <span>Param: {windowCapture.config.param}</span> */}
                  </EuiText>
                </>
              ),
            }))
          : []
      }
      valueOfSelected={selectedApplication?.name || ''}
      placeholder="Select an window capture application"
      onChange={value => handleWindowCaptureChange(value)}
      itemLayoutAlign="top"
      hasDividers
    />
  );
};

export default WindowCaptureSelector;
