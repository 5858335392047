import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EuiFormRow,
  EuiConfirmModal,
  EuiTitle,
  EuiTextColor,
  EuiColorPicker,
} from '@elastic/eui';

const ColorModal = ({
  setIsChangeColorVisible,
  originColor,
  handleConfirm,
}: {
  setIsChangeColorVisible: (value: boolean) => void;
  originColor: string;
  handleConfirm: (color: string) => void;
}) => {
  const { t, i18n } = useTranslation(['schedule', 'common']);
  const [selectedColor, setSelectedColor] = useState<string>();

  useEffect(() => {
    setSelectedColor(originColor);
  }, [originColor]);

  return (
    <EuiConfirmModal
      onCancel={() => setIsChangeColorVisible(false)}
      onConfirm={() => {
        selectedColor && handleConfirm(selectedColor);
        setIsChangeColorVisible(false);
      }}
      title={
        <EuiTitle>
          <EuiTextColor>{t('Change Color')}</EuiTextColor>
        </EuiTitle>
      }
      cancelButtonText={t('common:Cancel')}
      confirmButtonText={t('common:OK')}
      confirmButtonDisabled={!selectedColor}
    >
      <EuiFormRow label={t('common:Color', 'Label Color')}>
        <EuiColorPicker
          onChange={color => setSelectedColor(color)}
          color={selectedColor}
          placeholder="None"
          isClearable={true}
        />
      </EuiFormRow>
    </EuiConfirmModal>
  );
};
export default ColorModal;
