import { callApi } from 'utils/fetchApi';
import { Schedule } from 'types/schedule';

const getSchedule = async ({ queryKey }: any) => {
  const [_key, { screen, after, before }] = queryKey;
  const response = await callApi({
    method: 'GET',
    path:
      `/scheduler/schedule?` +
      `${screen ? `&screen=${screen}` : ''}` +
      `&after=${after.toISOString()}` +
      `&before=${before.toISOString()}`,
    withCredentials: true,
  });

  return await response.data;
};

const postSchedules = async (
  deleteSchedulesIds: number[],
  newSchedules: Schedule[]
) => {
  const response = await callApi({
    method: 'DELETE',
    path: `/scheduler/schedule/bulk_delete`,
    withCredentials: true,
    data: deleteSchedulesIds,
  }).then(async () => {
    const response = await callApi({
      method: 'POST',
      path: `/scheduler/schedule/bulk_create`,
      withCredentials: true,
      data: newSchedules.map(schedule => {
        return {
          ...schedule,
          content_type: schedule.content_type?.id,
          object_id: schedule.content_object?.id,
        };
      }),
    });

    return response.data;
  });

  return await response.data;
};

const postSchedule = async (schedule: Schedule) => {
  const response = await callApi({
    method: 'POST',
    path: `/scheduler/schedule`,
    withCredentials: true,
    data: {
      ...schedule,
      content_type: schedule.content_type?.id,
      object_id: schedule.content_object?.id,
    },
  });

  return await response.data;
};

const patchSchedule = async (schedule: Schedule) => {
  const response = await callApi({
    method: 'PATCH',
    path: `/scheduler/schedule/${schedule.id}`,
    withCredentials: true,
    data: {
      ...schedule,
      content_type: schedule.content_type?.id,
      object_id: schedule.content_object?.id,
    },
  });

  return await response.data;
};

const deleteSchedule = async (scheduleIds: number[]) => {
  const response = await callApi({
    method: 'DELETE',
    path: `/scheduler/schedule/bulk_delete`,
    withCredentials: true,
    data: scheduleIds,
  });

  return await response.data;
};

const getScheduleContentType = async () => {
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/schedule/content_types`,
    withCredentials: true,
  });

  return await response.data;
};

export {
  getSchedule,
  postSchedule,
  patchSchedule,
  deleteSchedule,
  getScheduleContentType,
  postSchedules,
};
