import ColorBall from './ColorBall';
import './style.scss';

const LightAndShadow = ({
  width,
  height,
  offset = 0,
}: {
  width: number;
  height: number;
  offset?: number;
}) => {
  return (
    <>
      <ColorBall
        width={width}
        height={height}
        x={-300}
        y={30}
        r={78}
        g={212}
        b={224}
        size={1600}
      />
      <ColorBall
        width={width}
        height={height}
        x={650}
        y={1224}
        r={254}
        g={82}
        b={34}
        size={1600}
      />
      <ColorBall
        width={width}
        height={height}
        x={400}
        y={1624}
        r={37}
        g={37}
        b={109}
        size={1200}
      />
      <ColorBall
        width={width}
        height={height}
        x={30}
        y={2224}
        r={169}
        g={66}
        b={197}
        size={1800}
      />
      <ColorBall
        width={width}
        height={height}
        x={300}
        y={3024}
        r={255}
        g={195}
        b={45}
        size={1600}
      />
      {/* <div
        style={{
          position: 'absolute',
          width,
          height,
          left: width,
        }}
      >
        <ColorBall
        width={width}
        height={height}
        x={-300}
        y={30}
        r={78}
        g={212}
        b={224}
        size={1600}
      />
      <ColorBall
        width={width}
        height={height}
        x={650}
        y={1224}
        r={254}
        g={82}
        b={34}
        size={1600}
      />
      <ColorBall
        width={width}
        height={height}
        x={400}
        y={1624}
        r={37}
        g={37}
        b={109}
        size={1200}
      />
      <ColorBall
        width={width}
        height={height}
        x={30}
        y={2224}
        r={169}
        g={66}
        b={197}
        size={1800}
      />
      <ColorBall
        width={width}
        height={height}
        x={300}
        y={3024}
        r={255}
        g={195}
        b={45}
        size={1600}
      />
      </div> */}
    </>
  );
};

export default LightAndShadow;
