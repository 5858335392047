import { useState, useContext } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiPageHeader,
  EuiButton,
  EuiFieldSearch,
  EuiCallOut,
  EuiBreadcrumbs,
} from '@elastic/eui';

import UserContext from 'contexts/UserContext';
import { paginatedDataPlaceholder } from 'utils/objects';
import { getFontList } from 'apis/Scheduler/font';
import UploadModal from 'components/upload/UploadModal';
import FontTable from './FontTable';

const FontIndex = () => {
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['font', 'common']);

  const pageSize = 15;
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [keyword, setKeyword] = useState('');
  const [isUploaderVisible, setIsUploaderVisible] = useState(false);

  const fontsQuery = useQuery(
    [
      'font',
      {
        pageIndex,
        keyword,
        organizationId: userContext.currentOrganization?.id,
      },
    ],
    getFontList,
    {
      keepPreviousData: true,
      initialData: paginatedDataPlaceholder,
      refetchOnWindowFocus: false,
      enabled: !!userContext.currentOrganization?.id,
    }
  );

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={[{ text: t('Material') }]}
        truncate={false}
        aria-label="Breadcrumbs of font list"
      />

      <EuiPageHeader
        pageTitle={t('Fonts')}
        rightSideItems={[
          <EuiButton
            onClick={() => {
              setIsUploaderVisible(true);
            }}
          >
            {t('Upload Font')}
          </EuiButton>,
        ]}
        bottomBorder={false}
      >
        <EuiFieldSearch
          fullWidth
          placeholder={`${t('common:Search')}...`}
          onSearch={value => {
            setKeyword(value);
          }}
        />
      </EuiPageHeader>

      {!fontsQuery.isError ? (
        <FontTable
          fontList={fontsQuery.data}
          pageIndex={pageIndex}
          pageSize={pageSize}
          isLoading={fontsQuery.isFetching}
          setPageIndex={setPageIndex}
        />
      ) : (
        <EuiCallOut
          title={t('Sorry, there was an error while fetching media list')}
          color="danger"
          iconType="alert"
        >
          <p>
            {t(
              'Please check your internet connectivity or try reload this page.'
            )}
          </p>
        </EuiCallOut>
      )}
      {isUploaderVisible && (
        <UploadModal
          type="Font"
          acceptFileType=".ttf"
          setIsUploaderVisible={setIsUploaderVisible}
        />
      )}
    </>
  );
};

export default FontIndex;
