import { useState, useContext, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiBreadcrumbs,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageHeader,
  EuiTitle,
  EuiButton,
  EuiSpacer,
  EuiCallOut,
} from '@elastic/eui';

import UserContext from 'contexts/UserContext';
import { Screen } from 'types/screen';
import LayerEditor from 'pages/Asset/AssetEditor/LayerEditor';
import { EditingLayer, Layer } from 'types/asset';
import LayerList from 'pages/Asset/AssetEditor/LayerList';
import ScreenSelector from 'components/scheduler/ScreenSelector';
import {
  getDefaultLayer,
  postDefaultLayer,
  patchDefaultLayer,
  deleteDefaultLayer,
  patchLayer,
} from 'apis/Scheduler/defaultSetting';
import { DefaultSetting } from 'types/defaultSetting';

const DefaultSettings = () => {
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['defaultSetting', 'common']);

  const [screen, setScreen] = useState<Screen>();
  const [editingLayer, setEditingLayer] = useState<EditingLayer>();
  const [editingLayerIndex, setEditingLayerIndex] = useState<number>(-1);
  const [defaultLayers, setDefaultLayers] = useState<Layer[]>([]);
  const [isLayerEditorVisible, setIsLayerEditorVisible] = useState(false);
  const [disableDrag, setDisableDrag] = useState(false);

  const defaultSettingQuery = useQuery(
    [
      'defaultLayerSetting',
      screen?.id,
      { organizationId: userContext.currentOrganization?.id },
    ],
    async () => {
      if (!screen) return;
      return await getDefaultLayer(screen?.id);
    }
  );

  const defaultLayerSettingMutaion = useMutation(
    editingLayerIndex >= 0 ? patchDefaultLayer : postDefaultLayer,
    {
      onSuccess: async () => {
        defaultSettingQuery.refetch();
        setDisableDrag(false);
      },
    }
  );

  const patchLayerSettingMutaion = useMutation(patchLayer, {
    onSuccess: async () => {
      defaultSettingQuery.refetch();
      setDisableDrag(false);
    },
  });

  const handleAddLayer = () => {
    setEditingLayer({
      name: '',
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      z_index: 99,
      is_primary: false,
      is_conditional: false,
      is_audio_enabled: false,
    });
    setEditingLayerIndex(-1);
    setIsLayerEditorVisible(true);
  };

  const handleEditLayer = (index: number) => {
    setEditingLayer(defaultLayers[index]);
    setEditingLayerIndex(index);
    setIsLayerEditorVisible(true);
  };

  const handleSaveLayer = () => {
    if (
      !editingLayer ||
      !editingLayer.content_type ||
      !editingLayer.content_object
    ) {
      return;
    }

    let editedLayer: Layer = {
      name: editingLayer.name,
      width: editingLayer.width,
      height: editingLayer.height,
      top: editingLayer.top,
      left: editingLayer.left,
      backgroundImage:
        editingLayer.content_type.model == 'media' &&
        'thumb_location' in editingLayer.content_object
          ? `${editingLayer.content_object.thumb_location}.png`
          : undefined,
      content_type: editingLayer.content_type,
      content_object: editingLayer.content_object,
      z_index: editingLayer.z_index,
      object_id: editingLayer.content_object.id,
      is_primary: editingLayer.is_primary,
      defaultSettingId: editingLayer.defaultSettingId,
      is_conditional: editingLayer.is_conditional,
      is_audio_enabled: editingLayer.is_audio_enabled,
    };

    defaultLayerSettingMutaion.mutate({
      id: editedLayer.defaultSettingId,
      screen: screen?.id || 0,
      layer: editedLayer,
      organization: userContext.currentOrganization?.id,
    });
    setDisableDrag(true);

    setEditingLayer(undefined);
    setIsLayerEditorVisible(false);
  };

  const handleDeleteLayer = (index: number): void => {
    const defaultSettingId = defaultLayers[index].defaultSettingId;
    if (!defaultSettingId) return;
    deleteDefaultLayer(defaultSettingId).then(response =>
      defaultSettingQuery.refetch()
    );
  };

  const handleDrag = (layers: Layer[]) => {
    setDefaultLayers(layers);
    layers.forEach(layer => {
      patchLayerSettingMutaion.mutate(layer);
    });
  };

  useEffect(() => {
    if (
      !defaultSettingQuery.isLoading &&
      defaultSettingQuery.isSuccess &&
      defaultSettingQuery.data
    ) {
      setDefaultLayers(
        defaultSettingQuery.data.map((defaultLayer: DefaultSetting) => ({
          ...defaultLayer.layer,
          defaultSettingId: defaultLayer.id,
        }))
      );
    }
  }, [
    defaultSettingQuery.data,
    defaultSettingQuery.isLoading,
    defaultSettingQuery.isSuccess,
  ]);

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={[{ text: 'Scheduling' }]}
        truncate={false}
        aria-label="Breadcrumbs of create asset"
      />

      <EuiPageHeader
        pageTitle={t('common:Default Settings')}
        bottomBorder={false}
        rightSideItems={[
          <EuiButton
            onClick={() => setScreen(undefined)}
            disabled={!screen}
            fill
          >
            {t('Change Screen')}
          </EuiButton>,
        ]}
      />

      {screen ? (
        <div>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiTitle>
                <h2>
                  {t('Layer Default Setting for')} {screen.name}
                </h2>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                onClick={handleAddLayer}
                disabled={defaultLayers.length >= 3}
              >
                {t('Add Layer')}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer />

          {defaultSettingQuery.isSuccess &&
            defaultSettingQuery.data.length === 0 && (
              <EuiCallOut
                iconType="iInCircle"
                title={t('This screen doesn‘t have any default layer.')}
              />
            )}

          <LayerList
            layers={defaultLayers}
            setLayers={setDefaultLayers}
            edit
            handleEditLayer={handleEditLayer}
            handleDeleteLayer={handleDeleteLayer}
            disableDrag={disableDrag}
            handleDrag={handleDrag}
          />
        </div>
      ) : (
        <>
          <EuiCallOut>
            <b>{t('Please select a screen to manage its default setting')}</b>
          </EuiCallOut>

          <EuiSpacer />

          <ScreenSelector onSelected={setScreen} />
        </>
      )}

      {isLayerEditorVisible && (
        <LayerEditor
          isForLayerDefaultSetting
          setIsLayerEditorVisible={setIsLayerEditorVisible}
          editingLayer={editingLayer}
          editingLayerIndex={editingLayerIndex}
          setEditingLayer={setEditingLayer}
          handleSaveLayer={handleSaveLayer}
        />
      )}
    </>
  );
};

export default DefaultSettings;
