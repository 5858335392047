import { EditingTemplateLayer, LayerTemplate } from 'types/layerTemplate';
import { callApi } from 'utils/fetchApi';

const getLayerTemplateList = async ({ queryKey }: any) => {
  const [_key, { organizationId, keyword, q = {} }] = queryKey;
  const qs = Object.keys(q)
    .map(key => (q[key] !== undefined ? `${key}=${q[key]}` : ''))
    .join('&');

  const response = await callApi({
    method: 'GET',
    path:
      `/scheduler/layer_template?limit=15` +
      `${organizationId ? `&organization=${organizationId}` : ''}` +
      `${keyword ? `&search=${keyword}` : ''}` +
      `&${qs}`,
    withCredentials: true,
  });

  return await response.data;
};

const getLayerTemplate = async (layerTemplateId: number) => {
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/layer_template/${layerTemplateId}`,
    withCredentials: true,
  });

  return await response.data;
};

const postLayerTemplate = async (layerTemplate: LayerTemplate) => {
  const response = await callApi({
    method: 'POST',
    path: `/scheduler/layer_template`,
    withCredentials: true,
    data: serializeLayerTemplate(layerTemplate),
  });

  return await response.data;
};

const patchLayerTemplate = async (layerTemplate: LayerTemplate) => {
  let layerTemplateData: any = { ...layerTemplate };
  layerTemplateData.layers = layerTemplateData.template_data.map(
    (layer: EditingTemplateLayer, index: number) => {
      return {
        ...layer,
        content_type: layer.content_type?.id,
        z_index: layerTemplate.template_data.length - index,
      };
    }
  );

  const response = await callApi({
    method: 'PATCH',
    path: `/scheduler/layer_template/${layerTemplate.id}`,
    withCredentials: true,
    data: serializeLayerTemplate(layerTemplate),
  });

  return await response.data;
};

const serializeLayerTemplate = (layerTemplate: LayerTemplate) => {
  let layerTemplateData: any = { ...layerTemplate };
  layerTemplateData.template_data = layerTemplateData.template_data.map(
    (layer: EditingTemplateLayer, index: number) => {
      return {
        ...layer,
        content_type: layer.content_type?.id,
        z_index: layerTemplate.template_data.length - index,
      };
    }
  );

  return layerTemplateData;
};

export {
  getLayerTemplateList,
  getLayerTemplate,
  postLayerTemplate,
  patchLayerTemplate,
};
