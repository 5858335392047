import { callApi } from 'utils/fetchApi';
import { Property, Client } from '../../types/property';

const getPropertyList = async ({ queryKey }: any) => {
  const [_key, { organizationId }] = queryKey;
  const response = await callApi({
    method: 'GET',
    path:
      `/corps/property?` +
      `${organizationId ? `organization=${organizationId}` : ''}`,
    withCredentials: true,
  });

  return await response.data;
};

const postProperty = async (property: Property) => {
  const response = await callApi({
    method: 'POST',
    path: `/corps/property`,
    withCredentials: true,
    data: property,
  });

  return await response.data;
};

const patchProperty = async (property: Property) => {
  const response = await callApi({
    method: 'PATCH',
    path: `/corps/property/${property.id}`,
    withCredentials: true,
    data: property,
  });

  return await response.data;
};

const getClientList = async ({ queryKey }: any) => {
  const [_key, { propertyId }] = queryKey;
  const response = await callApi({
    method: 'GET',
    path: `/corps/client?` + `${propertyId ? `property=${propertyId}` : ''}`,
    withCredentials: true,
  });

  return await response.data;
};

const postClient = async (client: Client) => {
  const response = await callApi({
    method: 'POST',
    path: `/corps/client`,
    withCredentials: true,
    data: client,
  });

  return await response.data;
};

const patchClient = async (client: Client) => {
  const response = await callApi({
    method: 'PATCH',
    path: `/corps/client/${client.id}`,
    withCredentials: true,
    data: client,
  });

  return await response.data;
};

const getClientAssets = async (client: Client) => {
  const response = await callApi({
    method: 'GET',
    path: `/corps/client/${client.id}/assets`,
    withCredentials: true,
  });

  return await response.data;
};

export {
  getPropertyList,
  postProperty,
  patchProperty,
  getClientList,
  postClient,
  patchClient,
  getClientAssets,
};
