import { useState, useRef, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiDescriptionList,
  EuiDescriptionListTitle,
  EuiDescriptionListDescription,
  EuiAspectRatio,
  EuiImage,
  EuiPopover,
  EuiFieldText,
  EuiBasicTableColumn,
  EuiButton,
} from '@elastic/eui';

import { PaginatedMedia, Media } from '../../types/media';
import { MEDIA_TYPES, MEDIA_TYPE_NAMES } from './consts';
import './style.scss';

const MediaTable = ({
  mediaList,
  pageIndex,
  pageSize,
  isLoading,
  setPageIndex,
  onEditMedia,
  handleDelete,
}: {
  mediaList: PaginatedMedia;
  pageIndex: Number;
  pageSize: Number;
  isLoading: boolean;
  setPageIndex: (pageIndex: number) => void;
  onEditMedia: (mediaId: number, data: any) => void;
  handleDelete?: (media: Media) => void;
}) => {
  const { t, i18n } = useTranslation(['media', 'common']);

  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<{
    [id: number]: any;
  }>({});
  const [editItem, setEditItem] = useState<number>(0);
  const [hoverRow, setHoverRow] = useState<number>(0);

  const nameRef = useRef<any>([]);
  nameRef.current = mediaList.results.map(
    (element, i) => nameRef.current[element.id] ?? createRef()
  );

  const pagination: any = {
    pageIndex,
    pageSize,
    totalItemCount: mediaList.count,
    hidePerPageOptions: true,
  };

  const onTableChange = ({ page }: { page: { index: number } }) => {
    const { index: pageIndex } = page;

    setPageIndex(pageIndex);
  };

  const toggleDetails = (item: Media) => {
    if (!item.id) return;
    const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };
    if (itemIdToExpandedRowMapValues[item.id]) {
      delete itemIdToExpandedRowMapValues[item.id];
    } else {
      const {
        id,
        type,
        width,
        height,
        duration,
        file_size,
        thumb_location,
      } = item;
      itemIdToExpandedRowMapValues[item.id] = (
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiDescriptionList>
              <EuiDescriptionListTitle>
                <h1>{t('common:Preview')}</h1>
              </EuiDescriptionListTitle>
              <EuiDescriptionListDescription>
                {type == MEDIA_TYPES.IMAGE && (
                  <EuiImage
                    className="preview-image"
                    src={`${thumb_location}.png`}
                    alt=""
                    size="m"
                    allowFullScreen
                  />
                )}
                {type == MEDIA_TYPES.VIDEO && (
                  <EuiAspectRatio width={16} height={9} maxWidth={360}>
                    <video
                      className="preview-video"
                      controls
                      poster={`${thumb_location}.png`}
                      preload="none"
                      src={`${thumb_location}.mp4`}
                    />
                  </EuiAspectRatio>
                )}
              </EuiDescriptionListDescription>
            </EuiDescriptionList>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiDescriptionList
              listItems={[
                ...(type == MEDIA_TYPES.VIDEO
                  ? [
                      {
                        title: `${t('common:Duration')}`,
                        description: duration,
                      },
                    ]
                  : []),
                {
                  title: `${t('Size')}`,
                  description: `${width}px x ${height}px`,
                },
                {
                  title: `${t('File Size')}`,
                  description: file_size,
                },
              ]}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    }
    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
  };

  const columns: EuiBasicTableColumn<Media>[] = [
    {
      field: 'id',
      name: '#',
      dataType: 'string',
      width: '60px',
    },
    {
      field: 'thumb_location',
      name: t('Thumb'),
      dataType: 'string',
      style: { height: '66px' },
      mobileOptions: {
        header: false,
        width: '100%',
        render: (item: Media) => (
          <EuiImage
            className="thumb-image"
            size="s"
            alt=""
            src={`${item.thumb_location}.png`}
          />
        ),
      },
      width: '100px',
      render: (thumb_location: any) => (
        <EuiImage
          className="thumb-image"
          size={50}
          alt=""
          src={`${thumb_location}.png`}
        />
      ),
    },
    {
      field: 'name',
      name: t('common:Name'),
      dataType: 'string',
      width: '50%',
      render: (name: string, item: Media) => (
        <div
          className="name-column"
          onMouseOver={() => {
            if (editItem == 0 && item.id) setHoverRow(item.id);
          }}
          onMouseLeave={() => {
            if (editItem == 0) setHoverRow(0);
          }}
        >
          {name}
          {item.id && (
            <EuiPopover
              isOpen={editItem == item.id}
              anchorPosition="upCenter"
              button={
                hoverRow == item.id && (
                  <EuiButtonIcon
                    aria-labelledby="edit"
                    iconType="pencil"
                    onClick={() => (item.id ? setEditItem(item.id) : {})}
                  />
                )
              }
              panelPaddingSize="none"
              closePopover={() => setEditItem(0)}
            >
              <EuiFieldText
                placeholder={t('Rename media')}
                inputRef={e => (item.id ? (nameRef.current[item.id] = e) : {})}
                append={
                  <EuiButtonEmpty
                    size="xs"
                    onClick={() => {
                      if (!item.id) return;
                      onEditMedia(item.id, {
                        name: nameRef.current[item.id].value,
                      });
                      setEditItem(0);
                    }}
                  >
                    {t('common:Save')}
                  </EuiButtonEmpty>
                }
              />
            </EuiPopover>
          )}
        </div>
      ),
    },
    {
      field: 'type',
      name: t('common:Type'),
      dataType: 'string',
      render: (type: number) => MEDIA_TYPE_NAMES[type],
    },
    {
      field: 'id',
      name: '',
      render: (id: number, item: Media) => {
        return item.deleted_at ? (
          <EuiButtonEmpty
            size="xs"
            onClick={() => {
              onEditMedia(id, {
                enable: true,
              });
            }}
          >
            {t('Enable Media')}
          </EuiButtonEmpty>
        ) : (
          <EuiButtonEmpty
            size="xs"
            color="danger"
            onClick={() => {
              onEditMedia(id, {
                disable: true,
              });
            }}
          >
            {t('Disable Media')}
          </EuiButtonEmpty>
        );
      },
    },
    {
      align: 'right',
      isExpander: true,
      render: (item: Media) => (
        <EuiButtonIcon
          onClick={() => toggleDetails(item)}
          aria-label={
            item.id && itemIdToExpandedRowMap[item.id] ? 'Collapse' : 'Expand'
          }
          iconType={
            item.id && itemIdToExpandedRowMap[item.id] ? 'arrowUp' : 'arrowDown'
          }
        />
      ),
    },
  ];

  return (
    <EuiBasicTable
      items={mediaList.results}
      itemId="id"
      itemIdToExpandedRowMap={itemIdToExpandedRowMap}
      columns={columns}
      loading={isLoading}
      pagination={pagination}
      onChange={onTableChange}
      isExpandable
      noItemsMessage={
        isLoading
          ? t('common:Loading', 'Loading media...')
          : t('No media found')
      }
    />
  );
};

export default MediaTable;
