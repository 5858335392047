import { firebaseAuth } from '../firebase';
import axios from 'axios';

type config = {
  method: string;
  data?: object;
  withCredentials: boolean;
  path: string;
};

const callApi = async ({ method, withCredentials, data, path }: config) => {
  let config: any = {
    url: `${process.env.REACT_APP_API_URL}${path}`,
    method,
    headers: {},
    data: data,
  };
  if (withCredentials) {
    const token = await firebaseAuth.currentUser?.getIdToken();
    config.headers.authorization = `Bearer ${token}`;
  }

  return axios(config);
};

const uploadFile = async (
  method: string,
  url: string,
  fields: object,
  file: File,
  onUploadProgress: (event: ProgressEvent) => void
) => {
  const formData = new FormData();
  Object.entries(fields).map(([key, value]) => formData.append(key, value));
  formData.append('file', file);

  let config: any = {
    url,
    method,
    headers: {},
    data: formData,
    onUploadProgress,
    transformRequest: (data: any) => {
      return data;
    },
  };

  return axios(config);
};

export { callApi, uploadFile };
