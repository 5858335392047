import { EuiBasicTable } from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import { PaginatedFonts } from '../../types/font';

const FontTable = ({
  fontList,
  pageIndex,
  pageSize,
  isLoading,
  setPageIndex,
}: {
  fontList: PaginatedFonts;
  pageIndex: Number;
  pageSize: Number;
  isLoading: boolean;
  setPageIndex: (pageIndex: number) => void;
}) => {
  const { t, i18n } = useTranslation(['font', 'common']);

  const pagination: any = {
    pageIndex,
    pageSize,
    totalItemCount: fontList.count,
    hidePerPageOptions: true,
  };

  const onTableChange = ({ page }: { page: { index: number } }) => {
    const { index: pageIndex } = page;

    setPageIndex(pageIndex);
  };

  const columns: any = [
    {
      field: 'name',
      name: t('common:Name'),
      dataType: 'string',
    },
  ];

  return (
    <EuiBasicTable
      items={fontList.results}
      itemId="id"
      columns={columns}
      loading={isLoading}
      pagination={pagination}
      onChange={onTableChange}
      noItemsMessage={isLoading ? t('Loading fonts') : t('No font found')}
      isExpandable
    />
  );
};

export default FontTable;
