import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { AxiosError } from 'axios';
import {
  EuiBreadcrumbs,
  EuiSpacer,
  EuiPageHeader,
  EuiButton,
  EuiForm,
  EuiTitle,
  EuiDescribedFormGroup,
  EuiFormRow,
  EuiFilePicker,
  EuiFieldText,
  useGeneratedHtmlId,
  EuiCheckbox,
  EuiImage,
  EuiFieldPassword,
} from '@elastic/eui';

import UserContext from 'contexts/UserContext';
import ToastContext from 'contexts/ToastContext';
import { Organization, OrganizationSetting } from 'types/organization';
import {
  getOrganization,
  putOrganizationSetting,
  generateApiToken,
  getLogoImageUploadSignedUrl,
} from 'apis/Corps/orgranization';
import { uploadFile } from 'utils/fetchApi';

const OrganizationSettings = () => {
  const userContext = useContext(UserContext);
  const toastContext = useContext(ToastContext);

  const [organization, setOrganization] = useState<Organization>();
  const [
    organizationSetting,
    setOrganizationSetting,
  ] = useState<OrganizationSetting>({
    organization_id: userContext.currentOrganization?.id,
    private_storage_enabled: false,
  });
  const [logoImageFile, setLogoImageFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<{ [column: string]: string[] }>({});

  const organizationQuery = useQuery<Organization>(
    'organization',
    async () => {
      if (userContext.currentOrganization?.id)
        return await getOrganization(userContext.currentOrganization?.id);
    },
    {
      enabled: !!userContext.currentOrganization?.id,
      refetchOnWindowFocus: false,
    }
  );

  const breadcrumbs = [
    {
      text: userContext.currentOrganization?.name,
    },
  ];

  const handleChangeLogo = (files: FileList | null) => {
    if (!organization) return;

    if (files?.length === 0 || !files?.[0]) {
      setOrganizationSetting({ ...organizationSetting, logo_url: '' });
      setLogoImageFile(null);
      return;
    }
    setOrganizationSetting({
      ...organizationSetting,
      // eslint-disable-next-line compat/compat
      logo_url: URL.createObjectURL(files[0]),
    });
    setLogoImageFile(files[0]);
  };

  const putOrganizationSettingMutaion = useMutation(putOrganizationSetting, {
    onSuccess: () => {
      organizationQuery.refetch();
      toastContext.setToasts([
        ...toastContext.toasts,
        {
          id: 'organization-setting-updated',
          title: 'Organization setting updated successfully!',
          color: 'success',
        },
      ]);
    },
    onError: (error: AxiosError) => {
      setErrors(error.response?.data);
    },
  });

  const handleSave = async () => {
    if (!userContext.currentOrganization?.id) return;
    let imageUrl = organizationSetting.logo_url;
    if (logoImageFile && userContext.currentOrganization) {
      const signedUrlResponse = await getLogoImageUploadSignedUrl(
        logoImageFile.name,
        userContext.currentOrganization?.id
      );
      await uploadFile(
        signedUrlResponse.method,
        signedUrlResponse.policy.url,
        signedUrlResponse.policy.fields,
        logoImageFile,
        () => {}
      )
        .then((response: any) => {
          imageUrl = `${response.config.url}${response.config.data.get('key')}`;
        })
        .catch(e => {
          console.log('Upload failed');
          console.log(e);
        });
    }
    putOrganizationSettingMutaion.mutate({
      ...organizationSetting,
      organization: userContext.currentOrganization?.id,
      logo_url: imageUrl,
      slug: organization?.slug,
    });
  };

  useEffect(() => {
    if (!organizationQuery.isLoading && organizationQuery.isSuccess) {
      setOrganization(organizationQuery.data);
      setOrganizationSetting(organizationQuery.data.organizationsetting);
    }
  }, [
    organizationQuery.data,
    organizationQuery.isLoading,
    organizationQuery.isSuccess,
  ]);

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={breadcrumbs}
        truncate={false}
        aria-label="An example of EuiBreadcrumbs"
      />

      <EuiSpacer size="xs" />

      <EuiPageHeader
        role=""
        pageTitle="Settings"
        rightSideItems={[
          <EuiButton onClick={handleSave} fill>
            Save
          </EuiButton>,
        ]}
      />

      <EuiForm
        component="form"
        //isInvalid={postMutaion.isError || patchMutaion.isError}
      >
        <EuiTitle>
          <h2>Basic Settings</h2>
        </EuiTitle>

        <EuiSpacer />

        <EuiDescribedFormGroup
          title={<h3>Organization Slug</h3>}
          description={
            <p>A slug name to identify your organization in GOTIGER system</p>
          }
        >
          <EuiFormRow>
            <EuiFieldText
              name="slug"
              aria-label="Organization Slug"
              value={organization?.slug}
              onChange={e =>
                organization &&
                setOrganization({
                  ...organization,
                  slug: e.target.value.toString(),
                })
              }
              //isInvalid={'name' in errors}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>

        <EuiDescribedFormGroup
          title={<h3>Organization Logo</h3>}
          description={<p>Your organization logo.</p>}
        >
          <EuiFormRow>
            <EuiFilePicker
              id={useGeneratedHtmlId()}
              display="default"
              initialPromptText="Select an image"
              onChange={handleChangeLogo}
              aria-label="Organization logo"
              accept="image/png, image/jpeg"
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>

        {organizationSetting?.logo_url && (
          <EuiDescribedFormGroup title={<h3></h3>}>
            <EuiImage
              src={organizationSetting.logo_url}
              alt="Organization Logo"
            />
          </EuiDescribedFormGroup>
        )}

        <EuiTitle>
          <h2>Private Storage Server Settings</h2>
        </EuiTitle>

        <EuiSpacer />

        <EuiDescribedFormGroup title={<h3>Enable Private Storage Server</h3>}>
          <EuiFormRow>
            <EuiCheckbox
              id={useGeneratedHtmlId()}
              label="Enabled"
              checked={organizationSetting?.private_storage_enabled}
              onChange={e =>
                organizationSetting &&
                setOrganizationSetting({
                  ...organizationSetting,
                  private_storage_enabled: e.target.checked,
                })
              }
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>

        <EuiDescribedFormGroup title={<h3>Server Host</h3>}>
          <EuiFormRow>
            <EuiFieldText
              name="host"
              aria-label="Server Host"
              placeholder="yourserverdomain.com"
              value={organizationSetting?.private_storage_host}
              onChange={e =>
                organizationSetting &&
                setOrganizationSetting({
                  ...organizationSetting,
                  private_storage_host: e.target.value,
                })
              }
              //isInvalid={'name' in errors}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Server Port</h3>}>
          <EuiFormRow>
            <EuiFieldText
              type="number"
              name="port"
              aria-label="Server Port"
              style={{ width: 100 }}
              placeholder="80"
              value={organizationSetting?.private_storage_port}
              onChange={e =>
                organizationSetting &&
                setOrganizationSetting({
                  ...organizationSetting,
                  private_storage_port: e.target.value,
                })
              }
              //isInvalid={'name' in errors}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>API Token</h3>}>
          <EuiFormRow>
            <EuiFieldPassword
              name="api_token"
              aria-label="API token"
              readOnly
              value={organizationSetting?.token_created_at}
            />
          </EuiFormRow>
          <EuiFormRow>
            <EuiButton
              onClick={() =>
                userContext.currentOrganization?.id &&
                generateApiToken(userContext.currentOrganization?.id)
              }
            >
              Generate New Token
            </EuiButton>
          </EuiFormRow>
        </EuiDescribedFormGroup>
      </EuiForm>
    </>
  );
};

export default OrganizationSettings;
