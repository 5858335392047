import { useState, useRef, useContext } from 'react';
import { useInfiniteQuery } from 'react-query';
import useDurationFormat from 'hooks/useDurationFormat';
import { useTranslation } from 'react-i18next';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiLoadingSpinner,
  EuiFieldSearch,
  EuiButtonEmpty,
  EuiToolTip,
} from '@elastic/eui';

import useDebounce from 'hooks/useDebounce';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { getAssetList } from 'apis/Scheduler/asset';
import UserContext from 'contexts/UserContext';
import LayerSelector from './LayerSelector';
import { Asset, Layer } from 'types/asset';

const AssetSelection = ({
  handlePickAsset,
  isAllowSelectLayer = false,
}: {
  handlePickAsset: (item: any) => void;
  isAllowSelectLayer?: Boolean;
}) => {
  const { t, i18n } = useTranslation(['asset', 'common']);
  const userContext = useContext(UserContext);
  const timeformat = useDurationFormat();

  const scrollAreaRef = useRef<any>();
  const listBottom = useRef<any>(null);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [pageIndex, setPageIndex] = useState<number>(0);
  const debouncedKeyword = useDebounce(searchKeyword, 1000);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery(
    ['asset-selections', debouncedKeyword],
    options => {
      return getAssetList({
        queryKey: [
          'asset-selections',
          options.pageParam ?? {
            organizationId: userContext.currentOrganization?.id,
            pageIndex,
            keyword: debouncedKeyword,
          },
        ],
      });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      getNextPageParam: lastPage => lastPage.next ?? undefined,
    }
  );

  useIntersectionObserver({
    root: scrollAreaRef.current,
    target: listBottom,
    onIntersect: () => {
      if (isFetchingNextPage || !hasNextPage) return;
      fetchNextPage({
        pageParam: {
          organizationId: userContext.currentOrganization?.id,
          pageIndex: pageIndex + 1,
          keyword: debouncedKeyword,
        },
      });
      setPageIndex(pageIndex + 1);
    },
    enabled: hasNextPage,
  });

  const handleKeywordChange = (value: string) => {
    setPageIndex(0);
    setSearchKeyword(value);
  };

  const handlePickLayer = (asset: Asset, layer: Layer) => {
    handlePickAsset({ ...asset, layer: layer, dummy: true });
  };

  return (
    <>
      <EuiFieldSearch
        placeholder={t('Search Asset')}
        value={searchKeyword}
        onChange={e => handleKeywordChange(e.target.value)}
        isClearable
        aria-label="Search Asset"
        spellCheck={false}
        fullWidth
      />
      <EuiFlexGroup
        ref={scrollAreaRef}
        direction="column"
        responsive={false}
        gutterSize="m"
        style={{ maxHeight: 500, overflow: 'auto', padding: 10 }}
      >
        {status === 'loading' ? (
          <p>{t('common:Loading')}...</p>
        ) : status === 'error' ? (
          <span>{t('No asset found')}</span>
        ) : (
          <>
            {data?.pages.map((page, index) => (
              <div key={index}>
                {page.results.map((item: any) => (
                  <EuiFlexItem key={item.id} className="media-item">
                    <EuiFlexGroup
                      responsive={false}
                      alignItems="center"
                      justifyContent="spaceBetween"
                      gutterSize="s"
                    >
                      <EuiFlexItem grow={3} style={{ overflow: 'hidden' }}>
                        <EuiTitle size="xs">
                          <EuiToolTip position="top" content={item.name}>
                            <h3
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              }}
                            >
                              {item.name}
                            </h3>
                          </EuiToolTip>
                        </EuiTitle>
                        <small>{timeformat(item.duration)}</small>
                      </EuiFlexItem>
                      {isAllowSelectLayer && (
                        <EuiFlexItem grow={false}>
                          <LayerSelector
                            asset={item}
                            handlePickLayer={handlePickLayer}
                          />
                        </EuiFlexItem>
                      )}
                      <EuiFlexItem grow={false}>
                        <EuiButtonEmpty
                          onClick={() =>
                            handlePickAsset({ ...item, dummy: false })
                          }
                        >
                          {t('Select Asset')}
                        </EuiButtonEmpty>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                ))}
              </div>
            ))}
          </>
        )}
        <EuiFlexItem>
          <div ref={listBottom} style={{ margin: '0 auto' }}>
            {isFetching && <EuiLoadingSpinner size="xl" />}
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default AssetSelection;
