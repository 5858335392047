import { useState, useContext } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiButton,
  EuiFieldSearch,
  EuiPageHeader,
  EuiCallOut,
  EuiBreadcrumbs,
} from '@elastic/eui';
import { useHistory } from 'react-router-dom';

import UserContext from 'contexts/UserContext';
import { paginatedDataPlaceholder } from 'utils/objects';
import { getAssetList } from 'apis/Scheduler/asset';
import AssetTable from './AssetTable';

const AssetIndex = () => {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['asset', 'common']);
  const pageSize = 15;
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [keyword, setKeyword] = useState<string>();

  const assetQuery = useQuery(
    [
      'asset',
      {
        pageIndex,
        keyword,
        organizationId: userContext.currentOrganization?.id,
      },
    ],
    getAssetList,
    {
      keepPreviousData: true,
      initialData: paginatedDataPlaceholder,
      refetchOnWindowFocus: false,
      enabled: !!userContext.currentOrganization?.id,
    }
  );

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={[{ text: t('common:Scheduling') }]}
        truncate={false}
        aria-label="Breadcrumbs of asset"
      />

      <EuiPageHeader
        pageTitle="Assets"
        rightSideItems={[
          <EuiButton onClick={() => history.push('/assets/create')}>
            {t('Create Asset')}
          </EuiButton>,
        ]}
        bottomBorder={false}
      >
        <EuiFieldSearch
          fullWidth
          value={keyword}
          onChange={e => setKeyword(e.target.value)}
          placeholder={`${t('common:Search')}...`}
        />
      </EuiPageHeader>

      {!assetQuery.isError ? (
        <AssetTable
          assetList={assetQuery.data}
          pageIndex={pageIndex}
          pageSize={pageSize}
          isLoading={assetQuery.isFetching}
          setPageIndex={setPageIndex}
          onDelete={assetQuery.refetch}
        />
      ) : (
        <EuiCallOut
          title="Sorry, there was an error while fetching asset list"
          color="danger"
          iconType="alert"
        >
          <p>
            {t(
              'common:Please check your internet connectivity or try reload this page.'
            )}
          </p>
        </EuiCallOut>
      )}
    </>
  );
};

export default AssetIndex;
