import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiBadge,
  EuiHorizontalRule,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui';

import { Asset } from 'types/asset';
import AssetSelection from './AssetSelection';

export default ({
  setIsOpenAssetPicker,
  handleFinishPickingAssets,
  isMultiple = true,
  isAllowSelectLayer = false,
}: {
  setIsOpenAssetPicker: (arg: boolean) => void;
  handleFinishPickingAssets: (assets: Asset[]) => void;
  isMultiple?: Boolean;
  isAllowSelectLayer?: Boolean;
}) => {
  const [pickedAssets, setPickedAssets] = useState<Asset[]>([]);
  const { t, i18n } = useTranslation(['asset', 'common']);

  const handlePickAsset = (asset: Asset) => {
    if (!isMultiple) {
      handleFinishPickingAssets([asset]);
      setIsOpenAssetPicker(false);
    }
    setPickedAssets([...pickedAssets, asset]);
  };

  const handleAdd = () => {
    handleFinishPickingAssets(pickedAssets);
    setIsOpenAssetPicker(false);
  };

  return (
    <EuiModal
      style={{ width: 600 }}
      onClose={() => setIsOpenAssetPicker(false)}
      className="playlist-function"
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{t('Asset Picker')}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        {isMultiple && (
          <>
            {t('Selected Asset')}:
            <EuiPanel>
              <EuiFlexGroup wrap gutterSize="s">
                {pickedAssets.map(asset => (
                  <EuiFlexItem grow={false}>
                    <EuiBadge
                      iconType="cross"
                      iconSide="right"
                      iconOnClick={() => {}}
                      iconOnClickAriaLabel="Click this icon to remove this asset"
                    >
                      {asset.name}
                    </EuiBadge>
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>
            </EuiPanel>
            <EuiHorizontalRule />
          </>
        )}

        <AssetSelection
          handlePickAsset={handlePickAsset}
          isAllowSelectLayer={isAllowSelectLayer}
        />
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={() => handleAdd()} fill>
          {t('common:Add')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
