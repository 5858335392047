import { callApi } from 'utils/fetchApi';

const getFontList = async ({ queryKey }: any) => {
  const [_key, { organizationId, pageIndex, keyword }] = queryKey;
  const response = await callApi({
    method: 'GET',
    path:
      `/scheduler/font?limit=15` +
      `${organizationId ? `&organization=${organizationId}` : ''}` +
      `${keyword ? `&search=${keyword}` : ''}` +
      `&offset=${pageIndex * 15}`,
    withCredentials: true,
  });

  return await response.data;
};

const getFontUploadSignedUrl = async (
  fileName: string,
  organizationId: number
) => {
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/font/upload_sign_url?filename=${fileName}&organization_id=${organizationId}`,
    withCredentials: true,
  });

  return await response.data;
};

const getFontByName = async (name: string) => {
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/font?&origin_filename=${name}.ttf`,
    withCredentials: true,
  });
  return await response.data;
};

const getFontDownloadUrls = async (
  organizationId: string,
  fileId: any
): Promise<string[]> => {
  const response = await callApi({
    method: 'GET',
    path: `/corps/organization/${organizationId}/file_download_url?id=${fileId}&type=font`,
    withCredentials: true,
  });
  return await response.data;
};

export {
  getFontList,
  getFontUploadSignedUrl,
  getFontDownloadUrls,
  getFontByName,
};
