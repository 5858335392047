import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiButton,
  EuiFieldSearch,
  EuiPageHeader,
  EuiCallOut,
  EuiBreadcrumbs,
  EuiBasicTable,
  EuiSpacer,
  EuiPanel,
} from '@elastic/eui';
import { useHistory } from 'react-router-dom';

import UserContext from 'contexts/UserContext';
import { paginatedDataPlaceholder } from 'utils/objects';
import { getPlaylistList, deletePlaylist } from 'apis/Scheduler/playlist';
import PlaylistTable from './PlaylistTable';
import { Playlist } from 'types/playlist';
import { css } from '@emotion/react';

const PlaylistIndex = () => {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['playlist', 'common']);

  const pageSize = 15;
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [selectedPlaylists, setSelectedPlaylists] = useState<Playlist[]>([]);
  const [searchKeyword, setSearchKeyword] = useState('');

  const playlistQuery = useQuery(
    [
      'playlist',
      {
        pageIndex,
        organizationId: userContext.currentOrganization?.id,
        keyword: searchKeyword,
      },
    ],
    getPlaylistList,
    {
      keepPreviousData: true,
      initialData: paginatedDataPlaceholder,
      refetchOnWindowFocus: false,
      enabled: !!userContext.currentOrganization?.id,
    }
  );

  const handleKeywordChange = (value: string) => {
    setPageIndex(0);
    setSearchKeyword(value);
  };

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={[{ text: t('common:Scheduling') }]}
        truncate={false}
        aria-label="Breadcrumbs of playlist"
      />

      <EuiPageHeader
        pageTitle={t('common:Playlist')}
        rightSideItems={[
          <EuiButton onClick={() => history.push('/playlists/create')}>
            {t('Create Playlist')}
          </EuiButton>,
          <EuiButton
            disabled={
              selectedPlaylists.length == 0 || selectedPlaylists.length > 8
            }
            onClick={() =>
              history.push(
                `/playlists/edit?playlistIds[]=${selectedPlaylists
                  .map(playlist => playlist.id)
                  .join('&playlistIds[]=')}`
              )
            }
          >
            {t('common:Edit')}
          </EuiButton>,
        ]}
        bottomBorder={false}
      >
        <EuiFieldSearch
          placeholder={`${t('common:Search')}...`}
          value={searchKeyword}
          onChange={e => handleKeywordChange(e.target.value)}
          isClearable
          aria-label="Search Playlist"
          spellCheck={false}
          fullWidth
        />
      </EuiPageHeader>

      {selectedPlaylists.length > 0 && (
        <EuiPanel>
          <EuiBasicTable
            rowProps={{
              className: 'playlistTableRow',
            }}
            items={selectedPlaylists.map((p: any) => p)}
            itemId="id"
            columns={[
              {
                field: 'label_color',
                name: '',
                width: '6px',
                className: 'assetLabel',
                mobileOptions: {
                  show: false,
                },
                render: (labelColor: string) => (
                  <div
                    style={{
                      borderLeft: `6px solid ${labelColor}`,
                      width: 6,
                      height: '100%',
                    }}
                  ></div>
                ),
              },
              {
                field: 'id',
                name: '#',
                dataType: 'string',
                width: '60px',
                // @ts-ignore
                css: css`
                  font-style: italic;
                `,
              },
              {
                field: 'name',
                name: t('common:Name', 'Playlist Name'),
                dataType: 'string',
              },
              {
                name: t('Actions'),
                actions: [
                  {
                    name: 'Remove',
                    description: 'Remove playlist',
                    icon: 'cross',
                    type: 'icon',
                    onClick: (item: any) => {
                      const newSelectedPlaylists = selectedPlaylists.filter(
                        selectedPlaylist => selectedPlaylist.id != item.id
                      );
                      setSelectedPlaylists(newSelectedPlaylists);
                    },
                  },
                ],
              },
            ]}
            hasActions
            isExpandable
          />
        </EuiPanel>
      )}

      <EuiSpacer />
      {!playlistQuery.isError ? (
        <PlaylistTable
          playlistList={playlistQuery.data}
          pageIndex={pageIndex}
          pageSize={pageSize}
          isLoading={playlistQuery.isFetching}
          setPageIndex={setPageIndex}
          handleDelete={(playlist: Playlist) => {
            deletePlaylist(playlist.id).then(playlistQuery.refetch);
          }}
          addSelectedPlaylist={(playlist: Playlist) => {
            setSelectedPlaylists(curr => {
              return [...curr, playlist];
            });
          }}
          selectedPlaylists={selectedPlaylists}
        />
      ) : (
        <EuiCallOut
          title="Sorry, there was an error while fetching playlist list"
          color="danger"
          iconType="alert"
        >
          <p>
            {t(
              'common:Please check your internet connectivity or try reload this page.'
            )}
          </p>
        </EuiCallOut>
      )}
    </>
  );
};

export default PlaylistIndex;
