import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiPageContent,
  EuiPageContentBody,
  EuiTitle,
  EuiSpacer,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiCard,
  EuiAspectRatio,
  EuiImage,
  EuiButton,
  EuiButtonEmpty,
  EuiLoadingSpinner,
  EuiLink,
} from '@elastic/eui';

import UserContext from 'contexts/UserContext';
import { getScreenList } from 'apis/Billboard/screen';
import MainHeader from 'layouts/MainHeader';

const Dashboard = () => {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['dashboard', 'common']);

  const screenQuery = useQuery(
    ['screens', { organizationId: userContext.currentOrganization?.id }],
    getScreenList,
    {
      enabled: !!userContext.currentOrganization?.id,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return (
    <>
      <EuiPageContent
        hasBorder={false}
        hasShadow={false}
        paddingSize="none"
        color="transparent"
        borderRadius="none"
        verticalPosition="center"
        horizontalPosition="center"
        style={{ minWidth: '80%' }}
      >
        <EuiPageContentBody>
          <EuiTitle>
            <h1>
              {`${t('Welcome')}`}, {userContext.currentUser?.full_name}
            </h1>
          </EuiTitle>

          <div style={{ margin: '16px 0' }}>
            <span style={{ fontSize: '1.2rem' }}>
              {t('Your current organization')}{' '}
              {userContext.currentOrganization?.name}
            </span>
          </div>

          <EuiSpacer />

          <EuiTitle size="s">
            <h2>{t('Quick Access')}</h2>
          </EuiTitle>

          <EuiSpacer />

          <EuiFlexGrid responsive={false}>
            <EuiFlexItem>
              <EuiButton
                fill
                onClick={() => history.push('/media', { isUploadMedia: true })}
              >
                {t('Upload Media')}
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton fill onClick={() => history.push('/assets/create')}>
                {t('Create Asset')}
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton fill onClick={() => history.push('/playlists/create')}>
                {t('Create Playlist')}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGrid>

          <EuiSpacer />

          <EuiTitle size="s">
            <h2>{t('common:Screen')}</h2>
          </EuiTitle>

          <EuiSpacer />

          {screenQuery.isLoading && <EuiLoadingSpinner size="xl" />}

          <EuiFlexGrid columns={3} gutterSize="l">
            {screenQuery.isSuccess ? (
              screenQuery.data.slice(0, 3).map((screen: any) => {
                return (
                  <EuiFlexItem key={screen.id}>
                    <EuiCard
                      textAlign="left"
                      image={
                        <div>
                          {screen.image_url && (
                            <EuiAspectRatio width={16} height={9}>
                              <EuiImage
                                src={screen.image_url}
                                alt={`Screen photo of ${screen.name}`}
                              />
                            </EuiAspectRatio>
                          )}
                        </div>
                      }
                      title={
                        <EuiLink
                          color="text"
                          onClick={() => history.push(`/screens/${screen.id}`)}
                        >
                          {screen.name}
                        </EuiLink>
                      }
                      description={screen.description}
                      footer={
                        <EuiFlexGroup justifyContent="flexEnd">
                          <EuiFlexItem grow={false}>
                            <EuiButton
                              onClick={() =>
                                history.push(`/schedule`, {
                                  screen: screen,
                                })
                              }
                            >
                              {t('common:Schedule')}
                            </EuiButton>
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <EuiButton
                              onClick={() =>
                                history.push(`/live_control`, {
                                  screen: screen,
                                })
                              }
                            >
                              {t('common:Live Control')}
                            </EuiButton>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      }
                    />
                  </EuiFlexItem>
                );
              })
            ) : (
              <></>
            )}
          </EuiFlexGrid>

          <EuiSpacer />

          {screenQuery.isSuccess && screenQuery.data.length > 3 && (
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty onClick={() => history.push('/screens')}>
                  {t('More Screen...')}
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
        </EuiPageContentBody>
      </EuiPageContent>
    </>
  );
};

export default Dashboard;
