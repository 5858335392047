import { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { EuiEmptyPrompt, EuiText, EuiLink, EuiSpacer } from '@elastic/eui';
import CenteredContentPage from 'layouts/CenteredContentPage';
import { firebaseAuth } from '../../firebase';

const VerifyPending = () => {
  let history = useHistory();
  const [user, setUser] = useState<any>();

  const handleSentVerificationMail = () => {
    if (user) {
      user.sendEmailVerification();
      alert('The verification mail was sent.');
    }
  };

  useEffect(() => {
    const firebaseUnsubscribe = firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        if (user.emailVerified) {
          history.replace('/dashboard');
        }
        setUser(user);
      }
    });
    return () => firebaseUnsubscribe();
  }, [history]);

  return (
    <CenteredContentPage>
      <EuiEmptyPrompt
        iconType="email"
        title={<h2>Verify Pending</h2>}
        body={
          <Fragment>
            <EuiText color="default">
              <h3>The verify email has sent to {user?.email}</h3>
            </EuiText>
            <EuiSpacer />
            <EuiText>
              <p>
                Please access the link in the mail to complete the verify
                procedure.
              </p>
            </EuiText>
            <EuiText>
              <p>
                Not received the email?{' '}
                <EuiLink color="accent" onClick={handleSentVerificationMail}>
                  Send Again
                </EuiLink>
              </p>
            </EuiText>
          </Fragment>
        }
      />
    </CenteredContentPage>
  );
};

export default VerifyPending;
