export const playlist = {
  en: {
    overDurationText:
      'Total Length: {{length}} more than duration {{duration}}',
  },
  'zh-TW': {
    Asset: 'Asset',
    'Create Playlist': '建立播放清單',
    'Create New Playlist': '建立新播放清單',
    'Sorry, there was an error while fetching playlist list':
      'Sorry, there was an error while fetching playlist list',
    'Total Duration': '總長度',
    'Edit Playlist': '編輯播放清單',
    'Editing Playlists': '編輯播放清單',
    'Duplicate This Playlist': '複製此播放清單',
    'Label Color': '標記顏色',
    'Allocate Asset': '配置$t(Asset)',
    'Close Asset Allocator': 'Close $t(Asset) Allocator',
    'Add Asset': '新增$t(Asset)',
    'Selected Playlist': '已選擇',
    'Select Playlist': '選擇',
    'Search Playlist': '搜尋播放清單',
    'No playlist found': '找不到播放清單',
    'Save Playlists': '儲存播放清單',
    overDurationText: '總長度: {{length}} 超過播放清單長度: {{duration}}',
    updateFailedText: '播放清單 #{{ids}} 更新失敗',
    'Total Length': '總長度',
    'Playlist total length more than duration': '播放清單總長度超過設定長度',
    'Playlist updated successfully!': '播放清單更新成功',
    'Playlist update failed!': '播放清單更新失敗',
    'Playlist duration can not more than 24 hours':
      '播放清單長度不能超過24小時',
    'Choose which playlist you would like to save.': '選擇要儲存的播放清單',
    'Asset Allocator': 'Asset配置',
    'Select allocate method': '選擇配置方式',
    'By Ratio': '按比例',
    'By Percentage': '按百分比',
    'Fill in Duration': '按長度',
    'This method will allocate asset in the play list by the specific ratio.':
      'This method will allocate asset in the play list by the specific ratio.',
    'This method will allocate asset in the play list by the specific percentage.':
      'This method will allocate asset in the play list by the specific percentage.',
    'This method will fill and repeat assets in the designated duration.':
      'This method will fill and repeat assets in the designated duration.',
    'Asset Name': '$t(Asset)名稱',
    'Repeat Times': '重複次數',
    Distribute: '配置',
    'No Property': 'No Property',
    'No Client': 'No Client',
  },
};
