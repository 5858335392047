import { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiFlexGrid,
  EuiFlexItem,
  EuiCard,
  EuiAspectRatio,
  EuiImage,
  EuiLoadingSpinner,
  EuiButtonEmpty,
  EuiFlexGroup,
} from '@elastic/eui';

import UserContext from 'contexts/UserContext';
import { Screen } from 'types/screen';
import { getScreenList } from 'apis/Billboard/screen';

const ScreenSelector = ({
  onSelected,
}: {
  onSelected: (screen: Screen) => void;
}) => {
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['common']);

  const screenQuery = useQuery<Screen[]>(
    ['screen', { organizationId: userContext.currentOrganization?.id }],
    getScreenList,
    {
      enabled: !!userContext.currentOrganization?.id,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    if (
      screenQuery.isSuccess &&
      screenQuery.data &&
      screenQuery.data.length === 1
    ) {
      onSelected(screenQuery.data[0]);
    }
  }, [onSelected, screenQuery.data, screenQuery.isSuccess]);

  return (
    <EuiFlexGrid columns={3} gutterSize="l">
      {screenQuery.isSuccess
        ? screenQuery.data.map(screen => (
            <EuiFlexItem key={screen.id}>
              <EuiCard
                textAlign="left"
                image={
                  <div>
                    {screen.image_url && (
                      <EuiAspectRatio width={16} height={9}>
                        <EuiImage
                          src={screen.image_url}
                          alt={`Screen photo of ${screen.name}`}
                        />
                      </EuiAspectRatio>
                    )}
                  </div>
                }
                title={screen.name}
                description={screen.description}
                footer={
                  <EuiFlexGroup justifyContent="center">
                    <EuiFlexItem grow={true}>
                      <EuiButtonEmpty
                        style={{ backgroundColor: '#25262e' }}
                        onClick={() => onSelected(screen)}
                      >
                        {t('Select')}
                      </EuiButtonEmpty>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
          ))
        : screenQuery.isLoading && (
            <EuiFlexItem>
              <EuiLoadingSpinner size="xl" />
            </EuiFlexItem>
          )}
    </EuiFlexGrid>
  );
};

export default ScreenSelector;
