import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
  EuiHeaderSectionItemButton,
  EuiAvatar,
  EuiLink,
  EuiImage,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHideFor,
} from '@elastic/eui';

import { firebaseAuth } from '../firebase';
import UserContext from 'contexts/UserContext';
import GOTIGERIcon from 'assets/images/gotiger_logo_square.svg';
import UserNav from './UserNav';
import ContextMenu from 'components/utils/ContextMenu';

const MainHeader = ({
  handleRedirect = () => {},
}: {
  handleRedirect?: (path: string, name: string) => void;
}) => {
  const { t, i18n } = useTranslation(['common']);
  const userContext = useContext(UserContext);

  const [userNavIsOpen, setUserNavIsOpen] = useState(false);

  return (
    <>
      <EuiHeader position="fixed">
        <EuiHeaderSection grow={false}>
          <EuiHeaderSectionItem>
            <EuiLink onClick={() => handleRedirect('/dashboard', '')}>
              {userContext.currentOrganization?.organizationsetting
                ?.logo_url ? (
                <EuiFlexGroup
                  alignItems="center"
                  gutterSize="m"
                  responsive={false}
                >
                  <EuiFlexItem grow={false}>
                    <EuiImage
                      style={{ maxHeight: 24, marginLeft: 8 }}
                      alt="Organization Logo"
                      src={
                        userContext.currentOrganization?.organizationsetting
                          .logo_url
                      }
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiTitle size="xs">
                      <h1>
                        {userContext.currentOrganization.name} {t('Scheduler')}
                      </h1>
                    </EuiTitle>
                  </EuiFlexItem>
                </EuiFlexGroup>
              ) : (
                <EuiHeaderLogo iconType={GOTIGERIcon}>
                  GOTIGER {t('Scheduler')}
                </EuiHeaderLogo>
              )}
            </EuiLink>
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
        <EuiHideFor sizes={['xs']}>
          <EuiHeaderSection grow={false}>
            <EuiHeaderSectionItem>
              <ContextMenu
                name={t('Billboard')}
                items={[
                  // {
                  //   name: 'Screens',
                  //   link: '/screens',
                  // },
                  {
                    name: t('Live Control'),
                    link: '/live_control',
                  },
                ]}
              />
            </EuiHeaderSectionItem>
            <EuiHeaderSectionItem>
              <ContextMenu
                name={t('Scheduling')}
                items={[
                  {
                    name: t('Media'),
                    link: '/media',
                  },
                  // {
                  //   name: 'Fonts',
                  //   link: '/fonts',
                  // },
                  {
                    name: t('Asset'),
                    link: '/assets',
                  },
                  {
                    name: t('Playlist'),
                    link: '/playlists',
                  },
                  {
                    name: t('Schedule'),
                    link: '/schedule',
                  },
                  {
                    name: t('Default Settings'),
                    link: '/default_settings',
                  },
                  {
                    name: t('Asset Templates'),
                    link: '/layer_templates',
                  },
                ]}
              />
            </EuiHeaderSectionItem>
            <EuiHeaderSectionItem>
              <ContextMenu
                name={t('Management')}
                items={[
                  // {
                  //   name: 'Inquiry',
                  //   link: '/inquiry',
                  // },
                  {
                    name: t('Reporting'),
                    link: '/reporting',
                  },
                  {
                    name: t('Property/Client'),
                    link: '/property_and_client',
                  },
                  {
                    name: t('User Management'),
                    link: `/organization/${userContext.currentOrganization?.id}/members`,
                  },
                  // {
                  //   name: 'Organization Settings',
                  //   link: `/organization/${userContext.currentOrganization?.id}/settings`,
                  // },
                ]}
              />
            </EuiHeaderSectionItem>
          </EuiHeaderSection>
        </EuiHideFor>
        <EuiHeaderSection side="right">
          <EuiHeaderSectionItem>
            <EuiHeaderSectionItemButton
              aria-label="Account menu"
              onClick={() => setUserNavIsOpen(!userNavIsOpen)}
            >
              <EuiAvatar
                name={
                  firebaseAuth.currentUser?.displayName ||
                  userContext.currentUser?.full_name ||
                  ''
                }
                size="s"
              />
            </EuiHeaderSectionItemButton>
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>

      {userContext.currentUser && (
        <UserNav
          userNavIsOpen={userNavIsOpen}
          setUserNavIsOpen={setUserNavIsOpen}
        />
      )}
    </>
  );
};

export default MainHeader;
