import { useContext, useState, useRef } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiPanel,
  EuiForm,
  EuiFormRow,
  EuiSelect,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiDatePicker,
  EuiSpacer,
  EuiOverlayMask,
  EuiLoadingSpinner,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import moment from 'moment';

import UserContext from 'contexts/UserContext';
import { Player, Screen } from 'types/screen';
import { Media } from 'types/media';
import { getPlayerList, getScreenList } from 'apis/Billboard/screen';
import { getDailyReport } from 'apis/Reporting/playlog';
import MediaSelector from 'pages/Asset/AssetEditor/LayerEditor/MediaSelector';
import DailyReportTable from './DailyReportTable';
import useReportExport from 'hooks/useReportExport';

const DailyReport = () => {
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['report', 'common']);

  const [date, setDate] = useState(moment());
  const [screen, setScreen] = useState<string>();
  const [player, setPlayer] = useState<string>();
  const [media, setMedia] = useState<Media>();
  const [playLog, setPlayLog] = useState<any>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const reportRef = useRef<any>();

  const screensQuery = useQuery(
    ['screens', { organizationId: userContext.currentOrganization?.id }],
    getScreenList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const playersQuery = useQuery<Player[]>(
    ['screen-players', screen],
    async () => {
      if (!screen) return;
      const data = await getPlayerList(parseInt(screen));
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!screen,
    }
  );

  const getReportMutaion = useMutation(getDailyReport, {
    onError: () => {
      setIsFetching(false);
    },
    onSuccess: data => {
      setIsFetching(false);
      setPlayLog(Object.keys(data.results).map(key => data.results[key]));
    },
  });

  const handleSubmit = () => {
    setIsFetching(true);
    getReportMutaion.mutate({
      player,
      start_at: moment(date).startOf('day').local().utc().format(),
      media: media?.id || '',
    });
  };

  return (
    <EuiPanel>
      <EuiForm>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFormRow label={t('Date')} fullWidth>
              <EuiDatePicker
                name="operationHoursFrom"
                selected={date}
                onChange={v => setDate(v || moment())}
                aria-label="Operation Hour from"
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label={t('common:Screen')} fullWidth>
              <EuiSelect
                fullWidth
                onChange={e => setScreen(e.target.value)}
                value={screen}
                options={
                  screensQuery.isSuccess
                    ? [
                        { text: t('All Screens') },
                        ...screensQuery.data.map((screen: Screen) => {
                          return {
                            text: screen.name,
                            value: screen.id,
                          };
                        }),
                      ]
                    : [{ text: t('All Screens') }]
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label={t('common:Player')} fullWidth>
              <EuiSelect
                fullWidth
                onChange={e => setPlayer(e.target.value)}
                value={player}
                options={
                  playersQuery.isSuccess
                    ? [
                        { text: t('All Players') },
                        ...playersQuery.data.map((player: Player) => {
                          return {
                            text: player.name || `Player #${player.id}`,
                            value: player.id,
                          };
                        }),
                      ]
                    : [{ text: t('All Players') }]
                }
                disabled={screen == undefined}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <MediaSelector
              selectedMedia={media}
              onChange={(media: Media) => setMedia(media)}
              onClear={() => setMedia(undefined)}
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiButton
              fill
              disabled={!parseInt(player || '0')}
              onClick={handleSubmit}
            >
              {t('Query')}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />
        {isFetching && (
          <EuiOverlayMask>
            <EuiLoadingSpinner />
          </EuiOverlayMask>
        )}
        <div ref={reportRef}>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiText>
                <h2>{t('Daily Report')}</h2>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiButton
                    disabled={playLog.length === 0}
                    onClick={useReportExport(
                      reportRef.current,
                      'word',
                      'Daily Report'
                    )}
                  >
                    {t('Export Word')}
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiButton
                    disabled={playLog.length === 0}
                    onClick={useReportExport(reportRef.current, 'print')}
                  >
                    {t('Print')}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer />
          {playLog && (
            <DailyReportTable
              log={playLog.map((log: any) => ({
                ...log,
                time: moment(log['play_start_at'])
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss'),
                screen: log['screen_name'],
                player: log['player_name'],
                asset_name: log['asset_name'],
                duration: log['duration'],
              }))}
            />
          )}
        </div>
      </EuiForm>
    </EuiPanel>
  );
};

export default DailyReport;
