import {
  EuiBasicTable,
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { useTranslation, Trans } from 'react-i18next';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import useDurationFormat from 'hooks/useDurationFormat';

import { LayerTemplate } from 'types/layerTemplate';
//import { deleteLayerTemplate, getLayerTemplate } from 'apis/Scheduler/layerTemplate';

const LayerTemplateTable = ({
  layerTemplateList,
  isLoading,
  readOnly = false,
  onDelete = () => {},
}: {
  layerTemplateList: LayerTemplate[];
  isLoading: boolean;
  readOnly?: boolean;
  onDelete?: () => void;
}) => {
  const { t, i18n } = useTranslation(['assetTemplate', 'common']);
  const timeformat = useDurationFormat();

  const history = useHistory();

  const [deletingLayerTemplate, setDeletingLayerTemplate] = useState<
    LayerTemplate | undefined
  >();

  const [
    isDeleteConfirmModalVisible,
    setIsDeleteConfirmModalVisible,
  ] = useState(false);

  // const deleteMutation = useMutation(deleteLayerTemplate, {
  //   onSuccess: onDelete,
  // });

  const columns: any = [
    {
      field: 'label_color',
      name: '',
      width: '6px',
      className: 'layerTemplateLabel',
      mobileOptions: {
        show: false,
      },
      render: (labelColor: string) => (
        <div
          style={{
            borderLeft: `6px solid ${labelColor}`,
            width: 6,
            height: '100%',
          }}
        ></div>
      ),
    },
    {
      field: 'name',
      name: t('common:Name', 'Asset Template Name'),
      dataType: 'string',
      render: (layerTemplateName: string, item: LayerTemplate) => (
        <span className="layerTemplateName">{layerTemplateName}</span>
      ),
    },
    {
      name: t('common:Actions'),
      actions: [
        {
          name: t('common:Edit'),
          description: 'Edit layerTemplate',
          icon: 'pencil',
          color: 'primary',
          type: 'icon',
          onClick: (layerTemplate: LayerTemplate) => {
            history.push(`/layer_templates/${layerTemplate.id}/edit`);
          },
          enabled: () => {
            return !readOnly;
          },
        },
        {
          name: t('common:Delete'),
          description: 'Delete layerTemplate',
          icon: 'trash',
          color: 'danger',
          type: 'icon',
          onClick: (layerTemplate: LayerTemplate) => {
            setIsDeleteConfirmModalVisible(true);
            setDeletingLayerTemplate(layerTemplate);
          },
          enabled: () => {
            return !readOnly;
          },
        },
      ],
    },
  ];

  return (
    <>
      <EuiBasicTable
        rowProps={{
          className: 'layerTemplateTableRow',
        }}
        items={layerTemplateList}
        itemId="id"
        columns={columns}
        loading={isLoading}
        hasActions
        isExpandable
        noItemsMessage={
          isLoading
            ? t('common:Loading', 'Loading Asset Templates...')
            : t('No Asset Template found')
        }
      />

      {isDeleteConfirmModalVisible && deletingLayerTemplate && (
        <EuiModal onClose={() => setIsDeleteConfirmModalVisible(false)}>
          <EuiModalHeader>
            <EuiTitle>
              <h2>{t('Delete Asset Template')}</h2>
            </EuiTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiText>
              <p>
                {t(
                  'common:modal:deleteAsk',
                  'Are you sure you want to delete layerTemplate',
                  { type: t('Asset Templates') }
                )}
                <b>{deletingLayerTemplate.name}</b>?
              </p>
              <Trans i18nKey="assetTemplate:modal:deleteContent">
                <p>
                  Any <b>playlist</b> or <b>schedule</b> associated to this
                  Asset Template will be affected.
                </p>
              </Trans>
            </EuiText>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton
              color="danger"
              onClick={() => {
                //if (deletingLayerTemplate?.id) deleteMutation.mutate(deletingLayerTemplate.id);
                setIsDeleteConfirmModalVisible(false);
              }}
            >
              {t('common:Confirm', 'Yes! Delete!')}
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </>
  );
};

export default LayerTemplateTable;
