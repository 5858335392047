import { useState, useContext } from 'react';
import { EuiSuperSelect, EuiText } from '@elastic/eui';
import { useQuery } from 'react-query';

import { CC } from 'types/cc';
import { getCCList } from 'apis/Scheduler/cc';
import UserContext from 'contexts/UserContext';

const CCSelector = ({
  selectedCC,
  onChange,
}: {
  selectedCC?: any;
  onChange: (CC?: CC) => void;
}) => {
  const userContext = useContext(UserContext);

  const CCQuery = useQuery<CC[]>(
    [
      'CCs',
      {
        organizationId: userContext.currentOrganization?.id,
      },
    ],
    getCCList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!userContext.currentOrganization?.id,
    }
  );

  const handleCCChange = (name: string) => {
    const selectedCC: CC | undefined = CCQuery.data?.find(el => {
      return el.name === name;
    });

    onChange(selectedCC);
  };

  return (
    <EuiSuperSelect
      fullWidth
      options={
        CCQuery.isSuccess
          ? CCQuery.data.map((CC: CC) => ({
              value: CC.name,
              inputDisplay: CC.name,
              dropdownDisplay: (
                <>
                  <strong>{CC.name}</strong>
                </>
              ),
            }))
          : []
      }
      valueOfSelected={selectedCC?.name || ''}
      placeholder="Select an cc source"
      onChange={value => handleCCChange(value)}
      itemLayoutAlign="top"
      hasDividers
    />
  );
};

export default CCSelector;
