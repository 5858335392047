import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useQuery } from 'react-query';
import {
  EuiButton,
  EuiImage,
  EuiPageHeader,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiHorizontalRule,
  EuiPanel,
  EuiSpacer,
  EuiBadge,
  EuiHealth,
  EuiLoadingSpinner,
  EuiLoadingContent,
  EuiModal,
  EuiModalHeader,
  EuiForm,
  EuiModalBody,
  EuiModalFooter,
  EuiFormRow,
  EuiFieldNumber,
  EuiBreadcrumbs,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import { database } from '../../../firebase';
import { Screen, Player } from 'types/screen';
import { getScreen, getPlayerList, pairPlayer } from 'apis/Billboard/screen';

const AddPlayerModal = ({
  screenId,
  setIsAddPlayerModalVisible,
  onSuccess,
}: {
  screenId: number;
  setIsAddPlayerModalVisible: (isVisible: boolean) => void;
  onSuccess: () => void;
}) => {
  const { t, i18n } = useTranslation(['screen', 'common']);
  const [pairCode, setPairCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <EuiModal onClose={() => setIsAddPlayerModalVisible(false)}>
      <EuiModalHeader>
        <EuiTitle>
          <h2>{t('Add Player')}</h2>
        </EuiTitle>
      </EuiModalHeader>
      <EuiForm>
        <EuiModalBody>
          <EuiFormRow
            label={t('Pair Code')}
            helpText={t(
              'pairHelp',
              'When new GOTIGER Player application setup on the computer, it will prompt the pair code.'
            )}
          >
            <EuiFieldNumber
              value={pairCode}
              onChange={e => setPairCode(e.target.value)}
            />
          </EuiFormRow>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButton
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                await pairPlayer(screenId, pairCode);
                onSuccess();
                setIsAddPlayerModalVisible(false);
              } catch (e) {
                setIsLoading(false);
                alert(e);
                console.error(e);
              }
            }}
          >
            {t('common:Confirm')}
          </EuiButton>
        </EuiModalFooter>
      </EuiForm>
    </EuiModal>
  );
};

const ScreenView = () => {
  let { screenId } = useParams<any>();
  const history = useHistory();
  const { t, i18n } = useTranslation(['screen', 'common']);

  const [isAddPlayerModalVisible, setIsAddPlayerModalVisible] = useState(false);
  const [playerStatus, setPlayerStatus] = useState<any>({});

  const screenQuery = useQuery<Screen>(
    ['screen', screenId],
    async () => {
      const data = await getScreen(screenId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const playersQuery = useQuery<Player[]>(
    ['screen-players', screenId],
    async () => {
      const data = await getPlayerList(screenId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    let playerStatusRef = database.ref(`/player_status`);
    playerStatusRef.on('value', (snapshot: any) => {
      const players = snapshot.val();
      setPlayerStatus(players);
    });

    return () => {
      playerStatusRef.off();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={[
          { text: 'Billboard' },
          {
            text: t('common:Screen', 'Screens'),
            onClick: () => history.push('/screens'),
          },
        ]}
        truncate={false}
        aria-label="Breadcrumbs of Screen view"
      />

      <EuiPageHeader
        pageTitle={screenQuery.isSuccess ? screenQuery.data.name : ''}
        description={screenQuery.isSuccess && screenQuery.data.description}
        rightSideItems={[
          <EuiButton onClick={() => history.push(`/screens/${screenId}/edit`)}>
            {t('Configure Screen')}
          </EuiButton>,
          <EuiButton
            onClick={() =>
              history.push(`/schedule`, {
                screen: screenQuery.data,
              })
            }
            fill
          >
            {t('Go to Schedule')}
          </EuiButton>,
        ]}
      />

      <div>
        <EuiTitle>
          <h2>{t('Basic Info')}</h2>
        </EuiTitle>

        <EuiSpacer />

        {screenQuery.isLoading && <EuiLoadingContent />}

        <EuiFlexGroup>
          <EuiFlexItem>
            {screenQuery.isSuccess && (
              <EuiDescriptionList
                textStyle="reverse"
                listItems={[
                  {
                    title: t('Location'),
                    description: screenQuery.data.location_description,
                  },
                  {
                    title: t('Coordinates'),
                    description: `${screenQuery.data.latitude}, ${screenQuery.data.longitude}`,
                  },
                  {
                    title: t('Operating Hours'),
                    description: t('All Day'),
                  },
                ]}
              />
            )}
          </EuiFlexItem>
          <EuiFlexItem>
            {screenQuery.isSuccess && screenQuery.data.image_url && (
              <EuiImage src={screenQuery.data.image_url} alt="" />
            )}
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiHorizontalRule />

        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <EuiTitle>
              <h2>{t('common:Player', 'Players')}</h2>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={() => setIsAddPlayerModalVisible(true)} fill>
              {t('Add Player')}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        {playersQuery.isLoading && <EuiLoadingSpinner />}

        <ul>
          {playersQuery.isSuccess &&
            playersQuery.data.map(player => (
              <li key={player.id}>
                <EuiPanel>
                  <EuiFlexGroup
                    justifyContent="spaceBetween"
                    alignItems="center"
                  >
                    <EuiFlexItem grow={false}>
                      <EuiFlexGroup justifyContent="flexStart">
                        <EuiFlexItem grow={false}>
                          <EuiHealth
                            color={
                              player.id &&
                              playerStatus[player.id]?.player_online
                                ? `success`
                                : 'subdued'
                            }
                          >
                            {t('Online')}
                          </EuiHealth>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                      <EuiFlexGroup>
                        <EuiFlexItem>
                          <EuiTitle>
                            <h3>
                              {player.name ||
                                `Player #${player.id} (${t('Unnamed')})`}
                            </h3>
                          </EuiTitle>
                        </EuiFlexItem>
                        {player.is_master && (
                          <EuiFlexItem grow={false}>
                            <EuiBadge color="primary">
                              {t('Master Player')}
                            </EuiBadge>
                          </EuiFlexItem>
                        )}
                        <EuiFlexItem grow={false}>
                          <EuiBadge>
                            {player.width} * {player.height}
                          </EuiBadge>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                    {false && (
                      <EuiFlexItem grow={false}>
                        <EuiButton>{t('Manage Player')}</EuiButton>
                      </EuiFlexItem>
                    )}
                  </EuiFlexGroup>
                </EuiPanel>

                <EuiSpacer size="s" />
              </li>
            ))}
        </ul>

        {screenQuery.data?.id && isAddPlayerModalVisible && (
          <AddPlayerModal
            screenId={screenQuery.data.id}
            setIsAddPlayerModalVisible={setIsAddPlayerModalVisible}
            onSuccess={playersQuery.refetch}
          />
        )}
      </div>
    </>
  );
};

export default ScreenView;
