import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiButton,
  EuiFlexItem,
  EuiSpacer,
  EuiPageHeader,
  EuiFlexGroup,
  EuiBreadcrumbs,
} from '@elastic/eui';

import { Player, Screen } from 'types/screen';
import { getScreenList, getPlayerList } from 'apis/Billboard/screen';
import UserContext from 'contexts/UserContext';
import LiveFire from './LiveFire';
import MainControlSource from './MainControlSource';
import ScreenSelector from 'components/scheduler/ScreenSelector';

const LiveControl = () => {
  const location = useLocation<{ screen: Screen }>();
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['liveControl', 'common']);

  const screenQuery = useQuery<Screen[]>(
    ['screen', { organizationId: userContext.currentOrganization?.id }],
    getScreenList,
    {
      enabled: !!userContext.currentOrganization?.id,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const [screen, setScreen] = useState<Screen | undefined>(
    location.state?.screen
  );
  const [players, setPlayers] = useState<Player[]>([]);

  const playersQuery = useQuery<Player[]>(
    ['screen-players', screen?.id],
    async () => {
      if (!screen?.id) return;
      const data = await getPlayerList(screen.id);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!screen,
    }
  );

  useEffect(() => {
    if (!playersQuery.isLoading && playersQuery.isSuccess) {
      setPlayers(playersQuery.data);
    }
  }, [playersQuery.data, playersQuery.isLoading, playersQuery.isSuccess]);

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={[
          { text: t('common:Billboard') },
          ...(screen ? [{ text: t('Live Control') }] : []),
        ]}
        truncate={false}
        aria-label="Breadcrumbs of live control entry"
      />

      <EuiPageHeader
        pageTitle={screen ? `${screen.name}` : t('Live Control')}
        rightSideItems={
          screenQuery.data && screenQuery.data.length > 1
            ? [
                <EuiButton
                  onClick={() => setScreen(undefined)}
                  disabled={!screen}
                  fill
                >
                  {t('Change Screen')}
                </EuiButton>,
              ]
            : []
        }
      />

      <EuiSpacer />

      {screen ? (
        <>
          <EuiFlexGroup direction="column">
            <EuiFlexItem>
              <LiveFire screen={screen} players={players} />
            </EuiFlexItem>
            <EuiFlexItem>
              <MainControlSource screen={screen} players={players} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      ) : (
        <ScreenSelector onSelected={setScreen} />
      )}
    </>
  );
};

export default LiveControl;
