import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { createContext, Dispatch, SetStateAction } from 'react';

const ToastContext = createContext<{
  toasts: Toast[];
  setToasts: Dispatch<SetStateAction<Toast[]>>;
}>({
  toasts: [],
  setToasts: () => {},
});

export default ToastContext;
