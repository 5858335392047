import { useState, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiPageHeader,
  EuiButton,
  EuiFieldSearch,
  EuiCallOut,
  EuiBreadcrumbs,
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiRadioGroup,
  EuiConfirmModal,
  EuiTitle,
  EuiTextColor,
  EuiButtonEmpty,
} from '@elastic/eui';

import { MEDIA_TYPES, MEDIA_TYPE_NAMES } from './consts';
import UserContext from 'contexts/UserContext';
import { paginatedDataPlaceholder } from 'utils/objects';
import {
  getMediaList,
  patchMedia,
  getAssetsWithMedia,
} from 'apis/Scheduler/media';
import { Asset } from 'types/asset';
import UploadModal from 'components/upload/UploadModal';
import MediaTable from './MediaTable';
import AssetFlyout from '../Asset/AssetFlyout';
import './style.scss';

const computedType = (type: string) => {
  if (type == 'showAll') return undefined;
  else if (type == MEDIA_TYPE_NAMES[MEDIA_TYPES.VIDEO])
    return MEDIA_TYPES.VIDEO;
  else if (type == MEDIA_TYPE_NAMES[MEDIA_TYPES.IMAGE])
    return MEDIA_TYPES.IMAGE;
};

const MediaIndex = () => {
  const location = useLocation<{ isUploadMedia: boolean }>();
  const queryClient = useQueryClient();
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['media', 'common']);

  const pageSize = 15;
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [keyword, setKeyword] = useState('');
  const [type, setType] = useState<string>('showAll');
  const [id, setId] = useState<number>();
  const [isShowDisabledmedia, setIsShowDisabledMedia] = useState<boolean>(
    false
  );
  const [isUploaderVisible, setIsUploaderVisible] = useState(
    location.state?.isUploadMedia || false
  );
  const [
    isDisableConfirmVisible,
    setIsDisableConfirmVisible,
  ] = useState<boolean>(false);
  const [isAssetFlyoutVisible, setIsAssetFlyoutVisible] = useState<boolean>(
    false
  );
  const [assetsWithDisableMedia, setAssetsWithDisableMedia] = useState<any>();
  const [previewAsset, setPreviewAsset] = useState<Asset>();
  const [editMediaId, setEditMediaId] = useState<number>();

  const mediaQuery = useQuery(
    [
      'media',
      {
        pageIndex,
        keyword,
        type: computedType(type),
        id,
        organizationId: userContext.currentOrganization?.id,
        deleted: isShowDisabledmedia,
      },
    ],
    getMediaList,
    {
      keepPreviousData: true,
      initialData: paginatedDataPlaceholder,
      refetchOnWindowFocus: false,
      enabled: !!userContext.currentOrganization?.id,
    }
  );

  const patchMutaion = useMutation(patchMedia, {
    onSuccess: () => {
      queryClient.invalidateQueries('media');
    },
  });

  const onEditMedia = (mediaId: number, data: any) => {
    if (data.name && data.name === '') return;
    if (data.disable) {
      getAssetsWithMedia(mediaId).then((assets: any) => {
        if (assets.length == 0) patchMutaion.mutate({ mediaId, data });
        else {
          setEditMediaId(mediaId);
          setAssetsWithDisableMedia(assets);
          setIsDisableConfirmVisible(true);
        }
      });
    } else {
      patchMutaion.mutate({ mediaId, data });
    }
  };

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={[{ text: t('common:Material') }]}
        truncate={false}
        aria-label="Breadcrumbs of media list"
      />

      <EuiPageHeader
        pageTitle={t('common:Media')}
        rightSideItems={[
          <EuiButton
            onClick={() => {
              setIsUploaderVisible(true);
            }}
            iconType="plus"
          >
            {t('Upload New Media')}
          </EuiButton>,
        ]}
        bottomBorder={false}
      >
        <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <strong>{t('Filter by ID')}:</strong>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFieldSearch
              type="number"
              placeholder={t('Enter ID')}
              onSearch={value => {
                setId(parseInt(value));
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <strong>{t('Search by Keyword')}:</strong>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFieldSearch
              placeholder={t('Enter Keyword')}
              onSearch={value => {
                setKeyword(value);
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <strong>{t('Filter by Type')}:</strong>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiRadioGroup
              options={[
                {
                  id: 'showAll',
                  label: t('Show All'),
                },
                {
                  id: MEDIA_TYPE_NAMES[MEDIA_TYPES.VIDEO],
                  label: t('Show only Videos'),
                },
                {
                  id: MEDIA_TYPE_NAMES[MEDIA_TYPES.IMAGE],
                  label: t('Show only Image'),
                },
              ]}
              idSelected={type}
              onChange={id => setType(id)}
              name="Type Filter"
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiCheckbox
              id={'show_disabled_media'}
              label={t('Show disabled media')}
              checked={isShowDisabledmedia}
              onChange={(e: any) => setIsShowDisabledMedia(e.target.checked)}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPageHeader>

      {!mediaQuery.isError ? (
        <MediaTable
          mediaList={mediaQuery.data}
          pageIndex={pageIndex}
          pageSize={pageSize}
          isLoading={mediaQuery.isFetching}
          setPageIndex={setPageIndex}
          onEditMedia={onEditMedia}
        />
      ) : (
        <EuiCallOut
          title="Sorry, there was an error while fetching media list"
          color="danger"
          iconType="alert"
        >
          <p>
            Please check your internet connectivity or try reload this page.
          </p>
        </EuiCallOut>
      )}
      {isUploaderVisible && (
        <UploadModal
          type="Media"
          acceptFileType="video/*,image/*"
          setIsUploaderVisible={setIsUploaderVisible}
        />
      )}
      {isDisableConfirmVisible && (
        <EuiConfirmModal
          maxWidth={'300'}
          onCancel={() => {
            setIsDisableConfirmVisible(false);
            setAssetsWithDisableMedia(undefined);
          }}
          onConfirm={() => {
            setIsDisableConfirmVisible(false);
            patchMutaion.mutate({
              mediaId: editMediaId,
              data: { disable: true },
            });
            // setEditMediaId(undefined);
            setAssetsWithDisableMedia(undefined);
          }}
          title={
            <EuiTitle>
              <EuiTextColor>Disable Media</EuiTextColor>
            </EuiTitle>
          }
          cancelButtonText={'Cancel'}
          confirmButtonText={'Disable'}
          buttonColor="danger"
        >
          <p>
            <EuiTextColor>Assets with media:</EuiTextColor>
          </p>
          {assetsWithDisableMedia.map((asset: any) => {
            return (
              <EuiButtonEmpty
                color="ghost"
                size="s"
                onClick={() => {
                  setPreviewAsset(asset);
                  setIsAssetFlyoutVisible(true);
                }}
              >
                #{asset.id} - {asset.name}
              </EuiButtonEmpty>
            );
          })}
        </EuiConfirmModal>
      )}
      {isAssetFlyoutVisible && previewAsset && (
        <AssetFlyout
          onClose={() => setPreviewAsset(undefined)}
          asset={previewAsset}
          enterFromParent={false}
          readOnly={true}
        />
      )}
    </>
  );
};

export default MediaIndex;
