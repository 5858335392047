import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiFormRow,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSuperSelect,
  EuiTextArea,
  EuiCheckbox,
  EuiColorPicker,
  EuiPopover,
  EuiButton,
} from '@elastic/eui';
import tinycolor from 'tinycolor2';
import MediaSelector from './MediaSelector';
import FontSelector from './FontSelector';

import { TextOverlay, TextOverlayContent } from 'types/textOverlay';
import { getDataTagList, getTextOverlayStyle } from 'apis/Scheduler/asset';
import './style.scss';
import ColorList from 'components/utils/ColorList';

const TextOverlayEditor = ({
  selectedTextOverlay,
  setEditingTextOverlay,
}: {
  selectedTextOverlay?: any;
  setEditingTextOverlay: (textOverlay: TextOverlay) => void;
}) => {
  const contentInputRef = useRef<any>();
  const { t, i18n } = useTranslation(['textOverlay', 'common']);

  const [dataTagList, setDataTagList] = useState<any>([]);
  const [localContent, setLocalContent] = useState<string>('');
  const [localTextOverlay, setLocalTextOverlay] = useState<TextOverlay>({
    name: '',
    content: [],
    repeat: false,
    style: {
      type: '',
      start: 0,
    },
  });
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [customColor, setCustomColor] = useState<string>();

  const dataTagQuery = useQuery(['dataTag'], async () => {
    const data = await getDataTagList();
    return data;
  });

  const textOverlayStyleQuery = useQuery('textOverlayStyle', async () => {
    const data = await getTextOverlayStyle();
    if (!selectedTextOverlay)
      setLocalTextOverlay({
        ...localTextOverlay,
        style: data['static'],
      });
    return data;
  });

  const contentToDisplay = (content: any) => {
    if (!content) return '';
    let contentString = '';
    let currFont: string = '';
    content.map((elm: TextOverlayContent, index: number) => {
      if (elm['effect'] && elm['effect'].length > 0) {
        elm['effect'].forEach((eff: string) => {
          contentString += `[${eff}]`;
        });
      }
      if (elm['color']) {
        contentString += `[Color=${elm['color']}]`;
      }
      if (elm['font']) {
        if (!currFont || currFont != elm['font']) {
          contentString += `[Font=${elm['font']}]`;
          currFont = elm['font'];
        }
      }
      if (elm['font_size']) {
        contentString += `[FontSize=${elm['font_size']}]`;
      }
      if (elm['type'] == 'text') {
        contentString += `${elm['text']}`;
      } else if (elm['type'] == 'datatag') {
        contentString += `[Datatag_${elm['value']}]`;
      } else if (elm['type'] == 'imagetag') {
        contentString += `[Imagetag=${elm['value']}]`;
      } else if (elm['type'] == 'bg_color') {
        contentString += `[BgColor=${elm['value']}]`;
      }
    });
    return contentString;
  };

  const defaultContent = () => {
    return {
      font: '',
      type: '',
      color: '',
      effect: [],
      font_size: 0,
    };
  };

  const displayToContent = (content: string) => {
    const tagsMatch = content.match(new RegExp('(?<=\\[)[^\\][]*(?=\\])', 'g'));
    const tags = tagsMatch ? tagsMatch.map(x => `[${x}]`) : [];
    const elms: Array<TextOverlayContent> = [];

    let pos = 0;
    let tmpElm: TextOverlayContent = defaultContent();
    let currFont: string = '';

    tags.forEach((tag, index) => {
      if (content.indexOf(tag, pos) != pos) {
        tmpElm = {
          ...tmpElm,
          type: 'text',
          text: content.substring(pos, content.indexOf(tag, pos)),
          font: currFont ? currFont : tmpElm.font,
        };
        pos += tmpElm['text'] ? tmpElm['text'].length : 0;
        elms.push(tmpElm);
        tmpElm = defaultContent();
      }
      if (content.indexOf(tag, pos) == pos) {
        const pureTag = tag.substring(1, tag.length - 1);
        const pureTagLower = pureTag.toLowerCase();
        if (currFont) {
          tmpElm['font'] = currFont;
        }

        if (pureTagLower.indexOf('datatag_') == 0) {
          tmpElm['type'] = 'datatag';
          tmpElm['value'] = pureTag.substring(pureTag.indexOf('_') + 1);
          elms.push(tmpElm);
          tmpElm = defaultContent();
        } else if (pureTagLower.indexOf('imagetag') == 0) {
          tmpElm['type'] = 'imagetag';
          tmpElm['value'] = pureTag.substring(pureTag.indexOf('=') + 1);
          elms.push(tmpElm);
          tmpElm = defaultContent();
        } else if (pureTag.indexOf('=') < 0) {
          tmpElm['effect']
            ? tmpElm['effect'].push(pureTag)
            : (tmpElm['effect'] = [pureTag]);
        } else if (pureTagLower.indexOf('bgcolor') == 0) {
          const colorValue = pureTag.substring(pureTag.indexOf('=') + 1);
          tmpElm = {
            type: 'bg_color',
            value: tinycolor(colorValue).toHexString(),
          };
          elms.push(tmpElm);
          tmpElm = defaultContent();
        } else {
          let tagName: string = pureTagLower.substring(0, tag.indexOf('=') - 1);
          let tagValue: string = pureTag.substring(pureTag.indexOf('=') + 1);
          if (tagName == 'color') {
            const colorValue = pureTag.substring(pureTag.indexOf('=') + 1);
            tagValue = tinycolor(colorValue).isValid()
              ? tinycolor(colorValue).toHexString()
              : tinycolor('white').toHexString();
          }
          if (tagName == 'font') {
            if (!currFont || currFont != tagValue) {
              currFont = tagValue;
            }
          }
          if (tagName == 'fontsize') tagName = 'font_size';
          tmpElm = {
            ...tmpElm,
            [tagName]: tagValue,
          };
        }
        pos += tag.length;
      }
    });
    if (pos < content.length) {
      elms.push({
        ...tmpElm,
        type: 'text',
        text: content.substring(pos, content.length),
        font: currFont || '',
      });
    }
    return elms;
  };

  const insertTag = (tag: any) => {
    const newContent = `${localContent.slice(
      0,
      contentInputRef.current.selectionStart
    )}${tag}${localContent.slice(
      contentInputRef.current.selectionStart,
      localContent.length
    )}`;
    setLocalContent(newContent);
    setEditingTextOverlay({
      ...localTextOverlay,
      content: displayToContent(newContent),
    });
  };

  const handleContentChange = (e: any) => {
    setEditingTextOverlay({
      ...localTextOverlay,
      content: displayToContent(e.target.value),
    });
    setLocalContent(e.target.value);
  };

  useEffect(() => {
    if (!dataTagQuery.isLoading && dataTagQuery.isSuccess) {
      setDataTagList(dataTagQuery.data);
    }
    if (selectedTextOverlay) {
      setLocalTextOverlay(selectedTextOverlay);
    }
    if (selectedTextOverlay && localContent == '') {
      setLocalContent(contentToDisplay(selectedTextOverlay.content));
    }
  }, [
    dataTagQuery.data,
    dataTagQuery.isLoading,
    dataTagQuery.isSuccess,
    localContent,
    selectedTextOverlay,
    setLocalTextOverlay,
  ]);

  return (
    <>
      <EuiFlexGroup style={{ paddingTop: 20 }}>
        <EuiFlexItem>
          <EuiFormRow label={t('Type')}>
            <EuiSuperSelect
              options={
                textOverlayStyleQuery.isSuccess
                  ? Object.keys(textOverlayStyleQuery.data)?.map(
                      (key: any) => ({
                        value: key,
                        inputDisplay: <span>{key}</span>,
                      })
                    )
                  : []
              }
              valueOfSelected={localTextOverlay?.style?.type}
              onChange={value =>
                setEditingTextOverlay({
                  ...localTextOverlay,
                  style: {
                    ...textOverlayStyleQuery.data[value],
                    ...localTextOverlay?.style,
                    type: value,
                  },
                })
              }
              aria-label="Type"
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow
            label={t('Start Position')}
            helpText={t(
              'PositionHelpText',
              'From the left of layer, 0.0 ~ 1.0'
            )}
          >
            <EuiFieldNumber
              placeholder="0.0 ~ 1.0"
              value={localTextOverlay.style?.start}
              onChange={e =>
                setEditingTextOverlay({
                  ...localTextOverlay,
                  style: {
                    ...localTextOverlay.style,
                    start: parseFloat(e.target.value),
                  },
                })
              }
              step={0.1}
              min={0}
              max={1}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup style={{ maxWidth: 1200, flexGrow: 0 }}>
        <EuiFlexItem>
          <EuiFormRow label={t('Data Tag')}>
            <EuiSuperSelect
              options={dataTagList?.map(
                (dataTag: { name: string; tag: any }) => {
                  return {
                    value: dataTag.tag,
                    inputDisplay: <span>{dataTag.name}</span>,
                  };
                }
              )}
              valueOfSelected={undefined}
              onChange={value => insertTag(value)}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow>
            <MediaSelector
              label={t('Image Tag')}
              selectedMedia={{}}
              onChange={media => {
                insertTag(`[ImageTag=${media.id}/${media.origin_filename}]`);
              }}
              filterType={2}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow>
            <FontSelector
              label={t('common:Font')}
              selectedFont={{}}
              onChange={font =>
                insertTag(
                  `[Font=${
                    font.origin_filename.substring(
                      0,
                      font.origin_filename.lastIndexOf('.')
                    ) || font.origin_filename
                  }]`
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label={t('Color')}>
            <EuiSuperSelect
              options={ColorList?.map((color: { name: string; value: any }) => {
                return {
                  value: `[Color=#${color.value}]`,
                  inputDisplay: <span>{color.name}</span>,
                };
              })}
              valueOfSelected={undefined}
              onChange={value => insertTag(value)}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label={t('Custom Color')}>
            <EuiPopover
              button={
                <EuiButton
                  iconType="arrowDown"
                  iconSide="right"
                  onClick={() => {
                    setIsPopoverOpen(!isPopoverOpen);
                  }}
                >
                  {t('Color picker')}
                </EuiButton>
              }
              isOpen={isPopoverOpen}
              closePopover={() => setIsPopoverOpen(false)}
            >
              <div>
                <EuiFormRow label={t('Color picker')}>
                  {
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiColorPicker
                          onChange={color => setCustomColor(color)}
                          placeholder="None"
                          isClearable={true}
                          color={customColor}
                          fullWidth
                        />
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiButton
                          onClick={() => {
                            insertTag(`[Color=${customColor}]`);
                            setCustomColor(undefined);
                            setIsPopoverOpen(!isPopoverOpen);
                          }}
                        >
                          {t('Insert')}
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  }
                </EuiFormRow>
              </div>
            </EuiPopover>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow fullWidth label={t('Content')}>
            <EuiTextArea
              value={localContent}
              onChange={handleContentChange}
              inputRef={contentInputRef}
              fullWidth
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup style={{ paddingBottom: 20 }}>
        <EuiFlexItem>
          <EuiFormRow>
            <EuiCheckbox
              id={'is_repeat'}
              label={t('Repeat')}
              checked={localTextOverlay?.repeat}
              onChange={e =>
                setEditingTextOverlay({
                  ...localTextOverlay,
                  repeat: e.target.checked,
                })
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default TextOverlayEditor;
