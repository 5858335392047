import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  EuiPage,
  EuiPageSideBar,
  EuiPageBody,
  EuiErrorBoundary,
  EuiPageProps,
  EuiShowFor,
} from '@elastic/eui';

import MainHeader from './MainHeader';
import SideNav from './SideNav';

interface Props {
  button?: any;
  children: any;
  withSideNav?: boolean;
  contentPaddingSize?: EuiPageProps['paddingSize'];
}

const Main: React.FC<Props> = ({
  children,
  withSideNav = true,
  contentPaddingSize = 'l',
}) => {
  const history = useHistory();
  const [isSideNavOpenOnMobile, setIsSideNavOpenOnMobile] = useState(false);
  const [selectedItemName, setSelectedItem] = useState('');

  const handleRedirect = (path: string, name: string) => {
    history.push(path);
    setIsSideNavOpenOnMobile(false);
    setSelectedItem(name);
  };

  return (
    <>
      <MainHeader handleRedirect={handleRedirect} />

      <EuiPage paddingSize="none" style={{ minHeight: 'calc(100vh - 49px)' }}>
        {withSideNav && (
          <EuiShowFor sizes={['xs']}>
            <EuiPageSideBar sticky>
              <SideNav
                isSideNavOpenOnMobile={isSideNavOpenOnMobile}
                setIsSideNavOpenOnMobile={setIsSideNavOpenOnMobile}
                handleRedirect={handleRedirect}
                selectedItemName={selectedItemName}
              />
            </EuiPageSideBar>
          </EuiShowFor>
        )}

        <EuiPageBody paddingSize={contentPaddingSize} panelled>
          <EuiErrorBoundary>{children}</EuiErrorBoundary>
        </EuiPageBody>
      </EuiPage>
    </>
  );
};

export default Main;
