import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiTitle,
  EuiText,
  EuiTextColor,
  euiDragDropReorder,
  EuiButtonIcon,
  EuiAccordion,
  EuiSpacer,
  htmlIdGenerator,
  EuiDescriptionList,
  EuiImage,
  EuiBadge,
  EuiLink,
} from '@elastic/eui';

import './style.scss';
import { Layer, Asset } from 'types/asset';
import { MEDIA_TYPES, MEDIA_TYPE_NAMES } from 'pages/Media/consts';
import { TemplateLayer } from 'types/layerTemplate';

const LayerList = ({
  setLayers = () => {},
  layers = [],
  edit,
  handleEditLayer,
  handleDeleteLayer,
  disableDrag = false,
  handleDrag,
  asset,
}: {
  setLayers?: (layers: Array<Layer | TemplateLayer | any>) => void;
  layers: Array<Layer | TemplateLayer>;
  edit: boolean;
  handleEditLayer?: (index: number) => void;
  handleDeleteLayer?: (index: number) => void;
  disableDrag?: boolean;
  handleDrag?: (layers: Array<Layer | TemplateLayer | any>) => void;
  asset?: Asset;
}) => {
  const { t, i18n } = useTranslation(['asset', 'common']);
  const [layerAccordionStates, setLayerAccordionStates] = useState<any>({});
  const onDragEnd: any = ({
    source,
    destination,
  }: {
    source: any;
    destination: any;
  }) => {
    if (source && destination) {
      const items = euiDragDropReorder(layers, source.index, destination.index);
      if (handleDrag)
        handleDrag(
          items.map((layer, index) => {
            return {
              ...layer,
              z_index: items.length - index,
            };
          })
        );
      else
        setLayers(
          items.map((layer, index) => {
            return {
              ...layer,
              z_index: items.length - index,
            };
          })
        );
      if (layerAccordionStates[source.index]) {
        setLayerAccordionStates({
          ...layerAccordionStates,
          [source.index]: false,
          [destination.index]: true,
        });
      }
    }
  };

  const onLayerAccordionToggle = (index: number, isOpen: boolean) => {
    setLayerAccordionStates({ ...layerAccordionStates, [index]: isOpen });
  };

  const handleWebPreview = (layer: any) => {
    console.log(layer);
    localStorage.setItem(
      'webPreview',
      JSON.stringify({ ...layer, asset: asset || null })
    );
    window.open(`/layer/preview/`);
  };

  return (
    <>
      <EuiSpacer />

      <EuiDragDropContext onDragEnd={onDragEnd}>
        <EuiDroppable droppableId="DROPPABLE_AREA_BARE">
          {layers.map((layer: Layer | TemplateLayer, index) =>
            layer.content_type.model === 'builtinmodule' &&
            'content_object' in layer &&
            'type' in layer.content_object &&
            layer.content_object.type === 'hands-detection' ? (
              <></>
            ) : (
              <EuiDraggable
                key={index}
                index={index}
                draggableId={index.toString()}
                disableInteractiveElementBlocking
                isDragDisabled={disableDrag}
              >
                <EuiAccordion
                  id={htmlIdGenerator()()}
                  className="euiAccordionForm layerListItem"
                  buttonClassName="euiAccordionForm__button"
                  arrowDisplay="none"
                  paddingSize="m"
                  onToggle={isOpen => onLayerAccordionToggle(index, isOpen)}
                  forceState={layerAccordionStates[index] ? 'open' : 'closed'}
                  buttonContent={
                    <EuiFlexGroup
                      responsive={false}
                      justifyContent="spaceBetween"
                      alignItems="center"
                    >
                      <EuiFlexItem grow={10} style={{ maxWidth: '100%' }}>
                        <EuiFlexGroup gutterSize="s">
                          {layer.is_primary && (
                            <EuiFlexItem grow={false}>
                              <EuiBadge>Primary Layer</EuiBadge>
                            </EuiFlexItem>
                          )}
                          {'is_conditional' in layer && layer.is_conditional && (
                            <EuiFlexItem grow={false}>
                              <EuiBadge>Conditional Layer</EuiBadge>
                            </EuiFlexItem>
                          )}
                        </EuiFlexGroup>
                        <EuiTitle size="xs">
                          <h3
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            {layer.name}
                          </h3>
                        </EuiTitle>

                        {'content_object' in layer && (
                          <EuiTextColor color="subdued">
                            <EuiFlexGroup
                              gutterSize="s"
                              alignItems="center"
                              responsive={false}
                            >
                              <EuiFlexItem grow={false}>
                                <EuiText size="s">
                                  <p>
                                    <EuiIcon
                                      type={
                                        layer.content_type.model == 'media' &&
                                        'content_object' in layer
                                          ? 'type' in layer.content_object &&
                                            MEDIA_TYPE_NAMES[
                                              layer.content_object.type
                                            ] == 'Image'
                                            ? 'image'
                                            : 'videoPlayer'
                                          : layer.content_type.model ==
                                            'textoverlay'
                                          ? 'visText'
                                          : 'inputOutput'
                                      }
                                      size="m"
                                    />
                                  </p>
                                </EuiText>
                              </EuiFlexItem>
                              <EuiFlexItem>
                                <EuiText
                                  size="s"
                                  style={{ wordBreak: 'break-all' }}
                                >
                                  {layer.content_type.model ==
                                    'textoverlay' && (
                                    <p>{layer.content_type.display_name}</p>
                                  )}
                                  {(layer.content_type.model == 'media' ||
                                    layer.content_type.model == 'livefeed' ||
                                    layer.content_type.model == 'cc') &&
                                    'content_object' in layer && (
                                      <p>
                                        {
                                          // @ts-ignore
                                          layer.content_object.name
                                        }
                                      </p>
                                    )}
                                  {layer.content_type.model == 'html' && (
                                    <p>
                                      {
                                        // @ts-ignore
                                        layer.content_object.url
                                      }
                                    </p>
                                  )}
                                  {layer.content_type.model ==
                                    'windowcapture' && (
                                    <p>
                                      {
                                        // @ts-ignore
                                        layer.content_object.path
                                      }
                                    </p>
                                  )}
                                </EuiText>
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          </EuiTextColor>
                        )}
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  }
                  extraAction={
                    edit
                      ? [
                          <EuiButtonIcon
                            key={`${index}_edit`}
                            iconType="pencil"
                            aria-label="Edit layer"
                            onClick={
                              handleEditLayer
                                ? () => handleEditLayer(index)
                                : () => {}
                            }
                          />,
                          <EuiButtonIcon
                            key={`${index}_del`}
                            iconType="cross"
                            aria-label="Delete layer"
                            onClick={
                              handleDeleteLayer
                                ? () => handleDeleteLayer(index)
                                : () => {}
                            }
                          />,
                        ]
                      : []
                  }
                >
                  <EuiFlexGroup responsive={false} direction="column">
                    {'content_object' in layer &&
                      layer.content_type.model == 'media' &&
                      'type' in layer.content_object && (
                        <EuiFlexItem style={{ textAlign: 'center' }}>
                          {layer.content_object.type == MEDIA_TYPES.IMAGE && (
                            <EuiImage
                              style={{ objectFit: 'contain', maxHeight: 200 }}
                              src={`${layer.content_object.thumb_location}.png`}
                              allowFullScreen
                              alt=""
                            />
                          )}
                          {layer.content_object.type == MEDIA_TYPES.VIDEO && (
                            <video controls style={{ maxHeight: '480px' }}>
                              <source
                                src={`${layer.content_object.thumb_location}.mp4`}
                              />
                            </video>
                          )}
                        </EuiFlexItem>
                      )}
                    {'content_object' in layer &&
                      layer.content_type.model == 'textoverlay' && (
                        <EuiFlexItem style={{ textAlign: 'center' }}>
                          <EuiLink onClick={() => handleWebPreview(layer)}>
                            {t('Web Preview')}
                          </EuiLink>
                        </EuiFlexItem>
                      )}
                    <EuiFlexItem>
                      <EuiDescriptionList
                        type="responsiveColumn"
                        listItems={[
                          {
                            title: `${t('Size')}`,
                            description: `${layer.width}px * ${layer.height}px`,
                          },
                          {
                            title: `${t('Position')}`,
                            description: `${layer.top}px from top, ${layer.left} from left`,
                          },
                        ]}
                        style={{ maxWidth: '400px' }}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiAccordion>
              </EuiDraggable>
            )
          )}
        </EuiDroppable>
      </EuiDragDropContext>
    </>
  );
};

export default LayerList;
