import { useState, useEffect, useContext, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import {
  EuiButton,
  EuiPageHeader,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiFieldText,
  EuiResizableContainer,
  EuiResizeObserver,
  EuiHorizontalRule,
  EuiForm,
  EuiSpacer,
  EuiColorPicker,
  useIsWithinBreakpoints,
  EuiDatePicker,
  EuiCheckbox,
  EuiAccordion,
  htmlIdGenerator,
  EuiBreadcrumbs,
  EuiText,
} from '@elastic/eui';
import moment from 'moment';
import { AxiosError } from 'axios';

import UserContext from 'contexts/UserContext';
import ToastContext from 'contexts/ToastContext';
import { Asset, Layer, EditingLayer } from '../../../types/asset';
import { getAsset, postAsset, patchAsset } from 'apis/Scheduler/asset';
import AssetPreviewer from './AssetPreviewer';
import LayerList from './LayerList';
import LayerEditor from './LayerEditor';
import { Schedule } from 'types/schedule';
import FieldDuration from 'components/utils/FieldDuration';
import useBeforeUnload from 'hooks/useBeforeUnload';

const AssetEditor = ({ action }: { action: 'Create' | 'Edit' }) => {
  let { assetId } = useParams<any>();
  const history = useHistory();
  const { t, i18n } = useTranslation(['asset', 'common']);
  const location = useLocation<{
    asset?: Asset;
    schedule?: Schedule;
    playlistIds?: string[];
  }>();
  const userContext = useContext(UserContext);
  const toastContext = useContext(ToastContext);

  const isMobile = useIsWithinBreakpoints(['xs', 's']);

  const [asset, setAsset] = useState<Asset>({
    name: '',
    label_color: '',
    duration: 0,
    layers: [],
    is_auto_duration: false,
    is_with_default_layer: true,
  });
  const [editingLayer, setEditingLayer] = useState<EditingLayer>();
  const [editingLayerIndex, setEditingLayerIndex] = useState<number>(-1);
  const [isLayerEditorVisible, setIsLayerEditorVisible] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [errors, setErrors] = useState<{ [column: string]: string[] }>({});
  const [leaveCheck, setLeaveCheck] = useState(false);

  const assetQuery = useQuery<Asset>(
    ['asset', assetId],
    async () => {
      const data = await getAsset(assetId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: action == 'Edit',
    }
  );

  const postMutaion = useMutation(postAsset, {
    onSuccess: data => {
      setLeaveCheck(true);
      history.push('/assets');
      toastContext.setToasts([
        ...toastContext.toasts,
        {
          id: 'asset-created',
          title: `New asset: ${data.name} created!`,
          color: 'success',
        },
      ]);
    },
    onError: (error: AxiosError) => {
      setErrors(error.response?.data);
    },
  });

  const patchMutaion = useMutation(patchAsset, {
    onSuccess: () => {
      assetQuery.refetch();
      toastContext.setToasts([
        ...toastContext.toasts,
        {
          id: 'asset-updated',
          title: 'Asset updated successfully!',
          color: 'success',
        },
      ]);
    },
    onError: (error: AxiosError) => {
      setErrors(error.response?.data);
    },
  });

  const onResize = ({ width, height }: { width: number; height: number }) => {
    setContainerWidth(width);
    setContainerHeight(height);
  };

  const handleAddLayer = () => {
    setEditingLayer({
      name: '',
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      z_index: 0,
      is_primary: false,
      is_conditional: false,
      is_audio_enabled: false,
    });
    setEditingLayerIndex(-1);
    setIsLayerEditorVisible(true);
  };

  const handleEditLayer = (index: number) => {
    setEditingLayer(asset?.layers[index]);
    setEditingLayerIndex(index);
    setIsLayerEditorVisible(true);
  };

  const handleSaveLayer = () => {
    if (
      !asset ||
      !editingLayer ||
      !editingLayer.content_type ||
      !editingLayer.content_object
    ) {
      return;
    }

    let editedLayer: Layer = {
      name: editingLayer.name,
      width: editingLayer.width,
      height: editingLayer.height,
      top: editingLayer.top,
      left: editingLayer.left,
      backgroundImage:
        editingLayer.content_type.model == 'media' &&
        'thumb_location' in editingLayer.content_object
          ? `${editingLayer.content_object.thumb_location}.png`
          : undefined,
      content_type: editingLayer.content_type,
      content_object: editingLayer.content_object,
      z_index:
        editingLayerIndex < 0 ? asset.layers.length + 1 : editingLayer.z_index,
      object_id: editingLayer.content_object.id,
      is_primary: editingLayer.is_primary,
      client: editingLayer.client,
      is_conditional: editingLayer.is_conditional,
      is_audio_enabled: editingLayer.is_audio_enabled,
    };

    if (editedLayer.is_primary) {
      setAsset({
        ...asset,
        layers: asset.layers.map(layer => ({
          ...layer,
          is_primary: false,
        })),
      });
    }

    if (editingLayerIndex < 0) {
      let newLayers = [...asset.layers];
      if (editingLayer.is_primary) {
        newLayers = asset.layers.map(layer => ({
          ...layer,
          is_primary: false,
        }));
      }
      setAsset({
        ...asset,
        layers: [editedLayer, ...newLayers],
      });
    } else {
      let newLayers = [...asset.layers];
      if (editingLayer.is_primary) {
        newLayers = asset.layers.map(layer => ({
          ...layer,
          is_primary: false,
        }));
      }
      newLayers[editingLayerIndex] = editedLayer;
      setAsset({
        ...asset,
        layers: newLayers,
      });
    }

    setEditingLayer(undefined);
    setEditingLayerIndex(-1);
    setIsLayerEditorVisible(false);
  };

  const handleSubmitAsset = () => {
    if (!asset) return;

    if (action == 'Create') {
      asset.organization = userContext.currentOrganization?.id;
      postMutaion.mutate(asset);
    } else {
      patchMutaion.mutate(asset);
    }
  };

  const handleDeleteLayer = (deleteIndex: number) => {
    asset.layers.splice(deleteIndex, 1);
    setAsset({
      ...asset,
      layers: asset.layers.map((layer, index) => ({
        ...layer,
        z_index: asset.layers.length - index,
      })),
    });
  };

  useEffect(() => {
    if (!assetQuery.isLoading && assetQuery.isSuccess) {
      setAsset(assetQuery.data);
    }
  }, [assetQuery.data, assetQuery.isLoading, assetQuery.isSuccess]);

  useEffect(() => {
    if (location.state?.asset?.id) {
      setAsset({
        id: undefined,
        ...location.state.asset,
        name: `Copy of ${location.state.asset.name}`,
      });
    }
  }, [location]);

  useBeforeUnload({
    when:
      (action == 'Edit' && asset != assetQuery.data) ||
      (action == 'Create' && !leaveCheck),
  });

  return (
    <>
      <Prompt
        when={
          (action == 'Edit' && asset != assetQuery.data) ||
          (action == 'Create' && !leaveCheck)
        }
        message={t('common:modal:Are you sure to cancel changes?')}
      />
      <EuiBreadcrumbs
        breadcrumbs={[
          { text: 'Layout' },
          { text: 'Asset', onClick: () => history.push('/assets') },
          ...(action == 'Create'
            ? []
            : [
                {
                  text: asset.name,
                },
              ]),
        ]}
        truncate={false}
        aria-label="Breadcrumbs of create asset"
      />

      <EuiPageHeader
        pageTitle={`${t(`common:${action}`)} 
          ${action == 'Create' ? t('New Asset') : t('common:Asset')}`}
        rightSideItems={[
          <EuiButton onClick={() => history.push('/assets')}>
            {t('common:Return to Assets')}
          </EuiButton>,
          ...(location.state?.playlistIds &&
          location.state?.playlistIds.length > 0
            ? [
                <EuiButton
                  onClick={() => {
                    if (location.state?.playlistIds) {
                      if (location.state?.playlistIds.length === 1) {
                        history.push(
                          `/playlists/${location.state?.playlistIds[0]}/edit`,
                          {
                            schedule: location.state.schedule,
                          }
                        );
                      } else {
                        history.push(
                          `/playlists/edit?${location.state?.playlistIds
                            .map(id => `playlistIds[]=${id}`)
                            .join('&')}`
                        );
                      }
                    }
                  }}
                  isLoading={postMutaion.isLoading || patchMutaion.isLoading}
                  disabled={postMutaion.isLoading || patchMutaion.isLoading}
                >
                  {t('common:Return To Playlist')}
                </EuiButton>,
              ]
            : []),
          ...(location.state?.schedule
            ? [
                <EuiButton
                  onClick={() =>
                    history.push(`/schedule`, {
                      schedule: location.state?.schedule,
                      screen: location.state?.schedule?.screen,
                    })
                  }
                  isLoading={postMutaion.isLoading || patchMutaion.isLoading}
                  disabled={postMutaion.isLoading || patchMutaion.isLoading}
                >
                  {t('common:Return To Schedule')}
                </EuiButton>,
              ]
            : []),
          action == 'Edit' && (
            <EuiButton
              onClick={() => history.push('/assets/create', { asset })}
            >
              {t('Duplicate This Asset')}
            </EuiButton>
          ),
          <EuiButton
            onClick={handleSubmitAsset}
            fill
            isLoading={postMutaion.isLoading || patchMutaion.isLoading}
            disabled={postMutaion.isLoading || patchMutaion.isLoading}
          >
            {action == 'Create' ? t('common:Submit') : t('common:Save')}
          </EuiButton>,
        ]}
        bottomBorder={false}
      />

      {action == 'Edit' && (
        <EuiText size="m" color="GrayText">
          Asset #{asset.id}
        </EuiText>
      )}

      <EuiSpacer />

      <EuiForm
        component="form"
        isInvalid={postMutaion.isError || patchMutaion.isError}
      >
        <EuiFlexGroup
          style={{ maxWidth: 1200, flexGrow: 0 }}
          alignItems="center"
        >
          <EuiFlexItem>
            <EuiFormRow
              fullWidth
              label={t('common:Name', 'Asset name')}
              isInvalid={'name' in errors}
              error={errors.name || []}
            >
              <EuiFieldText
                fullWidth
                value={asset?.name}
                onChange={e =>
                  asset ? setAsset({ ...asset, name: e.target.value }) : {}
                }
                isInvalid={'name' in errors}
              />
            </EuiFormRow>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiFormRow
              fullWidth
              label={t('common:Label Color')}
              isInvalid={'label_color' in errors}
              error={errors.label_color || []}
            >
              <EuiColorPicker
                onChange={color =>
                  asset ? setAsset({ ...asset, label_color: color }) : {}
                }
                color={asset?.label_color}
                placeholder="None"
                isClearable={true}
                fullWidth
                isInvalid={'label_color' in errors}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCheckbox
              id={'is_with_default_layer'}
              label={t('No Default Tickers')}
              checked={!asset?.is_with_default_layer}
              onChange={e =>
                asset
                  ? setAsset({
                      ...asset,
                      is_with_default_layer: !e.target.checked,
                    })
                  : {}
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiFormRow
              fullWidth
              label={t('common:Duration')}
              isInvalid={'duration' in errors}
              error={errors.duration || []}
            >
              <FieldDuration
                placeholder=""
                value={asset?.duration}
                setParsedValue={(seconds: number) =>
                  asset
                    ? setAsset({
                        ...asset,
                        duration: seconds,
                      })
                    : {}
                }
                disabled={asset?.is_auto_duration}
                isInvalid={'duration' in errors}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCheckbox
              id={'is_auto_duration'}
              label={t('Auto Duration')}
              checked={asset?.is_auto_duration}
              onChange={e =>
                asset
                  ? setAsset({
                      ...asset,
                      is_auto_duration: e.target.checked,
                      duration: e.target.checked ? 0 : asset.duration,
                    })
                  : {}
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiAccordion
          id={htmlIdGenerator()()}
          buttonContent={t('Advance Setting')}
        >
          <EuiFlexGroup
            style={{
              maxWidth: 1200,
              flexGrow: 0,
              paddingLeft: 12,
              marginTop: 2,
            }}
          >
            <EuiFlexItem>
              <EuiFormRow fullWidth label={t('Air date start')}>
                <EuiDatePicker
                  fullWidth
                  onChange={date =>
                    asset
                      ? setAsset({
                          ...asset,
                          air_date_start: date?.toISOString(),
                        })
                      : {}
                  }
                  selected={
                    asset?.air_date_start ? moment(asset?.air_date_start) : null
                  }
                  placeholder={t('Select start airing date')}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow fullWidth label={t('Air date end')}>
                <EuiDatePicker
                  fullWidth
                  onChange={date =>
                    asset
                      ? setAsset({
                          ...asset,
                          air_date_end: date?.toISOString(),
                        })
                      : {}
                  }
                  selected={
                    asset?.air_date_end ? moment(asset?.air_date_end) : null
                  }
                  placeholder={t('Select last airing date')}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiAccordion>
      </EuiForm>

      <EuiHorizontalRule />

      <EuiResizeObserver onResize={onResize}>
        {resizeRef => (
          <EuiResizableContainer
            direction={isMobile ? 'vertical' : 'horizontal'}
            style={{ flexGrow: 'inherit' }}
          >
            {(EuiResizablePanel, EuiResizableButton) => (
              <>
                <EuiResizablePanel
                  mode="main"
                  initialSize={70}
                  minSize="200px"
                  panelRef={resizeRef}
                >
                  <AssetPreviewer
                    containerWidth={containerWidth}
                    containerHeight={containerHeight}
                    layers={asset?.layers}
                  />
                </EuiResizablePanel>

                <EuiResizableButton />

                <EuiResizablePanel
                  mode="collapsible"
                  initialSize={30}
                  minSize="300px"
                >
                  <EuiFlexGroup
                    justifyContent="spaceBetween"
                    alignItems="center"
                    responsive={false}
                  >
                    <EuiFlexItem grow={false}>
                      <div>{t('common:Layer', 'Layers')}</div>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton
                        size="s"
                        iconType="plus"
                        onClick={handleAddLayer}
                      >
                        {t('Add Layer')}
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>

                  <LayerList
                    setLayers={(layers: Layer[]) =>
                      asset ? setAsset({ ...asset, layers }) : {}
                    }
                    layers={asset?.layers || []}
                    edit={true}
                    handleEditLayer={handleEditLayer}
                    handleDeleteLayer={handleDeleteLayer}
                    asset={
                      action == 'Edit' ? asset : { ...asset, id: undefined }
                    }
                  />
                </EuiResizablePanel>
              </>
            )}
          </EuiResizableContainer>
        )}
      </EuiResizeObserver>

      {isLayerEditorVisible && (
        <LayerEditor
          setIsLayerEditorVisible={setIsLayerEditorVisible}
          editingLayer={editingLayer}
          editingLayerIndex={editingLayerIndex}
          setEditingLayer={setEditingLayer}
          handleSaveLayer={handleSaveLayer}
        />
      )}
    </>
  );
};

export default AssetEditor;
