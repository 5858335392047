import { callApi } from 'utils/fetchApi';
import { Screen } from 'types/screen';

const getScreenList = async ({ queryKey }: any) => {
  const [_key, { organizationId }] = queryKey;
  const response = await callApi({
    method: 'GET',
    path:
      `/billboard/screen?` +
      `${organizationId ? `organization=${organizationId}` : ''}`,
    withCredentials: true,
  });

  return await response.data;
};

const getScreen = async (screenId: number) => {
  const response = await callApi({
    method: 'GET',
    path: `/billboard/screen/${screenId}`,
    withCredentials: true,
  });

  return await response.data;
};

const getPlayerList = async (screenId: number) => {
  const response = await callApi({
    method: 'GET',
    path: `/billboard/player?screen=${screenId}`,
    withCredentials: true,
  });

  return await response.data;
};

const postScreen = async (screen: Screen) => {
  const response = await callApi({
    method: 'POST',
    path: `/billboard/screen`,
    withCredentials: true,
    data: screen,
  });

  return await response.data;
};

const patchScreen = async (screen: Screen) => {
  const response = await callApi({
    method: 'PATCH',
    path: `/billboard/screen/${screen.id}`,
    withCredentials: true,
    data: screen,
  });

  return await response.data;
};

const getScreenImageUploadSignedUrl = async (
  fileName: string,
  organizationId: number
) => {
  const response = await callApi({
    method: 'GET',
    path: `/upload/user_content/get_upload_signed_url?filename=${fileName}&organization_id=${organizationId}`,
    withCredentials: true,
  });
  return await response.data;
};

const pairPlayer = async (screenId: number, pairCode: string) => {
  const response = await callApi({
    method: 'POST',
    path: `/billboard/player/pair`,
    withCredentials: true,
    data: {
      pair_code: pairCode,
      screen: screenId,
    },
  });

  return await response.data;
};

const getLiveFireRecord = async (query: any) => {
  const { screen, cursor } = query;
  const response = await callApi({
    method: 'GET',
    path:
      `/billboard/screen/${screen}/live_fire_record` +
      `${cursor ? `?cursor=${cursor}` : ''}`,
    withCredentials: true,
  });
  return await response.data;
};

export {
  getScreenList,
  getScreen,
  postScreen,
  patchScreen,
  getPlayerList,
  getScreenImageUploadSignedUrl,
  pairPlayer,
  getLiveFireRecord,
};
