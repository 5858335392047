import {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useQueryClient, useQuery } from 'react-query';
import { EuiOverlayMask, EuiLoadingSpinner } from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_dark.css';
import moment from 'moment';

import UserContext from 'contexts/UserContext';
import { firebaseAuth } from '../../firebase';
import { Organization } from 'types/organization';
import { getMe } from 'apis/Auth/me';
import { getOrganization } from 'apis/Corps/orgranization';

interface ComponentProps {
  children: React.ReactNode;
  path?: string;
  withSideNav?: boolean;
  exact?: boolean;
}

const PrivateRoute = ({
  children,
  path,
  withSideNav = true,
  ...rest
}: ComponentProps) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const userContext = useContext(UserContext);

  const [isLogin, setIslogin] = useState(false);
  const [currentOrganizationId, setCurrentOrganizationId] = useState<number>();
  const autoLogoutTimer = useRef<NodeJS.Timeout>();

  const meQuery = useQuery(
    'me',
    async () => {
      return await getMe();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const organizationQuery = useQuery<Organization>(
    ['organization', currentOrganizationId],
    async () => {
      if (!currentOrganizationId) return;
      return await getOrganization(currentOrganizationId);
    },
    {
      enabled: !!currentOrganizationId,
      refetchOnWindowFocus: false,
    }
  );

  const logout = useCallback(() => {
    userContext.setCurrentOrganization(undefined);
    firebaseAuth.signOut();
  }, [userContext]);

  const startLogoutTimer = useCallback(() => {
    const autoLogoutSeconds =
      userContext.currentOrganization?.organizationsetting?.auto_logout_seconds;

    if (!autoLogoutSeconds) {
      window.localStorage.removeItem('lastActiveTime');
      return null;
    }

    const now = moment();
    if (autoLogoutTimer.current) clearTimeout(autoLogoutTimer.current);
    const timerId = setTimeout(() => {
      console.log(`logout by last active time ${now}`);
      logout();
    }, now.clone().add(autoLogoutSeconds, 's').diff(moment(), 'millisecond'));
    autoLogoutTimer.current = timerId;
    console.log(
      `start auto logout timer, expect logout time ${now
        .clone()
        .add(autoLogoutSeconds, 's')}`
    );
    return now;
  }, [
    logout,
    userContext.currentOrganization?.organizationsetting?.auto_logout_seconds,
  ]);

  const handleClick = useCallback(() => {
    const now = startLogoutTimer();
    now && window.localStorage.setItem('lastActiveTime', now.toISOString());
  }, [startLogoutTimer]);

  useEffect(() => {
    const firebaseUnsubscribe = firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        if (!user.emailVerified) {
          history.replace('/verify_pending');
        }
        queryClient.invalidateQueries('me');
        setIslogin(true);
      } else {
        history.replace('/login', { intend: path });
      }
    });
    return () => firebaseUnsubscribe();
  }, [history, queryClient, path]);

  const hiddenOrganizations = useMemo((): number[] => {
    const hiddenOrgIds: number[] = [];
    userContext.currentUser?.organizations.forEach(organization => {
      if (organization.role >= 99) hiddenOrgIds.push(organization.organization);
    });
    return hiddenOrgIds;
  }, [userContext.currentUser?.organizations]);

  useEffect(() => {
    if (!meQuery.isLoading && meQuery.isSuccess && meQuery.data) {
      userContext.setCurrentUser(meQuery.data);
      const storedCurrentOrganizationId:
        | string
        | null = window.localStorage.getItem('currentOrganizationId');
      if (storedCurrentOrganizationId) {
        if (
          hiddenOrganizations.indexOf(parseInt(storedCurrentOrganizationId)) > 0
        ) {
          history.replace('/organization_entry');
        }
        setCurrentOrganizationId(parseInt(storedCurrentOrganizationId));
      } else {
        history.replace('/organization_entry');
      }
    }
  }, [
    hiddenOrganizations,
    history,
    meQuery.data,
    meQuery.isLoading,
    meQuery.isSuccess,
    userContext,
  ]);

  useEffect(() => {
    const autoLogoutSeconds =
      userContext.currentOrganization?.organizationsetting?.auto_logout_seconds;

    if (!autoLogoutSeconds) {
      window.localStorage.removeItem('lastActiveTime');
      return;
    }
    // console.log(`Active: ${window.localStorage.getItem('lastActiveTime')}`);
    if (
      window.localStorage.getItem('lastActiveTime') &&
      moment(window.localStorage.getItem('lastActiveTime')) >
        moment().add(autoLogoutSeconds, 's')
    ) {
      if (autoLogoutTimer.current) clearTimeout(autoLogoutTimer.current);
      console.log(
        `Last active: ${window.localStorage.getItem('lastActiveTime')}, logout`
      );
      logout();
      window.localStorage.removeItem('lastActiveTime');
    } else {
      const now = startLogoutTimer();
      now && window.localStorage.setItem('lastActiveTime', now.toISOString());
    }
  }, [
    logout,
    startLogoutTimer,
    userContext.currentOrganization?.organizationsetting?.auto_logout_seconds,
  ]);

  useEffect(() => {
    if (
      !organizationQuery.isLoading &&
      organizationQuery.isSuccess &&
      organizationQuery.data
    ) {
      userContext.setCurrentOrganization(organizationQuery.data);
    }
  }, [
    organizationQuery.data,
    organizationQuery.isLoading,
    organizationQuery.isSuccess,
    currentOrganizationId,
    userContext,
    history,
  ]);

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [handleClick]);

  return (
    <Route {...rest}>
      {isLogin && meQuery.data ? (
        meQuery.isSuccess && children
      ) : (
        <EuiOverlayMask>
          <EuiLoadingSpinner />
        </EuiOverlayMask>
      )}
    </Route>
  );
};

export default PrivateRoute;
