const ColorBall = ({
  x,
  y,
  width,
  height,
  size,
  r,
  g,
  b,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
  size: number;
  r: number;
  g: number;
  b: number;
}) => {
  return (
    <>
      <div
        style={{
          width,
          height,
          position: 'absolute',
          background: `
            radial-gradient(
              circle at ${x}px ${y}px,
              rgba(${r}, ${g}, ${b}, 1),
              rgba(${r}, ${g}, ${b}, 0) ${size}px
            ) transparent
          `,
          animation: 'gradient 15s ease-in-out infinite',
          backgroundAttachment: 'local',
        }}
      ></div>
      <div
        style={{
          width,
          height,
          position: 'absolute',
          background: `
            radial-gradient(
              circle at ${x + width}px ${y}px,
              rgba(${r}, ${g}, ${b}, 1),
              rgba(${r}, ${g}, ${b}, 0) ${size}px
            ) transparent
          `,
          animation: 'gradient 15s ease-in-out infinite',
          backgroundAttachment: 'local',
        }}
      ></div>
    </>
  );
};

export default ColorBall;
