import { useEffect, useState } from 'react';
import {
  EuiButton,
  EuiFlexGrid,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui';

import { database } from '../../firebase';
import { Screen, Player } from 'types/screen';
import { MainControlSource as TypeMainControlSource } from 'types/liveFire';
import { switchToPlayer } from 'apis/Billboard/switcher';

const MainControlSource = ({
  screen,
  players,
}: {
  screen: Screen;
  players: Player[];
}) => {
  const [
    mainControlSource,
    setMainControlSource,
  ] = useState<TypeMainControlSource>();

  useEffect(() => {
    let mainControlSourceRef = database.ref(
      `/main_control_source/${screen.id}`
    );
    const updatedlistener = mainControlSourceRef.on('value', snapshot => {
      const mainControlSourceData = snapshot.val();
      setMainControlSource(mainControlSourceData);
    });

    return () => mainControlSourceRef.off('value', updatedlistener);
  });

  if (!mainControlSource) return <></>;

  return (
    <>
      <EuiHorizontalRule />

      <EuiTitle>
        <h2>Main Control Source</h2>
      </EuiTitle>

      <EuiSpacer />

      <EuiFlexGrid columns={4}>
        {players.map(player => (
          <EuiFlexItem key={player.id}>
            <EuiButton
              fill={mainControlSource?.playerId == player.id}
              color={
                mainControlSource?.playerId == player.id ? 'success' : 'ghost'
              }
              {...(mainControlSource?.playerId == player.id
                ? { iconType: 'bullseye' }
                : {})}
              onClick={() => player.id && switchToPlayer(player.id)}
            >
              {player.name || `Player ${player.id}`}
            </EuiButton>
          </EuiFlexItem>
        ))}
      </EuiFlexGrid>
    </>
  );
};

export default MainControlSource;
