import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiButton,
  EuiCallOut,
  EuiSpacer,
  EuiBreadcrumbs,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiModalFooter,
  EuiTitle,
  EuiTextColor,
} from '@elastic/eui';

import { Screen } from 'types/screen';
import { Schedule } from 'types/schedule';
import { getScreenList } from 'apis/Billboard/screen';
import ScheduleEditor from './scheduleEditor';
import ScreenSelector from 'components/scheduler/ScreenSelector';
import ScheduleContext from 'contexts/ScheduleContext';
import UserContext from 'contexts/UserContext';
import { getScreen } from 'apis/Billboard/screen';

const ScheduleIndex = () => {
  const location = useLocation<{ screen: number; schedule?: Schedule }>();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['schedule', 'common']);

  const screenQuery = useQuery<Screen[]>(
    ['screen', { organizationId: userContext.currentOrganization?.id }],
    getScreenList,
    {
      enabled: !!userContext.currentOrganization?.id,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const [screen, setScreen] = useState<Screen | undefined>();
  const [isOpenScreenSelector, setIsOpenScreenSelector] = useState(false);
  const [errors, setErrors] = useState<{ [column: string]: string[] }>({});

  const getScreenData = useCallback(async (screenId: number) => {
    const screenData: Screen = await getScreen(screenId);
    setScreen(screenData);
    return screenData;
  }, []);

  const replaceHistory = () => {
    if (location.state?.schedule) {
      history.replace({ ...history.location, state: undefined });
    }
  };

  useEffect(() => {
    if (location.state?.screen && !screen) {
      getScreenData(location.state.screen);
    }
  }, [getScreenData, location, screen]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => replaceHistory);
    return () => {
      // Reset Location state if we leave this page
      //replaceHistory();
      window.removeEventListener('beforeunload', replaceHistory);
    };
  });

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={[
          { text: t('common:Scheduling') },
          ...(screen ? [{ text: t('common:Schedule') }] : []),
        ]}
        truncate={false}
        aria-label="Breadcrumbs of schedule"
      />

      {!isOpenScreenSelector && screen ? (
        <ScheduleEditor
          screens={screenQuery.data || []}
          screen={screen}
          setIsOpenScreenSelector={setIsOpenScreenSelector}
          backToSchedule={location.state?.schedule}
        />
      ) : (
        <>
          <EuiCallOut
            title={t('Please select a screen to manage its playback schedule')}
            iconType="iInCircle"
          />

          <EuiSpacer />

          <ScreenSelector
            onSelected={screen => {
              replaceHistory();
              setScreen(screen);
              setIsOpenScreenSelector(false);
            }}
          />
        </>
      )}

      {Object.keys(errors).length != 0 && (
        <EuiModal onClose={() => setErrors({})}>
          <EuiModalHeader>
            <EuiTitle>
              <h2>
                <EuiTextColor color="danger">
                  {t('Sorry, there was an error')}
                </EuiTextColor>
              </h2>
            </EuiTitle>
          </EuiModalHeader>
          <EuiModalBody>
            {errors.non_field_errors?.map(error => (
              <p>
                <EuiTextColor color="danger">{error}</EuiTextColor>
              </p>
            ))}
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton color="ghost" onClick={() => setErrors({})}>
              Okay
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </>
  );
};

export default ScheduleIndex;
