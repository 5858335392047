import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  EuiCollapsibleNav,
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiButtonEmpty,
  EuiTitle,
  EuiListGroup,
  EuiSpacer,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiModalFooter,
  EuiForm,
  EuiFormRow,
  EuiFieldPassword,
} from '@elastic/eui';

import firebase from 'firebase/app';
import { firebaseAuth } from '../firebase';
import UserContext from 'contexts/UserContext';
import ToastContext from 'contexts/ToastContext';

const UserNav = ({
  userNavIsOpen,
  setUserNavIsOpen,
}: {
  userNavIsOpen: boolean;
  setUserNavIsOpen: (isOpen: boolean) => void;
}) => {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const toastContext = useContext(ToastContext);

  const [
    isChangePasswordModalVisible,
    setIsChangePasswordModalVisible,
  ] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  return (
    <EuiCollapsibleNav
      isOpen={userNavIsOpen}
      size={240}
      onClose={() => setUserNavIsOpen(false)}
    >
      <div style={{ padding: 16 }}>
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          style={{ padding: 16 }}
        >
          <EuiTitle size="s">
            <h2>{userContext.currentOrganization?.name}</h2>
          </EuiTitle>
        </EuiFlexGroup>
        <EuiFlexGroup justifyContent="center" alignItems="center">
          <EuiFlexItem grow={false}>
            {firebaseAuth.currentUser?.displayName && (
              <EuiAvatar name={firebaseAuth.currentUser.displayName} size="m" />
            )}
          </EuiFlexItem>
          <EuiFlexItem>
            <div>
              {userContext.currentUser?.full_name ||
                firebaseAuth.currentUser?.displayName}
            </div>
            <div>{userContext.currentUser?.email}</div>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        {firebaseAuth.currentUser &&
          firebaseAuth.currentUser?.providerData.filter(
            provider => provider?.providerId === 'password'
          ).length > 0 && (
            <EuiListGroup
              listItems={[
                {
                  label: 'Change Password',
                  onClick: () => setIsChangePasswordModalVisible(true),
                },
              ]}
            />
          )}
      </div>

      <EuiFlexGroup style={{ padding: 16 }} alignItems="flexEnd">
        <EuiFlexItem>
          {userContext.currentUser &&
            userContext.currentUser.organizations.length > 1 && (
              <EuiFlexGroup
                justifyContent="center"
                alignItems="center"
                style={{ padding: 16 }}
              >
                <EuiButtonEmpty
                  onClick={() => {
                    userContext.setCurrentOrganization(undefined);
                    window.localStorage.removeItem('currentOrganizationId');
                    history.push('/organization_entry');
                  }}
                >
                  Switch Organization
                </EuiButtonEmpty>
              </EuiFlexGroup>
            )}
          <EuiFlexGroup
            justifyContent="center"
            alignItems="center"
            style={{ padding: 16 }}
          >
            <EuiButton
              onClick={() => {
                userContext.setCurrentOrganization(undefined);
                firebaseAuth.signOut();
              }}
            >
              Logout
            </EuiButton>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      {isChangePasswordModalVisible && (
        <EuiModal onClose={() => setIsChangePasswordModalVisible(false)}>
          <EuiModalHeader>
            <EuiTitle>
              <h2>Change Password</h2>
            </EuiTitle>
          </EuiModalHeader>
          <EuiForm>
            <EuiModalBody>
              <EuiFormRow label="Current Password">
                <EuiFieldPassword
                  value={currentPassword}
                  onChange={e => setCurrentPassword(e.target.value)}
                />
              </EuiFormRow>
              <EuiFormRow label="New Password">
                <EuiFieldPassword
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                />
              </EuiFormRow>
              <EuiFormRow label="Confirm Password">
                <EuiFieldPassword
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </EuiFormRow>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButton
                onClick={async () => {
                  if (
                    !firebaseAuth.currentUser?.email ||
                    newPassword !== confirmPassword
                  )
                    return;
                  const credential = firebase.auth.EmailAuthProvider.credential(
                    firebaseAuth.currentUser?.email,
                    currentPassword
                  );
                  try {
                    await firebaseAuth.currentUser?.reauthenticateWithCredential(
                      credential
                    );
                    await firebaseAuth.currentUser?.updatePassword(newPassword);
                    setIsChangePasswordModalVisible(false);
                    toastContext.setToasts([
                      ...toastContext.toasts,
                      {
                        id: 'password-changed',
                        title: 'Password Changed.',
                        color: 'success',
                        text: (
                          <p>
                            Please use new password to login GOTIGER Scheduler
                            next time.
                          </p>
                        ),
                      },
                    ]);
                  } catch (e) {
                    console.error(e);
                    alert(e);
                  }
                }}
              >
                Confirm
              </EuiButton>
            </EuiModalFooter>
          </EuiForm>
        </EuiModal>
      )}
    </EuiCollapsibleNav>
  );
};

export default UserNav;
