const ORDER_STATUS: { [key: string]: number } = {
  INCOMING: 1,
  PROCESSING: 2,
  PENDING_PAYMENT: 3,
  PAYMENT_CONFIRMED: 4,
  SCHEDULED: 5,
  ON_GOING: 6,
  ENDED: 7,
  ARCHIVED: 8,
  CANCELED: 9,
};

const ORDER_STATUS_NAMES: { [type: string]: string } = {
  [ORDER_STATUS.INCOMING]: 'Incoming',
  [ORDER_STATUS.PROCESSING]: 'Processing',
  [ORDER_STATUS.PENDING_PAYMENT]: 'Pending Payment',
  [ORDER_STATUS.PAYMENT_CONFIRMED]: 'Payment Confirmed',
  [ORDER_STATUS.SCHEDULED]: 'Scheduled',
  [ORDER_STATUS.ON_GOING]: 'On Going',
  [ORDER_STATUS.ENDED]: 'Ended',
  [ORDER_STATUS.ARCHIVED]: 'Archived',
  [ORDER_STATUS.CANCELED]: 'Canceled',
};

export { ORDER_STATUS, ORDER_STATUS_NAMES };
