import { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { I18nextProvider } from 'react-i18next';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import '@elastic/eui/dist/eui_theme_dark.css';
import { EuiProvider, EuiGlobalToastList } from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';

import './App.scss';
import i18n from './i18n';
import UserContext from 'contexts/UserContext';
import ToastContext from 'contexts/ToastContext';
import UploadContext from 'contexts/UploadContext';
import RedirectIfAuthenticated from 'components/auth/RedirectIfAuthenticated';
import SchedulerRoutes from 'routers/SchedulerRoutes';
import Login from 'pages/Login';
import VerifyPending from 'pages/VerifyPending';
import PlayerSetup from 'pages/PlayerSetup';
import { Organization } from 'types/organization';
import { User } from 'types/user';
import { UploadStatus, UploadProgress } from 'types/upload';
import PrivateRoute from 'components/auth/PrivateRoute';
import OrganizationSelector from 'pages/OrganizationSelector';
import UploadStateBar from 'components/upload/UploadStateBar';
import { FileItem } from 'types/upload';
import Gradient from 'pages/Gradient';
import LightAndShadow from 'pages/LightAndShadow';

const queryClient = new QueryClient();

function App() {
  const [
    currentOrganization,
    setCurrentOrganization,
  ] = useState<Organization>();
  const [currentUser, setCurrentUser] = useState<User>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadQueue, setUploadQueue] = useState<FileItem[]>([]);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>({});
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>({});
  const [toasts, setToasts] = useState<Toast[]>([]);

  const removeToast = (removedToast: Toast) => {
    setToasts(toasts.filter((toast: Toast) => toast.id !== removedToast.id));
  };

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <UploadContext.Provider
            value={{
              isUploading,
              setIsUploading,
              uploadQueue,
              setUploadQueue,
              uploadStatus,
              setUploadStatus,
              uploadProgress,
              setUploadProgress,
            }}
          >
            <ToastContext.Provider
              value={{
                toasts,
                setToasts,
              }}
            >
              <UserContext.Provider
                value={{
                  currentOrganization,
                  setCurrentOrganization,
                  currentUser,
                  setCurrentUser,
                }}
              >
                <EuiProvider colorMode="dark">
                  <Router>
                    <Switch>
                      <Route exact path="/">
                        <Redirect to="/dashboard" />
                      </Route>
                      <RedirectIfAuthenticated path="/login/:organizationSlug?">
                        <Login />
                      </RedirectIfAuthenticated>
                      <Route path="/verify_pending">
                        <VerifyPending />
                      </Route>
                      <Route path="/player_setup">
                        <PlayerSetup />
                      </Route>
                      <Route path="/gradient">
                        <Gradient width={2048} height={3060} />
                      </Route>
                      <Route path="/gradient2">
                        <Gradient width={1024} height={2880} />
                      </Route>
                      <Route path="/light_and_shadow">
                        <LightAndShadow width={2048} height={3060} />
                      </Route>
                      <PrivateRoute path="/organization_entry">
                        <OrganizationSelector />
                      </PrivateRoute>
                      <SchedulerRoutes />
                    </Switch>
                  </Router>
                </EuiProvider>
              </UserContext.Provider>
            </ToastContext.Provider>
            <UploadStateBar />
          </UploadContext.Provider>

          <EuiGlobalToastList
            toasts={toasts}
            dismissToast={removeToast}
            toastLifeTimeMs={6000}
          />
        </I18nextProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
