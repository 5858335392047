import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  EuiButtonEmpty,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiLoadingSpinner,
  EuiPopover,
  useGeneratedHtmlId,
} from '@elastic/eui';

import { Asset, Layer } from 'types/asset';
import { getAsset } from 'apis/Scheduler/asset';
import { MEDIA_TYPE_NAMES } from 'pages/Media/consts';

const LayerSelector = ({
  handlePickLayer,
  asset,
}: {
  handlePickLayer: (asset: Asset, layer: Layer) => void;
  asset: Asset;
}) => {
  const [isOpenLayerSelector, setIsOpenLayerSelector] = useState(false);
  const [layers, setLayers] = useState<Layer[]>();

  const assetQuery = useQuery<Asset>(
    ['asset', { id: asset.id }],
    async () => {
      if (!asset.id) return;
      const data = await getAsset(asset.id);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: isOpenLayerSelector,
    }
  );

  useEffect(() => {
    if (!assetQuery.isLoading && assetQuery.isSuccess) {
      setLayers(
        assetQuery.data.layers.filter(
          layer =>
            layer.content_type.model == 'media' ||
            layer.content_type.model == 'textoverlay' ||
            layer.content_type.model == 'html'
        )
      );
    }
  }, [assetQuery.data, assetQuery.isLoading, assetQuery.isSuccess]);

  const customContextMenuPopoverId = useGeneratedHtmlId({
    prefix: 'customContextMenuPopover',
  });

  return (
    <EuiPopover
      id={customContextMenuPopoverId}
      button={
        <EuiButtonEmpty
          color="warning"
          onClick={() => setIsOpenLayerSelector(!isOpenLayerSelector)}
        >
          Select a Layer
        </EuiButtonEmpty>
      }
      isOpen={isOpenLayerSelector}
      closePopover={() => setIsOpenLayerSelector(false)}
      panelPaddingSize="none"
      anchorPosition="downLeft"
    >
      <EuiContextMenuPanel>
        {assetQuery.isSuccess && layers ? (
          layers.map((layer: Layer) => (
            <EuiContextMenuItem
              key={layer.id}
              icon={
                layer.content_type.model == 'media' && 'content_object' in layer
                  ? 'type' in layer.content_object &&
                    MEDIA_TYPE_NAMES[layer.content_object.type] == 'Image'
                    ? 'image'
                    : 'videoPlayer'
                  : layer.content_type.model == 'textoverlay'
                  ? 'visText'
                  : 'inputOutput'
              }
              size="s"
              onClick={() => handlePickLayer(asset, layer)}
            >
              {layer.name}
            </EuiContextMenuItem>
          ))
        ) : (
          <EuiContextMenuItem>
            <EuiLoadingSpinner />
          </EuiContextMenuItem>
        )}
      </EuiContextMenuPanel>
    </EuiPopover>
  );
};

export default LayerSelector;
