import { Switch, Route } from 'react-router-dom';

import Main from 'layouts/Main';
import PrivateRoute from 'components/auth/PrivateRoute';
import Dashboard from 'pages/Dashboard';
import MediaIndex from 'pages/Media';
import FontIndex from 'pages/Font';
import ScreenIndex from 'pages/Screen';
import ScreenView from 'pages/Screen/ScreenView';
import ScreenEditor from 'pages/Screen/ScreenEditor';
import LiveControl from 'pages/LiveControl';
import AssetIndex from 'pages/Asset';
import AssetEditor from 'pages/Asset/AssetEditor';
import PlaylistIndex from 'pages/Playlist';
import PlaylistEditor from 'pages/Playlist/PlaylistEditor';
import MultiPlaylistEditor from 'pages/Playlist/MultiPlaylistEditor';
import ScheduleIndex from 'pages/Schedule';
import InquiryIndex from 'pages/Inquiry';
import DefaultSettings from 'pages/DefaultSettings';
import LayerTemplateIndex from 'pages/LayerTemplate';
import LayerTemplateEditor from 'pages/LayerTemplate/LayerTemplateEditor';
import Reporting from 'pages/Reporting';
import PropertyClient from 'pages/PropertyClient';
import Members from 'pages/Members';
import NotFound from 'pages/404';
import OrganizationSettings from 'pages/OrganizationSettings';
import TextOverlayPreview from 'pages/Asset/AssetEditor/LayerEditor/TextOverlayPreview';

const SchedulerRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path="/dashboard">
        <Main>
          <Dashboard />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/media">
        <Main>
          <MediaIndex />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/fonts">
        <Main>
          <FontIndex />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/screens" exact>
        <Main>
          <ScreenIndex />
        </Main>
      </PrivateRoute>
      <PrivateRoute path="/screens/create" exact>
        <Main>
          <ScreenEditor action="Create" />
        </Main>
      </PrivateRoute>
      <PrivateRoute path="/screens/:screenId" exact>
        <Main>
          <ScreenView />
        </Main>
      </PrivateRoute>
      <PrivateRoute path="/screens/:screenId/edit">
        <Main>
          <ScreenEditor action="Edit" />
        </Main>
      </PrivateRoute>
      <PrivateRoute path="/live_control" exact>
        <Main>
          <LiveControl />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/assets" exact>
        <Main>
          <AssetIndex />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/assets/create">
        <Main>
          <AssetEditor action="Create" />
        </Main>
      </PrivateRoute>
      <PrivateRoute path="/assets/:assetId/edit">
        <Main>
          <AssetEditor action="Edit" />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/playlists" exact>
        <Main>
          <PlaylistIndex />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/playlists/create">
        <Main>
          <PlaylistEditor action="Create" />
        </Main>
      </PrivateRoute>
      <PrivateRoute path="/playlists/:playlistId/edit">
        <Main>
          <PlaylistEditor action="Edit" />
        </Main>
      </PrivateRoute>
      <PrivateRoute path="/playlists/edit">
        <Main>
          <MultiPlaylistEditor />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/schedule">
        <Main>
          <ScheduleIndex />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/default_settings">
        <Main>
          <DefaultSettings />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/layer_templates" exact>
        <Main>
          <LayerTemplateIndex />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/layer_templates/create">
        <Main>
          <LayerTemplateEditor action="Create" />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/layer_templates/:layerTemplateId/edit">
        <Main>
          <LayerTemplateEditor action="Edit" />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/inquiry">
        <Main contentPaddingSize="none">
          <InquiryIndex />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/reporting">
        <Main>
          <Reporting />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/property_and_client">
        <Main>
          <PropertyClient />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/organization/:organizationId/members">
        <Main>
          <Members />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/organization/:organizationId/settings">
        <Main>
          <OrganizationSettings />
        </Main>
      </PrivateRoute>

      <PrivateRoute path="/layer/preview">
        <TextOverlayPreview />
      </PrivateRoute>

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default SchedulerRoutes;
