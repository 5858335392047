import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation, Trans } from 'react-i18next';
import {
  EuiBreadcrumbs,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiPageHeader,
  EuiResizableContainer,
  EuiResizeObserver,
  useIsWithinBreakpoints,
  EuiFormRow,
  EuiFieldText,
} from '@elastic/eui';
import { AxiosError } from 'axios';

import {
  LayerTemplate,
  TemplateLayer,
  EditingTemplateLayer,
} from 'types/layerTemplate';
import ToastContext from 'contexts/ToastContext';
import UserContext from 'contexts/UserContext';
import AssetPreviewer from 'pages/Asset/AssetEditor/AssetPreviewer';
import LayerList from 'pages/Asset/AssetEditor/LayerList';
import LayerEditor from 'pages/Asset/AssetEditor/LayerEditor';
import {
  getLayerTemplate,
  postLayerTemplate,
  patchLayerTemplate,
} from 'apis/Scheduler/layerTemplate';

const LayerTemplateEditor = ({ action }: { action: 'Create' | 'Edit' }) => {
  const { t, i18n } = useTranslation(['assetTemplate', 'common']);
  let { layerTemplateId } = useParams<any>();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const toastContext = useContext(ToastContext);

  const isMobile = useIsWithinBreakpoints(['xs', 's']);

  const [layerTemplate, setLayerTemplate] = useState<LayerTemplate>({
    name: '',
    template_data: [],
  });
  const [editingLayer, setEditingLayer] = useState<EditingTemplateLayer>();
  const [editingLayerIndex, setEditingLayerIndex] = useState<number>(-1);
  const [isLayerEditorVisible, setIsLayerEditorVisible] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [errors, setErrors] = useState<{ [column: string]: string[] }>({});

  const layerTemplateQuery = useQuery<LayerTemplate>(
    ['layerTemplate', layerTemplateId],
    async () => {
      const data = await getLayerTemplate(layerTemplateId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: action == 'Edit',
    }
  );

  const postMutaion = useMutation(postLayerTemplate, {
    onSuccess: data => {
      history.push('/layer_templates');
      toastContext.setToasts([
        ...toastContext.toasts,
        {
          id: 'layer-template-created',
          title: `New asset template: ${data.name} created!`,
          color: 'success',
        },
      ]);
    },
    onError: (error: AxiosError) => {
      setErrors(error.response?.data);
    },
  });

  const patchMutaion = useMutation(patchLayerTemplate, {
    onSuccess: () => {
      layerTemplateQuery.refetch();
      toastContext.setToasts([
        ...toastContext.toasts,
        {
          id: 'layer-template-updated',
          title: 'Asset template updated successfully!',
          color: 'success',
        },
      ]);
    },
    onError: (error: AxiosError) => {
      setErrors(error.response?.data);
    },
  });

  const handleSubmitLayerTemplate = () => {
    if (!layerTemplate) return;

    if (action == 'Create') {
      layerTemplate.organization = userContext.currentOrganization?.id;
      postMutaion.mutate(layerTemplate);
    } else {
      patchMutaion.mutate(layerTemplate);
    }
  };

  const handleAddLayer = () => {
    setEditingLayer({
      name: '',
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      z_index: 0,
      is_primary: false,
      is_audio_enabled: false,
    });
    setEditingLayerIndex(-1);
    setIsLayerEditorVisible(true);
  };

  const handleEditLayer = (index: number) => {
    setEditingLayer(layerTemplate?.template_data[index]);
    setEditingLayerIndex(index);
    setIsLayerEditorVisible(true);
  };

  const handleDeleteLayer = (deleteIndex: number) => {
    layerTemplate.template_data.splice(deleteIndex, 1);
    setLayerTemplate({
      ...layerTemplate,
      template_data: layerTemplate.template_data.map((layer, index) => ({
        ...layer,
        z_index: layerTemplate.template_data.length - index,
      })),
    });
  };

  const handleSaveLayer = () => {
    if (!layerTemplate || !editingLayer || !editingLayer.content_type) {
      return;
    }

    let editedLayer: TemplateLayer = {
      name: editingLayer.name,
      width: editingLayer.width,
      height: editingLayer.height,
      top: editingLayer.top,
      left: editingLayer.left,
      content_type: editingLayer.content_type,
      z_index:
        editingLayerIndex < 0
          ? layerTemplate.template_data.length + 1
          : editingLayer.z_index,
      is_primary: editingLayer.is_primary,
      is_audio_enabled: editingLayer.is_audio_enabled,
    };

    if (editedLayer.is_primary) {
      setLayerTemplate({
        ...layerTemplate,
        template_data: layerTemplate.template_data.map(layer => ({
          ...layer,
          is_primary: false,
        })),
      });
    }

    if (editingLayerIndex < 0) {
      let newLayers = [...layerTemplate.template_data];
      if (editingLayer.is_primary) {
        newLayers = layerTemplate.template_data.map(layer => ({
          ...layer,
          is_primary: false,
        }));
      }
      setLayerTemplate({
        ...layerTemplate,
        template_data: [editedLayer, ...newLayers],
      });
    } else {
      let newLayers = [...layerTemplate.template_data];
      if (editingLayer.is_primary) {
        newLayers = layerTemplate.template_data.map(layer => ({
          ...layer,
          is_primary: false,
        }));
      }
      newLayers[editingLayerIndex] = editedLayer;
      setLayerTemplate({
        ...layerTemplate,
        template_data: newLayers,
      });
    }

    setEditingLayer(undefined);
    setEditingLayerIndex(-1);
    setIsLayerEditorVisible(false);
  };

  useEffect(() => {
    if (!layerTemplateQuery.isLoading && layerTemplateQuery.isSuccess) {
      setLayerTemplate(layerTemplateQuery.data);
    }
  }, [
    layerTemplateQuery.data,
    layerTemplateQuery.isLoading,
    layerTemplateQuery.isSuccess,
  ]);

  const onResize = ({ width, height }: { width: number; height: number }) => {
    setContainerWidth(width);
    setContainerHeight(height);
  };

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={[
          { text: t('common:Layout') },
          {
            text: t('common:Asset Template'),
            onClick: () => history.push('/layer_templates'),
          },
          ...(action == 'Create'
            ? []
            : [
                {
                  text: layerTemplate.name,
                },
              ]),
        ]}
        truncate={false}
        aria-label="Breadcrumbs of create asset template"
      />

      <EuiPageHeader
        pageTitle={`${t(`common:${action}`)} ${t('Asset Template')}`}
        rightSideItems={[
          action == 'Edit' && (
            <EuiButton
              onClick={() =>
                history.push('/layer_templates/create', { layerTemplate })
              }
            >
              {t('Duplicate This Asset Template')}
            </EuiButton>
          ),
          <EuiButton
            onClick={handleSubmitLayerTemplate}
            fill
            isLoading={postMutaion.isLoading || patchMutaion.isLoading}
            disabled={postMutaion.isLoading || patchMutaion.isLoading}
          >
            {action == 'Create' ? t('common:Submit') : t('common:Save')}
          </EuiButton>,
        ]}
        bottomBorder={false}
      />

      <EuiForm
        component="form"
        isInvalid={postMutaion.isError || patchMutaion.isError}
      >
        <EuiFlexGroup style={{ maxWidth: 1200, flexGrow: 0 }}>
          <EuiFlexItem>
            <EuiFormRow
              label={t('common:Name', 'Asset Template Name')}
              isInvalid={'name' in errors}
              error={errors.name || []}
            >
              <EuiFieldText
                value={layerTemplate?.name}
                onChange={e =>
                  layerTemplate
                    ? setLayerTemplate({
                        ...layerTemplate,
                        name: e.target.value,
                      })
                    : {}
                }
                isInvalid={'name' in errors}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>

      <EuiResizeObserver onResize={onResize}>
        {resizeRef => (
          <EuiResizableContainer
            direction={isMobile ? 'vertical' : 'horizontal'}
            style={{ flexGrow: 'inherit' }}
          >
            {(EuiResizablePanel, EuiResizableButton) => (
              <>
                <EuiResizablePanel
                  mode="main"
                  initialSize={70}
                  minSize="200px"
                  panelRef={resizeRef}
                >
                  <AssetPreviewer
                    isForLayerTemplate
                    containerWidth={containerWidth}
                    containerHeight={containerHeight}
                    layers={layerTemplate?.template_data}
                  />
                </EuiResizablePanel>

                <EuiResizableButton />

                <EuiResizablePanel
                  mode="collapsible"
                  initialSize={30}
                  minSize="300px"
                >
                  <EuiFlexGroup
                    justifyContent="spaceBetween"
                    alignItems="center"
                    responsive={false}
                  >
                    <EuiFlexItem grow={false}>
                      <div>{t('common:Layer', 'Layers')}</div>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton
                        size="s"
                        iconType="plus"
                        onClick={handleAddLayer}
                      >
                        {t('Add Layer')}
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>

                  <LayerList
                    setLayers={layers =>
                      layerTemplate
                        ? setLayerTemplate({
                            ...layerTemplate,
                            template_data: layers,
                          })
                        : {}
                    }
                    layers={layerTemplate?.template_data || []}
                    edit={true}
                    handleEditLayer={handleEditLayer}
                    handleDeleteLayer={handleDeleteLayer}
                  />
                </EuiResizablePanel>
              </>
            )}
          </EuiResizableContainer>
        )}
      </EuiResizeObserver>

      {isLayerEditorVisible && (
        <LayerEditor
          isForLayerTemplate
          setIsLayerEditorVisible={setIsLayerEditorVisible}
          editingLayer={editingLayer}
          editingLayerIndex={editingLayerIndex}
          setEditingLayer={setEditingLayer}
          handleSaveLayer={handleSaveLayer}
        />
      )}
    </>
  );
};

export default LayerTemplateEditor;
