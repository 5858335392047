import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiButton,
  EuiPageHeader,
  EuiForm,
  EuiDescribedFormGroup,
  EuiFormRow,
  EuiFieldText,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiTitle,
  useGeneratedHtmlId,
  EuiResizeObserver,
  EuiTextArea,
  EuiDatePicker,
  EuiCheckbox,
  EuiImage,
  EuiBreadcrumbs,
} from '@elastic/eui';
import moment from 'moment';
import { AxiosError } from 'axios';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

import UserContext from 'contexts/UserContext';
import ToastContext from 'contexts/ToastContext';
import { Screen } from 'types/screen';
import { Asset } from 'types/asset';
import {
  getScreen,
  patchScreen,
  postScreen,
  getScreenImageUploadSignedUrl,
} from 'apis/Billboard/screen';
import { uploadFile } from 'utils/fetchApi';
import { getAsset } from 'apis/Scheduler/asset';
import AssetPicker from 'components/scheduler/AssetPicker';
import AssetPreviewer from 'pages/Asset/AssetEditor/AssetPreviewer';
import { Map, Marker } from 'components/utils/Map';
import { firebaseConfig } from '../../../firebase';

const ScreenEditor = ({ action }: { action: 'Create' | 'Edit' }) => {
  let { screenId } = useParams<any>();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const toastContext = useContext(ToastContext);
  const { t, i18n } = useTranslation(['screen', 'common']);

  const [isOpenAssetPicker, setIsOpenAssetPicker] = useState(false);
  const [screen, setScreen] = useState<Screen>({
    name: '',
    description: '',
    location_description: '',
    operating_start_at: null,
    operating_end_at: null,
    image_url: null,
    latitude: null,
    longitude: null,
    branding_asset: null,
  });
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [zoom, setZoom] = useState(12);
  const [isAllDay, setIsAllDay] = useState(false);
  const [brandingAsset, setBrandingAsset] = useState<Asset>();
  const [containerWidth, setContainerWidth] = useState(0);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<{ [column: string]: string[] }>({});

  const screenQuery = useQuery<Screen>(
    ['screen', screenId],
    async () => {
      const data = await getScreen(screenId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: action == 'Edit',
    }
  );

  const postMutaion = useMutation(postScreen, {
    onSuccess: data => {
      history.push('/screens');
      toastContext.setToasts([
        ...toastContext.toasts,
        {
          id: 'screen-created',
          title: `New screen: ${data.name} created!`,
          color: 'success',
        },
      ]);
    },
    onError: (error: AxiosError) => {
      setErrors(error.response?.data);
    },
  });
  const patchMutaion = useMutation(patchScreen, {
    onSuccess: () => {
      screenQuery.refetch();
      toastContext.setToasts([
        ...toastContext.toasts,
        {
          id: 'screen-updated',
          title: 'Screen configuration updated successfully!',
          color: 'success',
        },
      ]);
    },
    onError: (error: AxiosError) => {
      setErrors(error.response?.data);
    },
  });

  const handleChangeFile = (files: FileList | null) => {
    if (files?.length === 0 || !files?.[0]) {
      setScreen({ ...screen, image_url: '' });
      setImageFile(null);
      return;
    }
    // eslint-disable-next-line compat/compat
    setScreen({ ...screen, image_url: URL.createObjectURL(files[0]) });
    setImageFile(files[0]);
  };

  const handleChangesAllDay = (checked: boolean) => {
    setIsAllDay(checked);
    if (checked && screen) {
      setScreen({
        ...screen,
        operating_start_at: null,
        operating_end_at: null,
      });
    }
  };

  const handleSelectedBrandingAsset = async (asset: Asset) => {
    if (!asset.id) return;
    const assetData = await getAsset(asset.id);
    setBrandingAsset(assetData);
    setScreen({
      ...screen,
      branding_asset: asset.id,
    });
  };

  const handleSaveScreen = async () => {
    setErrors({});
    let imageUrl = screen.image_url;
    if (imageFile && userContext.currentOrganization) {
      const signedUrlResponse = await getScreenImageUploadSignedUrl(
        imageFile.name,
        userContext.currentOrganization?.id
      );
      await uploadFile(
        signedUrlResponse.method,
        signedUrlResponse.policy.url,
        signedUrlResponse.policy.fields,
        imageFile,
        () => {}
      )
        .then((response: any) => {
          imageUrl = `${response.config.url}${response.config.data.get('key')}`;
        })
        .catch(e => {
          console.log('Upload failed');
          console.log(e);
        });
    }
    if (action == 'Create') {
      screen.organization = userContext.currentOrganization?.id;
      postMutaion.mutate({
        ...screen,
        image_url: imageUrl || null,
        location_description: screen.description,
      });
    } else {
      patchMutaion.mutate({
        ...screen,
        image_url: imageUrl,
      });
    }
  };

  const onResize = ({ width, height }: { width: number; height: number }) => {
    setContainerWidth(width);
  };

  useEffect(() => {
    if (!screenQuery.isLoading && screenQuery.isSuccess) {
      setScreen(screenQuery.data);
      if (screenQuery.data.branding_asset) {
        getAsset(screenQuery.data.branding_asset).then(data =>
          setBrandingAsset(data)
        );
      }
      if (
        screenQuery.data.operating_end_at == null &&
        screenQuery.data.operating_start_at == null
      ) {
        setIsAllDay(true);
      }
      if (screenQuery.data.latitude && screenQuery.data.longitude)
        setCenter({
          lat: parseFloat(screenQuery.data.latitude),
          lng: parseFloat(screenQuery.data.longitude),
        });
    }
  }, [screenQuery.data, screenQuery.isLoading, screenQuery.isSuccess]);

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={[
          { text: t('common:Billboard') },
          {
            text: t('common:Screen', 'Screens'),
            onClick: () => history.push('/screens'),
          },
          ...(action == 'Edit'
            ? [
                {
                  text: screen.name,
                  onClick: () => history.push(`/screens/${screen.id}`),
                },
              ]
            : []),
        ]}
        truncate={false}
        aria-label="Breadcrumbs of configuring screen"
      />

      <EuiPageHeader
        pageTitle={
          action == 'Create' ? t('Create Screen') : t('Configure Screen')
        }
        rightSideItems={[
          <EuiButton
            onClick={handleSaveScreen}
            fill
            isLoading={postMutaion.isLoading || patchMutaion.isLoading}
            disabled={postMutaion.isLoading || patchMutaion.isLoading}
          >
            {action == 'Create'
              ? t('common:Create', 'Create New Screen')
              : t('common:Save')}
          </EuiButton>,
        ]}
      />

      <EuiForm
        component="form"
        style={{ margin: '0 auto' }}
        isInvalid={postMutaion.isError || patchMutaion.isError}
      >
        <EuiTitle>
          <h2>{t('Basic Info')}</h2>
        </EuiTitle>

        <EuiSpacer />

        <EuiDescribedFormGroup
          title={<h3>{t('Screen Photo')}</h3>}
          description={
            <p>
              {t(
                'photoDesc',
                'A photo could recognize your screen more easily.'
              )}
            </p>
          }
        >
          <EuiFormRow>
            <EuiFilePicker
              id={useGeneratedHtmlId()}
              display="default"
              initialPromptText={t('Select an image')}
              onChange={handleChangeFile}
              aria-label="Screen photo"
              accept="image/png, image/jpeg"
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>

        {screen?.image_url && (
          <EuiDescribedFormGroup title={<h3></h3>}>
            <EuiImage src={screen.image_url} alt="Screen Photo" />
          </EuiDescribedFormGroup>
        )}

        <EuiDescribedFormGroup
          title={<h3>{t('Screen Name')}</h3>}
          description={
            <p>{t('nameDesc', 'Major name to identify the screen')}</p>
          }
        >
          <EuiFormRow isInvalid={'name' in errors} error={errors.name || []}>
            <EuiFieldText
              name="name"
              aria-label="Screen name"
              value={screen?.name}
              onChange={e => setScreen({ ...screen, name: e.target.value })}
              isInvalid={'name' in errors}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>

        <EuiDescribedFormGroup
          title={<h3>{t('Screen Description')}</h3>}
          description={
            <p>{t('descDesc', 'More detailed information about the screen')}</p>
          }
        >
          <EuiFormRow
            isInvalid={'description' in errors}
            error={errors.description || []}
          >
            <EuiTextArea
              name="description"
              aria-label="Screen description"
              value={screen?.description}
              onChange={e =>
                setScreen({ ...screen, description: e.target.value })
              }
              isInvalid={'description' in errors}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>

        <EuiDescribedFormGroup
          title={<h3>{t('Screen Location')}</h3>}
          description={
            <p>{t('locationDesc', 'The geographic location of the screen')}</p>
          }
        >
          <Wrapper
            apiKey={firebaseConfig.apiKey || ''}
            render={(status: Status) => <p>{status}</p>}
          >
            <Map
              disableDefaultUI
              center={center}
              zoom={zoom}
              onClick={e =>
                e.latLng
                  ? setScreen({
                      ...screen,
                      latitude: e.latLng?.lat().toString().substring(0, 10),
                      longitude: e.latLng?.lng().toString().substring(0, 10),
                    })
                  : {}
              }
              onIdle={e => {
                setZoom(e.getZoom() || zoom);
                setCenter(e.getCenter()?.toJSON() || center);
              }}
              style={{ flexGrow: '1', height: '320px' }}
            >
              {screen.latitude && screen.longitude && (
                <Marker
                  position={{
                    lat: parseFloat(screen.latitude),
                    lng: parseFloat(screen.longitude),
                  }}
                />
              )}
            </Map>
          </Wrapper>
        </EuiDescribedFormGroup>

        <EuiDescribedFormGroup
          title={<h3>{t('Operation Hours')}</h3>}
          description={
            <p>
              {t(
                'hoursDesc',
                "Tells everyday's operatiing hours of the screen"
              )}
              .
            </p>
          }
        >
          <EuiFormRow>
            <EuiFlexGroup alignItems="center">
              <EuiFlexItem>
                <EuiDatePicker
                  name="operationHoursFrom"
                  showTimeSelect
                  showTimeSelectOnly
                  selected={
                    screen?.operating_start_at
                      ? moment(screen.operating_start_at, 'HH:mm:ss')
                      : undefined
                  }
                  onChange={e =>
                    setScreen({
                      ...screen,
                      operating_start_at: e?.format('HH:mm:ss') || null,
                    })
                  }
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  aria-label="Operation Hour from"
                  disabled={isAllDay}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>To</EuiFlexItem>
              <EuiFlexItem>
                <EuiDatePicker
                  name="operationHourTo"
                  showTimeSelect
                  showTimeSelectOnly
                  selected={
                    screen?.operating_end_at
                      ? moment(screen.operating_end_at, 'HH:mm:ss')
                      : undefined
                  }
                  onChange={e =>
                    setScreen({
                      ...screen,
                      operating_end_at: e?.format('HH:mm:ss') || null,
                    })
                  }
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  aria-label="Operation Hour to"
                  disabled={isAllDay}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiCheckbox
                  id="is_non_stop_screen"
                  label="All Day"
                  onChange={e => handleChangesAllDay(e.target.checked)}
                  checked={isAllDay}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        </EuiDescribedFormGroup>

        <EuiDescribedFormGroup
          title={<h3>{t('Branding Screen')}</h3>}
          description={
            <p>
              {t('brandingDesc', 'Set a branding screen when nothing to play')}
            </p>
          }
        >
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem>
              <EuiButton fullWidth onClick={() => setIsOpenAssetPicker(true)}>
                {t('Select an Asset')}
              </EuiButton>
            </EuiFlexItem>
            {/* <EuiFlexItem grow={false}>Or</EuiFlexItem>
            <EuiFlexItem>
              <EuiButton>Upload a Media</EuiButton>
            </EuiFlexItem> */}
          </EuiFlexGroup>
        </EuiDescribedFormGroup>

        {screen.branding_asset && (
          <EuiDescribedFormGroup
            title={<h3></h3>}
            description={
              <p>{t('previewDesc', 'Preview for branding screen')}</p>
            }
          >
            <EuiResizeObserver onResize={onResize}>
              {resizeRef => (
                <div ref={resizeRef}>
                  <AssetPreviewer
                    containerWidth={containerWidth}
                    containerHeight={containerWidth}
                    layers={brandingAsset?.layers}
                  />
                </div>
              )}
            </EuiResizeObserver>
          </EuiDescribedFormGroup>
        )}
      </EuiForm>

      {isOpenAssetPicker && (
        <AssetPicker
          handleFinishPickingAssets={assets =>
            handleSelectedBrandingAsset(assets[0])
          }
          isMultiple={false}
          setIsOpenAssetPicker={setIsOpenAssetPicker}
        />
      )}
    </>
  );
};

export default ScreenEditor;
