import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiText,
  EuiBadge,
  EuiButtonIcon,
  EuiSpacer,
  EuiButton,
} from '@elastic/eui';

import UserContext from 'contexts/UserContext';
import LayerTemplateSelector from 'pages/Asset/AssetEditor/LayerEditor/LayerTemplateSelector';
import { getLayerTemplateList } from 'apis/Scheduler/layerTemplate';
import { LayerTemplate, TemplateLayer } from 'types/layerTemplate';
import { FileItem } from 'types/upload';
import { prettyFileSize } from 'utils/helper';

const MediaListItem = ({
  fileItem,
  index,
  handleRemoveFile,
  setLayerTemplateName,
}: {
  fileItem: FileItem;
  index: number;
  handleRemoveFile: (index: number) => void;
  setLayerTemplateName: (layerTemplateName: string) => void;
}) => {
  const userContext = useContext(UserContext);

  const [
    layerTemplateSelectorVisible,
    setLayerTemplateSelectorVisible,
  ] = useState(false);

  const layerTemplateQuery = useQuery<LayerTemplate[]>(
    [
      'layerTemplate',
      {
        organizationId: userContext.currentOrganization?.id,
      },
    ],
    getLayerTemplateList,
    {
      keepPreviousData: true,
      initialData: [],
      refetchOnWindowFocus: false,
      enabled: !!userContext.currentOrganization?.id,
      refetchOnMount: false,
    }
  );

  return (
    <>
      <EuiFlexGroup alignItems="center" justifyContent="center">
        <EuiFlexItem style={{ maxWidth: 300, alignItems: 'center' }} grow={3}>
          {fileItem.file.type.match('image') && (
            <EuiImage
              style={{ height: 120 }}
              // eslint-disable-next-line compat/compat
              src={URL.createObjectURL(fileItem.file)}
              allowFullScreen
              alt=""
            />
          )}
          {fileItem.file.type.match('video') && (
            <video controls style={{ height: 150 }}>
              <source
                // eslint-disable-next-line compat/compat
                src={URL.createObjectURL(fileItem.file)}
              />
            </video>
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={6} style={{ overflow: 'hidden' }}>
          <EuiFlexGroup direction="column">
            <EuiFlexItem>
              <EuiText
                size="m"
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {fileItem.file.name}
              </EuiText>
              <EuiText>
                <p>{prettyFileSize(fileItem.file.size)}</p>
              </EuiText>
              <span>
                <EuiBadge>{fileItem.file.type}</EuiBadge>
              </span>
            </EuiFlexItem>
            {layerTemplateQuery.isSuccess &&
              layerTemplateQuery.data &&
              layerTemplateQuery.data.length > 0 && (
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup alignItems="center">
                    <EuiFlexItem>
                      <EuiText>
                        <strong>
                          {fileItem.layer_template_name || 'Unspecified Layer'}
                        </strong>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiButton
                        fill={fileItem.layer_template_name ? false : true}
                        onClick={() => setLayerTemplateSelectorVisible(true)}
                      >
                        Assign Layer
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              )}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            display="base"
            iconType="cross"
            aria-label="Delete"
            color="danger"
            style={{ alignSelf: 'flex-end' }}
            onClick={() => handleRemoveFile(index)}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />

      {layerTemplateSelectorVisible && (
        <LayerTemplateSelector
          mediaOnly
          setIsSelectorVisible={setLayerTemplateSelectorVisible}
          onConfirmed={(layerData: TemplateLayer) => {
            setLayerTemplateName(layerData.name);
            setLayerTemplateSelectorVisible(false);
          }}
        />
      )}
    </>
  );
};

export default MediaListItem;
