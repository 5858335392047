export const asset = {
  'zh-TW': {
    Asset: 'Asset',
    'No asset found': '找不到$t(Asset)',
    Thumb: '預覽',
    'File Size': '檔案大小',
    Size: '尺寸',
    modal: {
      deleteContent: `所有與此Asset關聯的播放清單與Schedule都將受影響`,
    },
    'New Asset': '新$t(Asset)',
    'Edit Asset': '編輯$t(Asset)',
    'Create Asset': '建立$t(Asset)',
    'Duplicate This Asset': '複製此$t(Asset)',
    'Air date start': '播放檔期起始日',
    'Air date end': '播放檔期結束日',
    'Auto Duration': 'Auto Duration',
    'No Default Tickers': 'No Default Tickers',
    'Advance Setting': 'Advance Setting',
    'Select start airing date': '選擇播放檔期起始日',
    'Select last airing date': '選擇播放檔期結束日',
    'Add Layer': '新增圖層',
    Layers: 'Layers',
    'Web Preview': '網頁預覽',
    'Asset Picker': '$t(Asset) Picker',
    'Selected Asset': '已選擇',
    'Search Asset': '搜尋$t(Asset)',
    'Select Asset': '選擇',
    layer: {
      'Choose Layer Template': '選擇圖層範本',
      'Primary Layer': '設為主圖層',
      'Conditional Layer': 'Conditional Layer',
      'Layer Type': '圖層類型',
      'Layer Name': '圖層名稱',
      'Play Audio': '播放聲音',
      'Play Condition': 'Play Condition',
      'Play Condition Module': 'Play Condition Module',
      Property: 'Property',
      'Select property': '選擇$t(Property)',
      Url: '連結',
      'Layer Position X': '圖層 x 偏移',
      'Layer Position Y': '圖層 y 偏移',
      'Layer Width': '圖層寬度',
      'Layer Height': '圖層高度',
      textOverlay: {
        Type: '跑馬燈類型',
        'Start Position': '起始位置',
        PositionHelpText: '從圖層左邊界開始, 0.0 ~ 1.0',
        'Data Tag': 'Data Tag',
        'Image Tag': 'Image Tag',
        Color: '顏色',
        'Custom Color': '自選顏色',
        'Color picker': '顏色選擇器',
        Insert: '插入',
        Content: '內容',
        Repeat: '重複',
      },
    },
  },
};
