import { callApi } from 'utils/fetchApi';
import { DefaultSetting } from 'types/defaultSetting';
import { Layer } from 'types/asset';

const getDefaultSettingLayerContentType = async () => {
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/default_layer_setting/layer_content_types`,
    withCredentials: true,
  });

  return await response.data;
};

const postDefaultLayer = async (data: DefaultSetting) => {
  const response = await callApi({
    method: 'POST',
    path: `/scheduler/default_layer_setting`,
    withCredentials: true,
    data: {
      ...data,
      layer: { ...data.layer, content_type: data.layer.content_type.id },
    },
  });

  return await response.data;
};

const patchDefaultLayer = async (data: DefaultSetting) => {
  const response = await callApi({
    method: 'PATCH',
    path: `/scheduler/default_layer_setting/${data.id}`,
    withCredentials: true,
    data: {
      ...data,
      layer: { ...data.layer, content_type: data.layer.content_type.id },
    },
  });

  return await response.data;
};

const getDefaultLayer = async (screen?: number) => {
  const response = await callApi({
    method: 'GET',
    path:
      `/scheduler/default_layer_setting?` +
      `${screen ? `screen=${screen}` : ''}`,
    withCredentials: true,
  });

  return await response.data;
};

const deleteDefaultLayer = async (defaultLayerId?: number) => {
  const response = await callApi({
    method: 'DELETE',
    path: `/scheduler/default_layer_setting/${defaultLayerId}`,
    withCredentials: true,
  });

  return await response.data;
};

const patchLayer = async (layer: Layer) => {
  const response = await callApi({
    method: 'PATCH',
    path: `/scheduler/layer/${layer.id}`,
    withCredentials: true,
    data: {
      z_index: layer.z_index,
    },
  });

  return await response.data;
};

export {
  getDefaultSettingLayerContentType,
  postDefaultLayer,
  patchDefaultLayer,
  getDefaultLayer,
  deleteDefaultLayer,
  patchLayer,
};
