import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiButton,
  EuiFieldSearch,
  EuiPageHeader,
  EuiCallOut,
  EuiBreadcrumbs,
} from '@elastic/eui';

import UserContext from 'contexts/UserContext';
import { paginatedDataPlaceholder } from 'utils/objects';
import { getLayerTemplateList } from 'apis/Scheduler/layerTemplate';
import { LayerTemplate } from 'types/layerTemplate';
import LayerTemplateTable from './LayerTemplateTable';

const LayerTemplateIndex = () => {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['assetTemplate', 'common']);
  const [keyword, setKeyword] = useState<string>();

  const layerTemplateQuery = useQuery<LayerTemplate[]>(
    [
      'layerTemplate',
      {
        keyword,
        organizationId: userContext.currentOrganization?.id,
      },
    ],
    getLayerTemplateList,
    {
      keepPreviousData: true,
      initialData: [],
      refetchOnWindowFocus: false,
      enabled: !!userContext.currentOrganization?.id,
    }
  );

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={[{ text: t('common:Scheduling') }]}
        truncate={false}
        aria-label="Breadcrumbs of layer template"
      />

      <EuiPageHeader
        pageTitle={t('Asset Templates')}
        rightSideItems={[
          <EuiButton onClick={() => history.push('/layer_templates/create')}>
            {t('Create Asset Template')}
          </EuiButton>,
        ]}
        bottomBorder={false}
      />

      {!layerTemplateQuery.isError ? (
        <LayerTemplateTable
          layerTemplateList={layerTemplateQuery.data || []}
          isLoading={layerTemplateQuery.isFetching}
          onDelete={layerTemplateQuery.refetch}
        />
      ) : (
        <EuiCallOut
          title={t(
            'common:errorOccur',
            'Sorry, there was an error while fetching asset list'
          )}
          color="danger"
          iconType="alert"
        >
          <p>
            {t(
              'common:Please check your internet connectivity or try reload this page.'
            )}
          </p>
        </EuiCallOut>
      )}
    </>
  );
};

export default LayerTemplateIndex;
