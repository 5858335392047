import { useState, useRef, useContext } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiLoadingSpinner,
  EuiFieldSearch,
} from '@elastic/eui';

import useDebounce from 'hooks/useDebounce';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import UserContext from 'contexts/UserContext';
import { getPlaylistList } from 'apis/Scheduler/playlist';

const PlaylisySelection = ({
  handlePickPlaylist,
}: {
  handlePickPlaylist: (item: any) => void;
}) => {
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['playlist', 'common']);

  const scrollAreaRef = useRef<any>();
  const listBottom = useRef<any>(null);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [pageIndex, setPageIndex] = useState<number>(0);
  const debouncedKeyword = useDebounce(searchKeyword, 1000);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery(
    ['playlist-selections', debouncedKeyword],
    options => {
      return getPlaylistList({
        queryKey: [
          'playlist-selections',
          options.pageParam ?? {
            organizationId: userContext.currentOrganization?.id,
            pageIndex,
            keyword: debouncedKeyword,
          },
        ],
      });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      getNextPageParam: lastPage => lastPage.next ?? undefined,
    }
  );

  useIntersectionObserver({
    root: scrollAreaRef.current,
    target: listBottom,
    onIntersect: () => {
      if (isFetchingNextPage || !hasNextPage) return;
      fetchNextPage({
        pageParam: {
          organizationId: userContext.currentOrganization?.id,
          pageIndex: pageIndex + 1,
          keyword: debouncedKeyword,
        },
      });
      setPageIndex(pageIndex + 1);
    },
    enabled: hasNextPage,
  });

  const handleKeywordChange = (value: string) => {
    setPageIndex(0);
    setSearchKeyword(value);
  };

  return (
    <>
      <EuiFieldSearch
        placeholder={t('Search Playlist')}
        value={searchKeyword}
        onChange={e => handleKeywordChange(e.target.value)}
        isClearable
        aria-label="Search Playlist"
        spellCheck={false}
        fullWidth
      />
      <EuiFlexGroup
        ref={scrollAreaRef}
        direction="column"
        responsive={false}
        gutterSize="m"
        style={{ maxHeight: 500, overflow: 'auto', padding: 10 }}
      >
        {status === 'loading' ? (
          <p>{t('common:Loading')}...</p>
        ) : status === 'error' ? (
          <span>{t('No playlist found')}</span>
        ) : (
          <>
            {data?.pages.map((page, index) => (
              <div key={index}>
                {page.results.map((item: any) => (
                  <EuiFlexItem
                    key={item.id}
                    className="media-item"
                    onClick={() => handlePickPlaylist(item)}
                  >
                    <EuiFlexGroup
                      style={{ padding: 6 }}
                      responsive={false}
                      alignItems="center"
                    >
                      <EuiFlexItem>
                        <EuiTitle size="xs">
                          <p className="eui-textNoWrap">{item.name}</p>
                        </EuiTitle>
                        {/* <small>{item.duration}s</small> */}
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                ))}
              </div>
            ))}
          </>
        )}
        <EuiFlexItem>
          <div ref={listBottom} style={{ margin: '0 auto' }}>
            {isFetching && <EuiLoadingSpinner size="xl" />}
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default PlaylisySelection;
