import {
  EuiBasicTable,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiButton,
} from '@elastic/eui';
import useDurationFormat from 'hooks/useDurationFormat';
import { useTranslation } from 'react-i18next';

const DailyPerformanceReportTable = ({ dateLog }: { dateLog: any }) => {
  const timeformat = useDurationFormat();
  const { t, i18n } = useTranslation(['report', 'common']);

  const columns: any[] = [
    {
      field: 'client_name',
      name: t('Name', 'Client Name', { field: t('common:Client') }),
      dataType: 'string',
      align: 'left',
    },
    {
      field: 'asset_name',
      name: t('Name', 'Asset Name', { field: t('common:Asset') }),
      dataType: 'string',
      align: 'right',
    },
    {
      field: 'layer_name',
      name: t('Name', 'Layer Name', { field: t('common:Layer') }),
      dataType: 'string',
      align: 'right',
    },
    {
      field: 'media_name',
      name: t('Name', 'Media Name', { field: t('common:Media') }),
      dataType: 'string',
      align: 'right',
    },
    {
      field: 'length',
      name: t('common:Length'),
      dataType: 'string',
      align: 'right',
      render: (duration: number) => <span> {timeformat(duration)}</span>,
    },
    {
      field: 'played_time',
      name: t('Num Times Played'),
      dataType: 'number',
      align: 'right',
      render: (num_times_played: number) => <span>{num_times_played}</span>,
    },
    {
      field: 'total_length',
      name: t('Total Duration'),
      dataType: 'string',
      align: 'right',
      render: (total_duration: number) => (
        <span> {timeformat(total_duration)} </span>
      ),
    },
  ];

  return (
    <>
      <EuiText>
        <h3>{new Date(dateLog.date).toLocaleDateString()}</h3>
      </EuiText>

      <EuiSpacer size="s" />

      <EuiBasicTable
        tableCaption="Daily Performance Report Table"
        items={dateLog.log}
        rowHeader="firstName"
        columns={columns}
      />
      <EuiFlexGroup alignItems="flexEnd" justifyContent="flexEnd">
        <EuiFlexItem grow={false}>
          <EuiText textAlign="left" size="s">
            {t('Total played')}: {dateLog.totalPlayed}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiText textAlign="right" size="s">
            {t('Total Length')}: {timeformat(dateLog.totalLength)}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
    </>
  );
};

export default DailyPerformanceReportTable;
