export const screen = {
  'zh-TW': {
    Screens: '版位',
    View: 'View',
    'Add Player': '新增播放器',
    'Pair Code': '配對碼',
    pairHelp:
      'When new GOTIGER Player application setup on the computer, it will prompt the pair code.',
    'Go to Schedule': '前往行程表',
    Location: 'Location',
    'All Day': 'All Day',
    Coordinates: '經緯度',
    'Master Player': '主播放器',
    'Manage Player': '管理播放器',
    'Live Control': 'Live Control',
    'Create Screen': '建立版位',
    'Configure Screen': '設定版位',
    'Cancel Changes': '取消變更',
    'Basic Info': '基本資料',
    'Screen Photo': '版位照片',
    photoDesc: 'A photo could recognize your screen more easily',
    'Select an image': '選擇圖片',
    'Screen Name': '版位名稱',
    nameDesc: 'Major name to identify the screen',
    'Screen Description': '版位描述',
    descDesc: '版位詳細資訊',
    'Screen Location': 'Screen Location',
    locationDesc: '版位的經緯度',
    'Operation Hours': 'Operation Hours',
    hoursDesc: '版位的每日operation hours',
    'Branding Screen': 'Branding Screen',
    brandingDesc: 'Set a branding screen when nothing to play',
    previewDesc: 'Preview for branding screen',
    'Select an Asset': '選擇Asset',
    Unnamed: '未命名',
  },
};
