import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { common } from 'translates/common';
import { login } from 'translates/login';
import { dashboard } from 'translates/dashboard';
import { defaultSetting } from 'translates/defaultSetting';
import { font } from 'translates/font';
import { media } from 'translates/media';
import { asset } from 'translates/asset';
import { playlist } from 'translates/playlist';
import { schedule } from 'translates/schedule';
import { screen } from 'translates/screen';
import { member } from 'translates/member';
import { propertyClient } from 'translates/propertyClient';
import { report } from 'translates/report';
import { liveControl } from 'translates/liveControl';
import { assetTemplate } from 'translates/assetTemplate';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    common: common.en,
    login: login.en,
    // dashboard: dashboard.en,
  },
  'zh-TW': {
    common: common['zh-TW'],
    login: login['zh-TW'],
    dashboard: dashboard['zh-TW'],
    defaultSetting: defaultSetting['zh-TW'],
    font: font['zh-TW'],
    media: media['zh-TW'],
    asset: asset['zh-TW'],
    layer: asset['zh-TW'].layer,
    textOverlay: asset['zh-TW'].layer.textOverlay,
    playlist: playlist['zh-TW'],
    schedule: schedule['zh-TW'],
    screen: screen['zh-TW'],
    member: member['zh-TW'],
    propertyClient: propertyClient['zh-TW'],
    report: report['zh-TW'],
    liveControl: liveControl['zh-TW'],
    assetTemplate: assetTemplate['zh-TW'],
  },
};

const lang = localStorage.getItem('lang');

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang ?? 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
