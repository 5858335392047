import { useContext, useState, useRef } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiPanel,
  EuiForm,
  EuiFormRow,
  EuiSelect,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiDatePicker,
  EuiSpacer,
  EuiOverlayMask,
  EuiLoadingSpinner,
  EuiText,
} from '@elastic/eui';
import moment from 'moment';

import UserContext from 'contexts/UserContext';
import { Property, Client } from 'types/property';
import { Player, Screen } from 'types/screen';
import { Media } from 'types/media';
import { getPlayerList, getScreenList } from 'apis/Billboard/screen';
import { getClientList, getPropertyList } from 'apis/Corps/property';
import MediaSelector from 'pages/Asset/AssetEditor/LayerEditor/MediaSelector';
import SignUsageTable from './SignUsageTable';
import { getSignUsage } from 'apis/Reporting/playlog';
import useReportExport from 'hooks/useReportExport';

const SignUsage = () => {
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['report', 'common']);

  const [after, setAfter] = useState(moment());
  const [before, setBefore] = useState(moment());
  const [property, setProperty] = useState<string>();
  const [client, setClient] = useState<string>();
  const [screen, setScreen] = useState<string>();
  const [player, setPlayer] = useState<string>();
  const [media, setMedia] = useState<Media>();
  const [playLog, setPlayLog] = useState<
    { property: string; log: any; totalPlayed: number; totalLength: number }[]
  >([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const reportRef = useRef<any>();

  const propertiesQuery = useQuery<Property[]>(
    ['property', { organizationId: userContext.currentOrganization?.id }],
    getPropertyList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const clientsQuery = useQuery<Client[]>(
    ['property-clients', { propertyId: property }],
    getClientList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!property,
    }
  );

  const screensQuery = useQuery(
    ['screen', { organizationId: userContext.currentOrganization?.id }],
    getScreenList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const playersQuery = useQuery<Player[]>(
    ['screen-players', screen],
    async () => {
      if (!screen) return;
      const data = await getPlayerList(parseInt(screen));
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!screen,
    }
  );

  const getReportMutaion = useMutation(getSignUsage, {
    onError: () => {
      setIsFetching(false);
    },
    onSuccess: data => {
      setIsFetching(false);
      const propertyLog: any = Object.keys(data.results).map(key => ({
        property: data.results[key][0]['property']['name'],
        totalPlayed: data.results[key].reduce(
          (acc: number, media: any) => acc + media['played_time'],
          0
        ),
        totalLength: data.results[key].reduce(
          (acc: number, media: any) =>
            acc + media['played_time'] * media['length'],
          0
        ),
        log: data.results[key].map((raw: any) => ({
          ...raw,
          total_length: raw['played_time'] * raw['length'],
        })),
      }));
      setPlayLog(propertyLog);
    },
  });

  const handleSubmit = () => {
    setIsFetching(true);
    getReportMutaion.mutate({
      player,
      start_at: moment(after).startOf('day').local().utc().format(),
      end_at: moment(before).startOf('day').local().utc().format(),
      media: media?.id || '',
      property,
      client,
    });
  };

  return (
    <EuiPanel>
      <EuiForm>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFormRow label={t('Date From')} fullWidth>
              <EuiDatePicker
                name="DateAfter"
                selected={after}
                onChange={v => setAfter(v || moment())}
                aria-label="Date After"
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFormRow label={t('Date To')} fullWidth>
              <EuiDatePicker
                name="DateBefore"
                selected={before}
                onChange={v => setBefore(v || moment())}
                aria-label="Date Before"
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label={t('Property')} fullWidth>
              <EuiSelect
                fullWidth
                onChange={e => setProperty(e.target.value)}
                value={property}
                options={
                  propertiesQuery.isSuccess
                    ? [
                        { text: t('All Property'), value: '' },
                        ...propertiesQuery.data.map((property: Property) => {
                          return {
                            text: property.name,
                            value: property.id,
                          };
                        }),
                      ]
                    : [{ text: t('All Property'), value: '' }]
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label={t('Client')} fullWidth>
              <EuiSelect
                fullWidth
                onChange={e => setClient(e.target.value)}
                value={client}
                options={
                  clientsQuery.isSuccess
                    ? [
                        { text: t('All Clients'), value: '' },
                        ...clientsQuery.data.map((client: Client) => {
                          return {
                            text: client.name || `Client #${client.id}`,
                            value: client.id,
                          };
                        }),
                      ]
                    : [{ text: t('All Clients'), value: '' }]
                }
                disabled={property == undefined}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label={t('Screen')} fullWidth>
              <EuiSelect
                fullWidth
                onChange={e => setScreen(e.target.value)}
                value={screen}
                options={
                  screensQuery.isSuccess
                    ? [
                        { text: t('All Screens') },
                        ...screensQuery.data.map((screen: Screen) => {
                          return {
                            text: screen.name,
                            value: screen.id,
                          };
                        }),
                      ]
                    : [{ text: t('All Screens') }]
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label={t('common:Player')} fullWidth>
              <EuiSelect
                fullWidth
                onChange={e => setPlayer(e.target.value)}
                value={player}
                options={
                  playersQuery.isSuccess
                    ? [
                        { text: t('All Players') },
                        ...playersQuery.data.map((player: Player) => {
                          return {
                            text: player.name || `Player #${player.id}`,
                            value: player.id,
                          };
                        }),
                      ]
                    : [{ text: t('All Players') }]
                }
                disabled={screen == undefined}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <MediaSelector
              selectedMedia={media}
              onChange={(media: Media) => setMedia(media)}
              onClear={() => setMedia(undefined)}
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiButton
              fill
              disabled={!parseInt(player || '0')}
              onClick={handleSubmit}
            >
              {t('Query')}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />
        {isFetching && (
          <EuiOverlayMask>
            <EuiLoadingSpinner />
          </EuiOverlayMask>
        )}
        <div ref={reportRef}>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiText>
                <h2>{t('Sign Usage by Customer')}</h2>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiButton
                    disabled={playLog.length === 0}
                    onClick={useReportExport(
                      reportRef.current,
                      'word',
                      'Sign Usage by Customer'
                    )}
                  >
                    {t('Export Word')}
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiButton
                    disabled={playLog.length === 0}
                    onClick={useReportExport(reportRef.current, 'print')}
                  >
                    {t('Print')}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>

          {playLog.length > 0 && (
            <p>
              {t('From')}
              <b> {after.startOf('day').toLocaleString()} </b>
              {t('to')}
              <b> {before.endOf('day').toLocaleString()}</b>
            </p>
          )}

          <EuiSpacer />

          {playLog.map((log: any) => (
            <>
              <SignUsageTable propertyLog={log} />
              <EuiSpacer />
            </>
          ))}
        </div>
      </EuiForm>
    </EuiPanel>
  );
};

export default SignUsage;
