import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiTitle,
  EuiStat,
} from '@elastic/eui';
import useDurationFormat from 'hooks/useDurationFormat';
import { useTranslation } from 'react-i18next';

const SignUsageTable = ({ propertyLog }: { propertyLog: any }) => {
  const timeformat = useDurationFormat();
  const { t, i18n } = useTranslation(['report', 'common']);

  const columns: any[] = [
    {
      field: 'client',
      name: t('Name', 'Client Name', { field: t('common:Client') }),
      dataType: 'string',
      align: 'left',
    },
    {
      field: 'media',
      name: t('Name', 'Media Name', { field: t('common:Media') }),
      dataType: 'string',
      align: 'right',
    },
    {
      field: 'length',
      name: t('Length'),
      dataType: 'string',
      align: 'right',
      render: (duration: number) => <span>{timeformat(duration)}</span>,
    },
    {
      field: 'played_time',
      name: t('Times Played'),
      dataType: 'string',
      align: 'right',
    },
    {
      field: 'total_length',
      name: t('Total Length'),
      dataType: 'string',
      align: 'right',
      render: (duration: number) => <span>{timeformat(duration)}</span>,
    },
  ];

  return (
    <>
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={2}>
          <EuiTitle>
            <h2>{propertyLog.property}</h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiStat
            titleSize="s"
            title={propertyLog.totalPlayed}
            description={t('Total Times Played')}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiStat
            titleSize="s"
            title={timeformat(propertyLog.totalLength)}
            description={t('Total Length')}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiBasicTable
        tableCaption={t('Sign Usage Table')}
        items={propertyLog.log}
        rowHeader="firstName"
        columns={columns}
      />
    </>
  );
};

export default SignUsageTable;
