import { useState, useEffect, useContext } from 'react';
import { UseMutationResult } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiModal,
  EuiModalHeader,
  EuiTitle,
  EuiForm,
  EuiModalBody,
  EuiFormRow,
  EuiFieldText,
  EuiModalFooter,
  EuiButton,
} from '@elastic/eui';
import UserContext from 'contexts/UserContext';

import { Property } from 'types/property';

const PropertyEditor = ({
  action,
  selectedProperty,
  propertyMutation,
  onClose,
}: {
  action: 'Create' | 'Edit';
  selectedProperty: Property | null;
  propertyMutation: UseMutationResult<Property, unknown, Property, unknown>;
  onClose: () => void;
}) => {
  const { t, i18n } = useTranslation(['propertyClient', 'common']);
  const userContext = useContext(UserContext);
  const [editingProperty, setEdtingProperty] = useState<Property>({
    name: '',
    clients: [],
    organization: userContext.currentOrganization?.id,
  });

  useEffect(() => {
    if (selectedProperty) setEdtingProperty({ ...selectedProperty });
  }, [action, selectedProperty]);

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiTitle>
          <h2>
            {action == 'Create' ? t('Add New Property') : t('Edit Property')}
          </h2>
        </EuiTitle>
      </EuiModalHeader>
      <EuiForm>
        <EuiModalBody>
          <EuiFormRow label={t('common:Name')}>
            <EuiFieldText
              value={editingProperty?.name}
              onChange={e =>
                setEdtingProperty({ ...editingProperty, name: e.target.value })
              }
            />
          </EuiFormRow>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButton
            fill
            onClick={() => propertyMutation.mutate(editingProperty)}
            disabled={propertyMutation.isLoading}
            isLoading={propertyMutation.isLoading}
          >
            {action == 'Create' ? t('common:Create') : t('common:Save')}
          </EuiButton>
        </EuiModalFooter>
      </EuiForm>
    </EuiModal>
  );
};

export default PropertyEditor;
