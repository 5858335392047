import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButtonIcon,
  EuiSpacer,
} from '@elastic/eui';

import { prettyFileSize } from 'utils/helper';

const FontListItem = ({
  file,
  index,
  handleRemoveFile,
}: {
  file: File;
  index: number;
  handleRemoveFile: (index: number) => void;
}) => {
  return (
    <>
      <EuiFlexGroup
        responsive={false}
        alignItems="center"
        justifyContent="center"
      >
        <EuiFlexItem grow={6} style={{ overflow: 'hidden' }}>
          <EuiText
            size="m"
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {file.name}
          </EuiText>
          <EuiText>
            <p>{prettyFileSize(file.size)}</p>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={1}>
          <EuiButtonIcon
            display="base"
            iconType="cross"
            aria-label="Delete"
            color="danger"
            style={{ alignSelf: 'flex-end' }}
            onClick={() => handleRemoveFile(index)}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
    </>
  );
};

export default FontListItem;
