import { useCallback, useEffect, useState, useContext, Fragment } from 'react';
import { useMutation, useQuery } from 'react-query';
import useDurationFormat from 'hooks/useDurationFormat';
import { EuiOverlayMask, EuiLoadingSpinner } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import {
  EuiTitle,
  EuiButton,
  EuiFlexGrid,
  EuiFlexItem,
  EuiCard,
  EuiSpacer,
  EuiFlexGroup,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiLink,
  EuiButtonIcon,
  EuiHealth,
  EuiText,
  EuiPanel,
  EuiBadge,
} from '@elastic/eui';
import { v4 as uuidv4 } from 'uuid';

import { database } from '../../firebase';
import UserContext from 'contexts/UserContext';
import { getLiveFireRecord } from 'apis/Billboard/screen';
import { Screen, Player } from 'types/screen';
import { LiveFireAsset, Status } from 'types/liveFire';
import { getAsset } from 'apis/Scheduler/asset';
import AssetPicker from 'components/scheduler/AssetPicker';
import AssetFlyout from '../Asset/AssetFlyout';

const LiveFire = ({
  screen,
  players,
}: {
  screen: Screen;
  players: Player[];
}) => {
  const { t, i18n } = useTranslation(['liveControl', 'common']);
  const userContext = useContext(UserContext);
  const [assets, setAssets] = useState<{ [id: string]: LiveFireAsset }>({});
  const [playAllStatus, setPlayAllStatus] = useState<{ [id: string]: string }>(
    {}
  );
  const [playingAsset, setPlayingAsset] = useState<string>();
  const [playerTimerId, setPlayerTimerId] = useState<{
    [playerId: string]: NodeJS.Timeout;
  }>();

  const [isOpenAssetPicker, setIsOpenAssetPicker] = useState(false);
  const btnLoadingStatus = ['initial', 'loading', 'playSent', 'stopping'];
  const [liveFireRecord, setLiveFireRecord] = useState([]);
  const [liveFireRecordList, setLiveFireRecordList] = useState([]);
  const [cursor, setCursor] = useState(null);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const [playerStatus, setPlayerStatus] = useState<any>({});
  const [isRTDBconnected, setIsRTDBconnected] = useState(false);

  const [previewAssetId, setPreviewAssetId] = useState<number | undefined>(
    undefined
  );

  const previewAsset = useQuery(
    ['asset', { previewAssetId }],
    () => previewAssetId && getAsset(previewAssetId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleAddLiveFireAsset = async (asset: any) => {
    let fireId = asset?.id.toString();
    if (asset.dummy) fireId += '_' + asset.layer.id;
    if (!asset.id || Object.keys(assets).length >= 10 || assets[fireId]) return;

    const assetData = await getAsset(asset.id);
    const playerLoadingStatus: {
      [player_id: string]: Status;
    } = {};

    if (asset.dummy)
      assetData.layers = [
        assetData.layers.find((layer: any) => layer.id == asset.layer.id),
      ];

    const liveFireAsset: LiveFireAsset = {
      userId: userContext.currentUser?.id || 0,
      asset: assetData,
      status: 'initial',
      playerLoadingStatus,
      uuid: uuidv4(),
      createdAt: new Date().toISOString(),
      liveFireType: asset.dummy ? 'layer' : 'asset',
    };

    players.forEach(player => {
      if (!player?.id) return;
      playerLoadingStatus[player.id.toString()] = 'initial';
      updateLiveFireStatus(liveFireAsset, 'initial', player.id.toString());
      database
        .ref(`/live_fire/${screen.id}/all/playerLoadingStatus/${player.id}`)
        .set('initial');
    });
    database.ref(`/live_fire/${screen.id}/all`).update({
      userId: userContext.currentUser?.id || 0,
      uuid: uuidv4(),
    });
  };

  const updateLiveFireStatus = useCallback(
    async (
      liveFireAsset: LiveFireAsset,
      status: Status,
      playerId: string | undefined
    ) => {
      if (!screen || !liveFireAsset.asset?.id) return;

      let fireId = liveFireAsset.asset?.id.toString();
      if (liveFireAsset.liveFireType == 'layer')
        fireId += '_' + liveFireAsset.asset.layers[0].id;

      const playerLoadingStatus: {
        [player_id: string]: Status;
      } = {};
      Object.keys(liveFireAsset.playerLoadingStatus).forEach(id => {
        playerLoadingStatus[id] =
          playerId == id || !playerId
            ? status
            : liveFireAsset.playerLoadingStatus[id];
      });
      console.log(playerLoadingStatus);
      let updatedAsset: LiveFireAsset = {
        ...liveFireAsset,
        status,
        playerLoadingStatus,
      };

      setAssets(current => {
        return {
          ...current,
          [fireId.toString()]: updatedAsset,
        };
      });

      database
        .ref(`/live_fire/${screen.id}/${fireId.toString()}`)
        .set(updatedAsset);
    },
    [screen]
  );

  const handleUnloadLiveFireAsset = async (liveFireAsset: LiveFireAsset) => {
    if (!screen || !liveFireAsset.asset?.id) return;
    let fireId = liveFireAsset.asset?.id.toString();
    if (liveFireAsset.liveFireType == 'layer')
      fireId += '_' + liveFireAsset.asset.layers[0].id;
    let liveFireAssetRef = database.ref(`/live_fire/${screen.id}/${fireId}`);
    liveFireAssetRef.once('value').then(snapshot => {
      if (snapshot.val()) liveFireAssetRef.remove();
    });

    setAssets(current => {
      let newAsset = { ...current };
      delete newAsset[fireId.toString()];
      return newAsset;
    });
  };

  const handleLiveFireAction = useCallback(
    (asset: LiveFireAsset, playerId?: number) => {
      if (!playerId) {
        if (asset.status == 'initial')
          return updateLiveFireStatus(asset, 'loading', undefined);
        if (asset.status == 'loaded')
          return updateLiveFireStatus(asset, 'playSent', undefined);
      } else {
        if (asset.playerLoadingStatus[playerId] == 'playing') {
          setIsStopping(true);
          setPlayingAsset(undefined);
          return updateLiveFireStatus(asset, 'stopping', playerId.toString());
        }
        if (asset.playerLoadingStatus[playerId] == 'loaded') {
          setPlayingAsset(asset.uuid);
          return updateLiveFireStatus(asset, 'playSent', playerId.toString());
        }
      }
    },
    [updateLiveFireStatus]
  );

  const handleFireAllAction = (playerId: number) => {
    if (!playAllStatus[playerId]) return;
    let status = '';
    if (playAllStatus[playerId] == 'playing') {
      status = 'stopping';
      pageChange(1, true);
    } else if (playAllStatus[playerId] == 'loaded') status = 'playSent';
    else return;

    database
      .ref(`/live_fire/${screen.id}/all/playerLoadingStatus/${playerId}`)
      .set(status);
  };

  const getActionName = (status: Status) => {
    if (status == 'loading') {
      return 'Loading';
    } else if (status == 'loaded') {
      return 'Play';
    } else if (status == 'playing') {
      return 'Stop';
    }
  };

  const getDisplayName = (liveFireAsset: LiveFireAsset) => {
    if (liveFireAsset.liveFireType == 'layer')
      return `${liveFireAsset.asset.name}_${liveFireAsset.asset.layers[0].name}`;
    return liveFireAsset.asset.name;
  };

  const columns: Array<EuiBasicTableColumn<any>> = [
    {
      field: 'player_name',
      name: t('common:Player'),
      width: '180px',
    },
    {
      field: 'start_at',
      name: t('common:Start At'),
      sortable: true,
      width: '180px',
      mobileOptions: {
        header: false,
        truncateText: false,
        enlarge: true,
        width: '100%',
      },
      render: (start_at: string) =>
        start_at && new Date(start_at).toLocaleString(),
    },
    {
      field: 'end_at',
      name: t('common:End At'),
      width: '180px',
      render: (end_at: string) => end_at && new Date(end_at).toLocaleString(),
    },
    {
      field: 'duration',
      name: t('common:Duration'),
      width: '80px',
      render: (duration: number) => <span>{duration || 0}s</span>,
    },
    {
      field: 'asset/layer',
      name: `${t('common:Asset')}/${t('common:Layer')}`,
      render: (asset_layer: any) => {
        return (
          <EuiFlexGroup direction="column">
            {asset_layer.map((item: any, index: number) => (
              <EuiFlexItem key={index}>
                <EuiLink
                  onClick={() =>
                    item.type == 'asset' &&
                    item.id &&
                    setPreviewAssetId(item.id)
                  }
                >
                  {item.name}
                </EuiLink>
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        );
      },
    },
    {
      field: 'user',
      name: t('common:User'),
      width: '180px',
    },
  ];

  const getLiveFireRecordMutation = useMutation(getLiveFireRecord, {
    onError: () => {
      setIsFetching(false);
    },
    onSuccess: data => {
      setIsFetching(false);
      const allRecord: any = liveFireRecordList;
      allRecord.push(data.results);
      setLiveFireRecordList(allRecord);
      setPage(page + 1);
      setLiveFireRecord(allRecord[allRecord.length - 1]);
      setCursor(data.cursor);
    },
  });

  const pageChange = async (page: number, reload: boolean = false) => {
    setIsFetching(true);
    if (reload) {
      setPage(0);
      setTimeout(
        () =>
          getLiveFireRecordMutation.mutate({
            screen: screen.id,
            cursor: null,
          }),
        1000
      );
    } else if (liveFireRecordList.length < page) {
      getLiveFireRecordMutation.mutate({
        screen: screen.id,
        cursor,
      });
    } else {
      setLiveFireRecord(liveFireRecordList[page - 1]);
      setPage(page);
      setIsFetching(false);
    }
  };

  const calculatePlayingAsset = (
    playerId: string,
    sortedAssets: LiveFireAsset[],
    i: number
  ) => {
    setPlayingAsset(sortedAssets[i].uuid);
    const id = setTimeout(() => {
      calculatePlayingAsset(
        playerId,
        sortedAssets,
        i + 1 >= sortedAssets.length ? 0 : i + 1
      );
    }, sortedAssets[i].asset.duration * 1000);
    setPlayerTimerId(curr => {
      return { ...curr, [playerId]: id };
    });
    console.log(`#${playerId} start timer ${id}, ${new Date().toISOString()}`);
  };

  const handlePlayerPlayAll = (
    playerId: string,
    status: string,
    liveFireAssets: { [key: string]: LiveFireAsset }
  ) => {
    if (Object.keys(liveFireAssets).length == 0) return;
    if (status == 'playing') {
      if (playerTimerId && playerTimerId[playerId] != undefined) return;
      const sortedAssets = Object.keys(liveFireAssets)
        .sort(
          (a: string, b: string) =>
            new Date(liveFireAssets[a].createdAt).getTime() -
            new Date(liveFireAssets[b].createdAt).getTime()
        )
        .map(key => liveFireAssets[key]);
      calculatePlayingAsset(playerId, sortedAssets, 0);
    } else if (status == 'stopping') {
      setPlayerTimerId(curr => {
        if (curr !== undefined) {
          clearTimeout(curr[playerId]);
          console.log(
            `#${playerId} clear timer ${
              curr[playerId]
            }, ${new Date().toISOString()}`
          );
        }
        const currTimer = curr;
        currTimer && delete currTimer[playerId];
        return currTimer;
      });
      setPlayingAsset(undefined);
    }
  };

  useEffect(() => {
    if (!screen?.id) return;
    let liveFireRef = database.ref(`/live_fire/${screen.id}`);
    let liveFireAllRef = database.ref(`live_fire/${screen.id}/all`);

    const updatedlistener = liveFireRef.on('value', snapshot => {
      const liveFireData = snapshot.val();
      if (!liveFireData) return;
      const liveFireAssets: { [key: string]: LiveFireAsset } = {};
      let incomingPlayAllStatus = {};
      Object.keys(liveFireData).map(assetId => {
        if (assetId == 'all') {
          Object.keys(liveFireData[assetId]['playerLoadingStatus']).map(
            playerId => {
              const playerPlayAllStatus =
                liveFireData[assetId]['playerLoadingStatus'][playerId];
              incomingPlayAllStatus = {
                ...incomingPlayAllStatus,
                ...{
                  [playerId]: playerPlayAllStatus,
                },
              };
            }
          );
        }
        if (liveFireData[assetId].asset) {
          liveFireAssets[assetId] = liveFireData[assetId];
          if (isStopping) {
            pageChange(1, true);
            setIsStopping(false);
          }
        }
      });
      setAssets(current => {
        return { ...current, ...liveFireAssets };
      });
      setPlayAllStatus(incomingPlayAllStatus);
    });

    const fireAllListener = liveFireAllRef.on('child_changed', snapshot => {
      const fireAllData = snapshot.val();
      Object.keys(fireAllData).map(playerId => {
        liveFireRef.get().then(snapshot => {
          const liveFireAssets = snapshot.val();
          delete liveFireAssets['all'];
          handlePlayerPlayAll(playerId, fireAllData[playerId], liveFireAssets);
        });
      });
    });

    const removelistener = liveFireRef.on('child_removed', snapshot => {
      setAssets(current => {
        let newAsset = { ...current };
        if (snapshot.key) delete newAsset[snapshot.key.toString()];
        return newAsset;
      });
      liveFireRef.once('value').then(snapshot => {
        if (snapshot.val() && Object.keys(snapshot.val()).length == 1)
          liveFireRef.remove();
      });
    });

    var connectedRef = database.ref('.info/connected');
    connectedRef.on('value', snap => {
      setIsRTDBconnected(snap.val());
    });

    let playerStatusRef = database.ref(`/player_status`);
    playerStatusRef.on('value', (snapshot: any) => {
      const players = snapshot.val();
      setPlayerStatus(players);
    });

    return () => {
      liveFireRef.off('value', updatedlistener);
      liveFireRef.off('child_removed', removelistener);
      liveFireAllRef.off('child_changed', fireAllListener);
      playerStatusRef.off();
    };
    // eslint-disable-next-line
  }, [isStopping, screen]);

  useEffect(() => {
    if (!screen?.id) return;
    pageChange(1);
    console.log(Object.values(playAllStatus).includes('playing'));
    console.log(
      Object.values(assets).filter(
        asset =>
          Object.values(asset.playerLoadingStatus).includes('playing') == true
      ).length > 0
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        <EuiFlexGroup responsive={false} justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <EuiTitle>
              <h2>{t('Live Fire')}</h2>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiTitle>
              <h2 style={{ color: '#7DDED8' }}>
                {t('LiveFireStatus', {
                  status:
                    Object.values(playAllStatus).includes('playing') ||
                    Object.values(assets).filter(
                      asset =>
                        Object.values(asset.playerLoadingStatus).includes(
                          'playing'
                        ) == true
                    ).length > 0
                      ? 'On'
                      : 'Off',
                })}
              </h2>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              onClick={() => setIsOpenAssetPicker(true)}
              disabled={Object.values(playAllStatus).includes('playing')}
            >
              {t('Add Asset')}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>

      <EuiSpacer />

      <EuiTitle>
        <h3>{t('common:Assets')}</h3>
      </EuiTitle>

      <EuiSpacer />

      {Object.keys(assets).length === 0 && (
        <EuiText>
          <i>{t('No Selected Assets')}</i>
        </EuiText>
      )}

      <EuiFlexItem>
        {Object.keys(assets)
          .sort(
            (a: string, b: string) =>
              new Date(assets[a].createdAt).getTime() -
              new Date(assets[b].createdAt).getTime()
          )
          .map((assetId, index) => (
            <Fragment key={index}>
              <EuiPanel>
                <EuiFlexGroup
                  alignItems="center"
                  justifyContent="spaceAround"
                  key={index}
                >
                  <EuiFlexItem grow={false}>
                    <EuiTitle size="s">
                      <h3>#{index + 1}</h3>
                    </EuiTitle>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiTitle size="xs">
                      <h3>
                        <EuiLink
                          color="text"
                          onClick={() => setPreviewAssetId(parseInt(assetId))}
                        >
                          {getDisplayName(assets[assetId])}
                        </EuiLink>
                      </h3>
                    </EuiTitle>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiFlexGroup direction="row">
                      <EuiFlexItem>
                        <EuiButton
                          onClick={() =>
                            updateLiveFireStatus(
                              assets[assetId],
                              'playSent',
                              undefined
                            )
                          }
                          isLoading={
                            Object.values(
                              assets[assetId].playerLoadingStatus
                            ).find(status =>
                              btnLoadingStatus.includes(status)
                            ) != undefined
                          }
                          disabled={Object.values(playAllStatus).includes(
                            'playing'
                          )}
                        >
                          {t('Play on all players')}
                        </EuiButton>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiButton
                          onClick={() =>
                            updateLiveFireStatus(
                              assets[assetId],
                              'stopping',
                              undefined
                            )
                          }
                          isLoading={
                            Object.values(
                              assets[assetId].playerLoadingStatus
                            ).find(status =>
                              btnLoadingStatus.includes(status)
                            ) != undefined
                          }
                          disabled={Object.values(playAllStatus).includes(
                            'playing'
                          )}
                        >
                          {t('Stop on all players')}
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton
                      color="ghost"
                      onClick={() => handleUnloadLiveFireAsset(assets[assetId])}
                      disabled={Object.values(playAllStatus).includes(
                        'playing'
                      )}
                    >
                      {t('Unload')}
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiPanel>
              <EuiSpacer size="s" />
            </Fragment>
          ))}
      </EuiFlexItem>

      <EuiSpacer />

      <EuiTitle>
        <h3>{t('common:Players')}</h3>
      </EuiTitle>

      <EuiSpacer />

      <EuiFlexGrid columns={3}>
        {players.map((player, index) => (
          <EuiFlexItem key={index}>
            <EuiCard
              title={player.name}
              description={
                <EuiHealth
                  color={
                    player.id && playerStatus[player.id]?.player_online
                      ? `success`
                      : 'success'
                  }
                >
                  {t('common:Online')}
                </EuiHealth>
              }
              hasBorder
              footer={
                <EuiFlexGroup direction="column">
                  {Object.keys(assets).length > 0 && player.id && (
                    <EuiFlexItem>
                      <EuiButton
                        color={
                          player.id && playAllStatus[player.id] == 'playing'
                            ? 'danger'
                            : 'success'
                        }
                        onClick={() =>
                          player.id && handleFireAllAction(player.id)
                        }
                        disabled={
                          playAllStatus[player.id] != 'loaded' &&
                          playAllStatus[player.id] != 'playing'
                        }
                      >
                        {player.id && playAllStatus[player.id] == 'playing'
                          ? t('Stop Loop All Assets On This Player')
                          : t('Loop Play All Assets On This Player')}
                      </EuiButton>
                    </EuiFlexItem>
                  )}
                  {Object.keys(assets)
                    .sort(
                      (a: string, b: string) =>
                        new Date(assets[a].createdAt).getTime() -
                        new Date(assets[b].createdAt).getTime()
                    )
                    .map((assetId, index) => (
                      <EuiFlexItem key={index}>
                        <EuiButton
                          color={
                            player.id?.toString() &&
                            assets[assetId].playerLoadingStatus[
                              player.id.toString()
                            ] == 'playing'
                              ? 'danger'
                              : 'primary'
                          }
                          fill={playingAsset == assets[assetId].uuid}
                          onClick={() =>
                            handleLiveFireAction(assets[assetId], player.id)
                          }
                          isLoading={btnLoadingStatus.includes(
                            player.id?.toString()
                              ? assets[assetId].playerLoadingStatus[
                                  player.id.toString()
                                ]
                              : 'initial'
                          )}
                          disabled={
                            assets[assetId].status == 'initial' ||
                            playAllStatus[player.id || -1] == 'playing'
                          }
                        >
                          <EuiFlexGroup alignItems="center">
                            {player.id &&
                              playAllStatus[player.id] == 'playing' &&
                              playingAsset == assets[assetId].uuid && (
                                <EuiFlexItem grow={false}>
                                  <EuiBadge color="success">
                                    {t('Now Playing')}
                                  </EuiBadge>
                                </EuiFlexItem>
                              )}
                            <EuiFlexItem>
                              {t(
                                // @ts-ignore
                                getActionName(
                                  player.id?.toString()
                                    ? assets[assetId].playerLoadingStatus[
                                        player.id.toString()
                                      ]
                                    : 'initial'
                                )
                              )}
                              {' #'}
                              {index + 1} {getDisplayName(assets[assetId])}
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiButton>
                      </EuiFlexItem>
                    ))}
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        ))}
      </EuiFlexGrid>

      <EuiSpacer />

      <EuiTitle>
        <h3>{t('Live Fire History')}</h3>
      </EuiTitle>

      <EuiSpacer />

      <EuiBasicTable
        tableCaption="Demo for EuiBasicTable with sorting"
        items={liveFireRecord}
        columns={columns}
        loading={isFetching}
      />
      <EuiFlexGroup
        responsive={false}
        gutterSize="s"
        alignItems="center"
        justifyContent="flexEnd"
      >
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            iconType="arrowLeft"
            aria-label="Prev"
            onClick={() => pageChange(page - 1)}
            disabled={page <= 1}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            iconType="arrowRight"
            aria-label="Next"
            onClick={() => pageChange(page + 1)}
            disabled={!cursor}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      {isOpenAssetPicker && (
        <AssetPicker
          handleFinishPickingAssets={assets =>
            handleAddLiveFireAsset(assets[0])
          }
          isMultiple={false}
          isAllowSelectLayer={true}
          setIsOpenAssetPicker={setIsOpenAssetPicker}
        />
      )}

      <AssetFlyout
        onClose={() => setPreviewAssetId(undefined)}
        asset={previewAsset.data}
        enterFromParent={true}
      />
      {!isRTDBconnected && (
        <EuiOverlayMask>
          <EuiLoadingSpinner />
        </EuiOverlayMask>
      )}
    </>
  );
};

export default LiveFire;
