import { useContext, useState } from 'react';
import {
  EuiPage,
  EuiPageBody,
  EuiPageSideBar,
  EuiPageHeader,
  EuiSideNav,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiCallOut,
} from '@elastic/eui';
import { useQuery, useQueryClient } from 'react-query';

import InquiryTable from './InquiryTable';
import UserContext from 'contexts/UserContext';
import { paginatedDataPlaceholder } from 'utils/objects';
import { getOrderList } from 'apis/Sell/order';

const InquiryIndex = () => {
  const queryClient = useQueryClient();
  const userContext = useContext(UserContext);

  const pageSize = 15;
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [keyword, setKeyword] = useState('');
  const [pageIndex, setPageIndex] = useState<number>(0);

  const createItem = (name: string, path: string, data = {}) => {
    // NOTE: Duplicate `name` values will cause `id` collisions.
    return {
      ...data,
      id: name,
      name,
      isSelected: selectedStatus === name,
      onClick: () => setSelectedStatus(name),
    };
  };

  const orderQuery = useQuery(
    [
      'order',
      {
        keyword,
        pageIndex,
        organizationId: userContext.currentOrganization?.id,
      },
    ],
    getOrderList,
    {
      keepPreviousData: true,
      initialData: paginatedDataPlaceholder,
      refetchOnWindowFocus: false,
      enabled: !!userContext.currentOrganization?.id,
    }
  );

  return (
    <EuiPage paddingSize="l">
      <EuiPageSideBar sticky>
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexItem>
            <EuiSideNav
              className="signage-management-nav"
              aria-label="Status Filter"
              mobileTitle="Statue Filter"
              items={[
                {
                  id: 'Filter by Status',
                  name: 'Filter by Status',
                  items: [
                    createItem('All', '/screens'),
                    createItem('Incoming', '/screens'),
                    createItem('Processing', '/live_control'),
                    createItem('Pending Payment', '/live_control'),
                    createItem('Payment Confirmed', '/live_control'),
                    createItem('Scheduled', '/live_control'),
                    createItem('On Going', '/live_control'),
                    createItem('Ended', '/live_control'),
                  ],
                },
              ]}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiSideNav
              className="signage-management-nav"
              aria-label="Status Filter"
              mobileTitle="Statue Filter"
              items={[
                {
                  id: 'Filter by Status',
                  name: '',
                  items: [
                    createItem('Archived', '/screens'),
                    createItem('Canceled', '/screens'),
                  ],
                },
              ]}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPageSideBar>

      <EuiPageBody paddingSize="l" panelled={true}>
        <EuiPageHeader pageTitle="Inquiry" bottomBorder={false}>
          <EuiFieldSearch
            fullWidth
            placeholder="Search..."
            onSearch={value => {
              //
            }}
          />
        </EuiPageHeader>

        {!orderQuery.isError ? (
          <InquiryTable
            orderList={orderQuery.data}
            pageIndex={pageIndex}
            pageSize={pageSize}
            isLoading={orderQuery.isFetching}
            setPageIndex={setPageIndex}
            selectedStatus={selectedStatus}
            onEditOrder={() => {}}
          />
        ) : (
          <EuiCallOut
            title="Sorry, there was an error while fetching media list"
            color="danger"
            iconType="alert"
          >
            <p>
              Please check your internet connectivity or try reload this page.
            </p>
          </EuiCallOut>
        )}
      </EuiPageBody>
    </EuiPage>
  );
};

export default InquiryIndex;
