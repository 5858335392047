import { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiSuperSelect,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiTitle,
  EuiBadge,
  EuiCheckbox,
  EuiSelect,
  EuiComboBox,
  EuiSpacer,
} from '@elastic/eui';

import UserContext from 'contexts/UserContext';
import { Client, Property } from 'types/property';
import { Media } from 'types/media';
import { LiveFeed } from 'types/liveFeed';
import { CC } from 'types/cc';
import LayerTemplateSelector from './LayerTemplateSelector';
import MediaSelector from './MediaSelector';
import LiveFeedSelector from './LiveFeedSelector';
import CCSelector from './CCSelector';
import TextOverlayEditor from './TextOverlayEditor';
import { EditingLayer, LayerContentType } from 'types/asset';
import { TextOverlay } from 'types/textOverlay';
import { getPropertyList, getClientList } from 'apis/Corps/property';
import { getLayerContentType } from 'apis/Scheduler/asset';
import { getDefaultSettingLayerContentType } from 'apis/Scheduler/defaultSetting';
import { EditingTemplateLayer, TemplateLayer } from 'types/layerTemplate';
import WindowCaptureSelector from './WindowCaptureSelector';

const LayerEditor = ({
  editingLayer,
  setEditingLayer,
  editingLayerIndex,
  setIsLayerEditorVisible,
  handleSaveLayer,
  isForLayerDefaultSetting = false,
  isForLayerTemplate = false,
}: {
  editingLayer: EditingLayer | EditingTemplateLayer | undefined;
  setEditingLayer: (layer: EditingLayer | EditingTemplateLayer | any) => void;
  editingLayerIndex: number;
  setIsLayerEditorVisible: (isVisible: boolean) => void;
  handleSaveLayer: () => void;
  isForLayerDefaultSetting?: boolean;
  isForLayerTemplate?: boolean;
}) => {
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['layer', 'common']);
  const [
    isOpenLayerTemplateSelector,
    setIsOpenLayerTemplateSelector,
  ] = useState(false);
  const [selectedLayerTemplateName, setSelectedLayerTemplateName] = useState<
    string | null
  >(null);
  const [properties, setProperties] = useState<Property[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedProperty, setSelectedProperty] = useState<{
    label: string;
    value: number;
  }>();
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>();

  const propertyQuery = useQuery(
    ['property', { organizationId: userContext.currentOrganization?.id }],
    getPropertyList
  );

  const clientQuery = useQuery(['client', {}], getClientList);

  const layerContentTypesQuery = useQuery<LayerContentType[]>(
    'layer_content_types',
    async () => {
      const data = await (isForLayerDefaultSetting
        ? getDefaultSettingLayerContentType()
        : getLayerContentType());
      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const handleLayerContentTypeChange = (value: string) => {
    const selectedContentType = layerContentTypesQuery.data?.find(el => {
      return el.model === value;
    });

    if (selectedContentType === undefined || !editingLayer) {
      return;
    }
    setEditingLayer({
      ...editingLayer,
      name:
        editingLayerIndex < 0
          ? `${selectedContentType.display_name} Layer`
          : editingLayer.name,
      content_type: selectedContentType,
      content_object: undefined,
    });
  };

  const handleLayerFieldChange = (fieldName: string, value: any) => {
    if (!editingLayer) {
      return;
    }

    setEditingLayer({
      ...editingLayer,
      [fieldName]: value,
    });
  };

  const handleSelectMedia = (media: Media | TextOverlay) => {
    if (!editingLayer) {
      return;
    }

    setEditingLayer({
      ...editingLayer,
      content_object: media,
      width:
        'width' in media && editingLayer.width == 0
          ? media.width
          : editingLayer.width || 0,
      height:
        'height' in media && editingLayer.height == 0
          ? media.height
          : editingLayer.height || 0,
    });
  };

  const handleSelectLiveFeed = (liveFeed?: LiveFeed) => {
    if (!editingLayer) {
      return;
    }

    setEditingLayer({
      ...editingLayer,
      content_object: liveFeed,
    });
  };

  const handleSelectCC = (cc?: CC) => {
    if (!editingLayer) {
      return;
    }

    setEditingLayer({
      ...editingLayer,
      content_object: cc,
    });
  };

  const handleChangeProperty = (property: any) => {
    setSelectedProperty(property);
    const client: Client[] = clients.filter(
      (c: Client) => c.property === property?.value
    );
    if (!editingLayer) {
      return;
    }
    setEditingLayer({ ...editingLayer, client: client[0].id });
  };

  const handleSelectWindowCapture = (windowCapture: any) => {
    if (!editingLayer) {
      return;
    }

    setEditingLayer({
      ...editingLayer,
      content_object: windowCapture,
    });
  };

  const validUrl = (url: string): boolean => {
    try {
      // eslint-disable-next-line compat/compat
      const newUrl = new URL(url);
      return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch (err) {
      return false;
    }
  };

  const validLayer = (): boolean => {
    let isValid = true;
    let errors = {};
    if ((editingLayer?.width || 0) <= 0) {
      isValid = false;
      errors = {
        ...errors,
        width: ['Width must > 0'],
      };
    }
    if ((editingLayer?.height || 0) <= 0) {
      isValid = false;
      errors = {
        ...errors,
        height: ['Height must > 0'],
      };
    }
    if (
      editingLayer?.content_type?.model == 'html' &&
      // @ts-ignore
      !validUrl(editingLayer?.content_object?.url)
    ) {
      isValid = false;
      errors = {
        ...errors,
        url: ['Not valid url'],
      };
    }
    if (!isValid) {
      setErrors(errors);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (
      editingLayer?.client &&
      !propertyQuery.isFetching &&
      !clientQuery.isFetching
    ) {
      const client = clientQuery.data.find(
        (c: Client) => c.id == editingLayer.client
      );
      const property = propertyQuery.data.find(
        (p: Property) => p.id == client?.property
      );
      if (!property) return;
      setSelectedProperty({
        value: property.id,
        label: property.name,
      });
    }
    if (!propertyQuery.isLoading && propertyQuery.isSuccess) {
      setProperties(propertyQuery.data);
    }
    if (!clientQuery.isLoading && clientQuery.isSuccess) {
      setClients(clientQuery.data);
    }
  }, [
    propertyQuery.data,
    propertyQuery.isFetching,
    clientQuery.data,
    editingLayer,
    propertyQuery.isLoading,
    propertyQuery.isSuccess,
    clientQuery.isFetching,
    clientQuery.isLoading,
    clientQuery.isSuccess,
  ]);

  return (
    <EuiModal
      style={{ width: 800 }}
      onClose={() => setIsLayerEditorVisible(false)}
      initialFocus="[name=popswitch]"
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>
            {editingLayerIndex >= 0 ? t(`common:Edit`) : t('common:Add')} Layer
          </h1>
        </EuiModalHeaderTitle>
        <EuiButton onClick={() => setIsOpenLayerTemplateSelector(true)}>
          {t('Choose Layer Template')}
        </EuiButton>
      </EuiModalHeader>

      <EuiModalBody>
        {isOpenLayerTemplateSelector && editingLayerIndex < 0 && (
          <LayerTemplateSelector
            setIsSelectorVisible={setIsOpenLayerTemplateSelector}
            onConfirmed={(layerData: TemplateLayer) => {
              setEditingLayer({
                ...editingLayer,
                top: layerData.top,
                left: layerData.left,
                width: layerData.width,
                height: layerData.height,
                content_type: layerContentTypesQuery.data?.find(el => {
                  // @ts-ignore
                  return el.id === layerData.content_type;
                }),
                name: layerData.name,
                content_object: undefined,
              });
              setSelectedLayerTemplateName(layerData.name);
              setIsOpenLayerTemplateSelector(false);
            }}
          />
        )}

        <EuiForm component="form">
          {!isForLayerDefaultSetting && (
            <EuiFormRow>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiCheckbox
                    id={'is_primary'}
                    label={t('Primary Layer')}
                    checked={editingLayer?.is_primary}
                    onChange={e =>
                      editingLayer
                        ? setEditingLayer({
                            ...editingLayer,
                            is_primary: e.target.checked,
                          })
                        : {}
                    }
                  />
                </EuiFlexItem>
                {editingLayer && 'is_conditional' in editingLayer && (
                  <EuiFlexItem>
                    <EuiCheckbox
                      id={'is_conditional'}
                      label={t('Conditional Layer')}
                      checked={editingLayer?.is_conditional}
                      onChange={e =>
                        editingLayer
                          ? setEditingLayer({
                              ...editingLayer,
                              is_conditional: e.target.checked,
                            })
                          : {}
                      }
                    />
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            </EuiFormRow>
          )}

          <EuiFormRow fullWidth label={t('Layer Type')}>
            <EuiSuperSelect
              options={
                layerContentTypesQuery.isSuccess
                  ? layerContentTypesQuery.data
                      ?.sort((a, b) => a.id - b.id)
                      .filter(item =>
                        editingLayer &&
                        'is_conditional' in editingLayer &&
                        editingLayer?.is_conditional
                          ? item.model == 'media'
                          : item
                      )
                      .map(layerContentType => {
                        return {
                          value: layerContentType.model,
                          inputDisplay: (
                            <span>{layerContentType.display_name}</span>
                          ),
                        };
                      })
                  : []
              }
              valueOfSelected={editingLayer?.content_type?.model}
              onChange={handleLayerContentTypeChange}
              aria-label="Layer Type"
              fullWidth
            />
          </EuiFormRow>

          <EuiFormRow fullWidth label={t('common:Name', 'Layer Name')}>
            <EuiFieldText
              fullWidth
              value={editingLayer?.name}
              onChange={e => handleLayerFieldChange('name', e.target.value)}
            />
          </EuiFormRow>

          {!isForLayerTemplate &&
            editingLayer &&
            'content_object' in editingLayer &&
            editingLayer?.content_type?.model == 'media' && (
              <>
                <MediaSelector
                  selectedMedia={editingLayer.content_object}
                  onChange={handleSelectMedia}
                  filterLayerTemplateName={selectedLayerTemplateName}
                />

                {editingLayer.content_object && (
                  <EuiFormRow fullWidth>
                    <EuiFlexGroup
                      responsive={false}
                      alignItems="center"
                      style={{ margin: '-18px -10px 4px -10px' }}
                    >
                      <EuiFlexItem grow={false}>
                        <EuiImage
                          style={{ maxWidth: 100 }}
                          size="s"
                          alt=""
                          src={
                            'thumb_location' in editingLayer.content_object
                              ? `${editingLayer.content_object.thumb_location}.png`
                              : ''
                          }
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiTitle size="s">
                          <p className="eui-textNoWrap">
                            {
                              // @ts-ignore
                              editingLayer.content_object.name
                            }
                          </p>
                        </EuiTitle>
                        <small>
                          {t('common:Duration', 'duration')}:{' '}
                          {'duration' in editingLayer.content_object
                            ? editingLayer.content_object.duration
                            : ''}
                        </small>
                        <small>
                          {t('asset:Size', 'size')}:{' '}
                          {'width' in editingLayer.content_object
                            ? editingLayer.content_object.width
                            : ''}
                          px *{' '}
                          {'height' in editingLayer.content_object
                            ? editingLayer.content_object.height
                            : ''}
                          px
                        </small>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiBadge>video</EuiBadge>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFormRow>
                )}

                <EuiCheckbox
                  id={'is_audio_enabled'}
                  label={t('Play Audio')}
                  checked={editingLayer?.is_audio_enabled}
                  onChange={e =>
                    editingLayer
                      ? setEditingLayer({
                          ...editingLayer,
                          is_audio_enabled: e.target.checked,
                        })
                      : {}
                  }
                />

                <EuiSpacer />

                {!isForLayerDefaultSetting && editingLayer.is_conditional && (
                  <>
                    <EuiFormRow fullWidth label={t('Play Condition Module')}>
                      <EuiSuperSelect
                        options={[
                          {
                            value: 'hands-detection',
                            inputDisplay: <span>Hands Detection</span>,
                          },
                          {
                            value: 'pose-detection',
                            inputDisplay: <span>Pose Detection</span>,
                          },
                        ]}
                        valueOfSelected={editingLayer?.condition_data?.module}
                        onChange={value =>
                          editingLayer.condition_data
                            ? setEditingLayer({
                                ...editingLayer,
                                condition_data: {
                                  ...(editingLayer.condition_data || {}),
                                  module: value,
                                },
                              })
                            : {}
                        }
                        aria-label="Play Condition Module"
                        fullWidth
                      />
                    </EuiFormRow>
                    <EuiFormRow fullWidth label={t('Play Condition')}>
                      <EuiSuperSelect
                        options={[
                          {
                            value: 'thumb_up',
                            inputDisplay: <span>Thumb Up</span>,
                          },
                        ]}
                        valueOfSelected={editingLayer?.condition_data?.type}
                        onChange={value =>
                          editingLayer.condition_data
                            ? setEditingLayer({
                                ...editingLayer,
                                condition_data: {
                                  ...(editingLayer.condition_data || {}),
                                  type: value,
                                },
                              })
                            : {}
                        }
                        aria-label="Play Condition"
                        fullWidth
                      />
                    </EuiFormRow>
                  </>
                )}

                {!isForLayerDefaultSetting && (
                  <EuiFormRow label={t('Property')} fullWidth>
                    <EuiFlexGroup>
                      <EuiFlexItem grow={1}>
                        <EuiComboBox
                          style={{ zIndex: 900 }}
                          fullWidth
                          placeholder={t('Select property')}
                          singleSelection={{ asPlainText: true }}
                          options={properties.map((p: Property) => ({
                            value: p.id,
                            label: p.name,
                          }))}
                          selectedOptions={
                            selectedProperty ? [selectedProperty] : []
                          }
                          onChange={(e: any) => handleChangeProperty(e[0])}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem grow={1}>
                        <EuiSelect
                          fullWidth
                          style={{ zIndex: 900 }}
                          options={clients
                            .filter(
                              (c: Client) =>
                                c.property === selectedProperty?.value
                            )
                            .map((c: Client) => ({
                              value: c.id,
                              text: c.name,
                            }))}
                          value={editingLayer?.client || 0}
                          onChange={e =>
                            setEditingLayer({
                              ...editingLayer,
                              client: parseInt(e.target.value),
                            })
                          }
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFormRow>
                )}
              </>
            )}

          {!isForLayerTemplate &&
            editingLayer &&
            'content_object' in editingLayer &&
            editingLayer?.content_type?.model == 'livefeed' && (
              <>
                <EuiFormRow fullWidth label={t('common:Live Feed')}>
                  <LiveFeedSelector
                    selectedLiveFeed={editingLayer.content_object}
                    onChange={handleSelectLiveFeed}
                  />
                </EuiFormRow>
              </>
            )}

          {!isForLayerTemplate &&
            editingLayer &&
            'content_object' in editingLayer &&
            editingLayer?.content_type?.model == 'cc' && (
              <>
                <EuiFormRow fullWidth label="CC">
                  <CCSelector
                    selectedCC={editingLayer.content_object}
                    onChange={handleSelectCC}
                  />
                </EuiFormRow>
              </>
            )}

          <EuiSpacer size="s" />

          {!isForLayerTemplate &&
            editingLayer &&
            'content_object' in editingLayer &&
            editingLayer?.content_type?.model == 'textoverlay' && (
              <>
                <TextOverlayEditor
                  selectedTextOverlay={editingLayer.content_object}
                  setEditingTextOverlay={handleSelectMedia}
                />
              </>
            )}

          {editingLayer?.content_type?.model == 'builtinmodule' && (
            <>
              <EuiFlexGroup style={{ paddingTop: 20 }}>
                <EuiFlexItem>
                  <EuiFormRow label="Built-in Module Type" fullWidth>
                    <EuiSuperSelect
                      fullWidth
                      options={[
                        {
                          value: 'POSE_SEGMENTATION',
                          inputDisplay: <span>Pose Segmentation</span>,
                        },
                      ]}
                      valueOfSelected={`POSE_SEGMENTATION`}
                      onChange={value => {}}
                      aria-label="Type"
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          )}

          {editingLayer?.content_type?.model == 'html' &&
            'content_object' in editingLayer && (
              <>
                <EuiFlexGroup style={{ paddingTop: 20 }}>
                  <EuiFlexItem>
                    <EuiFormRow
                      label={t('Url')}
                      fullWidth
                      error={errors?.url}
                      isInvalid={errors?.url && errors.url.length > 0}
                    >
                      <EuiFieldText
                        fullWidth
                        // @ts-ignore
                        isInvalid={errors?.url && errors.url.length > 0}
                        // @ts-ignore
                        value={editingLayer?.content_object?.url}
                        onChange={(e: any) => {
                          setEditingLayer({
                            ...editingLayer,
                            content_object: {
                              ...editingLayer.content_object,
                              url: e.target.value,
                            },
                          });
                        }}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </>
            )}

          {editingLayer?.content_type?.model == 'windowcapture' &&
            'content_object' in editingLayer && (
              <>
                <EuiFlexGroup style={{ paddingTop: 20 }}>
                  <EuiFlexItem>
                    <EuiFormRow label="Window Capture Application" fullWidth>
                      <WindowCaptureSelector
                        selectedApplication={editingLayer.content_object}
                        onChange={handleSelectWindowCapture}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </>
            )}

          {(isForLayerTemplate ||
            (editingLayer &&
              'content_object' in editingLayer &&
              editingLayer?.content_object)) && (
            <>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow label={t('Layer Position X')}>
                    <EuiFieldText
                      fullWidth
                      value={editingLayer?.left}
                      onChange={e =>
                        handleLayerFieldChange('left', e.target.value)
                      }
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label={t('Layer Position Y')}>
                    <EuiFieldText
                      fullWidth
                      value={editingLayer?.top}
                      onChange={e =>
                        handleLayerFieldChange('top', e.target.value)
                      }
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow
                    label={t('Layer Width')}
                    isInvalid={errors?.width && errors?.width?.length > 0}
                    error={errors?.width}
                  >
                    <EuiFieldText
                      fullWidth
                      value={editingLayer?.width}
                      isInvalid={errors?.width && errors?.width?.length > 0}
                      onChange={e =>
                        handleLayerFieldChange('width', e.target.value)
                      }
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label={t('Layer Height')}
                    isInvalid={errors?.height && errors?.height?.length > 0}
                    error={errors?.height}
                  >
                    <EuiFieldText
                      fullWidth
                      value={editingLayer?.height}
                      isInvalid={errors?.height && errors?.height?.length > 0}
                      onChange={e =>
                        handleLayerFieldChange('height', e.target.value)
                      }
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          )}
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={() => setIsLayerEditorVisible(false)}>
          {t('common:Cancel')}
        </EuiButtonEmpty>

        <EuiButton
          onClick={() => {
            if (validLayer()) handleSaveLayer();
          }}
          fill
        >
          {editingLayerIndex >= 0 ? t(`common:Save`) : t('common:Create')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default LayerEditor;
