import { useState, useEffect } from 'react';
import { UseMutationResult } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiModal,
  EuiModalHeader,
  EuiTitle,
  EuiForm,
  EuiModalBody,
  EuiFormRow,
  EuiFieldText,
  EuiModalFooter,
  EuiButton,
  EuiColorPicker,
} from '@elastic/eui';

import { Client } from 'types/property';

const ClientEditor = ({
  action,
  selectedClient,
  clientMutation,
  onClose,
}: {
  action: 'Create' | 'Edit';
  clientMutation: UseMutationResult<Client, unknown, Client, unknown>;
  selectedClient: Client | null;
  onClose: () => void;
}) => {
  const { t, i18n } = useTranslation(['propertyClient', 'common']);
  const [editingClient, setEditingClient] = useState<Client>({
    name: '',
    color: '',
    property: -1,
  });

  useEffect(() => {
    if (selectedClient) setEditingClient({ ...selectedClient });
  }, [action, selectedClient]);

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiTitle>
          <h2>{action == 'Create' ? t('Add New Client') : t('Edit Client')}</h2>
        </EuiTitle>
      </EuiModalHeader>
      <EuiForm>
        <EuiModalBody>
          <EuiFormRow label={t('common:Name')}>
            <EuiFieldText
              value={editingClient?.name}
              onChange={e =>
                setEditingClient({ ...editingClient, name: e.target.value })
              }
            />
          </EuiFormRow>
          <EuiFormRow label={t('Label Color')}>
            <EuiColorPicker
              onChange={color =>
                setEditingClient({ ...editingClient, color: color })
              }
              color={editingClient?.color}
              placeholder="None"
              isClearable={true}
              fullWidth
            />
          </EuiFormRow>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButton
            fill
            onClick={() => clientMutation.mutate(editingClient)}
            disabled={clientMutation.isLoading}
            isLoading={clientMutation.isLoading}
          >
            {action == 'Create' ? t('common:Create') : t('common:Save')}
          </EuiButton>
        </EuiModalFooter>
      </EuiForm>
    </EuiModal>
  );
};

export default ClientEditor;
