export const dashboard = {
  'zh-TW': {
    Welcome: '歡迎',
    'Quick Access': '快速連結',
    'Upload Media': '上傳Media',
    'Create Asset': '建立Asset',
    'Create Playlist': '建立播放清單',
    'More Screen...': '更多版位...',
  },
};
