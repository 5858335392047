import { callApi } from 'utils/fetchApi';
import { firebaseAuth } from '../../firebase';

const getMe = async () => {
  if (!firebaseAuth.currentUser) {
    return false;
  }

  const response = await callApi({
    method: 'GET',
    path: `/auth/me`,
    withCredentials: true,
  });

  return await response.data;
};

const patchMe = async (data: { full_name: string }) => {
  if (!firebaseAuth.currentUser) {
    return false;
  }

  const response = await callApi({
    method: 'PATCH',
    path: `/auth/me`,
    withCredentials: true,
    data,
  });

  return await response.data;
};

export { getMe, patchMe };
