import { useState, useRef, createRef } from 'react';
import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiDescriptionList,
  EuiDescriptionListTitle,
  EuiDescriptionListDescription,
  EuiAspectRatio,
  EuiImage,
  EuiPopover,
  EuiFieldText,
  EuiBasicTableColumn,
} from '@elastic/eui';
import moment from 'moment';

import { PaginatedOrder, Order } from '../../types/order';
import { ORDER_STATUS, ORDER_STATUS_NAMES } from './consts';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const InquiryTable = ({
  orderList,
  selectedStatus,
  pageIndex,
  pageSize,
  isLoading,
  setPageIndex,
  onEditOrder,
}: {
  orderList: PaginatedOrder;
  selectedStatus: string;
  pageIndex: number;
  pageSize: number;
  isLoading: boolean;
  setPageIndex: (pageIndex: number) => void;
  onEditOrder: (OrderId: number, name: string) => void;
}) => {
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<{
    [id: number]: any;
  }>({});
  const [editItem, setEditItem] = useState<number>(0);
  const [hoverRow, setHoverRow] = useState<number>(0);

  const nameRef = useRef<any>([]);
  nameRef.current = orderList.results.map(
    (element, i) => nameRef.current[element.id] ?? createRef()
  );

  const pagination: any = {
    pageIndex,
    pageSize,
    totalItemCount: orderList.count,
    hidePerPageOptions: true,
  };

  const onTableChange = ({ page }: { page: { index: number } }) => {
    const { index: pageIndex } = page;

    setPageIndex(pageIndex);
  };

  const toggleDetails = (item: Order) => {
    if (!item.inquiry.id) return;
    const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };
    if (itemIdToExpandedRowMapValues[item.inquiry.id]) {
      delete itemIdToExpandedRowMapValues[item.inquiry.id];
    } else {
      itemIdToExpandedRowMapValues[item.inquiry.id] = (
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiDescriptionList>
              <EuiDescriptionListTitle>
                <h1>Quotation NO.</h1>
              </EuiDescriptionListTitle>
              <EuiDescriptionListDescription>
                {item.inquiry.id}
              </EuiDescriptionListDescription>
            </EuiDescriptionList>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiDescriptionList>
              <EuiDescriptionListTitle>
                <h1>Quotation NO.</h1>
              </EuiDescriptionListTitle>
              <EuiDescriptionListDescription>
                {item.inquiry.id}
              </EuiDescriptionListDescription>
            </EuiDescriptionList>
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    }
    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
  };

  const columns: EuiBasicTableColumn<Order>[] = [
    {
      field: 'inquiry.created_at',
      name: 'Created At',
      dataType: 'string',
      render: (created_at: string) => moment(created_at).fromNow(),
    },
    {
      field: 'inquiry.campaign_name',
      name: 'Campaign Name',
      dataType: 'string',
      width: '50%',
    },
    {
      field: 'quotation',
      name: 'Quotation',
      dataType: 'string',
      render: (quotation: string) => (
        <b>{formatter.format(parseFloat(quotation))}</b>
      ),
    },
    {
      field: 'current_status.status',
      name: 'Status',
      dataType: 'string',
      render: (status: number) => ORDER_STATUS_NAMES[status],
    },
  ];

  return (
    <EuiBasicTable
      items={
        selectedStatus != 'All'
          ? orderList.results.filter(
              order =>
                ORDER_STATUS_NAMES[order.current_status.status] ===
                selectedStatus
            )
          : orderList.results
      }
      itemId="id"
      columns={columns}
      loading={isLoading}
      pagination={pagination}
      onChange={onTableChange}
      isExpandable
      noItemsMessage={isLoading ? 'Loading Order...' : 'No Order found'}
    />
  );
};

export default InquiryTable;
