import { useEffect, useState } from 'react';

import { EuiFieldText, EuiFieldTextProps } from '@elastic/eui';
import useDurationFormat from 'hooks/useDurationFormat';

interface FieldDurationProps extends EuiFieldTextProps {
  setParsedValue: (seconds: number) => void;
}

const FieldDuration = ({ setParsedValue, ...props }: FieldDurationProps) => {
  const formatDuration = useDurationFormat();

  const [isOnFocus, setIsOnFocus] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [value, setValue] = useState(
    formatDuration(props.value ? parseInt(props.value.toString()) : 0)
  );

  const isInputValid: (input: string) => boolean = input => {
    const parsedData = input.split(':');
    if (parsedData.length > 3 || parsedData.length <= 0) return false;
    for (let index = 0; index < parsedData.length; index++) {
      const element = parsedData[index];
      if (isNaN(parseInt(element))) return false;
    }
    if (
      parsedData.length == 2 &&
      (parseInt(parsedData[0]) > 60 || parseInt(parsedData[1])) > 60
    )
      return false;
    if (
      parsedData.length == 3 &&
      (parseInt(parsedData[1]) > 60 || parseInt(parsedData[2])) > 60
    )
      return false;
    return true;
  };

  const parseToSeconds = (input: string) => {
    const [x, y, z] = input.split(':');
    if (!z) {
      return parseInt(x) * 60 + parseInt(y);
    } else {
      return parseInt(x) * 3600 + parseInt(y) * 60 + parseInt(z);
    }
  };

  useEffect(() => {
    if (!isOnFocus && props.value && parseInt(props.value.toString()) > 0) {
      setValue(formatDuration(parseInt(props.value.toString())));
    }
  }, [isOnFocus, formatDuration, props.value, value]);

  return (
    <EuiFieldText
      {...props}
      value={value}
      onChange={e => {
        setValue(e.target.value);
        if (!isInputValid(e.target.value)) {
          setIsInvalid(true);
          setParsedValue(0);
        } else {
          setIsInvalid(false);
          setParsedValue(parseToSeconds(e.target.value));
        }
      }}
      onFocus={() => setIsOnFocus(true)}
      onBlur={() => setIsOnFocus(false)}
      isInvalid={isInvalid || props.isInvalid}
    />
  );
};

export default FieldDuration;
