import { useMemo } from 'react';
import { EuiTitle } from '@elastic/eui';
import { Layer } from 'types/asset';
import { EditingTemplateLayer } from 'types/layerTemplate';

const AssetPreviewer = ({
  containerWidth,
  containerHeight,
  layers = [],
  isForLayerTemplate = false,
}: {
  containerWidth: number;
  containerHeight: number;
  layers?: Array<Layer | EditingTemplateLayer>;
  isForLayerTemplate?: boolean;
}) => {
  const frameSize: { width: number; height: number } = useMemo(() => {
    if (layers.length == 0) {
      // Default 16:9
      return {
        width: 1920,
        height: 1080,
      };
    }

    const xMaxLayer: Layer | EditingTemplateLayer = layers.reduce(
      (a: Layer | EditingTemplateLayer, b: Layer | EditingTemplateLayer) => {
        return a.width * 1 + a.left * 1 > b.width * 1 + b.left * 1 ? a : b;
      }
    );
    const yMaxLayer: Layer | EditingTemplateLayer = layers.reduce(
      (a: Layer | EditingTemplateLayer, b: Layer | EditingTemplateLayer) => {
        return a.height * 1 + a.top * 1 > b.height * 1 + b.top * 1 ? a : b;
      }
    );

    return {
      width: xMaxLayer.width * 1 + xMaxLayer.left * 1,
      height: yMaxLayer.height * 1 + yMaxLayer.top * 1,
    };
  }, [layers]);

  const calFrameWidth: number = useMemo(() => {
    const containerRatio = containerWidth / containerHeight;
    const frameRatio = frameSize.width / frameSize.height;

    if (containerRatio >= frameRatio) {
      return (containerHeight - 70) * frameRatio;
    } else {
      return containerWidth;
    }
  }, [containerHeight, containerWidth, frameSize.height, frameSize.width]);

  const calFrameHeight: number = useMemo(() => {
    const containerRatio = containerWidth / containerHeight;
    const frameRatio = frameSize.width / frameSize.height;

    if (containerRatio >= frameRatio) {
      return containerHeight - 70;
    } else {
      return containerWidth / frameRatio;
    }
  }, [containerHeight, containerWidth, frameSize.height, frameSize.width]);

  return (
    <div
      className="container"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        minHeight: '200px',
      }}
    >
      <div
        className="frame"
        style={{
          background: 'black',
          width: calFrameWidth,
          height: calFrameHeight,
          position: 'relative',
        }}
      >
        {layers.map((layer, index) => (
          <div
            key={index}
            className="layer"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              zIndex: layer.z_index + 100,
              backgroundColor:
                'backgroundColor' in layer ? layer.backgroundColor : '',
              backgroundImage:
                'content_object' in layer &&
                layer.content_object &&
                'thumb_location' in layer.content_object
                  ? `url(${layer.content_object.thumb_location}.png)`
                  : 'none',
              backgroundSize: 'cover',
              backgroundRepeat: 'round',
              top: `${(layer.top / frameSize.height) * 100}%`,
              left: `${(layer.left / frameSize.width) * 100}%`,
              width: `${(layer.width / frameSize.width) * 100}%`,
              height: `${(layer.height / frameSize.height) * 100}%`,
              border: isForLayerTemplate ? '1px dashed #CCC' : '',
            }}
          >
            <EuiTitle className="eui-textCenter" size="s">
              <p
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {layer.content_type?.model == 'textoverlay' ||
                isForLayerTemplate
                  ? layer.name
                  : ''}
              </p>
            </EuiTitle>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssetPreviewer;
