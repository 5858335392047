export const report = {
  'zh-TW': {
    Reporting: '統計報告',
    'Date From': '起始日期',
    'Date To': '結束日期',
    'All Screens': '所有版位',
    'All Players': '所有播放器',
    Query: '查詢',
    'Export Word': '產出Word',
    Print: '列印',
    'Daily Performance Report': 'Daily Performance Report',
    'Sign Usage by Customer': 'Sign Usage by Customer',
    Name: '{{field}}名稱',
    'Num Times Played': '播放次數',
    'Total Duration': '播放時數',
    'Total played': '總播放次數',
    'Total Length': '總播放時數',
    Date: '日期',
    Time: '時間',
    Property: 'Property',
    Client: 'Client',
    'All Property': '所有$t(Property)',
    'All Client': '所有$t(Client)',
    'All Clients': '所有$t(Client)',
    'Pre-Run Schedule': 'Pre-Run Schedule',
    'Date/Time': '$t(Date)/$t(Time)',
    From: 'From',
    to: 'to',
    'Times Played': '播放次數',
  },
};
