export const login = {
  en: {
    login: 'Login',
    Password: 'Password',
    'Forgot password': 'Forgot password',
    'Sign in': 'Sign in',
    'Sign in with Google': 'Sign in with Google',
    'No account?': 'No account?',
    'Create one': 'Create one',

    'Create GOTIGER Account': 'Create GOTIGER Account',
    'Sign up': 'Sign up',
    'Sign up with Google': 'Sign up with Google',
    'Already have an account?': 'Already have an account?',
  },
  'zh-TW': {
    login: '登入',
    Password: '密碼',
    'Forgot password': '忘記密碼',
    'Sign in': '登入',
    'Sign in with Google': '使用 Google 帳號登入',
    'No account?': '沒有帳號?',
    'Create one': '開始建立 GOTIGER 帳號',

    'Create GOTIGER Account': '建立 GOTIGER 帳號',
    'Sign up': '使用 Email 建立帳號',
    'Sign up with Google': '使用 Google 帳號註冊',
    'Already have an account?': '已經有帳號了嗎?',
  },
};
