import {
  EuiButton,
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSelect,
  EuiSuperSelect,
  EuiText,
} from '@elastic/eui';
import { useState, useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { LayerTemplate, TemplateLayer } from 'types/layerTemplate';
import UserContext from 'contexts/UserContext';
import { getLayerContentType } from 'apis/Scheduler/asset';
import { getLayerTemplateList } from 'apis/Scheduler/layerTemplate';
import { LayerContentType } from 'types/asset';

const LayerTemplateSelector = ({
  mediaOnly = false,
  setIsSelectorVisible,
  onConfirmed,
}: {
  mediaOnly?: boolean;
  setIsSelectorVisible: (isVisible: boolean) => void;
  onConfirmed: (layerData: TemplateLayer) => void;
}) => {
  const userContext = useContext(UserContext);

  const [selectedLayerTemplate, setSelectedLayerTemplate] = useState<string>();
  const [selectedLayer, setSelectedLayer] = useState<TemplateLayer>();

  const layerTemplateQuery = useQuery<LayerTemplate[]>(
    [
      'layerTemplate',
      {
        organizationId: userContext.currentOrganization?.id,
      },
    ],
    getLayerTemplateList,
    {
      keepPreviousData: true,
      initialData: [],
      refetchOnWindowFocus: false,
      enabled: !!userContext.currentOrganization?.id,
    }
  );

  const layerContentTypesQuery = useQuery<LayerContentType[]>(
    'layer_content_types',
    async () => {
      const data = await getLayerContentType();
      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const mediaContentTypeId = useMemo(() => {
    if (layerContentTypesQuery.isSuccess && layerContentTypesQuery.data) {
      const mediaContentType = layerContentTypesQuery.data?.find(el => {
        // @ts-ignore
        return el.model === 'media';
      });
      return mediaContentType?.id;
    }
    return undefined;
  }, [layerContentTypesQuery.data, layerContentTypesQuery.isSuccess]);

  useEffect(() => {
    if (layerTemplateQuery.isSuccess && layerTemplateQuery.data) {
      if (layerTemplateQuery.data.length === 1) {
        setSelectedLayerTemplate('0');
      }
    }
  }, [layerTemplateQuery.data, layerTemplateQuery.isSuccess]);

  return (
    <EuiModal
      onClose={() => setIsSelectorVisible(false)}
      initialFocus="[name=popswitch]"
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Select Layer Template</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm component="form">
          <EuiFormRow fullWidth label="Choose Layer Template Set">
            <EuiSelect
              options={
                layerTemplateQuery.isSuccess
                  ? [
                      { text: 'Select Layer Template Set' },
                      ...layerTemplateQuery.data.map((layerTemplate, index) => {
                        return {
                          text: layerTemplate.name,
                          value: index,
                        };
                      }),
                    ]
                  : []
              }
              onChange={e => setSelectedLayerTemplate(e.target.value)}
              value={selectedLayerTemplate}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label="Choose Layer">
            <EuiSuperSelect
              options={
                layerTemplateQuery.isSuccess &&
                selectedLayerTemplate !== undefined
                  ? layerTemplateQuery.data[
                      parseInt(selectedLayerTemplate)
                    ].template_data
                      .filter(layer => {
                        if (mediaOnly) {
                          // @ts-ignore
                          return layer.content_type == mediaContentTypeId;
                        } else {
                          return true;
                        }
                      })
                      .map(layer => {
                        return {
                          value: layer.name,
                          inputDisplay: layer.name,
                          dropdownDisplay: (
                            <>
                              <strong>{layer.name}</strong>
                              <EuiText size="s" color="subdued">
                                <p>
                                  {`x: ${layer.left}, y: ${layer.top}, width:
                                  ${layer.width}, height: ${layer.height}`}
                                </p>
                              </EuiText>
                            </>
                          ),
                        };
                      })
                  : []
              }
              valueOfSelected={selectedLayer?.name || ''}
              onChange={value =>
                layerTemplateQuery.data && selectedLayerTemplate
                  ? setSelectedLayer(
                      layerTemplateQuery.data[
                        parseInt(selectedLayerTemplate)
                      ].template_data.find(el => {
                        return el.name === value;
                      })
                    )
                  : {}
              }
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={() => setIsSelectorVisible(false)}>
          Cancel
        </EuiButtonEmpty>

        <EuiButton
          disabled={!selectedLayer}
          onClick={() => (selectedLayer ? onConfirmed(selectedLayer) : {})}
          fill
        >
          Use Selected Template
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default LayerTemplateSelector;
