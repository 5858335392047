import { useState, useEffect, useContext, useCallback } from 'react';
import moment from 'moment';

import { getMediaDownloadUrls } from 'apis/Scheduler/media';
import { getFontDownloadUrls, getFontByName } from 'apis/Scheduler/font';
import { genParsedTextOverlayContent } from 'apis/Scheduler/asset';
import UserContext from 'contexts/UserContext';
import './textOverlayPreviewStyle.scss';

const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';
const TIME_FORMAT_12 = 'hh:mm';

const FRAME_PER_SECOND = 60;
const DEFAULT_ASSET_DURATION = 15;

const TextOverlayPreview = () => {
  const [cacheImgUrl, setCacheImgUrl] = useState<any>({});
  const layer = JSON.parse(localStorage.getItem('webPreview') || '');
  const userContext = useContext(UserContext);
  const organizationId = !userContext.currentOrganization
    ? null
    : userContext.currentOrganization.id;

  const setElement = useCallback((layer: any, content: any) => {
    const ratio = window.innerWidth / layer.width;

    var tagsElement = document.createElement('div');
    tagsElement.setAttribute('id', 'tags');
    var totalWidth = 0;
    var color = '#ffffff';
    var bgcolor = '#000000';

    content.forEach((tag: any) => {
      const type = tag['type'];
      if (type == 'bg_color') {
        bgcolor = tag['value'];
      } else if (type == 'text' || type == 'datatag') {
        const elemSpan = document.createElement('span');
        const text = tag['text'].replace(/ /g, '&nbsp;');
        var showText =
          type == 'text' ? text : dataTagProcess(elemSpan, tag['value']);
        elemSpan.innerHTML = showText;

        //-- append it to get real width
        document.body.append(elemSpan);
        elemSpan.style.height = `${100 * ratio}px`;
        elemSpan.style.fontSize = `${
          tag.font_size || layer.content_object.style.font_size * ratio
        }px`;
        // elemSpan.style.fontFamily = tag['font'].replace(/ /g, '-');
        if (tag['color']) elemSpan.style.color = tag['color'];
        else elemSpan.style.color = `${color}`;

        const elemW = elemSpan.offsetWidth;
        totalWidth += elemW || 0;

        console.log('Text:', showText);
        console.log('Width:', elemW);

        tagsElement.append(elemSpan);
      } else if (type == 'imagetag') {
        const elemImg = document.createElement('img');
        elemImg.setAttribute('id', 'img');
        elemImg.src = tag['src'];

        const width = tag['width'];
        const height = tag['height'];
        console.log('Image original width: ', width);
        console.log('Image original height: ', height);

        elemImg.style.width = width * (layer.height / height) * ratio + 'px';
        elemImg.style.height = layer.height * ratio + 'px';

        console.log(
          'Image ratio width: ',
          width * (layer.height / height) * ratio
        );
        console.log('Image ratio height: ', layer.height * ratio);
        totalWidth += width * (layer.height / height) * ratio;
        tagsElement.append(elemImg);
      }
    });
    //-- set div style
    tagsElement.style.height = `${layer.height * ratio}px`;
    tagsElement.style.display = 'inline-block';
    tagsElement.style.overflow = 'hidden';

    return {
      tagsElement,
      totalWidth,
      bgcolor,
    };
  }, []);

  const setAnimation = useCallback(
    async (layer: any, tagsElement: any, totalWidth: number) => {
      const ratio = window.innerWidth / layer.width;
      const textOverlay = layer.content_object;
      const type = textOverlay.style.type;
      var layerWidth = layer.width;
      var layerLeft = layer.left;

      const duration: number =
        layer.asset && layer.asset.duration > DEFAULT_ASSET_DURATION
          ? layer.asset.duration
          : DEFAULT_ASSET_DURATION;

      var objAnimate: any = {
        type: '',
        plays: [],
        durations: [],
      };

      switch (type) {
        case 'static':
          var left = (layerLeft + textOverlay.style.left * layerWidth) * ratio;
          var right =
            (layerLeft + textOverlay.style.right * layerWidth) * ratio;
          var displayWidth = Math.abs(left - right);

          if (textOverlay.repeat) {
            var repeatTimes = Math.ceil(displayWidth / totalWidth);
            const cloneElemets = getRepeatElements(repeatTimes, tagsElement);
            cloneElemets.forEach((elm: any) => tagsElement.appendChild(elm));
          }
          tagsElement.style.width = displayWidth + 'px';
          tagsElement.style.marginLeft =
            left + textOverlay.style.start * layerWidth * ratio + 'px';

          break;
        case 'rico':
          var start =
            (layerLeft + textOverlay.style.start * layerWidth) * ratio;
          var targetMovedPixel =
            Math.abs(textOverlay.style.target_speed) *
            FRAME_PER_SECOND *
            ratio *
            duration;

          if (textOverlay.repeat) {
            var tagsTotalLength =
              (Math.abs(textOverlay.style.target_speed) *
                duration *
                FRAME_PER_SECOND -
                layer.width * textOverlay.style.start) *
              ratio;
            var repeatTimes = Math.floor(tagsTotalLength / totalWidth) || 1;
            const cloneElemets = getRepeatElements(repeatTimes, tagsElement);
            cloneElemets.forEach((elm: any) => tagsElement.appendChild(elm));
          }
          tagsElement.style.marginLeft = start + 'px';

          console.log('Rico: ');
          console.log('TargetMovedPixel: ', targetMovedPixel, duration);

          objAnimate.type = 'rico';
          objAnimate.plays.push({
            marginLeft: start - targetMovedPixel + 'px',
          });
          objAnimate.durations.push(duration * 1000);

          break;
        case 'auto':
          var target_speed = textOverlay.style.target_speed
            ? textOverlay.style.target_speed
            : -6;
          var start =
            (layerLeft + textOverlay.style.start * layerWidth) * ratio;
          var end = (layerLeft + textOverlay.style.end * layerWidth) * ratio;
          var realTotalWidth = 0;

          if (textOverlay.repeat) {
            var tagsTotalLength =
              (Math.abs(target_speed) * duration * FRAME_PER_SECOND -
                (textOverlay.style.end - textOverlay.style.start)) *
              ratio;
            var repeatTimes = Math.floor(tagsTotalLength / totalWidth) || 1;
            realTotalWidth = totalWidth * repeatTimes;
            const cloneElemets = getRepeatElements(repeatTimes, tagsElement);
            cloneElemets.forEach((elm: any) => tagsElement.appendChild(elm));
          } else {
            realTotalWidth = totalWidth;
          }
          tagsElement.style.marginLeft = start + 'px';

          console.log('Auto: ');
          console.log('Real: ', realTotalWidth, duration);

          objAnimate.type = 'auto';
          objAnimate.plays.push({
            marginLeft: -(Math.abs(realTotalWidth) - end) + 'px',
          });
          objAnimate.durations.push(duration * 1000);

          break;
        case 'sweep':
          var in_duration = textOverlay.style.in_duration / 1000000;
          var out_duration = textOverlay.style.in_duration / 1000000;
          var in_speed = textOverlay.style.in_speed;
          var out_speed = textOverlay.style.out_speed;
          var target_speed = textOverlay.style.target_speed;
          var start =
            (layerLeft + textOverlay.style.start * layerWidth) * ratio;

          var targetMovedPixel =
            Math.abs(target_speed) *
            FRAME_PER_SECOND *
            (duration - in_duration - out_duration) *
            ratio;

          var in_pixel =
            Math.abs(in_duration * FRAME_PER_SECOND * in_speed) * ratio;
          var out_pixel =
            Math.abs(out_duration * FRAME_PER_SECOND * out_speed) * ratio;

          if (textOverlay.repeat) {
            var tagsTotalLength =
              targetMovedPixel +
              in_pixel +
              out_pixel -
              layer.width * textOverlay.style.start;
            var repeatTimes = Math.ceil(tagsTotalLength / totalWidth) || 1;
            console.log(tagsTotalLength, totalWidth);
            const cloneElemets = getRepeatElements(repeatTimes, tagsElement);
            cloneElemets.forEach((elm: any) => tagsElement.appendChild(elm));
          }

          tagsElement.style.marginLeft = start + 'px';

          console.log('Sweep: ');
          console.log('TargetMovedPixel: ', targetMovedPixel, duration);
          console.log('In: ', in_pixel, in_duration);
          console.log('Out: ', out_pixel, out_duration);

          objAnimate.type = 'sweep';
          objAnimate.plays.push({
            marginLeft: start - in_pixel + 'px',
          });
          objAnimate.durations.push(in_duration * 1000);

          objAnimate.plays.push({
            marginLeft: start - in_pixel - targetMovedPixel + 'px',
          });
          objAnimate.durations.push(
            (duration - out_duration - in_duration) * 1000
          );

          objAnimate.plays.push({
            marginLeft: start - in_pixel - targetMovedPixel - out_pixel + 'px',
          });
          objAnimate.durations.push(out_duration * 1000);

          break;
        default:
          break;
      }
      return objAnimate;
    },
    []
  );

  const getRepeatElements = (repeatTimes: number, tagsElement: any) => {
    if (repeatTimes == Infinity) repeatTimes = 1;
    console.log(`repeatTimes ${repeatTimes}`);

    const cloneElem: any = [];
    for (var i = 0; i < repeatTimes - 1; i++) {
      tagsElement.childNodes.forEach((node: any) =>
        cloneElem.push(node.cloneNode(true))
      );
    }
    return cloneElem;
  };

  const dataTagProcess = (element: any, tag: any) => {
    var value = tag.toLowerCase();

    if (value.startsWith('countdownto')) {
      tag = value.split('_')[1];
      // tag = moment().format(DATE_FORMAT);
    } else if (value == 'dayofweek') {
      tag = moment().weekday();
    } else if (value == 'date') {
      tag = moment().format(DATE_FORMAT);
    } else if (value == 'time') {
      tag = moment().format(TIME_FORMAT);
      element.setAttribute('class', 'tagTime');
    } else if (value == 'datetime') {
      tag = moment().format(DATETIME_FORMAT);
      element.setAttribute('class', 'tagDateTime');
    } else if (value == 'time12') {
      tag = moment().format(TIME_FORMAT_12);
      element.setAttribute('class', 'tagTime12');
    }
    return tag;
  };

  const startAnimation = (tagsElement: any, objAnimate: any) => {
    switch (objAnimate.type) {
      case 'rico':
        tagsElement?.animate(
          { ...objAnimate.plays[0], easing: 'linear' },
          { duration: objAnimate.durations[0] }
        );
        break;

      case 'auto':
        tagsElement?.animate(
          { ...objAnimate.plays[0], easing: 'linear' },
          { duration: objAnimate.durations[0] }
        );
        break;

      case 'sweep':
        tagsElement?.animate(
          { ...objAnimate.plays[0], easing: 'linear' },
          { duration: objAnimate.durations[0], fill: 'forwards' }
        );
        tagsElement?.animate(
          { ...objAnimate.plays[1], easing: 'linear' },
          {
            duration: objAnimate.durations[1],
            fill: 'forwards',
            delay: objAnimate.durations[0],
          }
        );
        tagsElement?.animate(
          { ...objAnimate.plays[2], easing: 'linear' },
          {
            duration: objAnimate.durations[2],
            fill: 'forwards',
            delay: objAnimate.durations[1],
          }
        );
        break;
    }
  };

  const setRequiredFont = (content: any) => {
    const requiredFonts: any = [];
    content.forEach((c: any) => {
      if (c['font'] && requiredFonts.indexOf(c['font']) < 0)
        requiredFonts.push(c['font']);
    });

    requiredFonts.forEach(async (f: any) => {
      const font = await getFontByName(f);
      const d: any = await getFontDownloadUrls(
        font[0].organization,
        font[0].id
      );

      const style = document.createElement('style');
      style.textContent = `
        @font-face {
          font-family: '${f.replace(/ /g, '-')}';
          src: url('${d['url']}');
        }
      `;
      document.head.appendChild(style);
    });
  };

  const getImgUrl = useCallback(
    async (tag: any, organizationId: any) => {
      if (!cacheImgUrl[tag]) {
        const res: any = await getMediaDownloadUrls(
          organizationId.toString(),
          tag.split('/')[0]
        );
        setCacheImgUrl({ ...cacheImgUrl, [tag]: res['url'] });
        return res['url'];
      } else {
        return cacheImgUrl[tag];
      }
    },
    [cacheImgUrl]
  );

  const setContentImgUrl = useCallback(
    async (content: any, organizationId: any) => {
      for (let c of content) {
        if (c['type'] == 'imagetag') {
          c['src'] = await getImgUrl(c['value'], organizationId);
        }
      }
      return content;
    },
    [getImgUrl]
  );

  const render = useCallback(
    async (layer: any, content: any) => {
      const elem = document.getElementById('divScreen');
      if (!elem) return;
      const { tagsElement, totalWidth, bgcolor } = setElement(layer, content);

      elem.append(tagsElement);
      elem.style.width = window.innerWidth + 'px';
      elem.style.height =
        layer.height * (window.innerWidth / layer.width) + 'px';
      elem.style.backgroundColor = bgcolor;

      const objAnimate = await setAnimation(layer, tagsElement, totalWidth);
      setTimeout(() => {
        elem.style.display = 'block';
        startAnimation(tagsElement, objAnimate);
      }, 1000);
      //-- update datatag value every second.
      setInterval(() => {
        document
          .querySelectorAll('.tagTime')
          ?.forEach((e: any) => (e.innerHTML = moment().format(TIME_FORMAT)));
        document
          .querySelectorAll('.tagDateTime')
          ?.forEach(
            (e: any) => (e.innerHTML = moment().format(DATETIME_FORMAT))
          );
        document.querySelectorAll('.tagTime12')?.forEach((e: any) => {
          e.innerHTML = moment().format(TIME_FORMAT_12);
        });
      }, 1000);
    },
    [setAnimation, setElement]
  );

  const fetchData = useCallback(
    async organizationId => {
      const layerContent = await genParsedTextOverlayContent(
        organizationId,
        layer.content_object.content
      );
      setRequiredFont(layerContent);
      const updatedContent = await setContentImgUrl(
        layerContent,
        organizationId
      );
      render(layer, updatedContent);
    },
    [layer, render, setContentImgUrl]
  );

  useEffect(() => {
    if (!organizationId) return;
    console.log(localStorage.getItem('webPreview'));
    fetchData(organizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return (
    <>
      <div id="divScreen"></div>
    </>
  );
};

export default TextOverlayPreview;
