import { useState, useContext } from 'react';
import { EuiSuperSelect, EuiText } from '@elastic/eui';
import { useQuery } from 'react-query';

import { LiveFeed } from 'types/liveFeed';
import { getLiveFeedList } from 'apis/Scheduler/liveFeed';
import UserContext from 'contexts/UserContext';

const LiveFeedSelector = ({
  selectedLiveFeed,
  onChange,
}: {
  selectedLiveFeed?: any;
  onChange: (liveFeed?: LiveFeed) => void;
}) => {
  const userContext = useContext(UserContext);

  const liveFeedQuery = useQuery<LiveFeed[]>(
    [
      'liveFeeds',
      {
        organizationId: userContext.currentOrganization?.id,
      },
    ],
    getLiveFeedList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!userContext.currentOrganization?.id,
    }
  );

  const handleLiveFeedChange = (name: string) => {
    const selectedLiveFeed: LiveFeed | undefined = liveFeedQuery.data?.find(
      el => {
        return el.name === name;
      }
    );

    onChange(selectedLiveFeed);
  };

  return (
    <EuiSuperSelect
      fullWidth
      options={
        liveFeedQuery.isSuccess
          ? liveFeedQuery.data.map((liveFeed: LiveFeed) => ({
              value: liveFeed.name,
              inputDisplay: liveFeed.name,
              dropdownDisplay: (
                <>
                  <strong>{liveFeed.name}</strong>
                  <EuiText size="s" color="subdued">
                    <p>
                      {liveFeed.input_type} / {liveFeed.mode}
                    </p>
                  </EuiText>
                </>
              ),
            }))
          : []
      }
      valueOfSelected={selectedLiveFeed?.name || ''}
      placeholder="Select an live feed source"
      onChange={value => handleLiveFeedChange(value)}
      itemLayoutAlign="top"
      hasDividers
    />
  );
};

export default LiveFeedSelector;
