import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  EuiEmptyPrompt,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiFieldPassword,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiText,
  EuiLink,
  EuiButtonEmpty,
  EuiCallOut,
  EuiImage,
  EuiSpacer,
} from '@elastic/eui';
import firebase from 'firebase/app';

import CenteredContentPage from 'layouts/CenteredContentPage';
import { firebaseAuth } from '../../firebase';
import { patchMe } from 'apis/Auth/me';

function Login() {
  let { organizationSlug } = useParams<any>();
  const { t, i18n } = useTranslation(['login', 'common']);
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');

  const [isResetMailSent, setIsResetMailSent] = useState(false);

  const handleSignInWithEmail = async () => {
    try {
      await firebaseAuth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      alert(e);
    }
  };

  const handleResetPassword = async () => {
    try {
      await firebaseAuth.sendPasswordResetEmail(email);
      setIsResetMailSent(true);
    } catch (e) {
      alert(e.message);
    }
  };

  const handleSignUpWithEmail = async () => {
    try {
      const userCredential = await firebaseAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      userCredential.user?.sendEmailVerification();
      await firebaseAuth.currentUser?.updateProfile({
        displayName: fullName,
      });
      await patchMe({ full_name: fullName });
    } catch (e) {
      alert(e);
    }
  };

  const handleSignUpWithGoogle = async () => {
    await firebaseAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  };

  return (
    <CenteredContentPage>
      {isResetMailSent && (
        <EuiCallOut
          title="Password reset email sent!"
          color="success"
          iconType="email"
        >
          <p>Please use new password to login after reset.</p>
        </EuiCallOut>
      )}

      <EuiSpacer />

      {organizationSlug && (
        <EuiFlexGroup justifyContent="center">
          <EuiFlexItem grow={false}>
            <EuiImage
              style={{ maxWidth: 200, maxHeight: 200 }}
              alt="Organization Logo"
              src="https://disneyscheduler.com/var/skin/Disney_Login_Scheduling.jpg"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}

      <EuiEmptyPrompt
        title={<h2>{isSignUp ? t('Create GOTIGER Account') : t('login')}</h2>}
        body={
          <EuiForm component="form">
            <EuiFlexGroup direction="column">
              {isSignUp && (
                <EuiFlexItem>
                  <EuiFormRow fullWidth label={t('common:Full Name')}>
                    <EuiFieldText
                      fullWidth
                      name="fullName"
                      value={fullName}
                      onChange={e => setFullName(e.target.value)}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              )}

              <EuiFlexItem>
                <EuiFormRow fullWidth label={t('common:email')}>
                  <EuiFieldText
                    fullWidth
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiFormRow
                  className="eui-textRight"
                  fullWidth
                  label={t('Password')}
                  helpText={
                    !isSignUp && (
                      <EuiButtonEmpty size="xs" onClick={handleResetPassword}>
                        {t('Forgot password')}
                      </EuiButtonEmpty>
                    )
                  }
                >
                  <EuiFieldPassword
                    fullWidth
                    type="dual"
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiButton
                  onClick={() => {
                    isSignUp
                      ? handleSignUpWithEmail()
                      : handleSignInWithEmail();
                  }}
                >
                  {isSignUp ? t('Sign up') : t('Sign in')}
                </EuiButton>
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiText>{t('common:or')}</EuiText>
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiButton onClick={handleSignUpWithGoogle}>
                  {isSignUp
                    ? t('Sign up with Google')
                    : t('Sign in with Google')}
                </EuiButton>
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiText>
                  {isSignUp ? t('Already have an account?') : t('No account?')}{' '}
                  <EuiLink
                    color="primary"
                    onClick={() =>
                      isSignUp ? setIsSignUp(false) : setIsSignUp(true)
                    }
                  >
                    {isSignUp ? t('Sign in') : t('Create one')}
                  </EuiLink>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiForm>
        }
      />
    </CenteredContentPage>
  );
}

export default Login;
