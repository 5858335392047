export const liveControl = {
  en: {
    LiveFireStatus: '$t(Live Fire) is {{status}}',
  },
  'zh-TW': {
    Asset: 'Asset',
    'Change Screen': '更改版位',
    'Live Fire': 'Live Fire',
    LiveFireStatus: '$t(Live Fire) is {{status}}',
    'Add Asset': '加入$t(Asset)',
    'No Selected Assets': '尚未加入$t(Asset)',
    'Play on all players': '在所有的播放器播放',
    'Stop on all players': '在所有的播放器停止播放',
    Unload: 'Unload',
    'Stop Loop All Assets On This Player': '停止循環播放',
    'Loop Play All Assets On This Player': '開始循環播放所有$t(Asset)',
    'Now Playing': '播放中',
    Play: '播放',
    Stop: '停止播放',
    Loading: '載入中',
    'Live Fire History': 'Live Fire 紀錄',
  },
};
