import { callApi } from 'utils/fetchApi';

const switchToPlayer = async (playerId: number) => {
  const response = await callApi({
    method: 'POST',
    path: `/billboard/player/${playerId}/switch_to_self`,
    withCredentials: true,
    data: { is_manual: true },
  });
  return await response.data;
};

export { switchToPlayer };
