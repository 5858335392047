import { useState, useEffect, useContext } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiPageHeader,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiTreeView,
  EuiTitle,
  EuiHorizontalRule,
  EuiBreadcrumbs,
  EuiSpacer,
  EuiLoadingContent,
  EuiShowFor,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiModalFooter,
} from '@elastic/eui';

import {
  getPropertyList,
  getClientList,
  postProperty,
  patchProperty,
  postClient,
  patchClient,
  getClientAssets,
} from 'apis/Corps/property';
import './style.scss';
import UserContext from 'contexts/UserContext';
import { Property, Client } from 'types/property';
import { Asset } from 'types/asset';
import PropertyEditor from './PropertyEditor';
import ClientEditor from './ClientEditor';
import AssetTable from '../Asset/AssetTable';

const PropertyClient = () => {
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['propertyClient', 'common']);
  const queryClient = useQueryClient();
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(
    null
  );
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [isOpenClientViewer, setIsOpenClientViewer] = useState(false);

  const [isOpenPropertyEditor, setIsOpenPropertyEditor] = useState(false);
  const [isOpenClientEditor, setIsOpenClientEditor] = useState(false);

  const [propertyEditorAction, setPropertyEditorAction] = useState<
    'Create' | 'Edit'
  >('Create');
  const [clientEditorAction, setClientEditorAction] = useState<
    'Create' | 'Edit'
  >('Create');

  const [properties, setProperties] = useState<Property[]>([]);
  const [clients, setClients] = useState<any>([]);
  const pageSize = 15;
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [selectedClientAssets, setSelectedClientAssets] = useState<Asset[]>([]);

  const addItem = (type: string, parentId: number | null): Array<any> => {
    return [
      {
        label: t('Add', `Add ${type}`, { type }),
        id: `add_${type}`,
        icon: <EuiIcon type="plus" />,
        callback: () => {
          if (type === 'Property') {
            setIsOpenPropertyEditor(true);
            setPropertyEditorAction('Create');
            setSelectedProperty(null);
          } else {
            setIsOpenClientEditor(true);
            setClientEditorAction('Create');
            setSelectedClient({
              name: '',
              color: '',
              property: parentId || -1,
            });
          }
          return '';
        },
      },
    ];
  };

  const propertyQuery = useQuery(
    ['property', { organizationId: userContext.currentOrganization?.id }],
    getPropertyList,
    {
      enabled: !!userContext.currentOrganization?.id,
      refetchOnWindowFocus: false,
    }
  );

  const clientQuery = useQuery(['client', {}], getClientList);

  const propertyMutation = useMutation(
    propertyEditorAction == 'Create' ? postProperty : patchProperty,
    {
      onSuccess: async (data: Property) => {
        setSelectedProperty(data);
        queryClient.invalidateQueries('property');
        setIsOpenPropertyEditor(false);
      },
    }
  );

  const clientMutation = useMutation(
    clientEditorAction == 'Create' ? postClient : patchClient,
    {
      onSuccess: async (data: Client) => {
        setSelectedClient(data);
        queryClient.invalidateQueries('client');
        setIsOpenClientEditor(false);
      },
    }
  );

  const setClientAssets = async (client: Client) => {
    if (!client) return;
    const assetData = await getClientAssets(client);
    setSelectedClientAssets(assetData);
  };

  useEffect(() => {
    if (!propertyQuery.isLoading && propertyQuery.isSuccess) {
      setProperties(propertyQuery.data);
    }
    if (!clientQuery.isLoading && clientQuery.isSuccess) {
      setClients(clientQuery.data);
    }
  }, [
    propertyQuery.data,
    propertyQuery.isLoading,
    propertyQuery.isSuccess,
    clientQuery.data,
    clientQuery.isLoading,
    clientQuery.isSuccess,
  ]);

  return (
    <>
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiBreadcrumbs
            breadcrumbs={[{ text: t('common:Management') }]}
            truncate={false}
            aria-label="Breadcrumbs of property and client"
          />

          <EuiPageHeader
            pageTitle={t('Property and Client')}
            bottomBorder={false}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem grow={1} style={{ height: '80vh', overflow: 'auto' }}>
              {propertyQuery.isLoading && <EuiLoadingContent />}

              <EuiTreeView
                className="property-client-tree"
                items={properties
                  .map((p: Property) => ({
                    label: p.name,
                    id: p.id?.toString() || '',
                    icon: <EuiIcon type="arrowRight" />,
                    iconWhenExpanded: <EuiIcon type="arrowDown" />,
                    isExpanded: false,
                    callback: () => {
                      setSelectedProperty({
                        ...p,
                        clients: clients
                          .filter((c: Client) => c.property == p.id)
                          .map((c: Client) => ({
                            label: c.name,
                            id: c.id,
                          })),
                      });
                      setSelectedClient(null);
                      return '';
                    },
                    children: clients
                      .filter((c: Client) => c.property == p.id)
                      .map((c: Client) => ({
                        label: c.name,
                        id: c.id?.toString(),
                        callback: () => {
                          setSelectedClient(c);
                          setClientAssets(c);
                          setIsOpenClientViewer(true);
                          return '';
                        },
                      }))
                      .concat(addItem('Client', p.id || null)),
                  }))
                  .concat(addItem('Property', null))}
                aria-label="Property and Client Tree"
              />
            </EuiFlexItem>
            <EuiFlexItem grow={3} className="eui-hideFor--xs">
              {selectedProperty && (
                <>
                  <div>
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiTitle>
                          <h2>
                            {t('Property')}: {selectedProperty?.name}
                          </h2>
                        </EuiTitle>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiButton
                          onClick={() => {
                            setIsOpenPropertyEditor(true);
                            setPropertyEditorAction('Edit');
                          }}
                        >
                          {t('Edit Property')}
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </div>
                  <EuiHorizontalRule />
                  {selectedClient?.id && (
                    <div>
                      <EuiFlexGroup>
                        <EuiFlexItem>
                          <EuiTitle>
                            <h2>
                              {t('Client')}: {selectedClient?.name}
                            </h2>
                          </EuiTitle>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            onClick={() => {
                              setIsOpenClientEditor(true);
                              setClientEditorAction('Edit');
                            }}
                          >
                            {t('Edit Client')}
                          </EuiButton>
                        </EuiFlexItem>
                      </EuiFlexGroup>

                      <div>{t('Label Color')}</div>
                      <div
                        className="py-2"
                        style={{
                          width: 100,
                          height: 30,
                          backgroundColor: `${selectedClient?.color}`,
                          border: '1px solid white',
                          margin: '4px 0',
                        }}
                      ></div>

                      <EuiSpacer />

                      <EuiTitle size="s">
                        <h3>{t('Assets')}</h3>
                      </EuiTitle>

                      <AssetTable
                        assetList={{
                          results: selectedClientAssets || [],
                          count: selectedClientAssets.length || 0,
                        }}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        isLoading={false}
                        setPageIndex={setPageIndex}
                        readOnly={true}
                      />
                    </div>
                  )}
                </>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      {isOpenPropertyEditor && (
        <PropertyEditor
          action={propertyEditorAction}
          selectedProperty={selectedProperty}
          propertyMutation={propertyMutation}
          onClose={() => {
            setIsOpenPropertyEditor(false);
          }}
        />
      )}
      {isOpenClientEditor && (
        <ClientEditor
          action={clientEditorAction}
          selectedClient={selectedClient}
          clientMutation={clientMutation}
          onClose={() => {
            setIsOpenClientEditor(false);
          }}
        />
      )}
      {selectedClient && isOpenClientViewer && (
        <EuiShowFor sizes={['xs']}>
          <EuiModal onClose={() => setIsOpenClientViewer(false)}>
            <EuiModalHeader>
              <EuiTitle>
                <h2>
                  {t('Client')}: {selectedClient.name}
                </h2>
              </EuiTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    onClick={() => {
                      setIsOpenClientEditor(true);
                      setClientEditorAction('Edit');
                    }}
                  >
                    {t('Edit Client')}
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem>
                  {t('Property')}: {selectedProperty?.name}
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiSpacer />

              <div>{t('Label Color')}</div>
              <div
                className="py-2"
                style={{
                  width: 100,
                  height: 30,
                  backgroundColor: `${selectedClient?.color}`,
                  border: '1px solid white',
                  margin: '4px 0',
                }}
              ></div>

              <EuiSpacer />

              <EuiTitle size="s">
                <h3>{t('Assets')}</h3>
              </EuiTitle>

              <AssetTable
                assetList={{
                  results: selectedClientAssets || [],
                  count: selectedClientAssets.length || 0,
                }}
                pageIndex={pageIndex}
                pageSize={pageSize}
                isLoading={false}
                setPageIndex={setPageIndex}
                readOnly={true}
              />
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButton onClick={() => setIsOpenClientViewer(false)}>
                {t('common:Close')}
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiShowFor>
      )}
    </>
  );
};

export default PropertyClient;
