import { EuiBasicTable } from '@elastic/eui';
import useDurationFormat from 'hooks/useDurationFormat';
import { useTranslation } from 'react-i18next';

const DailyReportTable = ({ log }: { log: any[] }) => {
  const timeformat = useDurationFormat();
  const { t, i18n } = useTranslation(['report', 'common']);

  const columns: any[] = [
    {
      field: 'time',
      name: t('Time'),
      dataType: 'string',
    },
    {
      field: 'asset_name',
      name: t('Name', 'Asset Name', { field: t('common:Asset') }),
      dataType: 'string',
      align: 'right',
    },
    {
      field: 'layer_name',
      name: t('Name', 'Layer Name', { field: t('common:Layer') }),
      dataType: 'string',
      align: 'right',
    },
    {
      field: 'duration',
      name: t('common:Duration'),
      dataType: 'string',
      align: 'right',
      render: (duration: number) => <span> {timeformat(duration)} </span>,
    },
  ];

  return (
    <>
      <EuiBasicTable
        tableCaption="Daily Report Table"
        items={log}
        rowHeader="firstName"
        columns={columns}
      />
    </>
  );
};

export default DailyReportTable;
