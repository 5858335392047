const ColorList = [
  { name: 'ABC News', value: '0096DB' },
  { name: 'ESPN Red', value: 'FC422E' },
  { name: 'Siemens', value: '00AAAD' },
  { name: 'ABC Red Swoosh', value: 'BD0014' },
  { name: 'Yellow', value: 'FFCB00' },
  { name: 'Orange', value: 'FF7900' },
  { name: 'Grapefruit', value: 'FD3B62' },
  { name: 'Blue', value: '0039A6' },
  { name: 'Green', value: '009A49' },
  { name: 'Black', value: '000000' },
  { name: 'White', value: 'FFFFFF' },
  { name: 'Brown', value: 'B28354' },
  { name: 'Fuschia', value: 'D303E6' },
  { name: 'Lilac', value: 'A878D4' },
  { name: 'Gray', value: 'A7A4A4' },
  { name: 'Mint', value: '7CC46C' },
];

export default ColorList;
