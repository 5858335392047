import { createContext, Dispatch, SetStateAction } from 'react';
import { UseQueryResult } from 'react-query';
import { Schedule } from 'types/schedule';

const ScheduleContext = createContext<{
  scheduleQuery: UseQueryResult<Schedule[], unknown> | undefined;
}>({
  scheduleQuery: undefined,
});

export default ScheduleContext;
