import { useState, useRef, useContext } from 'react';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiButtonEmpty,
  EuiFilePicker,
  EuiSpacer,
} from '@elastic/eui';

import UserContext from 'contexts/UserContext';
import UploadContext from 'contexts/UploadContext';
import { uploadFile } from 'utils/fetchApi';
import { getMediaList, getMediaUploadSignedUrl } from 'apis/Scheduler/media';
import { getFontUploadSignedUrl } from 'apis/Scheduler/font';
import { UPLOAD_STATUS } from './const';
import MediaListItem from './MediaListItem';
import FontListItem from './FontListItem';
import { paginatedDataPlaceholder } from 'utils/objects';
import { FileItem } from 'types/upload';

const UploadModal = ({
  type,
  acceptFileType,
  setIsUploaderVisible,
}: {
  type: 'Media' | 'Font';
  acceptFileType: string;
  setIsUploaderVisible: (isVisible: boolean) => void;
}) => {
  const userContext = useContext(UserContext);
  const uploadContext = useContext(UploadContext);
  const { t, i18n } = useTranslation(['media', 'common']);
  const queryClient = useQueryClient();

  const mediaQuery = useQuery(
    [
      'media',
      {
        pageIndex: 0,
        keyword: '',
        organizationId: userContext.currentOrganization?.id,
      },
    ],
    getMediaList,
    {
      keepPreviousData: true,
      initialData: paginatedDataPlaceholder,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!userContext.currentOrganization?.id,
    }
  );

  const mediaListMutation = useMutation(async (newMedia: any) => newMedia, {
    onSuccess: data =>
      queryClient.setQueryData(
        [
          'media',
          {
            pageIndex: 0,
            keyword: '',
            organizationId: userContext.currentOrganization?.id,
          },
        ],
        data
      ),
  });

  const uploaderRef = useRef<any>();

  const [fileItems, setFileItems] = useState<FileItem[]>([]);

  const onChange = (newFiles: FileList | null) => {
    uploaderRef.current.setState({ promptText: null });
    const insertFiles = Array.from(newFiles || []).filter((file: File) => {
      return (
        fileItems.findIndex(existsFile => existsFile.file.name == file.name) <
          0 &&
        (type === 'Media'
          ? file.type.match('video') || file.type.match('image')
          : true) &&
        (type === 'Font' ? file.name.match('.ttf') : true)
      );
    });
    setFileItems([
      ...fileItems,
      ...insertFiles.map((file: File) => {
        return {
          file,
          layer_template_name: null,
        };
      }),
    ]);
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...fileItems];
    newFiles.splice(index, 1);
    setFileItems(newFiles);
  };

  const handleUploadFiles = () => {
    fileItems.forEach(async fileItem => {
      uploadContext.setUploadStatus(current => {
        return {
          ...current,
          [fileItem.file.name]: UPLOAD_STATUS.START,
        };
      });

      if (!userContext.currentOrganization?.id) return;

      const signedUrlResponse =
        type === 'Media'
          ? await getMediaUploadSignedUrl(
              fileItem.file.name,
              userContext.currentOrganization.id,
              fileItem.layer_template_name
            )
          : await getFontUploadSignedUrl(
              fileItem.file.name,
              userContext.currentOrganization.id
            );

      let uploadUrl = signedUrlResponse.policy.url;
      let fields = signedUrlResponse.policy.fields;
      if (signedUrlResponse.private_storage_url) {
        uploadUrl = signedUrlResponse.private_storage_url;
        fields = {
          type,
          policy: JSON.stringify(signedUrlResponse.policy),
        };
      }
      uploadFile(
        signedUrlResponse.method,
        uploadUrl,
        fields,
        fileItem.file,
        (progressEvent: ProgressEvent) => {
          uploadContext.setUploadProgress(current => {
            return {
              ...current,
              [fileItem.file.name]: Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              ),
            };
          });
          uploadContext.setUploadStatus(current => {
            return {
              ...current,
              [fileItem.file.name]: UPLOAD_STATUS.UPLOADING,
            };
          });
        }
      )
        .then(() => {
          uploadContext.setUploadStatus(current => {
            return {
              ...current,
              [fileItem.file.name]: UPLOAD_STATUS.SUCCESSFULLY,
            };
          });
          mediaListMutation.mutate({
            ...mediaQuery.data,
            results: [
              {
                name: fileItem.file.name,
                origin_filename: fileItem.file.name,
                type: fileItem.file.type.match('image') ? 0 : 1,
                duration: '-',
                width: 0,
                height: 0,
                file_size: fileItem.file.size,
              },
              ...mediaQuery.data.results,
            ],
          });
        })
        .catch(e => {
          console.log(e);
          uploadContext.setUploadStatus(current => {
            return {
              ...current,
              [fileItem.file.name]: UPLOAD_STATUS.FAILED,
            };
          });
        });
    });
    uploadContext.setUploadQueue([...uploadContext.uploadQueue, ...fileItems]);
    uploadContext.setIsUploading(true);
    setIsUploaderVisible(false);
  };

  return (
    <EuiModal
      style={{ width: 800 }}
      onClose={() => setIsUploaderVisible(false)}
      initialFocus="[name=popswitch]"
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>
            {t('common:Upload New')} {t(`common:${type}`)}
          </h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiFilePicker
          ref={uploaderRef}
          multiple
          onChange={onChange}
          fullWidth
          accept={acceptFileType}
        />

        <EuiSpacer />

        {fileItems &&
          Array.from(fileItems).map((fileItem: FileItem, index: number) => {
            return type === 'Media' ? (
              <MediaListItem
                fileItem={fileItem}
                key={index}
                index={index}
                handleRemoveFile={handleRemoveFile}
                setLayerTemplateName={(layerTemplateName: string) => {
                  let newFileItems = [...fileItems];
                  newFileItems[index] = {
                    ...newFileItems[index],
                    layer_template_name: layerTemplateName,
                  };
                  setFileItems(newFileItems);
                }}
              />
            ) : (
              <FontListItem
                file={fileItem.file}
                key={index}
                index={index}
                handleRemoveFile={handleRemoveFile}
              />
            );
          })}
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={() => setIsUploaderVisible(false)}>
          {t('common:Cancel')}
        </EuiButtonEmpty>

        <EuiButton
          type="submit"
          form="modalFormId"
          onClick={() => handleUploadFiles()}
          fill
        >
          {t('common:Start Upload')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default UploadModal;
