export const assetTemplate = {
  'zh-TW': {
    Asset: 'Asset',
    'Asset Templates': '$t(Asset)範本',
    'Asset Template': '$t(Asset)範本',
    'Create Asset Template': '建立$t(Asset Templates)',
    'Asset Template Name': '$t(Asset Templates)名稱',
    'No Asset Template found': '找不到$t(Asset Templates)',
    'Delete Asset Template': '刪除$t(Asset Templates)',
    'Edit Asset Template': '編輯$t(Asset Templates)',
    'New Asset Template': '建立$t(Asset Templates)',
    'Duplicate This Asset Template': '複製此$t(Asset Templates)',
    'Add Layer': '新增圖層',
    modal: {
      deleteContent: `所有與此$t(Asset Templates)關聯的播放清單與Schedule都將受影響`,
    },
  },
};
