import { useContext, useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiModalFooter,
  EuiTitle,
  EuiForm,
  EuiFormRow,
  EuiDatePicker,
  EuiFieldNumber,
  EuiSuperSelect,
  EuiButton,
  EuiButtonEmpty,
} from '@elastic/eui';
import moment from 'moment';

import ScheduleContext from 'contexts/ScheduleContext';
import { Screen } from 'types/screen';
import { Schedule, ScheduleContentType } from 'types/schedule';
import { Asset } from 'types/asset';
import { Playlist } from 'types/playlist';
import { getScheduleContentType } from 'apis/Scheduler/schedule';
import { postSchedule, patchSchedule } from 'apis/Scheduler/schedule';
import AssetPicker from 'components/scheduler/AssetPicker';
import PlaylistPicker from 'components/scheduler/PlaylistPicker';
import { getAsset } from 'apis/Scheduler/asset';
import { getPlaylist } from 'apis/Scheduler/playlist';
import FieldDuration from 'components/utils/FieldDuration';

const EditorModal = ({
  screen,
  action,
  onClose,
  schedule,
  editSchedule,
}: {
  screen: Screen;
  action: 'Create' | 'Edit';
  schedule?: Schedule;
  onClose: () => void;
  editSchedule: (action: 'Create' | 'Edit', editingSchedule: Schedule) => void;
}) => {
  const scheduleContext = useContext(ScheduleContext);
  const { t, i18n } = useTranslation(['schedule', 'common']);
  const history = useHistory();

  const [editingSchedule, setEditingSchedule] = useState<Schedule>(
    schedule || {
      start_at: moment().toISOString(),
      end_at: moment().add(30, 'minutes').toISOString(),
      duration: moment.duration(30, 'minutes').asSeconds(),
      priority: 10,
    }
  );

  const [isOpenAssetPicker, setIsOpenAssetPicker] = useState(false);
  const [isOpenPlaylistPicker, setIsOpenPlaylistPicker] = useState(false);

  const scheduleContentTypesQuery = useQuery<ScheduleContentType[]>(
    'schedule_content_types',
    async () => {
      const data = await getScheduleContentType();
      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const handleScheduleContentTypeChange = (value: string) => {
    const selectedContentType = scheduleContentTypesQuery.data?.find(el => {
      return el.model === value;
    });

    if (selectedContentType === undefined || !editingSchedule) {
      return;
    }
    setEditingSchedule({
      ...editingSchedule,
      content_type: selectedContentType,
      content_object: undefined,
    });
  };

  const handleSelectedAsset = async (asset: Asset) => {
    if (!asset.id) return;
    const assetData = await getAsset(asset.id);
    setEditingSchedule({
      ...editingSchedule,
      content_object: assetData,
    });
  };

  const handleSelectedPlaylist = async (playlist: Playlist) => {
    if (!playlist.id) return;
    const playlistData = await getPlaylist(playlist.id);
    setEditingSchedule({
      ...editingSchedule,
      content_object: playlistData,
    });
  };

  useEffect(() => {
    if (schedule) {
      setEditingSchedule({
        ...schedule,
      });
    }
  }, [schedule]);

  return (
    <EuiModal onClose={onClose} className="schedule-function">
      <EuiModalHeader>
        <EuiTitle>
          <h2>
            {action == 'Create'
              ? t(`Add New Schedule`)
              : `${schedule?.content_type?.display_name}: ${schedule?.content_object?.name}`}
          </h2>
        </EuiTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm>
          <EuiFormRow label={t('common:Start At')}>
            <EuiDatePicker
              showTimeSelect
              timeFormat="hh:mm:ss A"
              onChange={date =>
                editingSchedule
                  ? setEditingSchedule({
                      ...editingSchedule,
                      start_at: date?.toISOString() || moment().toISOString(),
                      duration:
                        moment(editingSchedule?.end_at).diff(moment(date)) /
                        1000,
                    })
                  : {}
              }
              selected={moment(editingSchedule?.start_at)}
            />
          </EuiFormRow>
          <EuiFormRow label={t('common:Duration')}>
            <FieldDuration
              value={
                moment(editingSchedule?.end_at).diff(
                  moment(editingSchedule?.start_at)
                ) / 1000
              }
              setParsedValue={(seconds: number) =>
                editingSchedule
                  ? setEditingSchedule({
                      ...editingSchedule,
                      end_at: moment(editingSchedule.start_at)
                        .add(seconds, 'seconds')
                        .toISOString(),
                      duration: seconds,
                    })
                  : {}
              }
            />
          </EuiFormRow>
          <EuiFormRow label={t('common:End At')}>
            <EuiDatePicker
              showTimeSelect
              timeFormat="hh:mm:ss A"
              onChange={date =>
                editingSchedule
                  ? setEditingSchedule({
                      ...editingSchedule,
                      end_at: date?.toISOString() || moment().toISOString(),
                      duration:
                        moment(date).diff(moment(editingSchedule?.start_at)) /
                        1000,
                    })
                  : {}
              }
              selected={moment(editingSchedule?.end_at)}
            />
          </EuiFormRow>
          <EuiFormRow
            label={t('Priority')}
            helpText={t('The lower value the higher priority')}
          >
            <EuiFieldNumber
              onChange={e =>
                editingSchedule
                  ? setEditingSchedule({
                      ...editingSchedule,
                      priority: parseInt(e.target.value),
                    })
                  : {}
              }
              value={editingSchedule.priority}
            />
          </EuiFormRow>
          <EuiFormRow label={t('Content Type')}>
            <EuiSuperSelect
              valueOfSelected={editingSchedule?.content_type?.model}
              onChange={handleScheduleContentTypeChange}
              aria-label="Content Type"
              options={
                scheduleContentTypesQuery.isSuccess
                  ? scheduleContentTypesQuery.data
                      ?.sort((a, b) => a.id - b.id)
                      .map(layerContentType => {
                        return {
                          value: layerContentType.model,
                          inputDisplay: (
                            <span>{layerContentType.display_name}</span>
                          ),
                        };
                      })
                  : []
              }
            />
          </EuiFormRow>

          {editingSchedule?.content_type?.model && (
            <EuiFormRow label={t('Content')}>
              <>
                {editingSchedule.content_object && (
                  <>
                    <div style={{ padding: '8px 4px', fontSize: '20px' }}>
                      {editingSchedule.content_type.display_name}:&#160;
                      <EuiButtonEmpty
                        color="ghost"
                        size="s"
                        onClick={() =>
                          history.push(
                            `/${editingSchedule?.content_type?.model}s/${editingSchedule.content_object?.id}/edit`,
                            {
                              schedule: editingSchedule,
                            }
                          )
                        }
                      >
                        {editingSchedule.content_object.name}
                      </EuiButtonEmpty>
                    </div>
                  </>
                )}

                <EuiButton
                  fullWidth
                  onClick={() =>
                    editingSchedule?.content_type?.model == 'asset'
                      ? setIsOpenAssetPicker(true)
                      : setIsOpenPlaylistPicker(true)
                  }
                >
                  {t('Select')} {editingSchedule?.content_type.display_name}
                </EuiButton>
              </>
            </EuiFormRow>
          )}

          {isOpenAssetPicker && (
            <AssetPicker
              isMultiple={false}
              setIsOpenAssetPicker={setIsOpenAssetPicker}
              handleFinishPickingAssets={assets =>
                handleSelectedAsset(assets[0])
              }
            />
          )}

          {isOpenPlaylistPicker && (
            <PlaylistPicker
              isMultiple={false}
              setIsOpenPlaylistPicker={setIsOpenPlaylistPicker}
              handleFinishPickingPlaylists={playlists =>
                handleSelectedPlaylist(playlists[0])
              }
            />
          )}
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton
          fill
          onClick={() => {
            editSchedule(action, editingSchedule);
          }}
          disabled={!editingSchedule.content_object}
        >
          {action == 'Create' ? t('common:Add') : t('common:Save')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default EditorModal;
