import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  EuiButtonEmpty,
  EuiContextMenuPanel,
  EuiContextMenuItem,
  EuiPopover,
  useGeneratedHtmlId,
  EuiIcon,
} from '@elastic/eui';

type LinkItem = {
  name: string;
  link: string;
};

const ContextMenu = ({
  name,
  items = [],
}: {
  name: string;
  items?: LinkItem[];
}) => {
  const history = useHistory();

  const [isPopoverOpen, setPopover] = useState(false);
  const [focusedItemIndex, setFocusedItemIndex] = useState(-1);

  const singleContextMenuPopoverId = useGeneratedHtmlId({
    prefix: 'singleContextMenuPopover',
  });

  const onButtonClick = () => {
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const button = (
    <EuiButtonEmpty size="s" color="ghost" onClick={onButtonClick}>
      {name} <EuiIcon type="arrowDown" />
    </EuiButtonEmpty>
  );

  const handleRedirect = (path: string, index: number) => {
    history.push(path);
    setFocusedItemIndex(index);
    closePopover();
  };

  return (
    <EuiPopover
      id={singleContextMenuPopoverId}
      button={button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="downLeft"
      style={{ paddingLeft: '20px', paddingRight: '20px' }}
      initialFocus={false}
    >
      <EuiContextMenuPanel
        initialFocusedItemIndex={focusedItemIndex}
        size="s"
        items={items.map((item, index) => (
          <EuiContextMenuItem
            key={item.name}
            onClick={() => handleRedirect(item.link, index)}
          >
            {item.name}
          </EuiContextMenuItem>
        ))}
      />
    </EuiPopover>
  );
};

export default ContextMenu;
