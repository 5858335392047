import { EuiEmptyPrompt } from '@elastic/eui';

import CenteredContentPage from 'layouts/CenteredContentPage';

const NotFound = () => {
  return (
    <CenteredContentPage>
      <EuiEmptyPrompt
        title={<h2>Oops!</h2>}
        body={
          <>
            <h1>404 Not Found</h1>
          </>
        }
      />
    </CenteredContentPage>
  );
};

export default NotFound;
