import { useReactToPrint, IReactToPrintProps } from 'react-to-print';
import download from 'downloadjs';
import { ReactInstance } from 'react';

export default function useReportExport(
  content: ReactInstance,
  mode: 'print' | 'word',
  name?: string
) {
  let printProps: IReactToPrintProps = {
    content: () => content,
    bodyClass: 'report-print',
  };
  if (mode == 'word') {
    printProps = {
      ...printProps,
      print: async (printIframe: HTMLIFrameElement) => {
        printIframe.contentDocument
          ?.querySelectorAll('.euiTableRowCell--hideForDesktop, .euiButton')
          .forEach(child => child.remove());
        const table = printIframe.contentDocument?.getElementsByTagName(
          'table'
        );
        if (table)
          for (let i = 0; i < table.length; i++) {
            table[i].setAttribute('style', 'width: 100%');
            table[i].setAttribute('border', '1');
          }
        var blob = new Blob(
          [printIframe.contentDocument?.body.innerHTML || ''],
          {
            type: 'text/plain;charset=utf-8;',
          }
        );
        download(blob, `${name}.doc`);
      },
    };
  }
  return useReactToPrint(printProps);
}
