import { Playlist } from 'types/playlist';
import { callApi } from 'utils/fetchApi';

const getPlaylistList = async ({ queryKey }: any) => {
  const [_key, { organizationId, pageIndex, keyword }] = queryKey;
  const response = await callApi({
    method: 'GET',
    path:
      `/scheduler/playlist?limit=15` +
      `${organizationId ? `&organization=${organizationId}` : ''}` +
      `${keyword ? `&search=${keyword}` : ''}` +
      `&offset=${pageIndex * 15}`,
    withCredentials: true,
  });

  return await response.data;
};

const getPlaylist = async (playlistId: number) => {
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/playlist/${playlistId}`,
    withCredentials: true,
  });

  return await response.data;
};

const getPlaylists = async (playlistIds: number[]) => {
  console.log(`getPlaylists ${playlistIds.join(',')}`);
  const response = await callApi({
    method: 'GET',
    path: `/scheduler/playlist?ids=${playlistIds.join(',')}`,
    withCredentials: true,
  });

  return await response.data;
};

const postPlaylist = async (playlist: Playlist) => {
  const response = await callApi({
    method: 'POST',
    path: `/scheduler/playlist`,
    withCredentials: true,
    data: serializePlaylist(playlist),
  });

  return await response.data;
};

const patchPlaylist = async (playlist: Playlist) => {
  const response = await callApi({
    method: 'PATCH',
    path: `/scheduler/playlist/${playlist.id}`,
    withCredentials: true,
    data: serializePlaylist(playlist),
  });

  return await response.data;
};

const deletePlaylist = async (playlistId?: number) => {
  const response = await callApi({
    method: 'DELETE',
    path: `/scheduler/playlist/${playlistId}`,
    withCredentials: true,
  });

  return await response.data;
};

const serializePlaylist = (playlist: Playlist) => {
  let playlistData: any = {
    ...playlist,
    assets: playlist.assets.map((asset: Playlist['assets'][0]) => {
      return {
        asset: asset.asset.id,
        index: asset.index,
      };
    }),
  };
  return playlistData;
};

export {
  getPlaylistList,
  getPlaylist,
  getPlaylists,
  postPlaylist,
  patchPlaylist,
  deletePlaylist,
};
