import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EuiFlyout,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFlyoutBody,
  EuiDescriptionList,
  EuiHorizontalRule,
  EuiSpacer,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui';

import { Asset, Layer } from 'types/asset';
import LayerList from './AssetEditor/LayerList';
import AssetPreviewer from './AssetEditor/AssetPreviewer';
import { useHistory } from 'react-router-dom';
import useDurationFormat from 'hooks/useDurationFormat';

const AssetFlyout = ({
  onClose,
  asset,
  enterFromParent = false,
  readOnly = false,
}: {
  onClose: () => void;
  asset: Asset;
  enterFromParent?: boolean;
  readOnly?: boolean;
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation(['asset', 'common']);
  const [containerWidth, setContainerWidth] = useState(0);
  const durationFormat = useDurationFormat();

  const onRefChange = useCallback(node => {
    if (node) setContainerWidth(node.offsetWidth);
  }, []);

  if (!asset) return <></>;

  return (
    <EuiFlyout ownFocus onClose={onClose} aria-labelledby="Asset Flyout">
      <EuiFlyoutHeader hasBorder>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="m">
              <h2>
                {enterFromParent ? 'Asset: ' : ' '} {asset.name}
              </h2>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {!readOnly && (
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiButton
                    onClick={() => history.push(`/assets/${asset.id}/edit`)}
                  >
                    {t('Edit Asset')}
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiButton
                    onClick={() => history.push('/assets/create', { asset })}
                  >
                    {t('Duplicate This Asset')}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiDescriptionList
          listItems={[
            {
              title: `${t('common:Duration')}`,
              description: `${durationFormat(asset.duration)}`,
            },
            {
              title: `${t('Air date start')}`,
              description: asset.air_date_start || '--',
            },
            {
              title: `${t('Air date end')}`,
              description: asset.air_date_end || '--',
            },
          ]}
        />
        <EuiSpacer />
        <EuiHorizontalRule />
        <div ref={onRefChange} style={{ width: '100%' }}>
          <AssetPreviewer
            containerWidth={containerWidth * 0.8}
            containerHeight={containerWidth}
            layers={asset.layers}
          />
        </div>
        <LayerList
          layers={asset.layers}
          edit={false}
          asset={asset}
          disableDrag
        />
      </EuiFlyoutBody>
    </EuiFlyout>
  );
};

export default AssetFlyout;
