import { useEffect } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import '@elastic/eui/dist/eui_theme_dark.css';

import { firebaseAuth } from '../../firebase';

interface ComponentProps {
  children: React.ReactNode;
  path?: string;
}

const RedirectIfAuthenticated = ({ children, ...rest }: ComponentProps) => {
  const history = useHistory();
  const location = useLocation<{ intend: string }>();

  useEffect(() => {
    const firebaseUnsubscribe = firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        if (!user.emailVerified) {
          history.replace('/verify_pending');
        } else {
          if (location.state && location.state.intend) {
            history.replace(location.state.intend);
          } else {
            history.replace('/dashboard');
          }
        }
      }
    });
    return () => firebaseUnsubscribe();
  }, [history, location.state]);

  return <Route {...rest}>{children}</Route>;
};

export default RedirectIfAuthenticated;
