import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiBreadcrumbs,
  EuiButton,
  EuiPageHeader,
  EuiSpacer,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiAvatar,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiTitle,
  EuiModalFooter,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiButtonEmpty,
  EuiButtonEmptyColor,
} from '@elastic/eui';

import UserContext from 'contexts/UserContext';
import { Member, Organization, Roles } from 'types/organization';
import {
  getRoles,
  getOrganization,
  addOrganizationMember,
  updateMemberRole,
} from 'apis/Corps/orgranization';

const ActionButton = ({
  member,
  handler,
  text,
  color,
  disabled,
}: {
  member: Member;
  handler: (userId: number) => Promise<void>;
  text: string;
  color: EuiButtonEmptyColor;
  disabled: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAction = async () => {
    setIsLoading(true);
    await handler(member.user.id);
    setIsLoading(false);
  };

  return (
    <EuiButtonEmpty
      isLoading={isLoading}
      disabled={disabled}
      size="xs"
      onClick={() => handleAction()}
      color={color}
    >
      {text}
    </EuiButtonEmpty>
  );
};

const Members = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['member', 'common']);

  const [isAddMemberModalVisible, setIsAddMemberModalVisible] = useState(false);
  const [email, setEmail] = useState('');

  const organizationQuery = useQuery<Organization>(
    ['organization', parseInt(organizationId)],
    async () => {
      if (!parseInt(organizationId)) return;
      return await getOrganization(parseInt(organizationId));
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const rolesQuery = useQuery<Roles[]>(
    'roles',
    async () => {
      return await getRoles();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const breadcrumbs = [
    {
      text: userContext.currentOrganization?.name,
    },
  ];

  const columns: EuiBasicTableColumn<any>[] = [
    {
      field: 'user.full_name',
      name: '',
      dataType: 'string',
      width: '48px',
      render: (full_name: string) =>
        full_name ? (
          <EuiAvatar name={full_name} size="m" />
        ) : (
          <EuiAvatar name="?" size="m" />
        ),
    },
    {
      field: 'user.full_name',
      name: t('User'),
      dataType: 'string',
      render: (full_name: string) =>
        full_name || (
          <>
            {'Unknown User '}
            <i>(Invite Pending)</i>
          </>
        ),
    },
    {
      field: 'user.email',
      name: 'Email',
      dataType: 'string',
    },
    {
      field: 'role',
      name: t('Role'),
      render: (role: number) => <>{rolesMaping[role]}</>,
    },
  ];

  const rolesMaping = useMemo(() => {
    const roles: { [id: number]: string } = {};
    if (!rolesQuery.isSuccess) return roles;
    rolesQuery.data?.forEach(role => (roles[role.value] = role.name));
    return roles;
  }, [rolesQuery.data, rolesQuery.isSuccess]);

  const isAdmin = useMemo(() => {
    if (!userContext.currentUser?.organizations) return false;
    return (
      userContext.currentUser?.organizations.findIndex(
        organization =>
          organization.organization === parseInt(organizationId) &&
          organization.role <= 1
      ) > -1
    );
  }, [organizationId, userContext.currentUser?.organizations]);

  const handleDisableUser = async (userId: number) => {
    if (!userContext.currentOrganization?.id) return;
    await updateMemberRole(userContext.currentOrganization.id, userId, 99);
    await organizationQuery.refetch();
  };

  const handleEnableUser = async (userId: number) => {
    if (!userContext.currentOrganization?.id) return;
    await updateMemberRole(userContext.currentOrganization.id, userId, 2);
    await organizationQuery.refetch();
  };

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={breadcrumbs}
        truncate={false}
        aria-label="An example of EuiBreadcrumbs"
      />
      <EuiSpacer size="xs" />
      <EuiPageHeader
        role=""
        pageTitle={t('Members')}
        rightSideItems={[
          <EuiButton onClick={() => setIsAddMemberModalVisible(true)}>
            {t('Add Member')}
          </EuiButton>,
        ]}
      />

      <EuiBasicTable
        columns={columns.concat(
          isAdmin
            ? [
                {
                  field: 'user.id',
                  name: '',
                  dataType: 'number',
                  align: 'right',
                  width: '150px',
                  render: (userId: number, member: Member) => (
                    <ActionButton
                      text={t('Disable User')}
                      color="danger"
                      member={member}
                      handler={() => handleDisableUser(userId)}
                      disabled={
                        member.role >= 99 ||
                        member.user.id === userContext.currentUser?.id
                      }
                    />
                  ),
                },
                {
                  field: 'user.id',
                  name: '',
                  dataType: 'number',
                  align: 'right',
                  width: '150px',
                  render: (userId: number, member: Member) => (
                    <ActionButton
                      text={t('Enable User')}
                      color="success"
                      member={member}
                      handler={() => handleEnableUser(userId)}
                      disabled={
                        member.role < 99 ||
                        member.user.id === userContext.currentUser?.id
                      }
                    />
                  ),
                },
              ]
            : []
        )}
        items={organizationQuery.data?.members.map(member => member) || []}
      />

      {isAddMemberModalVisible && (
        <EuiModal onClose={() => setIsAddMemberModalVisible(false)}>
          <EuiModalHeader>
            <EuiTitle>
              <h2>{t('Add Member')}</h2>
            </EuiTitle>
          </EuiModalHeader>
          <EuiForm>
            <EuiModalBody>
              <EuiFormRow label="Email">
                <EuiFieldText
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </EuiFormRow>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButton
                onClick={async () => {
                  try {
                    if (!organizationId || !email) return;
                    await addOrganizationMember(
                      parseInt(organizationId),
                      email
                    );
                    organizationQuery.refetch();
                  } catch (error) {
                    console.error(error.code);
                    console.error(error.message);
                  }
                  setIsAddMemberModalVisible(false);
                }}
              >
                {t('common:Confirm')}
              </EuiButton>
            </EuiModalFooter>
          </EuiForm>
        </EuiModal>
      )}
    </>
  );
};

export default Members;
