import { callApi } from 'utils/fetchApi';

const getOrderList = async ({ queryKey }: any) => {
  const [_key, { organizationId, pageIndex, keyword }] = queryKey;

  const response = await callApi({
    method: 'GET',
    path:
      `/sell/order?limit=15` +
      `${organizationId ? `&organization=${organizationId}` : ''}` +
      `${keyword ? `&search=${keyword}` : ''}` +
      `&offset=${pageIndex ? pageIndex * 15 : 0}`,
    withCredentials: true,
  });

  return await response.data;
};

export { getOrderList };
