const prettyFileSize = (fileSize: number) => {
  if (fileSize < 1024) {
    return `${fileSize} B`;
  } else if (fileSize < 1024 * 1024) {
    return `${Math.round(fileSize / 1024)} KB`;
  } else if (fileSize < 1024 * 1024 * 1024) {
    return `${Math.round(fileSize / (1024 * 1024))} MB`;
  } else {
    return `${Math.round(fileSize / (1024 * 1024 * 1024))} GB`;
  }
};

export { prettyFileSize };
