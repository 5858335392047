import { createContext, Dispatch, SetStateAction } from 'react';
import { Organization } from 'types/organization';
import { User } from 'types/user';

const UserContext = createContext<{
  currentOrganization?: Organization;
  setCurrentOrganization: Dispatch<SetStateAction<Organization | undefined>>;
  currentUser?: User;
  setCurrentUser: Dispatch<SetStateAction<User | undefined>>;
}>({
  currentOrganization: undefined,
  setCurrentOrganization: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
});

export default UserContext;
