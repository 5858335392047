const MEDIA_TYPES: { [key: string]: number } = {
  VIDEO: 1,
  IMAGE: 2,
};

const MEDIA_TYPE_NAMES: { [type: string]: string } = {
  [MEDIA_TYPES.VIDEO]: 'Video',
  [MEDIA_TYPES.IMAGE]: 'Image',
};

export { MEDIA_TYPES, MEDIA_TYPE_NAMES };
