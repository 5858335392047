import { useContext } from 'react';
import { EuiCollapsibleNavGroup, EuiListGroup, EuiSideNav } from '@elastic/eui';

import UserContext from 'contexts/UserContext';
import './SideNav.scss';

const SideNav = ({
  isSideNavOpenOnMobile,
  setIsSideNavOpenOnMobile,
  handleRedirect,
  selectedItemName,
}: {
  isSideNavOpenOnMobile: boolean;
  setIsSideNavOpenOnMobile: (isOpen: boolean) => void;
  handleRedirect: (path: string, name: string) => void;
  selectedItemName: string;
}) => {
  const userContext = useContext(UserContext);

  const toggleOpenOnMobile = () => {
    setIsSideNavOpenOnMobile(!isSideNavOpenOnMobile);
  };

  const createItem = (name: string, path: string, data = {}) => {
    // NOTE: Duplicate `name` values will cause `id` collisions.
    return {
      ...data,
      id: name,
      name,
      isSelected: selectedItemName === name,
      onClick: () => handleRedirect(path, name),
    };
  };

  return (
    <>
      <EuiSideNav
        className="signage-management-nav"
        aria-label="Signage Management Navigator"
        mobileTitle="Signage Management"
        toggleOpenOnMobile={toggleOpenOnMobile}
        isOpenOnMobile={isSideNavOpenOnMobile}
        items={[
          {
            id: 'Billboard',
            name: 'Billboard',
            items: [
              createItem('Screens', '/screens'),
              createItem('Live Control', '/live_control'),
            ],
          },
          {
            id: 'Scheduling',
            name: 'Scheduling',
            items: [
              createItem('Media', '/media'),
              createItem('Fonts', '/fonts'),
              createItem('Layer Templates', '/layer_template'),
              createItem('Asset', '/assets'),
              createItem('Playlist', '/playlists'),
              createItem('Schedule', '/schedule'),
              createItem('Default Settings', '/default_settings'),
            ],
          },
          {
            id: 'Management',
            name: 'Management',
            items: [
              createItem('Inquiry', 'inquiry'),
              createItem('Reporting', '/reporting'),
              createItem('Property/Client', '/property_and_client'),
            ],
          },
        ]}
      />

      <EuiCollapsibleNavGroup
        title={
          <span>
            <strong>{userContext.currentOrganization?.name}</strong>
          </span>
        }
        isCollapsible={true}
        initialIsOpen={true}
        background="dark"
      >
        <EuiListGroup
          listItems={[
            {
              label: 'Organization Settings',
              onClick: () =>
                handleRedirect(
                  `/organization/${userContext.currentOrganization?.id}/settings`,
                  'Organization Settings'
                ),
            },
            {
              label: 'Members',
              onClick: () =>
                handleRedirect(
                  `/organization/${userContext.currentOrganization?.id}/members`,
                  'Members'
                ),
            },
          ]}
        />
      </EuiCollapsibleNavGroup>
    </>
  );
};

export default SideNav;
