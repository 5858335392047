export const schedule = {
  'zh-TW': {
    'Change Screen': '更改版位',
    'Cancel Changes': '取消變更',
    'Add New Schedule': '增加新Schedule',
    Priority: 'Priority',
    'The lower value the higher priority': '值越低priority越高',
    'Content Type': 'Content Type',
    Content: 'Content',
    Select: '選擇',
    'Please select a screen to manage its playback schedule':
      'Please select a screen to manage its playback schedule',
    'Sorry, there was an error': '發生錯誤',
    Add: '新增',
    Edit: '編輯',
    Copy: '複製',
    Cut: '剪下',
    Insert: '插入',
    Replace: '替換',
    Remove: '移除',
    'Hot Keys': '快捷鍵',
    Assign: 'Assign',
    'Assign Hot Key': '指派快捷鍵',
    'Change Color': '更改顏色',
  },
};
