import { useState, useContext } from 'react';
import {
  EuiBottomBar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiButtonEmpty,
  EuiPopover,
  EuiPanel,
  EuiProgress,
  EuiIcon,
  EuiText,
  EuiLoadingSpinner,
} from '@elastic/eui';

import { UPLOAD_STATUS } from './const';
import UploadContext from 'contexts/UploadContext';
import { FileItem } from 'types/upload';

const UploadStateBar = () => {
  const uploadContext = useContext(UploadContext);

  const [isUploadQueueShown, setIsUploadQueueShown] = useState(false);

  const clearUploadQueue = () => {
    uploadContext.setIsUploading(false);
    uploadContext.setUploadQueue([]);
  };

  if (uploadContext.isUploading) {
    return (
      <EuiBottomBar>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false}>
              {(uploadContext.uploadQueue || []).findIndex(
                (fileItem: FileItem) =>
                  uploadContext.uploadStatus[fileItem.file.name] ==
                  UPLOAD_STATUS.UPLOADING
              ) >= 0 && (
                <>
                  <EuiFlexItem grow={false}>
                    <EuiLoadingSpinner size="m" />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText color="secondary">
                      Uploading{' '}
                      {
                        uploadContext.uploadQueue?.filter(
                          (fileItem: FileItem) =>
                            uploadContext.uploadStatus[fileItem.file.name] ==
                            UPLOAD_STATUS.UPLOADING
                        ).length
                      }{' '}
                      files...
                    </EuiText>
                  </EuiFlexItem>
                </>
              )}
              {(uploadContext.uploadQueue || []).findIndex(
                (fileItem: FileItem) =>
                  uploadContext.uploadStatus[fileItem.file.name] ==
                  UPLOAD_STATUS.FAILED
              ) >= 0 && (
                <>
                  <EuiFlexItem grow={false}>
                    <EuiIcon type="alert" color="warning" />
                  </EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <EuiText color="warning">Some upload went wrong.</EuiText>
                  </EuiFlexItem>
                </>
              )}
              {(uploadContext.uploadQueue || []).every(
                (fileItem: FileItem) =>
                  uploadContext.uploadStatus[fileItem.file.name] ==
                  UPLOAD_STATUS.SUCCESSFULLY
              ) && (
                <>
                  <EuiFlexItem grow={false}>
                    <EuiIcon type="check" color="success" />
                  </EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <EuiText color="secondary">All file uploaded.</EuiText>
                  </EuiFlexItem>
                </>
              )}
              <EuiFlexItem grow={false}>
                <EuiPopover
                  panelPaddingSize="s"
                  button={
                    <EuiButton
                      color="primary"
                      fill
                      size="s"
                      onClick={() => {
                        setIsUploadQueueShown(!isUploadQueueShown);
                      }}
                    >
                      View Upload Queue
                    </EuiButton>
                  }
                  isOpen={isUploadQueueShown}
                  closePopover={() => setIsUploadQueueShown(false)}
                  repositionOnScroll={true}
                >
                  {uploadContext.uploadQueue?.map(
                    (fileItem: FileItem, index: number) => (
                      <EuiFlexGroup
                        key={index}
                        direction="column"
                        style={{ width: 320 }}
                        gutterSize="s"
                      >
                        <EuiFlexItem>
                          <EuiPanel
                            hasBorder={false}
                            hasShadow={false}
                            paddingSize="s"
                          >
                            <EuiFlexGroup
                              justifyContent="spaceBetween"
                              responsive={false}
                            >
                              <EuiFlexItem
                                grow={false}
                                style={{ width: '60%' }}
                              >
                                <EuiText
                                  size="xs"
                                  style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {fileItem.file.name}
                                </EuiText>
                              </EuiFlexItem>
                              <EuiFlexItem grow={false}>
                                <EuiText size="xs">
                                  {
                                    uploadContext.uploadStatus[
                                      fileItem.file.name
                                    ]
                                  }
                                </EuiText>
                              </EuiFlexItem>
                            </EuiFlexGroup>
                            <EuiProgress
                              value={
                                uploadContext.uploadProgress[fileItem.file.name]
                              }
                              max={100}
                              valueText
                              size="xs"
                            />
                          </EuiPanel>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    )
                  )}
                </EuiPopover>
              </EuiFlexItem>
              {(uploadContext.uploadQueue || []).findIndex(
                (fileItem: FileItem) =>
                  uploadContext.uploadStatus[fileItem.file.name] ==
                  UPLOAD_STATUS.UPLOADING
              ) < 0 && (
                <EuiFlexItem>
                  <EuiButtonEmpty
                    color="ghost"
                    size="s"
                    iconType="cross"
                    onClick={() => clearUploadQueue()}
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiBottomBar>
    );
  }

  return <></>;
};

export default UploadStateBar;
