import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  EuiButton,
  EuiPageHeader,
  EuiFlexGroup,
  EuiFlexGrid,
  EuiFlexItem,
  EuiCard,
  EuiAspectRatio,
  EuiImage,
  EuiBreadcrumbs,
  EuiLoadingSpinner,
} from '@elastic/eui';
import { useHistory } from 'react-router-dom';

import { getScreenList } from 'apis/Billboard/screen';
import UserContext from 'contexts/UserContext';

const ScreenIndex = () => {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const { t, i18n } = useTranslation(['screen', 'common']);

  const screenQuery = useQuery(
    ['screen', { organizationId: userContext.currentOrganization?.id }],
    getScreenList,
    {
      enabled: !!userContext.currentOrganization?.id,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return (
    <>
      <EuiBreadcrumbs
        breadcrumbs={[{ text: t('common:Billboard') }]}
        truncate={false}
        aria-label="Breadcrumbs of screen list"
      />

      <EuiPageHeader
        pageTitle={t('Screens')}
        rightSideItems={[
          <EuiButton onClick={() => history.push('/screens/create')}>
            {t('Create Screen')}
          </EuiButton>,
        ]}
      />

      {screenQuery.isLoading && <EuiLoadingSpinner size="xl" />}

      <EuiFlexGrid columns={3} gutterSize="l">
        {screenQuery.isSuccess ? (
          screenQuery.data.map((screen: any) => {
            return (
              <EuiFlexItem>
                <EuiCard
                  textAlign="left"
                  image={
                    <div>
                      {screen.image_url && (
                        <EuiAspectRatio width={16} height={9}>
                          <EuiImage
                            src={screen.image_url}
                            alt={`Screen photo of ${screen.name}`}
                          />
                        </EuiAspectRatio>
                      )}
                    </div>
                  }
                  title={screen.name}
                  description={screen.description}
                  footer={
                    <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiButton
                          onClick={() =>
                            history.push(`/live_control`, {
                              screen: screen,
                            })
                          }
                        >
                          {t('Live Control')}
                        </EuiButton>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiButton
                          onClick={() =>
                            history.push(`/schedule`, {
                              screen: screen,
                            })
                          }
                        >
                          {t('common:Schedule')}
                        </EuiButton>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiButton
                          onClick={() => history.push(`/screens/${screen.id}`)}
                        >
                          {t('View')}
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  }
                />
              </EuiFlexItem>
            );
          })
        ) : (
          <></>
        )}
      </EuiFlexGrid>
    </>
  );
};

export default ScreenIndex;
